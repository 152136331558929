import ErrorPage from './_errorPage';
import InfoPage from './_InfoPage';
import LoadingPage from './_loadingPage';
import Prevent from './_prevent';
import Mount from './_mount';
import SuccessPage from './_successPage';

const Template = () => <></>;
const Pages = () => <></>;
Template.Pages = Pages;
Template.Mount = Mount;
Template.Prevent = Prevent;
Pages.ErrorPage = ErrorPage;
Pages.InfoPage = InfoPage;
Pages.LoadingPage = LoadingPage;
Pages.SuccessPage = SuccessPage;

export default Template;
