import { Container, Row } from 'react-bootstrap';
import NavBar from './NavBar';
import SideBar from './SideBar';

interface ChildrenElement {
  children?: JSX.Element | JSX.Element[] | HTMLElement;
  // eslint-disable-next-line react/require-default-props
  showLoggedComponents?: boolean;
}

const Layout = ({ children, showLoggedComponents = true }: ChildrenElement) => (
  <Container fluid>
    <div className="d-flex flex-column w-100 min-h-100">
      <Row className="">
        <NavBar showLoggedComponents={showLoggedComponents} />
      </Row>
      <Row className="vh-100">
        <main className="d-flex p-0">
          {showLoggedComponents && <SideBar />}
          {children}
        </main>
      </Row>
    </div>
  </Container>
);

export default Layout;
