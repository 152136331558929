import useRequest from '../../../../@shared/Requests/useRequest';
import FailureToast from '../../../../@shared/Toasts/failureToast';
import SuccessToast from '../../../../@shared/Toasts/successToast';
import LoadingModal from '../../../auth/features/ForgotDialogues/LoadingModal';
import userEndpoints from '../../user.endpoints';
import ResetButton from './resetButton';

interface ResetButtonProps {
  userId: number;
}

const Index = ({ userId }: ResetButtonProps) => {
  const { fetch, success, error, loading, reset } = useRequest();

  return (
    <>
      {loading && <LoadingModal />}
      <ResetButton fetch={() => fetch(userEndpoints.sendEmailOfResetPasswordById(userId))} />
      {success && (
        <SuccessToast
          show={success}
          text="Sucesso ao resetar a senha do usuário"
          onShow={() => {
            setTimeout(() => {
              reset();
            }, 9000);
          }}
        />
      )}
      {error && (
        <FailureToast
          show={error}
          text="Falha ao resetar senha do usuário"
          innerFunctionWhenShow={() => reset()}
        />
      )}
    </>
  );
};

export default Index;
