import LoadingPage from '../template/_loadingPage';
import { LoadingContext } from './useLoadingGroup';
import useLoadingProvider from './useLoadingGroupProvider';

interface LoadingContextProps {
  children: JSX.Element[] | JSX.Element;
}

const LoadingGroup = ({ children }: LoadingContextProps) => {
  const context = useLoadingProvider();

  return (
    <LoadingContext.Provider value={context}>
      <div className={context.loading ? 'h-100 w-100' : 'd-none h-100 w-100'}>
        <LoadingPage />
      </div>
      <div className={!context.loading ? 'h-100 w-100' : 'd-none h-100 w-100'}>{children}</div>
    </LoadingContext.Provider>
  );
};
export default LoadingGroup;
