import { useForm } from 'react-hook-form';
import useDataGridCheckBox from './useDataGridCheckBox.hook';
import UserCategoryDataGridPresentation from './userCategoryDataGrid.presentation';
import { UserCategoryDataGridControlProps } from './userCategoryDataGrid.types';

const UserCategoryDataGridControl = ({
  control,
  name,
  className,
  defaultValue,
  style,
  rows: rowsProps,
}: UserCategoryDataGridControlProps) => {
  const { control: defaultControl } = useForm();
  const { onChange, value } = useDataGridCheckBox(control ?? defaultControl, name, defaultValue);

  return (
    <UserCategoryDataGridPresentation
      rows={rowsProps}
      className={className}
      value={value}
      onChange={onChange}
      style={style}
      name={name}
    />
  );
};

export default UserCategoryDataGridControl;
