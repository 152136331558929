import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';

interface IFailureToast {
  show: boolean;
  text: string;
  // eslint-disable-next-line react/require-default-props
  innerFunctionWhenShow?: Function;
}

const FailureToast = ({ text, show: fail, innerFunctionWhenShow }: IFailureToast) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (fail) setShow(true);
  }, [fail]);

  useEffect(() => {
    if (innerFunctionWhenShow !== undefined && !show) {
      innerFunctionWhenShow();
    }
  }, [show]);

  return (
    <ToastContainer
      position="bottom-start"
      className="p-3 position-fixed"
      style={{ zIndex: 99999 }}
    >
      <Toast onClose={() => setShow(false)} show={show} delay={8000} autohide>
        <Toast.Header>
          <div className="p-2 ps-0">
            <XCircleFill className="pl-2" color="red" size={30} />
          </div>
          <strong className="me-auto">Falha a atualizar</strong>
        </Toast.Header>
        <Toast.Body>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default FailureToast;
