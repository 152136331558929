/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, ButtonGroup, Col, Container, Row, Stack } from 'react-bootstrap';

import DataGrid from '../../../../../@shared/DataGrid';
import { BoxMovementCode } from '../../../box.models';
import FilterForm from '../FilterForm';

import useBoxDataGrid from '../useBoxDataGrid';
import boxColumns from './boxColumns';

type MustChecked = {
  selectedValues: any[];
  currentBoxMovement: BoxMovementCode | null;
  showSolicitDigitalizationFormDialog: boolean;
};

type Verifier = {
  isInvalid: boolean;
  message: string;
};

const columns = boxColumns();
const Bundled = () => {
  const boxDataGridRedux = useBoxDataGrid();
  const { currentRows, page, anySubmit, isFetchingRows, errorMessage, isInvalid, selectedIds } =
    boxDataGridRedux.useSelector();

  return (
    <Container>
      <Row className="h-100 py-4">
        <DataGrid>
          <DataGrid.ErrorDialog isInvalid={isInvalid} message={errorMessage} />
          <DataGrid.Header>
            <Container>
              <Row className="p-2">
                <Row>
                  <Col>
                    <DataGrid.AddButtonGroup to="/consult/add" className="pl-2">
                      {[{ text: 'Adicionar por csv', to: 'consult/add-csv' }]}
                    </DataGrid.AddButtonGroup>
                  </Col>
                </Row>

                <Row>
                  <FilterForm />
                </Row>
              </Row>
            </Container>
          </DataGrid.Header>
          <DataGrid.Main
            rowId="boxId"
            rows={currentRows}
            columns={columns}
            loading={isFetchingRows}
            selectedRows={selectedIds}
            onSelectedRowsChange={(r) => boxDataGridRedux.setSelectedRows(r)}
            className="datagrid-lg"
          />
          <DataGrid.Footer>
            <Stack direction="horizontal" className="w-100 justify-content-between">
              <DataGrid.PageNavigation
                currentPage={page}
                resetPagination={anySubmit}
                onTurnNextPage={() => boxDataGridRedux.turnNextPage()}
                onTurnPreviousPage={() => boxDataGridRedux.turnPreviousPage()}
              />
            </Stack>
          </DataGrid.Footer>
        </DataGrid>
      </Row>
    </Container>
  );
};

export default Bundled;
