import { useWatch } from 'react-hook-form';
import { CloudFilePath } from '../../../../../../@shared/Fields/models';
import useCategoryDynamicFieldRequest from '../../../../../../@shared/Requests/useCategoryDynamicFieldRequest';
import useRequest from '../../../../../../@shared/Requests/useRequest';
import { Csv, Option } from '../../../../../../@shared/models';
import { util } from '../../../../../../@shared/utils/util';
import boxesEndpoints from '../../../../box.endpoints';

interface UseCsvBoxAddFormRequests {
  control;
  cloudFile: CloudFilePath | null;
}

const useCsvBoxAddFormRequests = ({ control, cloudFile }: UseCsvBoxAddFormRequests) => {
  const value = useWatch({ control });
  const requestLoadCsv = useRequest<Csv>(boxesEndpoints.loadCsv(cloudFile as CloudFilePath));
  const boxTypesRequest = useRequest<Option[]>(boxesEndpoints.boxTypesOptions());
  const requests = useCategoryDynamicFieldRequest(
    value.category?.value,
    value.classification?.value,
    true,
    true,
    true
  );

  requestLoadCsv.useFetchBy(cloudFile || (value.classification && value.category));
  boxTypesRequest.useFetchBy('init');

  return {
    csv: requestLoadCsv.successData as Csv,
    options: {
      boxTypes: boxTypesRequest.successData ?? [],
      ...requests.options,
      descriptions: util.parseToOptions(requests.fields),
    },
    fields: requests.fields,
    loading: requests.loading || boxTypesRequest.loading || requestLoadCsv.loading,
    errorMessage:
      (requests?.backEndErrors?.message ||
        requestLoadCsv.backEndErrors?.message ||
        boxTypesRequest.backEndErrors?.message) ??
      '',
  };
};

export default useCsvBoxAddFormRequests;
