/* eslint-disable @typescript-eslint/no-unused-vars */

import ErrorDialog from '../../../../@shared/Dialogs/ErrorDialog';
import ScannedDocumentDataGridPresentation from './scannedDocumentDataGrid';
import useScannedDocumentDataGrid from './useScannedDocumentDataGrid';

const ScannedDocumentDataGrid = () => {
  const { loading, columns, error, errorMessage, rows } = useScannedDocumentDataGrid();

  return (
    <>
      <ErrorDialog show={error}>{errorMessage}</ErrorDialog>
      <ScannedDocumentDataGridPresentation columns={columns} rows={rows} />
    </>
  );
};

export default ScannedDocumentDataGrid;
