interface TheadSelectProps {
  children?: JSX.Element[] | JSX.Element;
}

const Thead = ({ children }: TheadSelectProps) => (
  <thead>
    <tr>{children || <></>}</tr>
  </thead>
);

export default Thead;
