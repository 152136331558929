import { FormError } from '../Alerts/errorFormAlert';
import DynamicField from './dynamicField';
import { InputGroup } from './models';

interface DynamicFieldGroupProps {
  fields: InputGroup[];
  register?: any;
  control?: any;
  errors?: Record<string, FormError>;
  children?: React.ReactNode | React.ReactNode[];
}

const DynamicFieldGroup = ({
  control,
  fields,
  register,
  errors,
  children,
}: DynamicFieldGroupProps) => (
  <>
    {fields.map((e, i) => {
      const id = e.id ?? `dynamicTextboxes${i.toString()}`;
      return (
        <DynamicField
          key={id}
          control={control}
          id={id}
          label={e.label}
          register={register && register(id)}
          tag={e.tag}
          type={e.type}
          defaultValue={e.defaultValue}
          errorMessage={(errors ?? {})[id]?.message}
          isInvalid={(errors ?? {})[id] !== undefined}
        >
          {children}
        </DynamicField>
      );
    })}
  </>
);

export default DynamicFieldGroup;
