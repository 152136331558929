import generatePassword from 'generate-password';
import { useMemo, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import TextField from '../../../../@shared/Fields/textField';

interface IFields {
  errors;
  // eslint-disable-next-line react/no-unused-prop-types
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line react/no-unused-prop-types
  control: Control<FieldValues, object>;
  // eslint-disable-next-line react/no-unused-prop-types
  password: string;
}

interface IFieldsPassword extends Omit<IFields, 'password'> {
  btnChecked: boolean;
}
const DefaultFields = ({ control, errors }: IFields) => (
  <>
    <TextField
      id="newPassword"
      control={control}
      label="Nova senha"
      type="password"
      errorMessage={errors?.newPassword?.message}
      isInvalid={errors?.newPassword !== undefined}
    />
    <TextField
      id="newPasswordConfirm"
      control={control}
      label="Confirma senha"
      type="password"
      errorMessage={errors?.newPasswordConfirm?.message}
      isInvalid={errors?.newPasswordConfirm !== undefined}
    />
  </>
);
const AutoPasswordFields = ({ errors, setValue, control, password }: IFields) => {
  setValue('newPassword', password);
  setValue('newPasswordConfirm', password);

  return (
    <>
      <TextField
        id="newPassword"
        control={control}
        label="Nova senha"
        disabled
        errorMessage={errors?.newPassword?.message}
        isInvalid={errors?.newPassword !== undefined}
      />
      <div className="d-none">
        <TextField
          id="newPasswordConfirm"
          control={control}
          label="Confirma senha"
          disabled
          type="password"
          defaultValue={password || ''}
          errorMessage={errors?.newPasswordConfirm?.message}
          isInvalid={errors?.newPasswordConfirm !== undefined}
        />
      </div>
      <Controller
        name="checkBoxChecked"
        control={control}
        render={({ field }) => (
          <Form.Group className="mb-3">
            <InputGroup hasValidation>
              <Form.Check type="checkbox" label="">
                <Form.Check.Input
                  defaultChecked={false}
                  isInvalid={errors?.checkBoxChecked !== undefined}
                  onChange={(e) => {
                    field.onChange(e.currentTarget.checked);
                  }}
                />
                <Form.Check.Label>Copiei minha senha</Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  {errors?.checkBoxChecked?.message}
                </Form.Control.Feedback>
              </Form.Check>
            </InputGroup>
          </Form.Group>
        )}
      />
    </>
  );
};

const FieldsPassword = ({ btnChecked, errors, setValue, control }: IFieldsPassword) => {
  const [password, setPassword] = useState('');

  useMemo(() => {
    const generate = generatePassword.generate({
      length: 10,
      numbers: true,
      symbols: true,
      strict: true,
    });

    setPassword(generate);
  }, []);

  return (
    <>
      {!btnChecked ? (
        <DefaultFields errors={errors} setValue={setValue} control={control} password={password} />
      ) : (
        <AutoPasswordFields
          errors={errors}
          setValue={setValue}
          control={control}
          password={password}
        />
      )}
    </>
  );
};

export default FieldsPassword;
