import { ReactNode, useContext, useEffect } from 'react';
import { AccordionBodyProps } from 'react-bootstrap/esm/AccordionBody';
import {
  CategoryAccordionContext,
  CategoryAccordionContextProps,
  CategorySelectable,
} from './categoryAccordion.context';
import { CategoryChildrenProps } from './categoryAccordion.types';

export interface CategoryAccordionBodyProps extends Omit<AccordionBodyProps, 'children'> {
  boxClassificationId?: number;
  categories?: CategorySelectable;
  children: (e: CategoryChildrenProps) => ReactNode | JSX.Element;
}

const CategoryAccordionChildren = ({
  categories,
  boxClassificationId,
  children,
}: CategoryAccordionBodyProps) => {
  const context = useContext<CategoryAccordionContextProps | null>(CategoryAccordionContext);

  useEffect(() => {
    if (categories) {
      context?.setCategories(categories);
    }
  }, [categories]);
  useEffect(() => {
    if (boxClassificationId) {
      context?.setBoxClassificationId(boxClassificationId);
    }
  }, [boxClassificationId]);

  if (!context) {
    return <></>;
  }
  return (
    <>
      {context.categories?.selected.map((e, i) =>
        children({
          boxClassificationId: context?.boxClassificationId,
          category: e,
          parents: context?.parents,
          users: context?.users,
          key: e.categoryId,
          eventKey: i.toString(),
        })
      )}
    </>
  );
};

export default CategoryAccordionChildren;
