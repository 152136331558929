import Step2Presentation from './step2';
import useStep2 from './useStep2';

const Step2 = () => {
  const {
    control,
    errors,
    fields,
    loading,
    handleClickNextStep,
    handleClickPreviousStep,
    handleSubmit,
  } = useStep2();

  return (
    <Step2Presentation
      control={control}
      errors={errors}
      fields={fields}
      loading={loading}
      onSubmit={handleSubmit}
      onClickNextStep={handleClickNextStep}
      onClickPreviousStep={handleClickPreviousStep}
    />
  );
};

export default Step2;
