import React from 'react';
import { Control, useController, useForm } from 'react-hook-form';
import Select, {
  GetOptionLabel,
  InputActionMeta,
  MultiValue,
  Options,
  SingleValue,
} from 'react-select';
import { Option } from '../models';
import { firstNotNullAndNotUndefined } from '../utils/util';
import Field from './Field';

interface OmitCustom
  extends Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    'ref' | 'size' | 'children' | 'value' | 'onFocus' | 'onBlur' | 'onKeyDown'
  > {}

export type SelectProps<T = Option, J = any> = OmitCustom & {
  [s: string]: any;
  isMulti?: boolean;
  id?: string;
  label: string;
  helperText?: string;
  required?: boolean;
  errorMessage?: string;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;

  isInvalid?: boolean;
  name?: string;
  control?: Control<any>;

  right?: JSX.Element;
  options?: T[];
  onChange?: (option: T) => void;
  getOptionLabel?: GetOptionLabel<T> | undefined;
  getOptionValue?: (option: T) => J | undefined;
  getOptionDisabled?: ((option: T, selectValue: Options<T>) => boolean) | undefined;
  getControllerValue?: (option: T) => J | undefined;
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
};

function useSelectField<T = Option, J = any>({
  label,
  helperText = '',
  id,
  errorMessage,
  isInvalid,
  control: controlProps,
  onChange,
  name,
  options,
  defaultValue,
  isMulti = false,
  right,
  containerClassName = '',
  className = '',
  inputClassName = '',
  getOptionLabel,
  onInputChange,
  getOptionValue,
  disabled,
  getOptionDisabled,
  getControllerValue,
  ...rest
}: SelectProps<T, J>) {
  const form = useForm();
  const control = firstNotNullAndNotUndefined(controlProps, form.control);
  const { field } = useController<any>({
    name: name ?? id ?? '',
    control,
  });

  const boxShadow = isInvalid
    ? '0 0 0 .25rem rgba(220,53,69,.25);'
    : '0 0 0 0.25rem rgba(13,110,253,.25)';

  const handleExtractValue = firstNotNullAndNotUndefined<Function | undefined>(
    getControllerValue,
    getOptionValue
  );

  const handleValue = () => {
    if (!options || options.length === 0 || !field.value) return field.value;

    if (!isMulti && handleExtractValue) {
      const value = options.find((op) => handleExtractValue(op) === field.value);
      return value ?? field.value;
    }

    if (!isMulti && !handleExtractValue) {
      const value = options.find((op) => op === field.value);
      return value ?? field.value;
    }

    const multiValue = Array.from<any>(field.value ?? []);

    if (isMulti || handleExtractValue) {
      const value = multiValue
        .flatMap((v) => {
          const option = options.find((op) => handleExtractValue?.(op) === v);
          return option ?? v;
        })
        .filter((e) => e !== undefined);

      return value;
    }

    const value = multiValue
      .flatMap((v) => {
        const option = options.find((op) => op === v);
        return option ?? v;
      })
      .filter((e) => e !== undefined);
    return value;
  };

  const handleChange = (selected: MultiValue<T> | SingleValue<T>) => {
    if (!handleExtractValue) {
      field.onChange(selected);
      onChange?.(selected as T);
      return;
    }
    if (isMulti && Array.isArray(selected)) {
      const v = selected.map((e) => handleExtractValue(e));
      field.onChange(v);
      onChange?.(v as any);
      return;
    }
    const v = handleExtractValue(selected);
    field.onChange(v);
    onChange?.(v);
  };
  return (
    <Field
      id={id}
      right={right}
      label={label}
      containerClassName={containerClassName}
      className={className}
      isInvalid={isInvalid as boolean}
      errorMessage={errorMessage}
      helperText={helperText}
    >
      <Select
        defaultValue={field.value}
        isDisabled={disabled}
        isOptionDisabled={getOptionDisabled}
        getOptionValue={getOptionValue ? (data) => String(getOptionValue(data)) : undefined}
        getOptionLabel={getOptionLabel}
        isMulti={isMulti}
        aria-label={label}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
            outline: state.isFocused ? 0 : 0,
            boxShadow: state.isFocused ? boxShadow : 'none',
            transition: '',
            '&:hover': {},
          }),
        }}
        classNames={{ control: () => `form-control ${isInvalid ? 'is-invalid' : ''}` }}
        name={name}
        placeholder="Selecione"
        options={options ?? []}
        className={inputClassName}
        ref={field.ref}
        onInputChange={onInputChange}
        value={handleValue()}
        onChange={handleChange}
        {...rest}
      />
    </Field>
  );
}

export default useSelectField;
