import classificationEndpoints from '../../../../modules/classification/classification.endpoint';
import { BoxClassification } from '../../../../modules/classification/classification.types';
import useRequestForm from '../../../Forms/RequestForm/useRequestForm';
import { formatDate } from '../../../utils/util';
import boxClassificationFormValidator from '../boxClassificationForm.validator';

const useBoxClassificationEditForm = (boxClassificationId: number) => {
  const { control, validator, submitResponse, handleSubmit, onSubmit, defaultValueRequest } =
    useRequestForm<BoxClassification>({
      validatorSchema: boxClassificationFormValidator,
      submitRequest: (e) => classificationEndpoints.edit(e),
      defaultValueRequest: () => classificationEndpoints.find(boxClassificationId),
      transformDefaultValues(obj) {
        return {
          ...obj,
          inclusionDate: formatDate(String(obj?.inclusionDate)),
          cancellationDate: formatDate(String(obj?.cancellationDate)),
        };
      },
    });

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    defaultValueError: defaultValueRequest?.error,
    defaultValueErrorMessage:
      defaultValueRequest?.backEndErrors?.message ?? 'Ocorreu um error desconhecido',
    submitError: submitResponse.error,
    submitErrorMessage: submitResponse.backEndErrors?.message,
    errors: validator.errors,
    loading: submitResponse.loading || defaultValueRequest?.loading,
  };
};

export default useBoxClassificationEditForm;
