import { Button, Stack } from 'react-bootstrap';
import { BoxClassificationItem } from '../../../../modules/classification/classification.endpoint';
import RequestForm from '../../../Forms/RequestForm';
import ShimmerPlaceholder from '../../../ShimmerPlaceholders';
import FormGroupShimmerPlaceholder from '../../../ShimmerPlaceholders/formGroupShimmerPlaceholder';
import BoxClassificationItemFormFields from '../boxClassificationItemForm.fields';
import BoxClassificationItemFormTemplate from '../boxClassificationItemForm.template';
import useBoxClassificationItemEditForm from './useBoxClassificationItemEditForm';

interface BoxClassificationItemEditFormProps {
  boxClassificationId: number;
  boxClassificationItemId: number;
  defaultValue: BoxClassificationItem;
}

const BoxClassificationItemEditForm = ({
  boxClassificationId,
  boxClassificationItemId,
  defaultValue,
}: BoxClassificationItemEditFormProps) => {
  const { control, handleSubmit, validator, loading, formats } = useBoxClassificationItemEditForm(
    boxClassificationId,
    boxClassificationItemId,
    defaultValue
  );
  if (loading) {
    return (
      <>
        <BoxClassificationItemFormTemplate
          type="edit"
          name={<FormGroupShimmerPlaceholder />}
          isPrinted={<FormGroupShimmerPlaceholder type="radio" />}
          observation={<FormGroupShimmerPlaceholder />}
          orderNumber={<FormGroupShimmerPlaceholder />}
          size={<FormGroupShimmerPlaceholder />}
          cancellationDate={<FormGroupShimmerPlaceholder />}
          inclusionDate={<FormGroupShimmerPlaceholder />}
          format={<FormGroupShimmerPlaceholder />}
          barcodeRef={<FormGroupShimmerPlaceholder type="radio" />}
        />
        <Stack direction="horizontal" className="justify-content-end">
          <ShimmerPlaceholder.Button style={{ width: '67px' }} />
        </Stack>
      </>
    );
  }
  return (
    <RequestForm errors={validator.errors} onSubmit={handleSubmit}>
      <BoxClassificationItemFormFields
        formats={formats}
        type="edit"
        control={control}
        errors={validator.errors}
      />

      <div className="d-flex justify-content-end">
        <Button type="submit">Editar</Button>
      </div>
    </RequestForm>
  );
};

export default BoxClassificationItemEditForm;
