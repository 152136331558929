import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import boxDataGridSlice from './slices/boxDataGridSlice';
import selectClientFormSlice from './slices/selectClientFormSlice';
import sideBarSlice from './slices/sideBarSlice';
import userSuccessToastSlice from './slices/userSuccessToastSlice';

const RootReducer = () =>
  combineReducers({
    sidebar: sideBarSlice,
    auth: authSlice,
    selectClientForm: selectClientFormSlice,
    userSuccessToast: userSuccessToastSlice,
    boxDataGrid: boxDataGridSlice,
  });

export default RootReducer;
