/* eslint-disable react/no-children-prop */

import { Col, Container, Row } from 'react-bootstrap';
import ErrorGroup from '../../../@shared/ErrorGroup';
import FieldsetOld from '../../../@shared/Fieldset/fieldsetOld';
import LoadingGroup from '../../../@shared/LoadingGroup';

import Layout from '../../layout';
import ScannedDocumentDataGrid from '../../scanned-document/features/ScannedDocumentDataGrid';
import BoxMovementHistoryDataGrid from '../features/BoxMovementHistoryDataGrid';
import BoxPanel from '../features/BoxPanel';
import BoxTitle from '../features/BoxTitle';

const BoxDetailPage = () => (
  <Layout>
    <LoadingGroup>
      <ErrorGroup>
        <Container>
          <Row>
            <div className="h-100 rounded-3 m-4" style={{ background: '#f6f6f6' }}>
              <Container fluid>
                <div className="p-4 pb-5">
                  <Row className="w-100">
                    <BoxTitle />
                  </Row>

                  <Row className="w-100 h-100">
                    <Col sm="12" md="5">
                      <Row className="d-flex h-100 w-100">
                        <FieldsetOld legend="Informações da caixa" className="w-100">
                          <BoxPanel />
                        </FieldsetOld>
                      </Row>
                    </Col>
                    <Col md="1" />
                    <Col sm="12" md="6">
                      <Row className="h-100">
                        <Row className="h-50">
                          <FieldsetOld legend="Histórico de movimentação">
                            <BoxMovementHistoryDataGrid />
                          </FieldsetOld>
                        </Row>

                        <Row className="h-50">
                          <FieldsetOld legend="Arquivos digitalizados">
                            <ScannedDocumentDataGrid />
                          </FieldsetOld>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </Row>
        </Container>
      </ErrorGroup>
    </LoadingGroup>
  </Layout>
);

export default BoxDetailPage;
