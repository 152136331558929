import React from 'react';
import { Accordion } from 'react-bootstrap';
import { CategoryAccordionItemContext } from './categoryAccordion.item.context';
import useCategoryAccordionItem from './categoryAccordion.item.hook';
import { AccordionItemProps } from './categoryAccordion.item.types';

const CategoryAccordionItem = ({
  eventKey,
  children,
  category: categoryProp,
}: AccordionItemProps) => {
  const value = useCategoryAccordionItem({ category: categoryProp });

  return (
    <CategoryAccordionItem.Context.Provider value={value}>
      <Accordion.Item eventKey={eventKey}>
        {typeof children === 'string' ? children : React.Children.map(children, (e) => e)}
      </Accordion.Item>
    </CategoryAccordionItem.Context.Provider>
  );
};
CategoryAccordionItem.Context = CategoryAccordionItemContext;
export default CategoryAccordionItem;
