import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import PublicRoute from '../../@shared/Routes/PublicRoute';
import Reset2FaEditPage from './pages/define-new-2fa';
import Reset2FaVerifyTokenPage from './pages/reset-2fa';

const baseUrl = '/reset-2fa';
export const routes = {
  baseUrlToken: `${baseUrl}/:token?`,
  defineNew2fa: `${baseUrl}/define-new-2fa`,
};

const Reset2faRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.defineNew2fa} exact>
      <Reset2FaEditPage />
    </PrivateRoute>
    <PublicRoute path={routes.baseUrlToken} exact>
      <Reset2FaVerifyTokenPage />
    </PublicRoute>
  </Switch>
);

export default Reset2faRoutes;
