import { useEffect } from 'react';
import { FilterStatus } from '../../../box.models';

function useFilterBySelected(selectStatus: FilterStatus, boxDataGridRedux) {
  useEffect(() => {
    if (FilterStatus.Selected === selectStatus) {
      boxDataGridRedux.IsSelectedValueInStatusFilter();
    } else {
      boxDataGridRedux.noIsSelectedValueInStatusFilter();
    }
  }, [selectStatus]);
}

export default useFilterBySelected;
