import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormValidatorProps } from '.';
import useRequest from '../../Requests/useRequest';
import { Exception, RequestPropsOmitFetch } from '../../models';
import isEmpty from '../../utils/util';

import useFormValidator from '../useFormValidator';
import useInitForm, { UseInitFormProps } from '../useInitForm';

export interface UseRequestForm<T extends FieldValues = any> extends UseInitFormProps<T> {
  validator: FormValidatorProps;
  submitResponse: RequestPropsOmitFetch;
  defaultValueRequest?: RequestPropsOmitFetch;
  onSubmit: (obj) => void;
  useHandleSuccess: (f: Function) => void;
  submitRequest: ((obj?: any) => AxiosRequestConfig) | null;
  useHandleRequest: (f: Function) => void;
}

type DefaultValues<T> = T | (() => T);
export interface UseRequestFormParams<T> {
  submitRequest?: (obj: T) => AxiosRequestConfig;
  validatorSchema: any;
  defaultValueRequest?: () => AxiosRequestConfig;
  backEndErrors?: any | undefined;
  defaultValues?: DefaultValues<T>;
  transformDefaultValues?: (obj: any) => any;
}

export enum RequestStatus {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

const innerTransform = (params: UseRequestFormParams<any>, defaultResponse) => {
  if (!params.defaultValues && !defaultResponse.successData) return undefined;
  if (params.transformDefaultValues !== undefined) {
    return params.transformDefaultValues(params.defaultValues ?? defaultResponse.successData);
  }
  return params.defaultValues ?? defaultResponse.successData;
};

export default <T extends FieldValues = any>(
  params: UseRequestFormParams<T>
): UseRequestForm<T> => {
  const { fetch, ...submitResponse } = useRequest();
  const defaultValueRequest = useRequest<T>();
  const preInitForm = useInitForm<T>(
    params.validatorSchema,
    innerTransform(params, defaultValueRequest)
  );
  const validator = useFormValidator(preInitForm.frontEndErros, submitResponse?.backEndErrors);

  useMemo(() => {
    if (params?.defaultValueRequest !== undefined) {
      defaultValueRequest.fetch(params.defaultValueRequest());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(params.backEndErrors)) {
      submitResponse.setStatus({
        backEndErrors: { message: params?.backEndErrors?.message } as Exception,
        error: true,
        loading: false,
        success: false,
        successData: null,
        statusCode: submitResponse.statusCode,
      });
    }
  }, [JSON.stringify(params.backEndErrors)]);

  const fetchSubmit = (obj) => {
    if (params.submitRequest !== undefined) {
      fetch(params.submitRequest(obj));
    }
  };
  const onSubmit = (obj) => {
    fetchSubmit(obj);
  };

  const useHandleSuccess = (f: Function) => {
    useEffect(() => {
      if (submitResponse.success === true) f();
    }, [f, submitResponse.success]);
  };

  const useHandleRequest = (f: Function) => {
    useEffect(() => {
      f();
    }, [f, submitResponse.loading, submitResponse.error, submitResponse.success]);
  };

  return {
    ...preInitForm,
    useHandleSuccess,
    onSubmit,
    submitResponse,
    validator,
    defaultValueRequest,
    useHandleRequest,
    submitRequest: params.submitRequest ?? null,
  };
};
