/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { CloudFilePath } from '../../../../../../@shared/Fields/models';
import { useLocalForm } from '../../../../../../@shared/MultiStepForm/StepsForm/stepsForm.hook';
import useDisable from '../../../../../../@shared/useDisable';
import useCsvBoxAddFormRequests from './csvBoxAddForm.hook.requests';
import csvBoxAddFormValidator from './csvBoxAddForm.validator';

interface UseCsvBoxAddFormParams {
  defaultValue: any;
  cloudFile: CloudFilePath;
}

const useCsvBoxAddForm = ({ defaultValue, cloudFile }: UseCsvBoxAddFormParams) => {
  const staticRequiredFields = 2;
  const [totalRequiredFields, setTotalRequiredFields] = useState(staticRequiredFields);

  const localForm = useLocalForm(1, defaultValue, csvBoxAddFormValidator(totalRequiredFields));

  const requests = useCsvBoxAddFormRequests({
    control: localForm.control,
    cloudFile,
  });
  useEffect(() => {
    setTotalRequiredFields(requests?.fields.length + staticRequiredFields);
  }, [requests.fields.length]);

  const value = localForm.getValues();

  const classificationDisable = useDisable(
    value.category === undefined || value.category === '',
    true,
    [value.category]
  );

  const boxTypeDisable = useDisable(
    value.classification === undefined || value.classification === '',
    true,
    [value.classification]
  );

  const csvTableFieldDisable = useDisable(
    value.classification === undefined ||
      value.classification === '' ||
      value.classification === undefined ||
      value.classification === '' ||
      value.boxType === undefined ||
      value.boxType === '',
    true,
    [value]
  );

  return {
    csvTableFieldDisable: { checkBox: csvTableFieldDisable, select: csvTableFieldDisable },
    boxTypeDisable,
    csvRows: requests?.csv?.rows ?? [],
    register: localForm.register,
    control: localForm.control,
    classificationDisable,
    loading: requests.loading,
    errorMessage: requests.errorMessage,
    options: requests.options,
    value,
    fields: requests.fields,
    errors: localForm.formState.errors,
    stepsStatus: localForm.stepsStatus,
    handleSubmit: localForm.handleSubmit,
  };
};

export default useCsvBoxAddForm;
