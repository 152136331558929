import { useContext, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CategoryFormContext } from './categoryForm.context';

export interface UserCategoryFormButtonProps {
  type?: 'edit' | 'add';
}

const CategoryFormButton = ({ type: typeProp }: UserCategoryFormButtonProps) => {
  const context = useContext(CategoryFormContext);

  useEffect(() => {
    if (typeProp && context && context.config) {
      context?.setConfig({ ...context.config, type: typeProp });
    }
  }, [typeProp]);

  return (
    <Row className="justify-content-end">
      <Col sm={12} md={3} className="pt-3 justify-content-end d-flex">
        <Button type="submit">{context?.config?.type === 'edit' ? 'Editar' : 'Adicionar'}</Button>
      </Col>
    </Row>
  );
};

export default CategoryFormButton;
