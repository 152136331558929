/* eslint-disable @typescript-eslint/no-unused-vars */
import { Control, DeepRequired, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { CloudFilePath } from '../../../../../@shared/Fields/models';
import { Option } from '../../../../../@shared/models';

import useStepsForm from '../../../../../@shared/MultiStepForm/StepsForm/stepsForm.hook';
import useCsvBoxAddForm from './CsvBoxAddForm/csvBoxAddForm.hook';
import useInsertPopulateFields from './step1.hook.insertPopulateFields';

export interface Step1Props {
  handleClickNextStep: () => void;
  handleClickPreviousStep: () => void;
  handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  errors: FieldErrorsImpl<DeepRequired<any>>;
  loading: boolean;
  rows: string[][];
  register: UseFormRegister<any>;
  control: Control;
  classificationDisable: boolean;
  boxTypeDisable: boolean;
  csvTableFieldDisable?: { checkBox?: boolean; select?: boolean };
  options: {
    descriptions: Option[];
    classifications: Option[];
    categories: Option[];
    boxTypes: Option[];
  };
}

const useStep1 = (): Step1Props => {
  const step = useStepsForm();
  const form = useCsvBoxAddForm({
    cloudFile: step.value?.['0'] as CloudFilePath,
    defaultValue: step.value?.['1'],
  });

  step.useUpdateStepStatusClickNext(form.value, form.errors, form.stepsStatus, 0);
  useInsertPopulateFields({
    classificationsOptions: form.options.classifications,
    descriptionFields: form.fields,
  });

  return {
    csvTableFieldDisable: form.csvTableFieldDisable,
    boxTypeDisable: form.boxTypeDisable,
    classificationDisable: form.classificationDisable,
    loading: form.loading,
    control: form.control,
    rows: form.csvRows,
    options: form.options,
    register: form.register,
    errors: form.errors,
    handleSubmit: () => form.handleSubmit,
    handleClickNextStep: () => {
      form.handleSubmit(() => {})();
    },
    handleClickPreviousStep: () => step.previousStep(),
  };
};

export default useStep1;
