import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router';
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';
import RequestForm from '../../../../@shared/Forms/RequestForm';
import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import { SubsidiaryClient } from '../../../../@shared/models';

import VerifyLoading from '../../../../@shared/Verifiers/verifyLoading';

import ErrorPage from '../../../../@shared/template/_errorPage';
import { CenterAligner } from '../../../../styles';
import authEndpoints from '../../auth.endpoints';
import ClientSelect from '../ClientSelect';
import useSelectClientFormSlice from './useSelectClientFormSlice';
import authClientSelectFormValidator from './validator';

interface ChooseClientStateForm {
  subsidiaries: SubsidiaryClient;
}

const ChooseClientForm = () => {
  const authRedux = useAuthSlice();
  const selectClientFormRedux = useSelectClientFormSlice();
  const { clientSelected } = selectClientFormRedux.useState();
  const form = useRequestForm<ChooseClientStateForm>({
    validatorSchema: authClientSelectFormValidator,
    submitRequest: (obj) => authEndpoints.chooseClient(obj.subsidiaries as any),
  });
  selectClientFormRedux.useSelectClient(form.submitResponse.success);
  authRedux.useUpdateSecret(form.submitResponse, false, 'ClientForm');

  if (clientSelected && authRedux.hasSecretBeenUpdated) {
    return <Redirect to="/home" />;
  }
  if (form.submitResponse.loading) {
    return <VerifyLoading loadings={[true]} />;
  }
  if (form.submitResponse.error) {
    return (
      <ErrorPage
        text={form.submitResponse.backEndErrors?.message ?? 'houve algum error, tente novamente'}
      />
    );
  }

  return (
    <CenterAligner>
      <RequestForm
        style={{ maxWidth: '800px' }}
        className="w-100"
        method="post"
        validated={form.validator.validated}
        onSubmit={form.handleSubmit(form.onSubmit)}
      >
        <ClientSelect
          isInvalid={form.validator.errors?.subsidiaries !== undefined}
          errorMessage={form.validator.errors?.subsidiaries?.message}
          control={form.control}
        />

        <Button type="submit">Confirmar</Button>
      </RequestForm>
    </CenterAligner>
  );
};

export default ChooseClientForm;
