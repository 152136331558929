import { useContext } from 'react';
import AddAccordionContext, { AddAccordionActions } from './addAccordion.context';

interface AddAccordionDialogProps<T> {
  children: (value: T[], actions: AddAccordionActions<T>) => React.ReactNode | React.ReactNode;
}

const AddAccordionDialog = <T = any,>({ children }: AddAccordionDialogProps<T>) => {
  const context = useContext(AddAccordionContext);

  if (context?.show && typeof children === 'function') {
    return (
      <>
        {children(context.value, {
          add: context.add,
          remove: context.remove,
          set: context.set,
          show: context.show,
          open: context.open,
          close: context.close,
        })}
      </>
    );
  }
  return <></>;
};

export default AddAccordionDialog;
