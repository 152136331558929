import React, { ReactNode } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface StepProps {
  children: ReactNode;
}

const Step = ({ children }: StepProps) => (
  <Stack direction="vertical" className="justify-content-between" style={{ height: '700px' }}>
    {children}
  </Stack>
);

interface ButtonGroupProps {
  children: ReactNode | ReactNode[];
}

Step.ButtonGroup = ({ children }: ButtonGroupProps) => {
  if (Array.isArray(children)) {
    return (
      <Stack direction="horizontal" className="justify-content-between mt-3">
        {React.Children.map(children, (e) => e)}
      </Stack>
    );
  }
  return (
    <Stack direction="horizontal" className="align-items-end mt-3">
      {children}
    </Stack>
  );
};

interface StepButtonProps {
  onClick?: () => void;
  to?: string | { path: string; state?: any; params: any };
  children?: string | ReactNode;
  type?: 'button' | 'submit' | 'reset';
}

Step.NextButton = ({ type = 'submit', onClick, to, children = 'Próximo' }: StepButtonProps) => {
  if (to) {
    return (
      <Link to={to}>
        <Button type={type} onClick={onClick}>
          {children}
        </Button>
      </Link>
    );
  }
  return <Button type={type}>{children}</Button>;
};

Step.PreviousButton = ({ type, onClick, to, children = 'Anterior' }: StepButtonProps) => {
  if (to) {
    return (
      <Link to={to}>
        <Button type={type} onClick={onClick}>
          {children}
        </Button>
      </Link>
    );
  }
  return (
    <Button type={type} onClick={onClick}>
      {children}
    </Button>
  );
};

export default Step;
