import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { CloudFilePath } from '../../@shared/Fields/models';
import { CONTENT_TYPE, PageSize } from '../../constants/constants';
import EnumerableFormat from '../../constants/enumerableFormat';

import {
  AddBox,
  AddParameterizedCsv,
  BoxMovement,
  BoxMovementCode,
  BoxRow,
  RequestPropsListBoxParams,
  RequestPropsListDescriptions,
} from './box.models';

const name = 'boxes';
const nameWithMe = 'boxes/me';
const boxClassification = 'box-classifications';
const boxesEndpoints = {
  boxTypesOptions(): AxiosRequestConfig {
    return { method: 'get', url: `${name}/types`, params: { format: EnumerableFormat.OPTION } };
  },
  add(data: AddBox): AxiosRequestConfig {
    return { method: 'post', url: `${name}/add`, data };
  },
  addParameterizedCsv(data: AddParameterizedCsv): AxiosRequestConfig {
    return { method: 'post', url: `${name}/add-list/parameterized-csv`, data };
  },
  loadCsv(data: CloudFilePath): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/add/load-csv`,
      data,
    };
  },
  uploadCsv(data: FormData): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/add/upload-csv`,
      data,
      headers: { 'Content-Type': CONTENT_TYPE.MULTIPART_FORM_DATA },
    };
  },
  list(params: RequestPropsListBoxParams): AxiosRequestConfig {
    return { method: 'get', url: `${nameWithMe}/browse`, params };
  },
  listByCsv(params: RequestPropsListBoxParams): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${nameWithMe}/browse`,
      params,
      headers: { Accept: CONTENT_TYPE.TEXT_CSV },
    };
  },
  getFaceSheetLabelPdf(params: { boxes: number[]; pageSize?: PageSize }): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/face-sheet-label.pdf`,
      params,
      paramsSerializer: (params2) => qs.stringify(params2, { arrayFormat: 'repeat' }),
      responseType: 'blob',
    };
  },
  listBoxClassificationItemsTextbox(params: { boxClassificationId: number }): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${boxClassification}/${params.boxClassificationId}/items/browse:dynamic-field`,
    };
  },
  listBoxClassificationOptions(params?: { categoryId?: number }): AxiosRequestConfig {
    if (params?.categoryId) {
      return {
        method: 'get',
        url: `${boxClassification}/me/browse:options`,
        params: { ...params, format: EnumerableFormat.OPTION },
      };
    }
    return {
      method: 'get',
      url: `${boxClassification}/me/browse:options`,
      params: { ...params, format: EnumerableFormat.OPTION },
    };
  },
  listBoxClassificationOptionCancellationDatePair(params?: {
    categoryId?: number;
  }): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${boxClassification}/me/browse:option-cancellation-date-pairs`,
      params,
    };
  },
  listUserCategoryOption(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `users/me/browse-categories:options`,
    };
  },
  solicitMovement(
    description: string,
    selectedBoxes: BoxRow[],
    boxMovementCod: BoxMovementCode,
    boxMigrationTypeId?: number
  ): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/move`,
      data: selectedBoxes?.map(
        (e) =>
          ({
            boxId: e.boxId,
            boxMigrationTypeId: boxMigrationTypeId ?? 0,
            boxMovementCod,
            boxMovementDescription: description,
            boxMovementId: e.boxMovementId,
          } as BoxMovement)
      ),
    };
  },
  listMigrationCategories(): AxiosRequestConfig {
    return { method: 'get', url: `users/me/list-categories`, params: { isMigration: true } };
  },
  findDescription(params: RequestPropsListDescriptions): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/movements/descriptions`,
      params,
    };
  },
  detail(urlBoxId: number): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/${urlBoxId}/detail`,
    };
  },
  history(urlBoxId: number): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/${urlBoxId}/movements`,
    };
  },
};

export default boxesEndpoints;
