/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';

import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import secretService from '../../../../@shared/storage/secretService';
import LoadingPage from '../../../../@shared/template/_loadingPage';

import isEmpty from '../../../../@shared/utils/util';
import yup from '../../../../config/yup/customYup';

import authEndpoints from '../../auth.endpoints';

import ConfirmationDialog from '../../../../@shared/Dialogs/confirmationDialog';
import ForgotDialogues from '../ForgotDialogues';
import LoginFormPresentation from './loginForm';

const validator = yup
  .object({
    emailOrUsername: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const LoginForm = () => {
  const redux = useAuthSlice();
  const history = useHistory();
  const loc = useLocation<any>();
  const [logged, setLogged] = useState(false);
  const form = useRequestForm({
    submitRequest: (obj) => authEndpoints.auth(obj),
    validatorSchema: validator,
  });

  useEffect(() => {
    if (form.submitResponse.success && !form.submitResponse.loading) {
      redux.generateNewTokensSuccessNotLogged(form.submitResponse.successData, 'LoginForm');
      setLogged(true);
    } else if (!isEmpty(secretService.get())) {
      setLogged(true);
    }
  }, [form.submitResponse.loading, form.submitResponse.success]);

  if (form.submitResponse.loading) return <LoadingPage />;
  if (form.submitResponse.statusCode === 403) {
    redux.logout();
    return <Redirect to="/login" />;
  }
  if (logged) return <Redirect to="/login/2fa" />;
  return (
    <ForgotDialogues>
      <ConfirmationDialog
        show={loc.state?.showSessionDialog === true}
        title="Ops.."
        bodyText="Sua sessão expirou. Por favor, faça o login novamente para continuar."
        handleClose={() => {
          history.replace({ ...loc, state: { showSessionDialog: false } });
          history.go(0);
        }}
      />
      <LoginFormPresentation
        method="post"
        onSubmit={form.handleSubmit(form.onSubmit)}
        control={form.control}
        validated={form.validator.validated}
        errors={form.validator.errors}
      />
    </ForgotDialogues>
  );
};

export default LoginForm;
