import DataGrid from '../../../../@shared/DataGrid';
import ErrorPage from '../../../../@shared/template/_errorPage';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import useClassificationDataGrid from './classificationDataGrid.hook';

const ClassificationDataGrid = () => {
  const { columns, rows, loading, errorText, error } = useClassificationDataGrid();

  if (error) return <ErrorPage text={errorText} />;
  if (loading) return <LoadingPage className="w-100 h-100" />;
  return (
    <DataGrid className="p-0">
      <DataGrid.Header className="p-2">
        <DataGrid.AddButton to="classifications/add" title="adicionar classificação" />
      </DataGrid.Header>
      <DataGrid.Main
        rowId="classificationId"
        rows={rows}
        columns={columns}
        headerRowHeight={100}
        rowHeight={50}
        className="datagrid-lg"
      />
    </DataGrid>
  );
};

export default ClassificationDataGrid;
