import { Button } from 'react-bootstrap';
import TextField from '../../../../@shared/Fields/textField';
import RequestForm, { FormControlProps } from '../../../../@shared/Forms/RequestForm';

interface ILoginFormPresentation extends FormControlProps {}

const LoginFormPresentation = ({
  method,
  errors,
  validated,
  onSubmit,
  control,
  ...props
}: ILoginFormPresentation) => (
  <>
    <RequestForm
      {...props}
      errors={errors}
      method={method}
      noValidate
      validated={validated}
      onSubmit={onSubmit}
      className="align-self-start"
    >
      <TextField
        id="emailOrUsername"
        label="Email ou Usuário"
        control={control}
        errorMessage={errors?.emailOrUsername?.message}
        isInvalid={errors?.emailOrUsername !== undefined}
      />
      <TextField
        id="password"
        label="Senha"
        type="password"
        control={control}
        errorMessage={errors?.password?.message}
        isInvalid={errors?.password !== undefined}
      />
      <Button type="submit">Logar</Button>
    </RequestForm>
  </>
);

export default LoginFormPresentation;
