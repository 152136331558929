import authEndpoints from '../../../modules/auth/auth.endpoints';
import useAuthSlice from '../../Auth/useAuthSlice';
import useRequestForm from '../../Forms/RequestForm/useRequestForm';
import VerifyLoading from '../../Verifiers/verifyLoading';
import { TwoStepAuthenticator } from '../../models';
import pinFormValidator from '../../pinFormValidator';
import './index.css';
import PinFormPresentation from './pinForm';
import VerifyAlreadyDoneTwoStepPresentation from './verifyAlreadyDoneTwoStep';

const PinForm = ({
  twoStepAuthenticator,
  onSuccessRedirect,
  onSuccessComponent,
}: {
  twoStepAuthenticator: TwoStepAuthenticator | null;
  onSuccessRedirect?: JSX.Element;
  onSuccessComponent?: JSX.Element;
}) => {
  const redux = useAuthSlice();
  const form = useRequestForm({
    validatorSchema: pinFormValidator,
    submitRequest: (obj) => authEndpoints.verifyPin({ pin: obj.pin }),
  });
  redux.useUpdateSecret(form.submitResponse, true, 'pinForm/verifyPin');

  if (redux.hasSecretBeenUpdated && onSuccessRedirect) return onSuccessRedirect;
  if (form.submitResponse.loading) return <VerifyLoading loadings={[true]} />;
  return (
    <>
      {redux.hasSecretBeenUpdated && onSuccessComponent}
      <VerifyAlreadyDoneTwoStepPresentation
        authenticator={
          twoStepAuthenticator?.qrCode
            ? {
                qrCodeSetupImageUrl: twoStepAuthenticator?.qrCode ?? '',
                manualEntryKey: twoStepAuthenticator?.manualCode ?? '',
                account: '',
              }
            : null
        }
        isVerifiedTwoFactors={twoStepAuthenticator?.hasByPass ?? false}
      >
        <>
          <PinFormPresentation
            className="max-w-select m-auto"
            onSubmit={form.handleSubmit(form.onSubmit)}
            method="post"
            control={form.control}
            validated={form.validator.validated}
            errors={form.validator.errors}
          />
        </>
      </VerifyAlreadyDoneTwoStepPresentation>
    </>
  );
};

export default PinForm;
