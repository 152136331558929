/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';
import useOrganizeStatusRequest from '../../../../@shared/Forms/useOrganizeStatusRequest';
import useFormDialog from '../../../../@shared/Forms/useRequestFormDialog';
import SuccessToast from '../../../../@shared/Toasts/successToast';
import emailValidator from '../../../../@shared/emailValidator';
import { JsxElements } from '../../../../@shared/models';
import { CenterAligner, DialogGroup } from '../../../../styles';
import authEndpoints from '../../auth.endpoints';
import LoadingModal from './LoadingModal';
import EmailDialog from './emailDialog';
import ExplanationFormDialog from './explanationDialog';
import LinkButtonGroup from './linkButtonGroup';

interface Container {
  children: JsxElements;
}

const StyledFormGroup = styled(FormGroup)`
  max-width: 207px;
  margin: auto;
  display: flex;
`;

const ForgotDialogues = ({ children }: Container) => {
  const forgotPasswordFormDialog = useFormDialog({
    validatorSchema: emailValidator,
    submitRequest: (obj) => authEndpoints.sendEmailOfResetPasswordByEmail({ email: obj.email }),
  });

  const forgotTwoFactorVerificationDialog = useFormDialog({
    validatorSchema: emailValidator,
    submitRequest: (obj) =>
      authEndpoints.sendEmailOfResetTwoStepVerificationByEmail({ email: obj.email }),
  });

  const forgotPasswordAndTwoFactorVerificationDialog = useFormDialog({
    validatorSchema: emailValidator,
    submitRequest: (obj) => authEndpoints.verifyEmail({ email: obj.email }),
  });

  const requestStatus = useOrganizeStatusRequest([
    forgotPasswordFormDialog.submitResponse,
    forgotTwoFactorVerificationDialog.submitResponse,
    forgotPasswordAndTwoFactorVerificationDialog.submitResponse,
  ]);

  forgotPasswordFormDialog.useClose(forgotPasswordFormDialog.submitResponse.success);

  forgotTwoFactorVerificationDialog.useClose(
    forgotTwoFactorVerificationDialog.submitResponse.success
  );

  forgotPasswordAndTwoFactorVerificationDialog.useClose(
    forgotPasswordAndTwoFactorVerificationDialog.submitResponse.success
  );

  if (requestStatus.loading) return <LoadingModal />;
  return (
    <>
      <DialogGroup className="dialog-group">
        <EmailDialog
          control={forgotPasswordFormDialog.control}
          title="Esqueci minha senha"
          bodyText="Digite seu endereço de e-mail cadastrado no sistema para redefinir sua senha."
          name="forgot-password"
          show={forgotPasswordFormDialog.show}
          onHide={forgotPasswordFormDialog.close}
          method="post"
          errors={forgotPasswordFormDialog.validator.errors}
          validated={forgotPasswordFormDialog.validator.validated}
          onSubmit={forgotPasswordFormDialog.handleSubmit(forgotPasswordFormDialog.onSubmit)}
          register={forgotPasswordFormDialog.register}
        />
        <EmailDialog
          control={forgotTwoFactorVerificationDialog.control}
          title="Esqueci minha verificação de duas etapas"
          bodyText="digite seu endereço de e-mail cadastrado no sistema para redefinir sua verificação de duas etapas"
          name="forgot-two-factor"
          show={forgotTwoFactorVerificationDialog.show}
          onHide={forgotTwoFactorVerificationDialog.close}
          method="post"
          errors={forgotTwoFactorVerificationDialog.validator.errors}
          validated={forgotTwoFactorVerificationDialog.validator.validated}
          onSubmit={forgotTwoFactorVerificationDialog.handleSubmit(
            forgotTwoFactorVerificationDialog.onSubmit
          )}
          register={forgotTwoFactorVerificationDialog.register}
        />
        {forgotPasswordAndTwoFactorVerificationDialog.submitResponse.success ? (
          <ExplanationFormDialog
            onClose={() => {
              forgotPasswordFormDialog.submitResponse.reset();
              forgotTwoFactorVerificationDialog.submitResponse.reset();
              forgotPasswordAndTwoFactorVerificationDialog.submitResponse.reset();
            }}
          />
        ) : (
          <EmailDialog
            control={forgotPasswordAndTwoFactorVerificationDialog.control}
            title="Esqueci minha senha"
            bodyText="Digite seu endereço de e-mail cadastrado no sistema para verificar sua identidade"
            name="forgot-both"
            show={forgotPasswordAndTwoFactorVerificationDialog.show}
            onHide={forgotPasswordAndTwoFactorVerificationDialog.close}
            method="post"
            errors={forgotPasswordAndTwoFactorVerificationDialog.validator.errors}
            validated={forgotPasswordAndTwoFactorVerificationDialog.validator.validated}
            onSubmit={forgotPasswordAndTwoFactorVerificationDialog.handleSubmit(
              forgotPasswordAndTwoFactorVerificationDialog.onSubmit
            )}
            register={forgotPasswordAndTwoFactorVerificationDialog.register}
          />
        )}
      </DialogGroup>

      <StyledFormGroup className="form-group">
        <CenterAligner className="flex-column">
          {children}
          <LinkButtonGroup
            onClickForgotPasswordDialog={forgotPasswordFormDialog.open}
            onClickForgotTwoFactorVerificationDialog={forgotTwoFactorVerificationDialog.open}
            onClickForgotPasswordAndTwoFactorVerificationDialog={
              forgotPasswordAndTwoFactorVerificationDialog.open
            }
          />
        </CenterAligner>
      </StyledFormGroup>

      <SuccessToast
        show={
          forgotPasswordFormDialog.submitResponse.success ||
          forgotTwoFactorVerificationDialog.submitResponse.success
        }
        onShow={() => {
          forgotPasswordFormDialog.submitResponse.reset();
          forgotTwoFactorVerificationDialog.submitResponse.reset();
          forgotPasswordAndTwoFactorVerificationDialog.submitResponse.reset();
        }}
        text="Sua solicitação foi efetuada com sucesso, por favor verifique seu email"
      />
    </>
  );
};

export default ForgotDialogues;
