import { Accordion, AccordionProps } from 'react-bootstrap';
import FetchParents, { FetchParentsProps } from '../../Requests/components/fetchParents';
import FetchPossibleUsers from '../../Requests/components/fetchPossibleUsers';
import CategoryAccordionBadge from './categoryAccordion.badge';
import CategoryAccordionButton from './categoryAccordion.button';
import CategoryAccordionChildren from './categoryAccordion.children';
import CategoryAccordionCloseButton from './categoryAccordion.closeButton';
import {
  CategoryAccordionContext,
  CategoryAccordionContextProps,
  CategorySelectable,
} from './categoryAccordion.context';
import CategoryAccordionCategories from './categoryAccordion.fetchCategories';
import CategoryAccordionHeader from './categoryAccordion.header';
import useCategoryAccordion from './categoryAccordion.hook';
import CategoryAccordingLoading from './categoryAccordion.loading';
import CategoryAccordionTitle from './categoryAccordion.title';
import CategoryAccordionItem from './item/categoryAccordion.item';

export interface CategoryAccordionProps extends AccordionProps {
  boxClassificationId: number;
  categories?: CategorySelectable | null;
}

const CategoryAccordion = ({
  boxClassificationId: boxClassificationIdProps,
  categories: categoriesProp,
  children,
  ...rest
}: CategoryAccordionProps) => {
  const {
    boxClassificationId,
    categories,
    monitor,
    parents,
    setBoxClassificationId,
    setCategories,
    setParents,
    status,
    setUsers,
    users,
  } = useCategoryAccordion({
    boxClassificationId: boxClassificationIdProps,
    categories: categoriesProp,
  });

  return (
    <CategoryAccordion.Context.Provider
      value={{
        categories,
        setCategories,
        boxClassificationId,
        setBoxClassificationId,
        error: status.error,
        loading: status.loading,
        success: status.success,
        parents,
        setParents,
        monitor,
        users,
        setUsers,
      }}
    >
      <Accordion {...rest}>{children}</Accordion>
    </CategoryAccordion.Context.Provider>
  );
};

CategoryAccordion.Loading = CategoryAccordingLoading;
CategoryAccordion.Context = CategoryAccordionContext;
CategoryAccordion.Children = CategoryAccordionChildren;
CategoryAccordion.Header = CategoryAccordionHeader;
CategoryAccordion.Item = CategoryAccordionItem;
CategoryAccordion.Body = Accordion.Body;
CategoryAccordion.Badge = CategoryAccordionBadge;
CategoryAccordion.Button = CategoryAccordionButton;
CategoryAccordion.CloseButton = CategoryAccordionCloseButton;
CategoryAccordion.Title = CategoryAccordionTitle;
CategoryAccordion.FetchCategories = CategoryAccordionCategories;
CategoryAccordion.FetchParents = (
  props: Omit<FetchParentsProps<CategoryAccordionContextProps>, 'context'>
) => <FetchParents context={CategoryAccordionContext} {...props} />;
CategoryAccordion.FetchPossibleUsers = () => (
  <FetchPossibleUsers context={CategoryAccordionContext} />
);

export default CategoryAccordion;
