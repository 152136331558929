import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import ErrorFormAlert, { FormError } from '../../../../../../@shared/Alerts/errorFormAlert';
import { InputGroup } from '../../../../../../@shared/Fields/models';
import InfoPage from '../../../../../../@shared/template/_InfoPage';
import LoadingPage from '../../../../../../@shared/template/_loadingPage';

interface TagFormProps {
  fields: InputGroup[];
  control: any;
  isLoading: boolean;
  errors: Record<string, FormError> | undefined;
  onSubmit;
}

const TagForm = ({ fields, errors, onSubmit, isLoading, control }: TagFormProps) => {
  if (isLoading) return <LoadingPage />;
  if (fields.length === 0)
    return (
      <Container>
        <Row>
          <div className="w-100 h-100 p-4">
            <Container>
              <ErrorFormAlert errors={errors} />
            </Container>
          </div>
        </Row>
        <Row>
          <InfoPage text="Não há itens para serem definidos" hiddenLink />
        </Row>
      </Container>
    );
  return (
    <>
      <Form onSubmit={onSubmit} className="mb-5">
        <Form.Group className="d-flex align-content-center justify-content-center">
          <Stack gap={3}>
            {fields?.map((e) => (
              <Container key={Math.random()}>
                <Row>
                  <Col sm="3" md="6" className="text-end">
                    <Form.Label>{e.label}</Form.Label>
                  </Col>
                  <Col>
                    <Controller
                      defaultValue={false}
                      control={control}
                      name={`tags.${String(e.id)}`}
                      render={({ field }) => (
                        <Form.Check
                          name={`tags.${String(e.id)}`}
                          type="switch"
                          disabled
                          defaultChecked={field.value}
                          onChange={(k) => {
                            field.onChange(k.target.checked);
                          }}
                          className="h5"
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Container>
            ))}
          </Stack>
        </Form.Group>
        <Button hidden type="submit" />
      </Form>
    </>
  );
};

export default TagForm;
