/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { RequestPropsOmitFetch, UseRequest } from '../models';

import { IError } from '../Verifiers/verifyError';

export default (allFetch: UseRequest[] | RequestPropsOmitFetch[]) => {
  const [data, setData] = useState<UseRequest[] | RequestPropsOmitFetch[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [backendError, setBackendError] = useState<Array<IError>>([]);

  useEffect(() => {
    const castData = JSON.stringify(data);
    const castAllFetch = JSON.stringify(allFetch);
    if (castData !== castAllFetch) setData(allFetch);
  }, [allFetch, data]);

  useEffect(() => {
    const filterLoading = data.filter((y) => y?.loading === true);
    const filterSuccess = data.filter((y) => y?.success === true);
    const filterError = data.filter((y) => y?.error === true);
    const filterBackendError = data
      .map((e) => e.backEndErrors)
      // .filter((y) => y !== {})
      .filter((y) => y !== undefined);

    setLoading(filterLoading.length > 0);
    setSuccess(filterSuccess.length > 0);
    setError(filterError.length > 0);
    setBackendError(filterBackendError);
  }, [data]);

  return {
    loading,
    success,
    error,
    backendError,
  };
};
