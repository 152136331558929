import { Container, Row } from 'react-bootstrap';
import { JsxElements, SetupCode } from '../../models';

import QrCode from './qrCode';

interface VerifyAlreadyDoneTwoStepProps {
  authenticator: SetupCode | null;
  isVerifiedTwoFactors: boolean;
  children: JsxElements;
}

const VerifyAlreadyDoneTwoStepPresentation = ({
  children,
  authenticator,
  isVerifiedTwoFactors,
}: VerifyAlreadyDoneTwoStepProps) => (
  <Container>
    <Row className="h-100">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="w-50">
          {isVerifiedTwoFactors === true ? (
            <div>{children}</div>
          ) : (
            <>
              {authenticator !== null && authenticator !== undefined && (
                <div className="d-flex flex-column align-items-center">
                  <QrCode
                    manualEntryKey={authenticator.manualEntryKey}
                    qrCodeSetupImageUrl={authenticator.qrCodeSetupImageUrl}
                  />
                </div>
              )}
              <div>{children}</div>
            </>
          )}
        </div>
      </div>
    </Row>
  </Container>
);

export default VerifyAlreadyDoneTwoStepPresentation;
