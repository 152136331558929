import { createContext } from 'react';

export type AddAccordionChildren<T = any> = Omit<AddAccordionState, 'children' | 'value'> & {
  value: T;
};

export type AddAccordionActions<T> = {
  add: (value: T) => void;
  remove: (index: number) => void;
  set: (value: T[]) => void;
  show: boolean;
  close: () => void;
  open: () => void;
};

export interface AddAccordionState<T = any> extends AddAccordionActions<T> {
  value: T[];
}

const AddAccordionContext = createContext<AddAccordionState | undefined>(undefined);

export default AddAccordionContext;
