import { Form } from 'react-bootstrap';
import FieldCollectionPanel from './fieldCollectionPanel';
import FieldPanel from './fieldPanel';
import TitlePanel from './titlePanel';

interface PanelProps {
  children: JSX.Element[];
  className?: string;
}

const Panel = ({ children, className = '' }: PanelProps) => (
  <Form className={`panel-bg-light ${className}`}>{children}</Form>
);

Panel.Title = TitlePanel;
Panel.FieldCollection = FieldCollectionPanel;
Panel.Field = FieldPanel;
export default Panel;
