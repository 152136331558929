export interface IRedirectTypeClient {
  isAdmin: boolean;
  isSubsidiaryAdmin: boolean;
  onAdmin: JSX.Element;
  onSubsidiaryAdmin: JSX.Element;
  onClient: JSX.Element;
}

const RedirectTypeClient = ({
  isAdmin,
  isSubsidiaryAdmin,
  onClient,
  onAdmin,
  onSubsidiaryAdmin,
}: IRedirectTypeClient) => {
  if (isAdmin) return onAdmin;
  if (isSubsidiaryAdmin) return onSubsidiaryAdmin;
  return onClient;
};

export default RedirectTypeClient;
