import { useEffect, useState } from 'react';
import categoryEndpoints from '../../modules/category/category.endpoints';
import { InputGroup } from '../Fields/models';
import useRequest from './useRequest';

type UseRequestGetBoxClassificationItemOption = {
  disableDefaultFetch?: boolean;
  extraCondition?: boolean;
};

const useRequestGetBoxClassificationItemTextBox = (
  boxClassificationId: number,
  options?: UseRequestGetBoxClassificationItemOption
) => {
  const [params, setParams] = useState<null | { boxClassificationId: number }>(null);
  const [items, setItems] = useState<InputGroup[]>([]);
  const requestItem = useRequest<InputGroup[]>(
    categoryEndpoints.listBoxClassificationItemTextBox(params as any)
  );

  useEffect(() => {
    if (boxClassificationId?.toString() !== params?.toString() && boxClassificationId) {
      setParams({ boxClassificationId: Number(boxClassificationId) });
    }
  }, [boxClassificationId]);

  requestItem.useFetchBy(
    options?.disableDefaultFetch !== true &&
      (options?.extraCondition !== undefined && options.extraCondition !== null
        ? params && options.extraCondition
        : params),
    params
  );
  requestItem.useSuccess(() => {
    setItems(requestItem.successData ?? []);
  });

  return {
    ...requestItem,
    params,
    successData: items,
  };
};

export default useRequestGetBoxClassificationItemTextBox;
