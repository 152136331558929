import useAuthSlice from '../Auth/useAuthSlice';
import { TwoStepAuthenticator } from '../models';
import RedirectTypeClient from './redirectTypeClient';
import RedirectVerifyAlreadyDoneTwoStep from './RedirectVerifyAlreadyDoneTwoStep';
import typePinForm from './typePinForm';

interface TwoFactorFormProps {
  onSuccess: JSX.Element;
  onSuccessType?: 'component' | 'redirect';
  onAdmin: JSX.Element;
  onSubsidiaryAdmin?: JSX.Element;
  onClient?: JSX.Element;
  authenticator?: TwoStepAuthenticator;
}

const RedirectTypeClientFor2FaForm = ({
  authenticator,
  onSuccessType = 'redirect',
  onSubsidiaryAdmin,
  onAdmin,
  onClient,
  onSuccess,
}: TwoFactorFormProps) => {
  const redux = useAuthSlice();
  const { twoStepAuthenticator } = redux.useState();
  const auth = authenticator ?? twoStepAuthenticator;
  const defaultComponent = typePinForm(onSuccessType, auth, onSuccess);

  if (auth?.token) redux.useLogin('RedirectTypeClientFor2FaForm', auth?.token);
  return (
    <RedirectTypeClient
      isAdmin={authenticator?.hasByPass ?? twoStepAuthenticator?.hasByPass ?? false}
      onAdmin={onAdmin}
      isSubsidiaryAdmin={!authenticator?.hasByPass ?? !twoStepAuthenticator?.hasByPass ?? false}
      onSubsidiaryAdmin={onSubsidiaryAdmin ?? defaultComponent}
      onClient={onClient ?? defaultComponent}
    />
  );
};

const TwoFactorAuthenticationForm = ({
  onAdmin,
  onSuccess,
  authenticator,
  onClient,
  onSubsidiaryAdmin,
  onSuccessType = 'redirect',
}: TwoFactorFormProps) => {
  if (!authenticator) {
    return (
      <RedirectVerifyAlreadyDoneTwoStep
        onSuccess={
          <RedirectTypeClientFor2FaForm
            authenticator={authenticator}
            onAdmin={onAdmin}
            onClient={onClient}
            onSubsidiaryAdmin={onSubsidiaryAdmin}
            onSuccess={onSuccess}
            onSuccessType={onSuccessType}
          />
        }
      />
    );
  }
  return (
    <RedirectTypeClientFor2FaForm
      authenticator={authenticator}
      onAdmin={onAdmin}
      onClient={onClient}
      onSubsidiaryAdmin={onSubsidiaryAdmin}
      onSuccess={onSuccess}
      onSuccessType={onSuccessType}
    />
  );
};

export default TwoFactorAuthenticationForm;
