import { Accordion } from 'react-bootstrap';
import { BoxClassificationItem } from '../../classification.endpoint';

export interface BoxClassificationItemAccordionPresentationProps {
  boxClassificationId: number;
  boxClassificationItems: BoxClassificationItem[];
  defaultActiveKey?: string;
  className?: string;
  header?: React.ReactNode;
  body: (e: {
    boxClassificationId: number;
    key: number;
    boxClassificationItem: BoxClassificationItem;
    eventKey: string;
  }) => React.ReactNode;
}

const BoxClassificationItemAccordionPresentation = ({
  boxClassificationId,
  boxClassificationItems,
  defaultActiveKey,
  className,
  body,
  header,
}: BoxClassificationItemAccordionPresentationProps) => (
  <Accordion className={className} defaultActiveKey={defaultActiveKey}>
    {header}
    {Array.from(boxClassificationItems).map((boxClassificationItem, index) =>
      body({
        key: boxClassificationItem.boxClassificationItemId,
        boxClassificationId,
        boxClassificationItem,
        eventKey: index.toString(),
      })
    )}
  </Accordion>
);

export default BoxClassificationItemAccordionPresentation;
