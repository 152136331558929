import categoryEndpoints from '../../modules/category/category.endpoints';
import { CheckBox } from '../Fields/models';
import { UseRequestOption } from './requests.type';
import useRequest from './useRequest';

const useRequestGetPotentialUsersForCategoryCheckBox = (options?: UseRequestOption) => {
  const request = useRequest<CheckBox[]>(categoryEndpoints.listPotentialUsersAssociate());
  const extraValid = options?.extraCondition ? options?.extraCondition : true;
  const disableDefaultFetchValid = options?.disableDefaultFetch ? false : extraValid;

  request.useFetchBy(disableDefaultFetchValid && !request.loading, 'init');

  return {
    ...request,
    successData: request.successData ?? [],
  };
};

export default useRequestGetPotentialUsersForCategoryCheckBox;
