/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosRequestConfig } from 'axios';
import EnumerableFormat from '../../constants/enumerableFormat';
import { Category } from './features/CategoryForm/types';

const pathUserMeCategories = 'users/me/categories';
const pathCategories = 'categories';
const pathBoxClassification = 'box-classifications';
const pathBoxClassificationItems = 'boxes/descriptions';

const categoryEndpoints = {
  addBoxClassification(data: { name: string; categoryId: number }) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/me/add`,
      data,
    } as AxiosRequestConfig;
  },
  patchFormatOfBoxClassificationItem(data: Record<number, string>) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/items/formats/patch`,
      data: { formats: data },
    } as AxiosRequestConfig;
  },

  listBoxClassificationItemTextBox(params: { boxClassificationId: number }) {
    return {
      method: 'get',
      url: `${pathBoxClassification}/${params?.boxClassificationId}/items/browse:dynamic-field`,
    } as AxiosRequestConfig;
  },

  listBoxClassificationOptions(params?: { categoryId?: number }): AxiosRequestConfig {
    if (params?.categoryId) {
      return {
        method: 'get',
        url: `${pathBoxClassification}/me/browse:options`,
        params: { ...params, format: EnumerableFormat.OPTION },
      };
    }
    return {
      method: 'get',
      url: `${pathBoxClassification}/me/browse:options`,
      params: { ...params, format: EnumerableFormat.OPTION },
    };
  },

  listUserCategoryOptions(params?: { classificationId?: number }) {
    return {
      method: 'get',
      url: `users/me/browse-categories:options`,
      params: { ...params },
    } as AxiosRequestConfig;
  },
  listPotentialUsersAssociate() {
    return {
      method: 'get',
      url: `${pathCategories}/me/potential-users-associate`,
    } as AxiosRequestConfig;
  },
  browseCategories() {
    return {
      method: 'get',
      url: `categories/me/browse:rows`,
    } as AxiosRequestConfig;
  },
  browseOptions() {
    return {
      method: 'get',
      url: `categories/me/browse:options`,
    } as AxiosRequestConfig;
  },
  browseTreeView() {
    return {
      method: 'get',
      url: `clients/me/browse-categories:treeview`,
    } as AxiosRequestConfig;
  },
  listCategoriesCreateOrSelectOptions() {
    return {
      method: 'get',
      url: `clients/me/users/me/list-categories:create-or-select-options`,
    } as AxiosRequestConfig;
  },
  listMigrationsCategories() {
    return {
      method: 'get',
      url: `clients/me/list-categories`,
      params: { isMigration: true },
    } as AxiosRequestConfig;
  },
  listMigrationsTypes() {
    return {
      method: 'get',
      url: `list-migration-types`,
    } as AxiosRequestConfig;
  },
  edit(url: { categoryId: number }, data: Category) {
    return {
      method: 'post',
      url: `categories/me/${url.categoryId}/edit`,
      data,
    } as AxiosRequestConfig;
  },
  detail(url: { categoryId }) {
    return {
      method: 'get',
      url: `categories/me/${url.categoryId}/detail`,
    } as AxiosRequestConfig;
  },
  add(data: {
    name: string;
    parentId: number | null | undefined;
    isMigration: boolean;
    users: number[];
    isDocumentCategory?: boolean;
    boxClassificationId?: number;
  }) {
    return {
      method: 'post',
      url: `categories/me/add`,
      data,
    } as AxiosRequestConfig;
  },
};

export default categoryEndpoints;
