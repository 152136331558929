import { Button, Container, Row, Stack } from 'react-bootstrap';

interface StepButtonsPresentationProps {
  disableNextStep?: boolean;
  disablePreviousStep?: boolean;
  onClickPreviousStep?: Function;
  onClickNextStep?: Function;
  hiddenPreviousStep?: boolean;
  lastStep?: boolean;
}

const StepButtonsPresentation = ({
  disableNextStep,
  disablePreviousStep,
  onClickPreviousStep,
  hiddenPreviousStep,
  onClickNextStep,
  lastStep: isLastStep = false,
}: StepButtonsPresentationProps) => (
  <Container>
    <Row>
      <Stack direction="horizontal" className="justify-content-between">
        <Button
          type="button"
          size="lg"
          disabled={disablePreviousStep}
          onClick={() => {
            if (onClickPreviousStep) onClickPreviousStep();
          }}
          hidden={hiddenPreviousStep}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          size="lg"
          disabled={disableNextStep}
          onClick={() => {
            if (onClickNextStep) onClickNextStep();
          }}
        >
          {isLastStep ? 'Enviar' : 'Proximo'}
        </Button>
      </Stack>
    </Row>
  </Container>
);

export default StepButtonsPresentation;
