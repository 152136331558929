import { useEffect } from 'react';
import isEmpty from '../../../utils/util';

const useSharePopulateValue = (
  context: StepsFormContextValues,
  obj: any,
  deps?: React.DependencyList
) => {
  useEffect(() => {
    if (!isEmpty(context?.populate)) {
      context?.setPopulate({ ...context.populate, ...obj });
    } else {
      context?.setPopulate({ ...obj });
    }
  }, deps ?? [JSON.stringify(obj)]);
};
export default useSharePopulateValue;
