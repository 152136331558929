import { Pager } from '../models';

const pagerReducer = {
  turnNextPage: (state: Pager) => {
    state.page += 1;
    state.totalPageChanges += 1;
  },
  turnPreviousPage: (state: Pager) => {
    state.page -= 1;
    state.totalPageChanges += 1;
  },
};

export default pagerReducer;
