import { Form } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';
import Field, { FieldProps } from './Field';

interface SwitchFieldProps extends FieldProps {
  name?: string;
  control: Control | Control<any>;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const SwitchField = ({
  name,
  control,
  label,
  className,
  isInvalid,
  id,
  defaultChecked,
  disabled,
  ...props
}: SwitchFieldProps) => {
  const { field } = useController({
    name: id ?? name ?? Math.random().toString(),
    control,
    defaultValue: defaultChecked,
  });

  return (
    <Field {...props}>
      <Form.Check
        disabled={disabled}
        checked={field.value}
        value={field.value}
        type="switch"
        name={field.name}
        label={label}
        isInvalid={isInvalid}
        className={className}
        onChange={(e) => field.onChange(e.target.checked)}
      />
    </Field>
  );
};
export default SwitchField;
