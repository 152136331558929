import ErrorPage from '../template/_errorPage';
import { ErrorGroupContext } from './useErrorGroup';
import useErrorGroupProvider from './useErrorGroupProvider';

interface ErrorGroupProps {
  children: JSX.Element[] | JSX.Element;
  linkUrl?: string;
  linkText?: string;
  onClickLink?: Function;
  hiddenLink?: boolean;
}

const ErrorGroup = ({ children, ...props }: ErrorGroupProps) => {
  const context = useErrorGroupProvider();

  return (
    <ErrorGroupContext.Provider value={context}>
      <div className={context.error ? 'h-100 w-100' : 'd-none h-100 w-100'}>
        <ErrorPage text={context.message} {...props} />
      </div>
      <div className={!context.error ? 'h-100 w-100' : 'd-none h-100 w-100'}>{children}</div>
    </ErrorGroupContext.Provider>
  );
};
export default ErrorGroup;
