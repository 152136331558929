import { useEffect, useState } from 'react';
import { Status } from 'status';

export type MonitorValues = Record<string, Status>;
export type Track = (key: string, value: Status) => void;
export type FnKey = (key: string) => void;

export interface TrackProps {
  track: Track;
}

export interface Monitor extends Status {
  values: MonitorValues;
  track: Track;
  setFailure: FnKey;
  setSuccess: FnKey;
  setLoading: FnKey;
  reset: FnKey;
}

export interface Monitoring extends Status {
  monitor: {
    values: MonitorValues;
    track: Track;
    setFailure: FnKey;
    setSuccess: FnKey;
    setLoading: FnKey;
    reset: FnKey;
  };
}

const useMonitor = (): Monitor => {
  const [values, setValues] = useState<MonitorValues>({});
  const [status, setStatus] = useState({ error: false, loading: false, success: false });

  useEffect(() => {
    const array = Object.values(values);
    const error = array.some((e) => e.error);
    const loading = array.some((e) => e.loading);
    const success = array.some((e) => e.success);
    setStatus({ error, loading, success });
  }, [values]);

  const track: Track = (key: string, value: Status) => {
    const newValues = { ...values, [key]: value };
    setValues(newValues);
  };

  const setFailure = (key: string) => {
    track(key, { error: true, loading: false, success: false });
  };

  const setSuccess = (key: string) => {
    track(key, { error: false, loading: false, success: true });
  };

  const setLoading = (key: string) => {
    track(key, { error: false, loading: true, success: false });
  };

  const reset = (key: string) => {
    track(key, { error: false, loading: false, success: false });
  };

  return {
    setFailure,
    setSuccess,
    setLoading,
    error: status.error,
    loading: status.loading,
    success: status.success,
    track,
    values,
    reset,
  };
};

export default useMonitor;
