import { AxiosError } from 'axios';
import useAuthSlice from '../@shared/Auth/useAuthSlice';
import { ThunkApi } from '../@shared/models';
import api from '../config/api/api';
import HelpersInterceptor from './HelpersInterceptor';

export function interceptorRequest() {
  api.interceptors.request.use(
    (request) => HelpersInterceptor.setAuthTokenInHeaders(request),
    (error: AxiosError) => Promise.reject(error.request)
  );
}

export function useInterceptorResponse() {
  const redux = useAuthSlice();
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const statusCode = error.response?.status;
      switch (statusCode) {
        case 401:
          HelpersInterceptor.tryRefreshToken(
            redux.generateNewTokensSuccess,
            redux.generateNewTokensFail,
            error
          );
          break;

        case 403:
          // HelpersInterceptor.redirect403Forbidden(dispatch, error);
          break;

        default:
          break;
      }
      // const response = error.response as AxiosResponse;
      return Promise.reject(error);
    }
  );
}

export default function catchErrorAxios(error: any, thunkApi: ThunkApi) {
  const { data } = error;
  const reject = thunkApi.rejectWithValue(data);
  return reject;
}
