import { useContext } from 'react';
import { Control } from 'react-hook-form';
import RadioGroup from '../../../../../../@shared/Fields/radioButtonGroup';
import { shouldPrefix } from '../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../categoryForm.context';
import { CategoryFormRequiredFieldsProps } from '../types';

const CategoryFormIsDocumentCategoryField = ({
  readonly,
  prefix,
  errors,
  control: controlProps,
}: CategoryFormRequiredFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProps ?? context?.config?.control ?? ({} as Control);

  return (
    <RadioGroup
      label="É categoria de documento"
      control={control}
      name={shouldPrefix(prefix, 'isDocumentCategory')}
      type="bool"
      errorMessage={errors?.isDocumentCategory?.message}
      isInvalid={errors?.isDocumentCategory !== undefined}
    >
      {[
        { key: 'isDocumentCategory.true', label: 'Sim', value: true, disable: readonly },
        { key: 'isDocumentCategory.false', label: 'Não', value: false, disable: readonly },
      ]}
    </RadioGroup>
  );
};

export default CategoryFormIsDocumentCategoryField;
