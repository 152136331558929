import { Button, Container } from 'react-bootstrap';
import { Link, LinkProps } from 'react-router-dom';

interface RedirectButtonProps<T> extends LinkProps<T> {
  variant?: string;
  className?: string;
  children?: React.ReactNode | string | number;
}

const RedirectButton = ({ to, className, variant, children }: RedirectButtonProps<any>) => (
  <Container>
    <Link to={to}>
      <Button variant={variant} className={className}>
        {children}
      </Button>
    </Link>
  </Container>
);

export default RedirectButton;
