import { Alert, Form } from 'react-bootstrap';
import AddAccordion from '../../../../../../@shared/Accordions/Add';
import CategoryAccordion from '../../../../../../@shared/Accordions/Categories/categoryAccordion';
import Step from '../../../../../../@shared/Steps';
import SuccessPage from '../../../../../../@shared/template/_successPage';
import CategoryChooserDialog from '../../../../../category/features/CategoryChooserDialog';
import CategoryAddForm from '../../../../../category/features/CategoryForm/categoryAddForm';
import { CategoryEditFormState } from '../../../../../category/features/CategoryForm/types';
import { classificationUrls } from '../../../../classification.routes.config';
import { BoxClassificationAddFormProps } from '../boxClassificationAddFormSteps.types';
import useBoxClassificationAddFormStep3 from './boxClassificationAddForm.step3.hook';

const BoxClassificationAddFormStep3 = ({
  onNext,
  onPrevious,
  ...rest
}: BoxClassificationAddFormProps.Step3) => {
  const {
    control,
    errors,
    fields,
    parents,
    categories,
    handleSubmit,
    isEmpty,
    success,
    successData,
  } = useBoxClassificationAddFormStep3({
    ...rest,
    onSubmit: onNext,
  });

  if (success)
    return (
      <SuccessPage
        linkUrl={classificationUrls.edit(successData.boxClassificationId)}
        linkText="ir para nova classificação"
        text="Classificação Adicionada com sucesso"
      />
    );
  return (
    <>
      {isEmpty && (
        <Alert variant="danger" className="mb-0 rounded-bottom-0">
          <p className="m-0">{errors.categories?.message}</p>
        </Alert>
      )}

      <AddAccordion value={fields.categories} onChange={fields.onCategoriesChange}>
        <Form onSubmit={handleSubmit} className="h-100">
          <Step>
            <div>
              <AddAccordion.Header title="Categorias" className={isEmpty ? 'rounded-top-0' : ''} />
              <AddAccordion.Body<CategoryEditFormState>>
                {(e, actions, i) => (
                  <CategoryAccordion.Item key={i.toString()} eventKey={i.toString()}>
                    <CategoryAccordion.Button>
                      <CategoryAccordion.CloseButton onClick={() => actions.remove(i)} />
                      <CategoryAccordion.Title>{e.name}</CategoryAccordion.Title>
                      <CategoryAccordion.Badge
                        // eslint-disable-next-line no-nested-ternary
                        type={e.readonly === undefined ? undefined : e.readonly ? 'include' : 'new'}
                      />
                    </CategoryAccordion.Button>
                    <CategoryAccordion.Body>
                      <CategoryAddForm.Fields
                        prefix={`categories[${i}]`}
                        readonly={e.readonly}
                        control={control}
                        parents={parents}
                        errors={
                          (Array.isArray(errors.categories) ? errors.categories : [])?.at(i) as any
                        }
                        direction="horizontal"
                      />
                    </CategoryAccordion.Body>
                  </CategoryAccordion.Item>
                )}
              </AddAccordion.Body>
            </div>

            <Step.ButtonGroup>
              <Step.PreviousButton
                onClick={() => onPrevious && onPrevious({ categories: fields.categories })}
              />
              <Step.NextButton />
            </Step.ButtonGroup>
          </Step>
        </Form>

        <AddAccordion.Dialog>
          {(_, actions) => (
            <CategoryChooserDialog
              show={actions.show}
              options={{ categories: categories as any, parents }}
              defaultValue={fields.categories}
              onSubmit={(l) => {
                actions.set(l);
              }}
              onHide={actions.close}
            />
          )}
        </AddAccordion.Dialog>
      </AddAccordion>
    </>
  );
};

export default BoxClassificationAddFormStep3;
