import Layout from '../../layout';
import LoginForm from '../features/LoginForm';

const LoginPage = () => (
  <Layout showLoggedComponents={false}>
    <LoginForm />
  </Layout>
);

export default LoginPage;
