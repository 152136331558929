import { Container, FormGroup, Table } from 'react-bootstrap';
import './index.css';

import Field from '../Field';
import Header from './header.presentation';

interface TableFieldPresentationProps {
  label?: string;
  helperText: string;
  isInvalid?: boolean;
  top: JSX.Element[] | JSX.Element;
  errorMessage?: string;
  className?: string;
  children: JSX.Element[] | JSX.Element;
}

const TableField = ({
  helperText,
  label,
  top,
  isInvalid,
  errorMessage,
  className = '',
  children,
}: TableFieldPresentationProps) => (
  <Field
    label={label}
    helperText={helperText}
    isInvalid={isInvalid}
    containerClassName="table-select"
    errorMessage={errorMessage}
  >
    <Container className="overflow-auto p-0 container-border d-grid">
      <FormGroup>
        <Header>
          <>{top}</>
        </Header>
        <Table bordered className={`m-0 ${className}`}>
          {children}
        </Table>
      </FormGroup>
    </Container>
  </Field>
);

export default TableField;
