/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputGroup } from '../../../../../@shared/Fields/models';
import { Option } from '../../../../../@shared/models';
import useStepsForm from '../../../../../@shared/MultiStepForm/StepsForm/stepsForm.hook';
import { POPULATE_CLASSIFICATION, POPULATE_DESCRIPTIONS } from '../boxWizardForm.constants';

type PopulateData = {
  classificationsOptions: Option[];
  descriptionFields: InputGroup[];
};

const useInsertPopulateFields = (options: PopulateData) => {
  const stepForm = useStepsForm();
  const populateValue = {
    [POPULATE_DESCRIPTIONS]: options.descriptionFields,
    [POPULATE_CLASSIFICATION]: options.classificationsOptions,
  };

  stepForm.useSharePopulateValue(populateValue, [JSON.stringify(options)]);
};

export default useInsertPopulateFields;
