import { useState } from 'react';

type Error = { message: string; value: boolean };

function getMessage(state: Map<string, Error>) {
  const v = Array.from(state.values())
    .filter((e) => e.value === true)
    .map((e) => e.message);

  switch (v.length) {
    case 0:
      return null;
    case 1:
      return v.at(0);

    default:
      return v.reduce((p, c) => `${p}\n${c}`);
  }
}

const useErrorGroupProvider = () => {
  const [state, setState] = useState<Map<string, Error>>(new Map<string, Error>());

  return {
    error: state.size > 0 && !Array.from(state.values()).every((e) => e.value === false),
    message: getMessage(state),

    setError(name, message, value) {
      const newState = new Map<string, Error>(state.entries());
      newState.set(name, { message, value });
      setState(newState);
    },
  };
};

export default useErrorGroupProvider;
