import { Nav, Stack } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { JsxElements } from '../../../@shared/models';

import Lines from './lines';

interface ISideBar {
  children: JsxElements;
}

const Menu = ({ children }: ISideBar) => (
  <div className="bg-secondary text-white h-100">
    <Nav className="flex-column position-sticky sticky-top h-100 d-flex align-items-end">
      <Stack direction="vertical" className="justify-content-between">
        <div>{children}</div>
        <div className="nav-link">
          <Image src="./assets/arquivo_local_logo.png" width={150} height={50} />
        </div>
      </Stack>
    </Nav>
  </div>
);

Menu.Lines = Lines;
export default Menu;
