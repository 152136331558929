import Layout from '../../layout';
import ResetPasswordForm from '../features/ResetPasswordForm';

const ResetPasswordEditPage = () => (
  <Layout showLoggedComponents={false}>
    <ResetPasswordForm />
  </Layout>
);

export default ResetPasswordEditPage;
