import { DependencyList, useEffect, useState } from 'react';

export interface IDialogParams {
  handleClose?: Function;
  handleOpen?: Function;
}

export interface IDialog {
  show: boolean;
  open: () => void;
  close: () => void;
  closeCount: number;
  useOpen: (condition: any, trigger?: React.DependencyList | undefined) => void;
  useClose: (condition: any, trigger?: React.DependencyList | undefined) => void;
  useHandleClose: (f: Function) => void;
}

export default (params?: IDialogParams): IDialog => {
  const [show, setShow] = useState(false);
  const [closeCount, setCloseCount] = useState(0);

  const open = () => {
    if (params?.handleOpen) {
      params.handleOpen();
    }
    setShow(true);
  };
  const close = () => {
    if (params?.handleClose) {
      params.handleClose();
    }
    setShow(false);
    setCloseCount(closeCount + 1);
  };

  const useHandleClose = (f: Function) => {
    useEffect(() => {
      if (show === false && closeCount > 0) {
        f();
      }
    }, [show]);
  };

  const useOpen = (condition: any, trigger?: DependencyList) => {
    useEffect(() => {
      if (condition) open();
    }, trigger ?? [condition]);
  };

  const useClose = (condition: any, trigger?: DependencyList) => {
    useEffect(() => {
      if (condition) close();
    }, trigger ?? [condition]);
  };

  return {
    show,
    open,
    close,
    closeCount,
    useOpen,
    useClose,
    useHandleClose,
  };
};
