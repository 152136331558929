import { AxiosError, AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';

import { HttpRequest } from 'http';
import api from '../../config/api/api';

import { Exception, UseRequest } from '../models';
import { util } from '../utils/util';

const useRequest = <S extends any = any, E extends Exception = Exception>(
  r?: AxiosRequestConfig
): UseRequest<S, E> => {
  const [requestConfig, setRequestConfig] = useState<AxiosRequestConfig>();
  const [status, setStatus] = useState<HttpRequest<S, E>>({
    backEndErrors: null,
    error: false,
    loading: false,
    success: false,
    successData: null,
    statusCode: 0,
  });
  const isActive = useRef<boolean>(false);
  const abortSignal = useRef<AbortController>(new AbortController());

  useMemo(() => {
    if (r !== undefined) {
      setRequestConfig(r);
    }
  }, [JSON.stringify(r)]);

  const reset = () => {
    setStatus({
      backEndErrors: null,
      error: false,
      loading: false,
      success: false,
      successData: null,
      statusCode: 0,
    });
  };

  const fetch = (r2?: AxiosRequestConfig) => {
    setStatus({
      backEndErrors: null,
      error: false,
      loading: true,
      success: false,
      successData: null,
      statusCode: 0,
    });
    const f = async () => {
      isActive.current = true;
      if (r2 !== undefined) {
        try {
          const response = await api.request({ ...r2, signal: abortSignal.current.signal });
          const data = await response?.data;
          setStatus({
            backEndErrors: null,
            error: false,
            loading: false,
            success: true,
            successData: data,
            statusCode: response.status,
          });
        } catch (er) {
          const axiosError = (await er) as AxiosError;
          const errorResponse = axiosError.response;

          const responseType = errorResponse?.config.responseType;

          if (axiosError.response?.status === 401) {
            throw axiosError;
          }

          if (responseType === 'blob') {
            const jsonString = await errorResponse?.data.text();
            const data = JSON.parse(jsonString);

            const backendError: Exception =
              {
                status: data?.status ?? null,
                data: data?.data ?? null,
                message: data?.message ?? data.title ?? null,
                source: data?.source ?? null,
                stackTrace: data?.stackTrace ?? null,
              } ?? errorResponse;
            setStatus({
              backEndErrors: backendError as E,
              error: true,
              loading: false,
              success: false,
              successData: null,
              statusCode: 0,
            });
          } else {
            if (axiosError.response?.status === 413) {
              const backendError: Exception =
                {
                  status: errorResponse?.status ?? null,
                  data: errorResponse?.data?.data ?? null,
                  message: 'Arquivo muito grande',
                  source: errorResponse?.data?.source ?? null,
                  stackTrace: errorResponse?.data?.stackTrace ?? null,
                } ?? errorResponse;
              setStatus({
                backEndErrors: backendError as E,
                error: true,
                loading: false,
                success: false,
                successData: null,
                statusCode: 0,
              });
              return;
            }
            const backendError: Exception =
              {
                status: errorResponse?.status ?? null,
                data: errorResponse?.data?.data ?? null,
                message: errorResponse?.data?.message ?? null,
                source: errorResponse?.data?.source ?? null,
                stackTrace: errorResponse?.data?.stackTrace ?? null,
              } ?? errorResponse;
            setStatus({
              backEndErrors: backendError as E,
              error: true,
              loading: false,
              success: false,
              successData: null,
              statusCode: 0,
            });
          }

          console.error(errorResponse);
        } finally {
          isActive.current = false;
        }
      }
    };

    if (!isActive.current) {
      f();
    }
  };

  const useFetchBy = (condition: any, trigger?: any | 'init') => {
    const f = requestConfig ? () => fetch(requestConfig) : fetch;
    const dependencyListEmptyWhenTriggerEqualArray =
      trigger === 'init' ? [] : [trigger ?? condition];
    useEffect(() => {
      if (condition) f();
    }, dependencyListEmptyWhenTriggerEqualArray);
  };

  const useFetchMemoBy = (condition: any, trigger?: any | 'init') => {
    const f = requestConfig ? () => fetch(requestConfig) : fetch;
    const dependencyListEmptyWhenTriggerEqualArray =
      trigger === 'init' ? [] : [trigger ?? condition];
    useMemo(() => {
      if (condition) f();
    }, dependencyListEmptyWhenTriggerEqualArray);
  };

  const useSuccess = (f: () => void) => {
    useEffect(() => {
      if (status.success) {
        f();
      }
    }, [JSON.stringify(status)]);
  };

  const useFailure = (f: () => void) => {
    useEffect(() => {
      if (status.error) {
        f();
      }
    }, [JSON.stringify(status)]);
  };

  const useLoading = (f: () => void) => {
    useEffect(() => {
      if (status.loading) {
        f();
      }
    }, [JSON.stringify(status)]);
  };

  const useChange = (f: () => void) => {
    useEffect(() => {
      f();
    }, [JSON.stringify(status)]);
  };

  const useRequestConfig = (config: AxiosRequestConfig<any> | undefined) => {
    useMemo(() => {
      if (!util.isEqualDeep(config, requestConfig)) setRequestConfig(config);
    }, [config]);
  };

  return {
    fetch: requestConfig ? () => fetch(requestConfig) : fetch,
    useChange,
    abortSignal: abortSignal.current,
    ...status,
    useFetchMemoBy,
    reset,
    useLoading,
    useFailure,
    setRequestConfig,
    requestConfig,
    useFetchBy,
    useSuccess,
    useRequestConfig,
    setStatus,
  };
};

export default useRequest;
