import { Option } from '../../../models';
import { SelectColumn } from '../../models';

type SelectColumnParams = {
  columnSize: number;
  id: string;
  options: Option[];
  key?: string[];
};

export default function createColumns(
  defaultValue: SelectColumn[],
  params: SelectColumnParams
): SelectColumn[] {
  return params.columnSize === 0
    ? []
    : Array(params.columnSize)
        .fill(null)
        .map((e, i) => ({
          key: defaultValue.at(i)?.key ?? params?.key?.at(i) ?? Math.random().toString(),
          disabled: defaultValue?.at(i)?.disabled ?? false,
          options: params.options,
        }));
}
