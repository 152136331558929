import AlertRedirect from '../Alerts/redirectAlert';

const SuccessPage = ({
  text,
  linkUrl,
  linkText,
  hiddenLink,
  className,
  onClick,
}: {
  linkText?: string;
  text?: string | JSX.Element;
  linkUrl?: string;
  hiddenLink?: boolean;
  className?: string;
  onClick?: () => void;
}) => (
  <div className={`w-100 h-100 p-4 ${className}`}>
    <AlertRedirect
      title="Sucesso"
      text={text ?? 'não definido'}
      variant="success"
      linkText={linkText}
      linkUrl={linkUrl}
      hiddenLink={hiddenLink}
      onClick={onClick}
    />
  </div>
);

export default SuccessPage;
