import { Control, useController } from 'react-hook-form';

const useDataGridCheckBox = (
  control: Control<any>,
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultValue?: number[] | ReadonlySet<string>
) => {
  const { field } = useController({
    control,
    name,
  });

  return { ...field, value: new Set(field.value) as ReadonlySet<string> };
};

export default useDataGridCheckBox;
