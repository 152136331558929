/* eslint-disable arrow-body-style */
import { TestConfig } from 'yup';
import { util } from '../../../../@shared/utils/util';
import yup from '../../../../config/yup/customYup';
import { BoxMovementCode, BoxRow } from '../../box.models';

// const allFilterAreNotNull: TestConfig<any> = {
//   name: 'allFilterAreNotNull',
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   test: (e) => {
//     return (
//       (e.currentPage != null && e.sizePage != null) ||
//       (e.initialDate != null && e.finalDate != null)
//     );
//   },
//   message:
//     'Para realizar uma consulta, é necessário escolher um dos filtros disponíveis: intervalo de datas ou por paginação.',
// };

// const isAValidDateRangeFilterAndIsNotNull: TestConfig<any> = {
//   name: 'isAValidDateRangeFilterAndIsNotNull',
//   test: (e) => {
//     const areNotNull = e.initialDate || e.finalDate;

//     if (areNotNull) return true;
//     const initialDate = util.dateIsoFormat(e.initialDate) ?? 'aaa';
//     const finalDate = util.dateIsoFormat(e.finalDate) ?? 'aaa';

//     const initialDateString = new Date(initialDate).toString().toLowerCase();
//     const finalDateString = new Date(finalDate).toString().toLowerCase();

//     const initialDateValid = initialDateString !== 'Invalid Date'.trim().toLowerCase();
//     const finalDateValid = finalDateString !== 'Invalid Date'.trim().toLowerCase();

//     return initialDateValid && finalDateValid;
//   },
//   message:
//     'Para realizar uma consulta por paginação é necessário que "data inicial" seja maior ou igual a "data final"',
// };

const isDifferentStatus: TestConfig<any> = {
  name: 'isDifferentStatus',
  message:
    'O arquivo não foi selecionado. Você não pode selecionar arquivos para movimento em grupo que estejam com status diferentes',
  test: ({ currentBoxMovement, selectedRows }) => {
    const anyStatusDifferent = !selectedRows?.every((v) => v?.status === currentBoxMovement);
    const twoOrMoreSelected = selectedRows?.length >= 2;
    const boxMomentNotNull = currentBoxMovement !== null && currentBoxMovement !== undefined;

    return !(anyStatusDifferent && twoOrMoreSelected && boxMomentNotNull);
  },
};

const isSolicitDigitalizationAndExceedLimit: TestConfig<any> = {
  name: 'isSolicitDigitalizationAndExceedLimit',
  message:
    'Você selecionou mais de um arquivo para digitalização. Apenas um arquivo pode ser selecionado por vez para ser digitalizado',
  test: ({ showSolicitDigitalizationFormDialog, selectedRows }) =>
    !(showSolicitDigitalizationFormDialog && selectedRows?.length >= 2),
};

const isStatusThatCannotSelect: TestConfig<any> = {
  name: 'isStatusThatCannotSelect',
  message: 'O arquivo não foi selecionado. O status deste arquivo não permite esta seleção',
  test: ({ selectedRows, currentBoxMovement }) => {
    const IsCurrentBoxMovementUndefined = currentBoxMovement !== null;

    const hasValueSelected = selectedRows?.length >= 1;
    const IsCurrentBoxMovementIsValid =
      currentBoxMovement !== BoxMovementCode.Excluded &&
      currentBoxMovement !== BoxMovementCode.InDigitalization &&
      currentBoxMovement !== BoxMovementCode.InclusionCanceled &&
      currentBoxMovement !== BoxMovementCode.MigratedToAnotherFile;

    if (!hasValueSelected) return true;
    return IsCurrentBoxMovementUndefined && IsCurrentBoxMovementIsValid;
  },
};

const validateBoxDataGrid = yup
  .object({
    currentBoxMovement: yup.number().nullable(),
    selectedRows: yup.array<BoxRow>(),
    showSolicitDigitalizationFormDialog: yup.bool(),
    currentPage: yup.number(),
    sizePage: yup.number(),
    initialDate: yup
      .string()
      .nullable()
      .test((e) => util.isDateFormat(e, true)),
    finalDate: yup
      .string()
      .nullable()
      .test((e) => util.isDateFormat(e, true)),
  })
  .when(['currentPage', 'sizePage', 'initialDate', 'finalDate'], {
    is: null || undefined || '',
    then: (schema) => {
      schema.required();
      return schema;
    },
    otherwise: (schema) => {
      schema.when(['currentPage', 'sizePage'], {
        is: null || undefined || '',
        then: (schemaPagination) => {
          schema.when(['initialDate', 'finalDate'], {
            is: null || undefined || '',
            then(schemaDateRange) {
              return schemaDateRange.required();
            },
            otherwise(schemaDateRange) {
              return schemaDateRange.required();
            },
          });

          return schemaPagination;
        },
        otherwise: (schemaPagination) => {
          schemaPagination.required();
          return schemaPagination;
        },
      });

      return schema;
    },
  })
  .test(isStatusThatCannotSelect)
  .test(isSolicitDigitalizationAndExceedLimit)
  .test(isDifferentStatus);

export default validateBoxDataGrid;
