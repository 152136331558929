import { Container, Stack } from 'react-bootstrap';
import { PencilSquare, Plus } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import InfoCard from '../../../../@shared/Cards/infoCard';
import clientsUrl from '../../../client/clients.urls';

const SettingsExplore = () => {
  const history = useHistory();
  return (
    <Container className="d-flex justify-content-center">
      <Stack direction="horizontal" className="justify-content-center flex-wrap" gap={3}>
        <InfoCard
          title="Criar Novo Cliente"
          text="Adicione um novo cliente ao sistema. Preencha os detalhes necessários para criar um perfil e começar a gerenciar as informações do cliente."
          style={{
            minWidth: '270px',
            maxWidth: '300px',
            height: '350px',
          }}
          onClick={() => history.push(clientsUrl.create)}
        >
          <Plus />
        </InfoCard>

        <InfoCard
          title="Editar Cliente Atual"
          text="Realizar ajustes personalizados nos dados do cliente atual. Mantenha as informações atualizadas com facilidade."
          style={{
            minWidth: '270px',
            maxWidth: '300px',
            height: '350px',
          }}
          onClick={() => history.push(clientsUrl.edit)}
        >
          <PencilSquare />
        </InfoCard>
      </Stack>
    </Container>
  );
};

export default SettingsExplore;
