import { Spinner } from 'react-bootstrap';
import { CenterAligner } from '../../styles';

interface ILoading {
  // eslint-disable-next-line react/require-default-props
  className?: '';
}

const Loading = ({ className }: ILoading) => {
  const c = `w-100 h-100 d-flex flex-column className ${className}`;
  return (
    <CenterAligner className={c}>
      <h1>Carregando...</h1>
      <br />
      <Spinner animation="border" variant="primary" />
    </CenterAligner>
  );
};

export default Loading;
