import { Image } from 'react-bootstrap';
import { CenterAligner } from '../../../styles';

const QrCode = ({
  manualEntryKey,
  qrCodeSetupImageUrl,
  className,
}: {
  manualEntryKey: string;
  qrCodeSetupImageUrl: string;
  className?: string;
}) => (
  <div className={`w-100 ${className}`}>
    <figure>
      <CenterAligner>
        <Image src={qrCodeSetupImageUrl} alt="qr code" />
      </CenterAligner>

      <figcaption className="flex-column flex-md-column flex-lg-row d-flex gap-1">
        <p>
          <strong>Código manual</strong>:
        </p>
        <p className="text-wrap text-justify text-break mw-fit-content flex-2">{manualEntryKey}</p>
      </figcaption>
    </figure>
  </div>
);

export default QrCode;
