import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm, useWatch } from 'react-hook-form';
import yup from '../../../../../../config/yup/customYup';
import { AddBoxClassificationItem } from '../../../../classification.endpoint';
import { BoxClassificationAddForm } from '../boxClassificationAddFormSteps.types';
import { formats } from '../../../../../../constants/constants';

interface UseBoxClassificationAddFormStep2Props {
  defaultValue?: BoxClassificationAddForm | BoxClassificationAddForm.Step2;
  onSubmit?: (e: BoxClassificationAddForm.Step2) => void;
}

const useBoxClassificationAddFormStep2 = (props?: UseBoxClassificationAddFormStep2Props) => {
  const form = useForm<BoxClassificationAddForm.Step2>({
    defaultValues: (props?.defaultValue as any)?.step2 ?? props?.defaultValue,
    resolver: yupResolver(
      yup.object({
        items: yup.array().of(
          yup.object({
            format: yup.string().required().trim(),
            name: yup.string().required().min(3),
            size: yup.number().required(),
            observation: yup.string(),
            orderNumber: yup.number(),
            isPrinted: yup.bool().required(),
            barcodeRef: yup.bool().required(),
          })
        ),
      })
    ),
  });

  const itemsController = useController<BoxClassificationAddForm.Step2>({
    name: 'items',
    control: form.control,
  });
  const items = useWatch<BoxClassificationAddForm.Step2>({
    control: form.control,
    name: 'items',
  });

  const onSubmit = (e: BoxClassificationAddForm.Step2) => {
    if (props?.onSubmit) props.onSubmit(e);
  };

  return {
    ...form,
    formats,
    fields: {
      items: items as AddBoxClassificationItem[],
      onItemsChange: (e: AddBoxClassificationItem[]) => itemsController.field.onChange(e),
    },

    handleSubmit: form.handleSubmit(onSubmit),
  };
};

export default useBoxClassificationAddFormStep2;
