import { Container } from 'react-bootstrap';
import Layout from '../../layout';
import BoxClassificationAddFormSteps from '../features/ClassificationForm/Add/boxClassificationAddFormSteps';
import { BoxClassificationAddFormProps } from '../features/ClassificationForm/Add/boxClassificationAddFormSteps.types';

const BoxClassificationAddPageStep1 = (props?: BoxClassificationAddFormProps.Step1) => (
  <Layout>
    <Container className="mt-4" style={{ maxWidth: '900px' }}>
      <h2 className="my-4">Criar Classificação: Informações Básicas</h2>
      <BoxClassificationAddFormSteps.Step1 {...props} />
    </Container>
  </Layout>
);

const BoxClassificationAddPageStep2 = (props?: BoxClassificationAddFormProps.Step2) => (
  <Layout>
    <Container className="mt-4" style={{ maxWidth: '900px' }}>
      <h2 className="my-4">Criar Classificação: Atribuir campos à Classificação</h2>
      <BoxClassificationAddFormSteps.Step2 {...props} />
    </Container>
  </Layout>
);

const BoxClassificationAddPageStep3 = (props?: BoxClassificationAddFormProps.Step3) => (
  <Layout>
    <Container className="mt-4" style={{ maxWidth: '900px' }}>
      <h2 className="my-4">Criar Classificação: Atribuir usuários à Classificação</h2>
      <BoxClassificationAddFormSteps.Step3 {...props} />
    </Container>
  </Layout>
);

const ClassificationAddPage = {
  Step1: BoxClassificationAddPageStep1,
  Step2: BoxClassificationAddPageStep2,
  Step3: BoxClassificationAddPageStep3,
};

export default ClassificationAddPage;
