import useCategoryEditForm from './categoryEditForm.hook';
import CategoryForm, { CategoryFormProps } from './categoryForm';
import { CategoryEditFormState } from './types';

export interface CategoryEditFormProps extends CategoryFormProps<CategoryEditFormState> {
  direction?: 'horizontal' | 'vertical';
}

const UserCategoryEditForm = ({
  users,
  parents,
  defaultValue,
  direction = 'vertical',
  onSubmit: onSubmitProp,
}: CategoryEditFormProps) => {
  const { handleSubmit, loading, success, error, errorData } = useCategoryEditForm();

  return (
    <CategoryForm<CategoryEditFormState>
      users={users}
      parents={parents}
      defaultValue={defaultValue}
      type="edit"
      loading={loading}
      success={success}
      error={error}
      errorData={errorData}
      onSubmit={onSubmitProp ?? handleSubmit}
    >
      <CategoryForm.ErrorAlert />
      <CategoryForm.Loading direction={direction}>
        <CategoryForm.Fields direction={direction} />
        <CategoryForm.Button />
      </CategoryForm.Loading>
    </CategoryForm>
  );
};

export default UserCategoryEditForm;
