import './categoryAddDialog.css';
import { Stack } from 'react-bootstrap';
import { Link45deg, Plus } from 'react-bootstrap-icons';
import InfoCard from '../../../../@shared/Cards/infoCard';

interface ChoicesProps {
  onCreating?: () => void;
  onIncludeAlreadyExist?: () => void;
}

const Choices = ({ onCreating, onIncludeAlreadyExist }: ChoicesProps) => (
  <Stack direction="horizontal" className="align-items-stretch" gap={3}>
    <InfoCard
      title="Criar categoria"
      text=" Utilize esta opção se deseja criar uma categoria completamente nova. Isso é útil quando
            você precisa organizar seus itens de uma maneira que ainda não foi contemplada pelo
            sistema."
      onClick={onCreating}
    >
      <Plus />
    </InfoCard>

    <InfoCard
      title="Selecionar categoria existente"
      text="Utilize esta opção se deseja adicionar uma categoria que já está presente no sistema.
            Isso pode ser útil ao associar um item a uma categoria existente."
      onClick={onIncludeAlreadyExist}
    >
      <Link45deg />
    </InfoCard>
  </Stack>
);

export default Choices;
