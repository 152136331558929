import React, { useContext } from 'react';
import ShimmerPlaceholder from '../../../../@shared/ShimmerPlaceholders';
import CategoryFormFields from './Fields';
import { CategoryFormContext } from './categoryForm.context';

export interface UserCategoryFormLoadingProps {
  direction?: 'horizontal' | 'vertical';
  children?: React.ReactNode | React.ReactNode[];
}

const CategoryFormLoading = ({
  direction = 'vertical',
  children,
}: UserCategoryFormLoadingProps) => {
  const context = useContext(CategoryFormContext);

  if (context?.loading) {
    return (
      <CategoryFormFields.Template
        direction={direction}
        name={<ShimmerPlaceholder.FormGroup type="input" />}
        isDocumentCategory={<ShimmerPlaceholder.FormGroup type="radio" />}
        isMigration={<ShimmerPlaceholder.FormGroup type="radio" />}
        parentId={<ShimmerPlaceholder.FormGroup type="radio" />}
        users={<ShimmerPlaceholder.DataGrid />}
        inclusionDate={<ShimmerPlaceholder.FormGroup type="input" />}
        cancellationDate={<ShimmerPlaceholder.FormGroup type="input" />}
        cancellationDescription={<ShimmerPlaceholder.FormGroup type="input" />}
      />
    );
  }

  return <>{React.Children.map(children, (e) => e)}</>;
};

export default CategoryFormLoading;
