import { AxiosError, AxiosRequestConfig } from 'axios';
import { createBrowserHistory } from 'history';
import { Secret } from '../@shared/storage/model';
import secretService from '../@shared/storage/secretService';

export default abstract class HelpersInterceptor {
  static flag = false;

  public static setAuthTokenInHeaders(request: AxiosRequestConfig<any>) {
    try {
      const { accessToken } = secretService.get();
      const instance = request as any;
      if (accessToken) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        instance.headers.Authorization = `Bearer ${accessToken}`;
      }
      return instance;
    } catch (e) {
      return e;
    }
  }

  public static async tryRefreshToken(
    generateNewTokensSuccess: (secret: Secret, path: string) => void,
    generateNewTokensFail: (path: string) => void,
    error: AxiosError
  ) {
    const history = createBrowserHistory();
    if (error.response?.status === 401) {
      if (!this.flag) {
        this.flag = true;
        secretService
          .generateNewTokensAsync()
          .then((response) => {
            const secret = response?.data as Secret;
            generateNewTokensSuccess(secret, 'interceptor');
            return response;
          })
          .catch((er) => {
            history.push('/login');
            generateNewTokensFail('interceptor');
            return er;
          });
      }
    }
    return null;
  }

  public static redirect403Forbidden(logout: () => void, error: AxiosError): any {
    const history = createBrowserHistory();
    if (error.response?.status === 403) {
      logout();
      secretService.remove();
      history.go(0);
      history.push('/login');
    }
  }
}
