import { Button, Col, Container } from 'react-bootstrap';
import DatePicker from '../../../../@shared/Fields/datePicker';
import { CheckBox, TreeViewNode } from '../../../../@shared/Fields/models';
import RadioGroup from '../../../../@shared/Fields/radioButtonGroup';
import TextField from '../../../../@shared/Fields/textField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../@shared/Forms/RequestForm';
import ResetButton from '../../../user/features/ResetButton';
import SendEmailCheckBox from '../../../user/features/SendEmailCheckBox';

export interface FillUserProps {
  categories: TreeViewNode[];
  roles: CheckBox[];
}

interface AdminFormProps extends FormRegisterProps, FormControlProps, Record<string, any> {
  type: 'edit' | 'add';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AdminEditFormProps extends AdminFormProps {
  type: 'edit';
  userId: number;
}

const AdminForm = ({
  type,
  errors,
  register,
  control,
  userId,
  ...props
}: AdminFormProps | AdminEditFormProps) => (
  <Container className="m-0">
    <Col sm="12" md="6" className="py-4">
      <div className="max-vh-100 overflow-auto w-100">
        <RequestForm {...props} errors={errors} className="h-100">
          {type === 'edit' ? (
            <input defaultValue={userId} {...register('userId')} type="hidden" />
          ) : (
            <></>
          )}
          <TextField
            id="name"
            label="Nome"
            control={control}
            errorMessage={errors?.name?.message}
            isInvalid={errors?.name !== undefined}
          />
          <TextField
            id="surname"
            label="Sobrenome"
            control={control}
            errorMessage={errors?.surname?.message}
            isInvalid={errors?.surname !== undefined}
          />
          <TextField
            id="cpf"
            label="CPF"
            control={control}
            errorMessage={errors?.cpf?.message}
            isInvalid={errors?.cpf !== undefined}
          />
          <DatePicker
            id="birthDate"
            label="Data de nascimento"
            name="birthDate"
            control={control}
            errorMessage={errors?.birthDate?.message}
            isInvalid={errors?.birthDate !== undefined}
          />
          <TextField
            id="occupation"
            label="Ocupação"
            control={control}
            errorMessage={errors?.occupation?.message}
            isInvalid={errors?.occupation !== undefined}
          />
          <TextField
            id="login"
            label="Login"
            control={control}
            errorMessage={errors?.login?.message}
            isInvalid={errors?.login !== undefined}
          />
          {type === 'edit' ? <ResetButton userId={Number(userId)} /> : <></>}
          <TextField
            id="email"
            label="Email"
            control={control}
            errorMessage={errors?.email?.message}
            isInvalid={errors?.email !== undefined}
          />
          <RadioGroup
            inline
            id="active"
            name="active"
            control={control}
            label="Perfil esta ativo"
            errorMessage={errors?.active?.message}
            isInvalid={errors?.active !== undefined}
          >
            {[
              { key: 'sim', label: 'Sim', value: true },
              { key: 'sao', label: 'Não', value: false },
            ]}
          </RadioGroup>
          {type === 'add' ? <SendEmailCheckBox control={control} /> : <></>}
          <Button type="submit">{type === 'add' ? 'Adicionar' : 'Editar'}</Button>
        </RequestForm>
      </div>
    </Col>
  </Container>
);

export default AdminForm;
