export const baseUrl = '/classifications';

export const routes = {
  baseUrl,
  add: {
    step1: `${baseUrl}/add`,
    step2: `${baseUrl}/add/2`,
    step3: `${baseUrl}/add/3`,
  },
  edit: `${baseUrl}/:boxClassificationId/edit`,
};

export const classificationUrls = {
  baseUrl: routes.baseUrl,
  add: routes.add,
  edit: (classificationId: number) => `${baseUrl}/${classificationId}/edit`,
};
