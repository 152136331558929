import React from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IInfoAlert {
  title?: string;
  text: string | JSX.Element;
  linkUrl?: string;
  linkText?: string;
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  onClick?: Function;
  hiddenLink?: boolean;
}

const RedirectAlert = ({
  title = 'Ocorreu um erro',
  text,
  linkUrl = '/login',
  linkText = 'Voltar para tela de login',
  variant,
  onClick = Function,
  hiddenLink,
}: IInfoAlert) => (
  <Container className="w-100">
    <Row className="d-flex justify-content-center">
      <Col>
        <Alert variant={variant} className="w-100 mt-4">
          <Alert.Heading>{title}</Alert.Heading>
          {React.isValidElement(text) ? text : <p>{text}</p>}
          <Link hidden={hiddenLink} to={linkUrl} onClick={() => onClick()}>
            {linkText}
          </Link>
        </Alert>
      </Col>
    </Row>
  </Container>
);

export default RedirectAlert;
