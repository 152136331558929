import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';

import { InputGroup } from '../../../../@shared/Fields/models';

import DatePicker from '../../../../@shared/Fields/datePicker';
import DynamicFieldGroup from '../../../../@shared/Fields/dynamicFieldGroup';
import SelectField from '../../../../@shared/Fields/selectField';
import SwitchField from '../../../../@shared/Fields/switchField';
import TextField from '../../../../@shared/Fields/textField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../@shared/Forms/RequestForm';
import { BoxForm, OptionsBoxForm } from '../../box.models';
import { BoxFormDisabled } from './boxForm.hook';

export interface BoxFormPresentationProps
  extends FormRegisterProps<BoxForm>,
    FormControlProps<BoxForm> {
  options: OptionsBoxForm;
  dynamicFields: InputGroup[];
  disabled: BoxFormDisabled;
}

const BoxFormPresentation = ({
  method,
  onSubmit,
  register,
  validated,
  errors,
  className,
  options,
  dynamicFields,
  control,
  disabled,
  ...props
}: BoxFormPresentationProps) => (
  <Container>
    <RequestForm
      {...props}
      errors={errors}
      method={method}
      noValidate
      validated={validated}
      onSubmit={onSubmit}
      className={`${className} w-100`}
    >
      <Container>
        <Row>
          <Col sm="12">
            <Card className="mb-3">
              <Card.Header>Informações Gerais</Card.Header>
              <Card.Body>
                <Container>
                  <Row>
                    <Col sm="12" md="6">
                      <SelectField
                        getOptionValue={(e) => e.value}
                        dynamic
                        id="categoryId"
                        name="categoryId"
                        label="Categoria"
                        control={control}
                        errorMessage={errors?.category?.message}
                        isInvalid={errors?.category !== undefined}
                        options={options?.categories ?? []}
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <SelectField
                        getOptionValue={(e) => e?.value}
                        dynamic
                        id="classificationId"
                        name="classificationId"
                        label="Classificação/Modelo de indexação"
                        disabled={disabled.classification}
                        control={control}
                        errorMessage={errors?.classificationId?.message}
                        isInvalid={errors?.classificationId !== undefined}
                        options={options?.classifications?.map((e) => e.option) ?? []}
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <DatePicker
                        control={control}
                        disabled={disabled.exclusionDate}
                        name="exclusionDate"
                        id="exclusionDate"
                        label="Data Estimada para Exclusão"
                        errorMessage={errors?.exclusionDate?.message}
                        isInvalid={errors?.exclusionDate !== undefined}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6">
            <Card className="mb-3 h-100">
              <Card.Header>Endereço</Card.Header>
              <Card.Body>
                <TextField
                  id="location"
                  disabled={disabled.location}
                  label="Localização"
                  control={control}
                  errorMessage={errors?.location?.message}
                  isInvalid={errors?.location !== undefined}
                />
                <Card>
                  <Card.Header>
                    <SwitchField
                      disabled={disabled.isManualBarcode}
                      defaultChecked={false}
                      id="isManualBarcode"
                      label="Definir Manualmente Código de Barras"
                      control={control}
                      errorMessage={errors?.isManualBarcode?.message}
                      isInvalid={errors?.isManualBarcode !== undefined}
                    />
                  </Card.Header>
                  <Card.Body>
                    <TextField
                      id="barcode"
                      disabled={disabled.barcode}
                      label="Código de Barras"
                      control={control}
                      errorMessage={errors?.barcode?.message}
                      isInvalid={errors?.barcode !== undefined}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="6">
            <Card className="mb-3 h-100">
              <Card.Header>Descrição da Caixa</Card.Header>
              <Card.Body>
                <DynamicFieldGroup
                  control={control}
                  register={register}
                  errors={errors}
                  fields={dynamicFields}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Stack className="mt-3 align-items-md-end align-items-lg-end">
              <Button type="submit">Cadastrar</Button>
            </Stack>
          </Col>
        </Row>
      </Container>
    </RequestForm>
  </Container>
);

export default BoxFormPresentation;
