import * as yup from 'yup';

export interface ClientEditFormState {
  logo: string;
  pendingEmailAlert: string;
  migrationTypes: number[];
  subsidiaryId: number;
  isImportCsvLocation: boolean;
  email: string;
  street: string;
  complement: string;
  district: string;
  state: string;
  city: string;
  postcode: string;
  streetNumber: string;
  homePhone: string;
  commercialPhone: string;
  fax: string;
  cellPhone: string;
  code: string;
  name: string;
  tradingName: string;
  documentId: string;
  workCategory: string;
  isLegalEntity: boolean;
  foundingDate: string;
}

const clientEditFormValidator = yup
  .object({
    cellPhone: yup.string(),
    postcode: yup.string().max(12),
    city: yup.string().required(),
    commercialPhone: yup.string(),
    complement: yup.string(),
    district: yup.string(),
    email: yup.string(),
    fax: yup.string(),
    homePhone: yup.string(),
    street: yup.string(),
    streetNumber: yup.string(),
    state: yup.string().required().min(2).max(2),
    code: yup.string(),
    pendingEmailAlert: yup.string(),
    isImportCsvLocation: yup.boolean().required(),
    logo: yup.string(),
    documentId: yup.string().max(26),
    foundingDate: yup.date().required().min('1/1/1753'),
    isLegalEntity: yup.boolean().required(),
    name: yup.string().required().min(3),
    tradingName: yup.string(),
    workCategory: yup.string(),
    subsidiaryId: yup.number().required(),
    migrationTypes: yup.array(yup.number()),
  })
  .required();

export default clientEditFormValidator;
