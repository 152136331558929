import { useContext } from 'react';
import { shouldPrefix } from '../../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../../categoryForm.context';
import { CategoryFormDatesFieldsProps } from '../../types';
import TextField from '../../../../../../../@shared/Fields/textField';

const CategoryFormCancellationDescriptionField = ({
  prefix,
  errors,
  control: controlProp,
}: CategoryFormDatesFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProp ?? context?.config?.control;

  return (
    <TextField
      disabled
      as="textarea"
      rows={3}
      name={shouldPrefix(prefix, 'cancellationDescription')}
      id={shouldPrefix(prefix, 'cancellationDescription')}
      containerClassName="w-100"
      label="Causa do cancelamento"
      control={control as any}
      errorMessage={errors?.cancellationDescription?.message}
      isInvalid={errors?.cancellationDescription !== undefined}
    />
  );
};

export default CategoryFormCancellationDescriptionField;
