import ClientForm from '..';
import useAuthSlice from '../../../../../@shared/Auth/useAuthSlice';
import useRequestForm from '../../../../../@shared/Forms/RequestForm/useRequestForm';
import ErrorPage from '../../../../../@shared/template/_errorPage';
import LoadingPage from '../../../../../@shared/template/_loadingPage';
import SuccessPage from '../../../../../@shared/template/_successPage';
import clientsEndpoint from '../../../clients.endpoints';
import { ClientFormState } from '../types';
import clientAddFormValidator from './clientAddForm.validator';

const ClientAddForm = () => {
  const auth = useAuthSlice();
  const form = useRequestForm<ClientFormState>({
    submitRequest: (obj) => clientsEndpoint.addClient(obj),
    validatorSchema: clientAddFormValidator,
  });

  if (form.submitResponse.loading || form.defaultValueRequest?.loading) return <LoadingPage />;

  if (form.defaultValueRequest?.error) {
    return <ErrorPage text={form.defaultValueRequest.backEndErrors?.message as any} />;
  }
  if (form.submitResponse.success) {
    return <SuccessPage text="Cliente foi adicionado com sucesso" onClick={() => auth.logout()} />;
  }

  return (
    <ClientForm
      type="add"
      method="post"
      register={form.register}
      errors={form.validator.errors}
      control={form.control}
      noValidate
      onSubmit={form.handleSubmit(form.onSubmit)}
    />
  );
};

export default ClientAddForm;
