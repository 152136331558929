import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CategoryFormTemplateBaseProps } from './types';

interface CategoryFormVerticalTemplateProps extends CategoryFormTemplateBaseProps {}

const CategoryFormVerticalTemplate: React.FC<CategoryFormVerticalTemplateProps> = ({
  name,
  parentId,
  isMigration,
  isDocumentCategory,
  inclusionDate,
  cancellationDate,
  cancellationDescription,
  users,
}) => (
  <Row>
    <Col sm="12">
      <Row>{name}</Row>
    </Col>
    <Col sm="12">
      <Row>{parentId}</Row>
    </Col>
    <Col sm="12">
      <Row>
        <Col>{isMigration}</Col>
        <Col>{isDocumentCategory}</Col>
      </Row>
    </Col>
    <Row>
      <Col sm={12}>{users}</Col>
    </Row>
    {inclusionDate && cancellationDate ? (
      <Col sm="12">
        <Row>
          {inclusionDate ? <Col>{inclusionDate}</Col> : <></>}
          {cancellationDate ? <Col>{cancellationDate}</Col> : <></>}
        </Row>
      </Col>
    ) : (
      <></>
    )}
    {cancellationDescription ? (
      <Col sm="12">
        <Row>
          <Col>{cancellationDescription}</Col>
        </Row>
      </Col>
    ) : (
      <></>
    )}
  </Row>
);

export default CategoryFormVerticalTemplate;
