import { useEffect, useState } from 'react';
import { Status } from 'status';
import { CheckBox, TreeViewNode } from '../../Fields/models';
import useMonitor from '../../Monitor/useMonitor';
import { CategorySelectable } from './categoryAccordion.context';

export interface UseCategoryAccordionProps {
  boxClassificationId: number | null;
  categories?: CategorySelectable | null;
}

const useCategoryAccordion = ({
  boxClassificationId: boxClassificationIdProps,
  categories: categoriesProp,
}: UseCategoryAccordionProps) => {
  const [categories, setCategories] = useState<CategorySelectable | null>(null);
  const [boxClassificationId, setBoxClassificationId] = useState<number | null>(null);
  const [parents, setParents] = useState<TreeViewNode[]>([]);
  const [users, setUsers] = useState<CheckBox[]>([]);
  const monitor = useMonitor();

  useEffect(() => {
    if (boxClassificationIdProps) {
      setBoxClassificationId(boxClassificationIdProps);
    }
  }, [boxClassificationIdProps]);

  useEffect(() => {
    if (categoriesProp) {
      setCategories(categoriesProp);
    }
  }, [categoriesProp]);

  return {
    users,
    setUsers,
    categories,
    setCategories,
    boxClassificationId,
    setBoxClassificationId,
    monitor,
    parents,
    setParents,
    status: { error: monitor.error, loading: monitor.loading, success: monitor.success } as Status,
  };
};

export default useCategoryAccordion;
