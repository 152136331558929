import { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { BoxClassification } from '../../../modules/classification/classification.types';
import RequestForm, { FormProps } from '../../Forms/RequestForm';
import BoxClassificationFormFields from './boxClassificationForm.fields';

export interface BoxClassificationFormProps extends FormProps {
  type: 'add' | 'edit';
  control: Control | Control<BoxClassification>;
  button?: ReactNode;
  className?: string;
}

const BoxClassificationForm = ({
  type,
  button,
  className,
  ...props
}: BoxClassificationFormProps) => (
  <RequestForm {...props} className={`h-100 ${className}`}>
    <BoxClassificationFormFields
      button={button}
      type={type}
      control={props.control}
      errors={props.errors ?? {}}
    />
  </RequestForm>
);

export default BoxClassificationForm;
