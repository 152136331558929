/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import { CheckBox, TreeViewNode } from '../../../../@shared/Fields/models';
import useMonitor from '../../../../@shared/Monitor/useMonitor';
import { CategoryFormConfig } from './categoryForm.context';
import { Category, CategoryBaseForm } from './types';

interface UseCategoryFormProps<T extends CategoryBaseForm> {
  type?: 'edit' | 'add';
  defaultValue?: Category | null;
  parents?: TreeViewNode[];
  users?: CheckBox[];
  onSubmit?: (e: T) => void;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  errorData?: Record<string, FormError>;
}

const useCategoryForm = <T extends CategoryBaseForm>(props: UseCategoryFormProps<T>) => {
  const form = useForm<any>({
    defaultValues: {
      cancellationDate: props?.defaultValue?.cancellationDate as any,
      cancellationDescription: props?.defaultValue?.cancellationDescription ?? '',
      categoryId: props?.defaultValue?.categoryId,
      inclusionDate: props?.defaultValue?.inclusionDate as any,
      isDocumentCategory: props?.defaultValue?.isDocumentCategory,
      isMigration: props?.defaultValue?.isMigration,
      name: props?.defaultValue?.name,
      parent: props?.defaultValue?.parent,
      parentId: props?.defaultValue?.parentId,
      users: (props?.defaultValue?.users?.map((e) => e.userId) ?? []) as any,
    },
  });
  const [config, setConfig] = useState<CategoryFormConfig | null>({
    control: form.control,
    type: null,
  });
  const [users, setUsers] = useState<CheckBox[]>([]);
  const [parents, setParents] = useState<TreeViewNode[]>([]);
  const { error, loading, success, ...rest } = useMonitor();

  const traverseAndUpdateDisabled = (node: TreeViewNode, defaultValueCategoryId: string) => {
    // eslint-disable-next-line no-param-reassign
    node.disabled = node.value === defaultValueCategoryId;

    if (node.children) {
      node.children.forEach((child) => {
        traverseAndUpdateDisabled(child, defaultValueCategoryId);
      });
    }
  };

  useEffect(() => {
    if (props.errorData) {
      Object.keys(props.errorData).forEach((key) => {
        form.setError(key, { message: props.errorData?.[key]?.message ?? '' });
      });
    }
  }, [props.errorData]);

  const disableValue = (e: TreeViewNode) => {
    const updatedNode = { ...e };

    if (e.children.length > 0) {
      updatedNode.children = e.children.map((child) => disableValue(child));
    }

    updatedNode.disabled = e.value === props?.defaultValue?.categoryId.toString();
    return updatedNode;
  };

  useEffect(() => {
    if (props.parents) {
      setParents(props.parents.map((e) => disableValue(e)));
    }
  }, [props.parents, props.defaultValue?.categoryId]);

  useEffect(() => {
    if (props.users) {
      setUsers(props.users);
    }
  }, [props.users]);

  useEffect(() => {
    if (props.type && config) {
      setConfig({ ...config, type: props.type });
    }
  }, [props.type]);

  useEffect(() => {
    rest.track('parent-component', {
      error: props.error ?? false,
      loading: props.loading ?? false,
      success: props.success ?? false,
    });
  }, [props.error, props.loading, props.success]);

  return {
    handleSubmit: props?.onSubmit && form.handleSubmit(props.onSubmit),
    error,
    errors: form.formState.errors,
    setErrors: form.setError,
    loading,
    success,
    users,
    setUsers,
    parents,
    setParents,
    config,
    monitor: rest,
    setConfig,
  };
};

export default useCategoryForm;
