import React from 'react';
import { Control, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import DatePicker from './datePicker';
import { InputTags, InputTypes } from './models';
import SelectField from './selectField';
import TextField, { TextFieldType } from './textField';

export interface DynamicFieldProps {
  id: string;
  type: InputTypes;
  control: Control<FieldValues, any>;
  tag: InputTags;
  register: UseFormRegisterReturn;
  errorMessage?: string;
  label: string;
  isInvalid: boolean;
  helperText?: string;
  placeholder?: string;
  defaultValue?: any;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const DynamicField = ({
  control,
  id,
  isInvalid,
  type,
  label,
  register,
  errorMessage,
  helperText,
  tag,
  placeholder,
  defaultValue,
  children,
  className,
}: DynamicFieldProps) => {
  switch (tag) {
    case 'datepicker':
      return (
        <DatePicker
          id={id}
          key={id}
          name={id}
          label={label}
          placeholder={placeholder}
          control={control}
          register={register}
          helperText={helperText}
          errorMessage={errorMessage}
          isInvalid={isInvalid}
          className={className}
          defaultValue={defaultValue}
        />
      );
    case 'select':
      return (
        <SelectField
          id={id}
          key={id}
          name={id}
          label={label}
          placeholder={placeholder}
          control={control}
          register={register}
          helperText={helperText}
          errorMessage={errorMessage}
          isInvalid={isInvalid}
          defaultValue={defaultValue ?? ''}
          className={className}
          dynamic={false}
        >
          {React.Children.toArray(children)}
        </SelectField>
      );
    default:
      return (
        <TextField
          id={id}
          key={id}
          name={id}
          label={label}
          placeholder={placeholder}
          control={control}
          helperText={helperText}
          errorMessage={errorMessage}
          isInvalid={isInvalid}
          defaultValue={defaultValue}
          className={className}
          type={type as TextFieldType}
        />
      );
  }
};

export default DynamicField;
