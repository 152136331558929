import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Column, SelectColumn } from 'react-data-grid';
import { Link } from 'react-router-dom';

import { formatDate } from '../../../../../@shared/utils/util';
import { Reticence } from '../../../../../styles';
import { BoxRow } from '../../../box.models';
import boxesUrls from '../../../box.urls';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WrapperFormatter = ({ value }: { value: string }) => (
  <div className="h-100 d-flex flex-column justify-content-center">
    <div
      style={{
        display: 'inline-block',
        fontSize: '400',
        lineHeight: '1.5',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        textAlign: 'justify',
      }}
    >
      {value}
    </div>
  </div>
);

const PopOvers = ({ value }: { value: string }) => (
  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{value}</Tooltip>}>
    <Reticence className="d-block">{value}</Reticence>
  </OverlayTrigger>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const boxColumns = () =>
  [
    SelectColumn,
    {
      key: 'barCode',
      name: 'Cód. de Barras',
      resizable: true,
    },
    {
      key: 'number',
      name: 'N° Arquivo',
      resizable: true,
    },
    {
      key: 'category',
      name: 'Categoria',
      resizable: true,
    },
    {
      key: 'classification',
      name: 'Classificação',
      formatter: ({ row }) => <PopOvers value={row.classification} />,
      resizable: true,
    },
    {
      key: 'description',
      name: 'Descrição',
      formatter: ({ row }) => <PopOvers value={row.description} />,
      resizable: true,
    },
    {
      key: 'inclusionDate',
      name: 'Inclusão',
      formatter: ({ row }) => <>{formatDate(row.inclusionDate)}</>,
      resizable: true,
    },
    {
      key: 'statusDescription',
      name: 'Status',
      resizable: true,
    },
    {
      key: 'location',
      name: 'Localização',
      resizable: true,
    },
    {
      key: 'boxId',
      name: '',
      width: 100,
      formatter: (row) => (
        <Link to={boxesUrls.detail(row.row.boxId)}>
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Button variant="primary" className="mw-25 rounded">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </div>
        </Link>
      ),
    },
  ] as Column<BoxRow>[];

export default boxColumns;
