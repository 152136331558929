import { TwoStepAuthenticator } from '../models';
import PinForm from './PinForm';

const typePinForm = (
  type: 'component' | 'redirect',
  twoStepAuthenticator: TwoStepAuthenticator | null,
  onSuccess: JSX.Element
) =>
  type === 'redirect' ? (
    <PinForm twoStepAuthenticator={twoStepAuthenticator} onSuccessRedirect={onSuccess} />
  ) : (
    <PinForm twoStepAuthenticator={twoStepAuthenticator} onSuccessComponent={onSuccess} />
  );

export default typePinForm;
