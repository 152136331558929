import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CategoryFormTemplateBaseProps } from './types';

interface CategoryFormHorizontalTemplateProps extends CategoryFormTemplateBaseProps {}

const CategoryFormHorizontalTemplate: React.FC<CategoryFormHorizontalTemplateProps> = ({
  name,
  parentId,
  isMigration,
  isDocumentCategory,
  inclusionDate,
  cancellationDate,
  cancellationDescription,
  children,
  users,
}) => (
  <>
    <Row>
      <Col md={6}>
        <Row>
          <Col md={12}>{name}</Col>
        </Row>
      </Col>
    </Row>
    <Row sm={12}>
      <Col sm={12} md={6}>
        {parentId}
      </Col>
      <Col sm={12} md={3}>
        {isMigration}
      </Col>
      <Col sm={12} md={3}>
        {isDocumentCategory}
      </Col>
    </Row>
    <Row sm={12}>
      {children}
      <Col sm={12} md={6}>
        {users}
      </Col>
      <Col md={6}>
        <Row>
          {inclusionDate ? <Col md={6}>{inclusionDate}</Col> : <></>}
          {cancellationDate ? <Col md={6}>{cancellationDate}</Col> : <></>}
        </Row>
        {cancellationDescription ? <Col md={12}>{cancellationDescription}</Col> : <></>}
      </Col>
    </Row>
  </>
);

export default CategoryFormHorizontalTemplate;
