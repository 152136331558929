/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useAuthSlice from '../../../@shared/Auth/useAuthSlice';
import { RootState } from '../../../redux/application';
import { close, open } from '../../../redux/slices/sideBarSlice';

import AccountCircleNavBar from './AccountCircleNavBar';

interface INavbar {
  showLoggedComponents: boolean;
}

const NavBar = ({ showLoggedComponents = true }: INavbar) => {
  const redux = useAuthSlice();
  const authState = redux.useState();
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    if (isOpen) dispatch(close());
    else dispatch(open());
  };

  const LoggedComponents = () => (
    <Nav>{authState.wasLogged && showLoggedComponents && <AccountCircleNavBar />}</Nav>
  );

  return (
    <Navbar id="sidebar" bg="primary" variant="dark" className="px-3">
      <Navbar.Brand href="#home">
        <Container>
          <span>
            GDOC-CORE
            {authState.userInfo?.clientName ? (
              <>
                <span className="border-start border-1 mx-2 " style={{ height: '5px' }} />

                <small className="fw-light">{authState.userInfo?.clientName}</small>
              </>
            ) : (
              <></>
            )}
          </span>
        </Container>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-end">
        {showLoggedComponents ? LoggedComponents() : <></>}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
