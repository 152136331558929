import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';

const useNewValueSelected = (name: string, control: Control, f: (v: string[]) => void) => {
  const watch = useWatch({ control, name }) as string[];
  useEffect(() => {
    f(watch?.map((value) => value ?? ''));
  }, [watch]);
};
export default useNewValueSelected;
