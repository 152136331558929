/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Exception } from '../../../../@shared/models';
import { BoxLinkProps } from '../../box.models';
import BuilderBoxForm from './BuilderBoxForm';
import BoxForm from './boxForm';

export interface BoxAddFormProps {
  top?: (props: BoxLinkProps) => JSX.Element;
}

const BoxAddForm = ({ top }: BoxAddFormProps) => {
  const [classification, setClassification] = useState<any>(0);
  const [category, setCategory] = useState<number>(0);
  return (
    <BuilderBoxForm
      fetch={{ categories: true, classifications: true, fields: true }}
      classification={classification}
      setClassificationSelected={setClassification}
      category={category}
      render={(props) => (
        <>
          <BoxForm
            errorMessage={(props.backEndErrors as Exception)?.message}
            loading={props.loading}
            top={top}
            fields={props.fields}
            options={props.options ?? []}
            onClassificationChange={(v) => setClassification(v)}
            onCategoryChange={(v) => setCategory(v)}
          />
        </>
      )}
    />
  );
};

export default BoxAddForm;
