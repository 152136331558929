import { Alert, Form } from 'react-bootstrap';
import { BoxRow } from '../../box.models';

const BoxDescriptionAlert = ({ values: descriptions }: { values: BoxRow }) => (
  <Alert variant="dark" key={descriptions.boxId}>
    <Form.Group controlId="solicitationDialog.barCode">
      <p className="m-0">
        <strong>Código de barra</strong>: {descriptions.barCode}
      </p>
    </Form.Group>
    <Form.Group controlId="solicitationDialog.category">
      <p className="m-0">
        <strong>Categoria</strong>: {descriptions.category}
      </p>
    </Form.Group>
    <Form.Group controlId="solicitationDialog.description">
      <p className="m-0 text-truncate">
        <strong>Descrição</strong>: {descriptions.description}
      </p>
    </Form.Group>
    <Form.Group controlId="solicitationDialog.status">
      <p className="m-0">
        <strong>Status</strong>: {descriptions.status}
      </p>
    </Form.Group>
  </Alert>
);

export default BoxDescriptionAlert;
