import { Button, ButtonGroup, Container } from 'react-bootstrap';
import BoxClassificationForm from '../../../../../../@shared/BoxClassifications/Forms/boxClassificationForm';
import { BoxClassificationAddFormProps } from '../boxClassificationAddFormSteps.types';
import useBoxClassificationAddFormStep1 from './boxClassificationAddForm.step1.hook';

const BoxClassificationAddFormStep1 = ({
  onNext,
  defaultValue,
}: BoxClassificationAddFormProps.Step1) => {
  const { control, handleSubmit, errors } = useBoxClassificationAddFormStep1({
    defaultValue,
    onSubmit: onNext,
  });
  return (
    <Container style={{ height: '700px' }}>
      <BoxClassificationForm
        className="d-flex flex-column justify-content-between"
        errors={errors}
        type="add"
        control={control}
        button={
          <ButtonGroup className="d-flex justify-content-md-end">
            <div>
              <Button type="submit">Próximo</Button>
            </div>
          </ButtonGroup>
        }
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default BoxClassificationAddFormStep1;
