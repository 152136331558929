import Layout from '../../layout';
import AddUserForm from '../features/UserAddForm';

const UserAddPage = () => (
  <Layout>
    <AddUserForm />
  </Layout>
);

export default UserAddPage;
