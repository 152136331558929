import { useEffect } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import useRequest from '../../../../@shared/Requests/useRequest';
import isEmpty, { formatDate } from '../../../../@shared/utils/util';
import profileEndpoints from '../../profile.endpoints';
import { Profile } from '../../profile.models';

export default (setValue: UseFormSetValue<FieldValues>) => {
  const { error, backEndErrors, fetch, loading, success, successData } = useRequest();

  useEffect(() => {
    const data = successData as Profile;
    if (!isEmpty(data)) {
      setValue('name', data.name);
      setValue('surname', data.surname);
      setValue('birthDate', formatDate(data.birthDate));
      setValue('occupation', data.occupation);
    }
  }, [successData, setValue]);

  useEffect(() => {
    fetch(profileEndpoints.findAsync());
  }, []);

  return {
    error,
    backEndErrors,
    loading,
    success,
    successData,
  };
};
