import { useContext } from 'react';
import { Control } from 'react-hook-form';
import { CheckBox } from '../../../../../../../@shared/Fields/models';
import UserCategoryDataGridControl from '../../../../../../../@shared/UserCategoryDataGrid/userCategoryDataGrid.control';
import { CategoryFormContext } from '../../../categoryForm.context';
import { CategoryFormFieldsBaseProps } from '../../types';

export interface CategoryFormGrantUsersProps extends CategoryFormFieldsBaseProps {
  users?: CheckBox[];
}

const CategoryFormGrantUsers = ({
  control: controlProp,
  users: usersProps,
}: CategoryFormGrantUsersProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProp ?? context?.config?.control;
  const users = usersProps ?? context?.users;

  return <UserCategoryDataGridControl name="users" rows={users} control={control as Control} />;
};

export default CategoryFormGrantUsers;
