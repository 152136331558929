import { Col, Container, Row } from 'react-bootstrap';

import DataGrid from '../../../../@shared/DataGrid';
import scannedDocumentsUrls from '../../scanned-document.url';
import Search from './Search';

interface FilterFormProps {
  isCellSelected: boolean;
  value: string;
  filtrate: (name: string) => void;
  boxId: number;
}

const FilterForm = ({ isCellSelected, value, filtrate, boxId }: FilterFormProps) => (
  <Container fluid className="h-100">
    <Row className="h-100">
      <Col sm="9" md="10" className="ps-0 h-100">
        <Search value={value} filtrate={filtrate} isCellSelected={isCellSelected} />
      </Col>
      <Col className="h-100">
        <DataGrid.AddButton
          to={scannedDocumentsUrls(boxId).add}
          className="pl-2 h-100 align-items-center"
        />
      </Col>
    </Row>
  </Container>
);

export default FilterForm;
