import useDialog from '../useDialog';
import ErrorDialogPresentation from './errorDialog';

interface ErrorDialogProps {
  show: boolean;
  children: string;
  className?: string;
}

const ErrorDialog = ({ show, children, className }: ErrorDialogProps) => {
  const dialog = useDialog();
  dialog.useOpen(show);

  return (
    <ErrorDialogPresentation
      className={`w-100 ${className}`}
      message={children}
      show={dialog.show}
      title="Ocorreu um erro"
      onHide={() => dialog.close()}
    />
  );
};

export default ErrorDialog;
