import { Control } from 'react-hook-form';
import useFieldBooleanObserver from '../../useFieldBooleanObserver';

export interface UseHasHeaderCheckboxValue {
  useHas: (fun: Function) => void;
  useHasNot: (fun: Function) => void;
  value: boolean;
}

const useHasHeaderCheckbox = (control: Control): UseHasHeaderCheckboxValue => {
  const {
    useFalse: useHasNot,
    useTrue: useHas,
    value,
  } = useFieldBooleanObserver('hasHeader', control);

  return {
    useHas,
    useHasNot,
    value,
  };
};
export default useHasHeaderCheckbox;
