import { Button, Col, Row } from 'react-bootstrap';
import CsvTableField from '../../../../../../@shared/Fields/TableField/CsvTableField';
import SelectField from '../../../../../../@shared/Fields/selectField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../../../@shared/Forms/RequestForm';
import { CsvBoxAddFormOptions } from '../../../../box.models';

type CsvBoxAddFormPresentationProps = FormRegisterProps &
  Omit<FormControlProps, 'method'> & {
    errors?: Record<string, any> | undefined;
    control?: any;
    options: CsvBoxAddFormOptions;
    rows: string[][];
    classificationDisable: boolean;
    onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
    boxTypeDisable: boolean;
    csvTableFieldDisable?: { checkBox?: boolean; select?: boolean };
  };

const CsvBoxAddFormPresentation = ({
  errors,
  control,
  method,
  validated,
  register,
  submitRef,
  defaultValue,
  options,
  rows,
  hidden,
  onSubmit,
  classificationDisable,
  boxTypeDisable,
  csvTableFieldDisable,
  ...props
}: CsvBoxAddFormPresentationProps) => (
  <RequestForm {...props} method={method} validated={validated} onSubmit={onSubmit}>
    <Row>
      <Col>
        <SelectField
          dynamic
          id="category"
          name="category"
          label="Categoria"
          control={control}
          errorMessage={errors?.category?.message}
          isInvalid={errors?.category}
          options={options.categories}
        />
      </Col>
      <Col>
        <SelectField
          dynamic
          disabled={classificationDisable}
          id="classification"
          name="classification"
          label="Classificação/Modelo de indexação"
          control={control}
          errorMessage={errors?.classification?.message}
          isInvalid={errors?.classification}
          options={options.classifications}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <SelectField
          disabled={boxTypeDisable}
          dynamic
          id="boxType"
          name="boxType"
          label="Tipo de Caixa"
          control={control}
          errorMessage={errors?.boxType?.message}
          isInvalid={errors?.boxType}
          options={options.boxTypes}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <CsvTableField
          disabled={csvTableFieldDisable}
          id="descriptions"
          rows={rows}
          options={options.descriptions}
          control={control}
          isInvalid={errors?.descriptions}
          errorMessage={errors?.descriptions?.message}
        />
      </Col>
    </Row>
    <Button hidden type="submit" />
  </RequestForm>
);

export default CsvBoxAddFormPresentation;
