import FetchPossibleUsers from '../../../../@shared/Requests/components/fetchPossibleUsers';
import CategoryAddFormFields from './Fields/addFields';
import { TemplateBaseProps } from './Fields/types';
import CategoryForm, { CategoryFormBaseProps } from './categoryForm';
import { CategoryFormContext } from './categoryForm.context';
import CategoryFormButton from './categoryFormButton';
import CategoryFormLoading from './categoryFormLoading';
import { CategoryAddFormState } from './types';

interface CategoryAddFormProps
  extends CategoryFormBaseProps<CategoryAddFormState>,
    TemplateBaseProps {}

const CategoryAddForm = (props?: CategoryAddFormProps) => <CategoryForm {...props} />;

CategoryAddForm.Loading = CategoryFormLoading;
CategoryAddForm.Context = CategoryFormContext;
CategoryAddForm.Fields = CategoryAddFormFields;
CategoryAddForm.Button = CategoryFormButton;
CategoryAddForm.FetchPossibleUsers = <FetchPossibleUsers context={CategoryFormContext} />;

export default CategoryAddForm;
