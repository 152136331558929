import useRequest from '../../../../@shared/Requests/useRequest';
import { SubsidiaryClient } from '../../../../@shared/models';
import categoryEndpoints from '../../../category/category.endpoints';
import ClientsEndpoint from '../../clients.endpoints';

interface UseClientFormProps {
  type: 'edit' | 'add';
  subsidiaries?: [{ subsidiaryId: number; name: string }];
  migrationTypes?: [{ migrationTypeId: number; name: string }];
}

const useClientForm = ({
  type,
  subsidiaries: subsidiariesProp,
  migrationTypes: migrationTypesProp,
}: UseClientFormProps) => {
  const migrationsRequest = useRequest<{ migrationTypeId: number; name: string }[]>(
    categoryEndpoints.listMigrationsTypes()
  );

  const subsidiaryRequest = useRequest<SubsidiaryClient[]>(ClientsEndpoint.listSubsidiaries());

  migrationsRequest.useFetchBy(type === 'add', 'init');
  subsidiaryRequest.useFetchBy(type === 'add', 'init');

  const subsidiaries =
    subsidiariesProp ??
    subsidiaryRequest.successData?.map((e) => ({
      subsidiaryId: e.subsidiaryId,
      name: e.tradingName,
    })) ??
    [];

  const migrationTypes = migrationTypesProp ?? migrationsRequest.successData ?? [];

  return {
    loading: migrationsRequest.loading || subsidiaryRequest.loading,
    migrationTypes,
    subsidiaries,
  };
};

export default useClientForm;
