import Dialog, { IDialog } from '..';

interface IErrorDialogPresentation {
  (props: IDialog & { message: string }): JSX.Element;
  (props: IDialog & { errors: object }): JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const turnMessageIntoFormError = (params) => {
  const { message, errors } = params;

  if (params.message) {
    return { exception: { message } };
  }
  return errors;
};

const ErrorDialogPresentation: IErrorDialogPresentation = (dialogProps) => (
  <Dialog {...dialogProps}>
    <Dialog.Header />
    <Dialog.Body className="p-0">
      <Dialog.ErrorAlert
        dismissible={false}
        disableDefaultHeading={false}
        errors={turnMessageIntoFormError(dialogProps)}
        style={{ minHeight: 150 }}
        fullSize
      />
    </Dialog.Body>
    <Dialog.Footer>
      <Dialog.ConfirmButton type="button" />
    </Dialog.Footer>
  </Dialog>
);

export default ErrorDialogPresentation;
