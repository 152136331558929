import { Redirect } from 'react-router';
import VerifyResetToken from '../../../@shared/Verifiers/verifyResetToken';
import Layout from '../../layout';

const Reset2FaVerifyTokenPage = () => (
  <Layout showLoggedComponents={false}>
    <VerifyResetToken
      tokenType="reset-2fa"
      onSuccess={<Redirect to="/reset-2fa/define-new-2fa" />}
    />
  </Layout>
);

export default Reset2FaVerifyTokenPage;
