import { BrowserRouter } from 'react-router-dom';
import { interceptorRequest, useInterceptorResponse } from './middlewares/interceptors';
import AdminRoutes from './modules/admin/admin.routes';
import AuthRoutes from './modules/auth/auth.routes';
import ConsultRoutes from './modules/box/box.routes';
import CategoryRoutes from './modules/category/category.routes';
import ClassificationRoutes from './modules/classification/classification.routes';
import ClientRoutes from './modules/client/clients.routes';
import ComplexRoutes from './modules/complex/complex.routes';
import HomeRoutes from './modules/home/home.routes';
import ProfileRoutes from './modules/profile/profile.routes';
import Reset2faRoutes from './modules/reset-2fa/reset-2fa.routes';
import ResetPasswordRoutes from './modules/reset-password/reset-password.routes';
import ScannedDocumentsRoutes from './modules/scanned-document/scanned-document.routes';
import SettingsRoutes from './modules/setting/settings.routes';
import UsersRoutes from './modules/user/user.routes';

const Routes = () => (
  <>
    {interceptorRequest()}
    {useInterceptorResponse()}

    <BrowserRouter>
      <AuthRoutes />
      <HomeRoutes />
      <ProfileRoutes />
      <CategoryRoutes />
      <SettingsRoutes />
      <UsersRoutes />
      <ConsultRoutes />
      <ScannedDocumentsRoutes />
      <ResetPasswordRoutes />
      <Reset2faRoutes />
      <ComplexRoutes />
      <ClassificationRoutes />
      <ClientRoutes />
      <AdminRoutes />
    </BrowserRouter>
  </>
);

export default Routes;
