import { Accordion, ColProps, PlaceholderProps } from 'react-bootstrap';

export type AccordingItemShimmerShimmerPlaceholderProps = ColProps & PlaceholderProps & {};

const AccordingItemShimmerPlaceholder = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties | undefined;
}) => (
  <Accordion.Item
    eventKey="0"
    className={`collapsed placeholder-glow d-block ${className}`}
    style={style}
  >
    <div className="col-12 p-3 rounded" style={{ height: '52px' }}>
      <div className="placeholder col-3 rounded" />
    </div>
  </Accordion.Item>
);

export default AccordingItemShimmerPlaceholder;
