import React from 'react';
import Form from './profileForm';
import useFindProfile from './useFindProfile';
import useChangePassword from './useChangePassword';
import VerifyLoading from '../../../../@shared/Verifiers/verifyLoading';
import VerifyError from '../../../../@shared/Verifiers/verifyError';

const ProfileForm = () => {
  const {
    arrayErrors,
    register,
    handleSubmit,
    onSubmit,
    validated,
    success,
    logout,
    control,
    setValue,
    reset,
    loading: changePasswordLoading,
    clearErrors,
  } = useChangePassword();
  const findProfile = useFindProfile(setValue);

  return (
    <VerifyLoading loadings={[changePasswordLoading, findProfile.loading]}>
      <VerifyError
        errors={[findProfile]}
        defaultText="ops.. houve uma falha ao tentar acessar o perfil de usuário"
      >
        <Form
          method="post"
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          validated={validated}
          showDialog={success}
          register={register}
          errors={arrayErrors}
          closeDialog={logout}
          setValue={setValue}
          reset={reset}
          clearErrors={clearErrors}
        />
      </VerifyError>
    </VerifyLoading>
  );
};

export default ProfileForm;
