import { Control } from 'react-hook-form';
import { Option } from '../../../models';
import useCsvTableField from './csvTableField.hook';
import CsvTableFieldPresentation from './csvTableField.presentation';

interface CsvSelectableHeaderTableProps {
  id: string;
  rows: string[][];
  options: Option[];
  control: Control;
  errorMessage: string;
  isInvalid: boolean;
  disabled?: { checkBox?: boolean; select?: boolean };
}

const CsvTableField = ({
  control: controlProps,
  errorMessage: errorMessageProps,
  isInvalid: isInvalidProps,
  options: optionsProps,
  rows: rowsProps,
  id: idProps,
  disabled,
}: CsvSelectableHeaderTableProps) => {
  const { columns, rows, className, value } = useCsvTableField({
    id: idProps,
    rows: rowsProps,
    options: optionsProps,
    control: controlProps,
    select: { disabled: disabled?.select },
  });

  return (
    <CsvTableFieldPresentation
      disabled={disabled}
      value={value}
      className={className}
      columns={columns}
      control={controlProps}
      errorMessage={errorMessageProps}
      isInvalid={isInvalidProps}
    >
      {rows}
    </CsvTableFieldPresentation>
  );
};
export default CsvTableField;
