import { Button } from 'react-bootstrap';
import TextField from '../../../../@shared/Fields/textField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../@shared/Forms/RequestForm';
import { CenterAligner } from '../../../../styles';

interface IPresentational extends FormRegisterProps, FormControlProps {}

const NewPasswordFormPresentation = ({
  onSubmit,
  errors,
  register,
  method,
  validated,
  control,
  ...props
}: IPresentational) => (
  <>
    <CenterAligner className="d-flex flex-column">
      <RequestForm
        {...props}
        errors={errors}
        method={method}
        validated={validated}
        onSubmit={onSubmit}
      >
        <TextField
          id="newPassword"
          label="Nova senha"
          control={control}
          type="password"
          errorMessage={errors?.newPassword?.message}
          isInvalid={errors?.newPassword !== undefined}
        />
        <TextField
          id="newPasswordConfirm"
          label="Confirma senha"
          control={control}
          type="password"
          errorMessage={errors?.newPasswordConfirm?.message}
          isInvalid={errors?.newPasswordConfirm !== undefined}
        />
        <Button type="submit">Alterar senha</Button>
      </RequestForm>
    </CenterAligner>
  </>
);

export default NewPasswordFormPresentation;
