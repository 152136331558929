import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Column } from 'react-data-grid';
import { useLoadingGroup } from '../../../../@shared/LoadingGroup/useLoadingGroup';
import useRequest from '../../../../@shared/Requests/useRequest';
import useWindowDocument from '../../../../@shared/WindowDocument/useWindowDocument';
import { ScannedDocument } from '../../../../@shared/models';
import scannedDocumentsEndpoints from '../../scanned-document.endpoints';

import FilterForm from './filterForm';

const scannedDocumentColumns = (
  value: string,
  filtrate: (filterText: string) => void,
  boxId: number,
  blob: (scannedDocumentId: number, fileName: string) => void
) =>
  [
    {
      key: 'fileName',
      name: '',
      headerRenderer: (h) => (
        <FilterForm
          filtrate={filtrate}
          isCellSelected={h.isCellSelected}
          value={value}
          boxId={boxId}
        />
      ),
      formatter: (dataGrid) => (
        <Button
          onClick={() => {
            blob(dataGrid.row.scannedDocumentId, dataGrid.row.alias);
          }}
        >
          {dataGrid.row.alias}
        </Button>
      ),
    },
  ] as Column<ScannedDocument>[];

interface UseScannedDocumentColumnsProps {
  value: string;
  boxId: number;
  fileNameSelected?: string;
  filtrate: (filterText: string) => void;
}

const useScannedDocumentColumns = ({ boxId, filtrate, value }: UseScannedDocumentColumnsProps) => {
  const [scannedDocument, setScannedDocument] = useState<{
    id: number;
    fileName: string;
  }>({ fileName: '', id: 0 });

  const requestOpenFile = useRequest<Blob>();
  const { setLoading } = useLoadingGroup();

  const handleChange = (id: number, fileName: string) => {
    setScannedDocument({ id, fileName });
  };

  requestOpenFile.useChange(() => {
    if (setLoading) setLoading('columns', requestOpenFile.loading);
  });

  useWindowDocument({
    blob: requestOpenFile.successData,
    show: requestOpenFile.success,
    name: scannedDocument.fileName,
  });
  useEffect(() => {
    const IsNotZero = () => scannedDocument.id !== 0;
    if (IsNotZero()) {
      requestOpenFile.fetch(
        scannedDocumentsEndpoints.download({ scannedDocumentId: scannedDocument.id })
      );
    }
  }, [scannedDocument]);

  useEffect(() => {
    if (requestOpenFile.error || requestOpenFile.success)
      setScannedDocument({ id: 0, fileName: '' });
  }, [requestOpenFile.error, requestOpenFile.success]);

  return {
    columns: scannedDocumentColumns(value, filtrate, boxId, (id, name) => handleChange(id, name)),
    request: requestOpenFile,
  };
};

export default useScannedDocumentColumns;
