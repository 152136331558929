import { Redirect, Switch } from 'react-router';
import PublicRoute from '../../@shared/Routes/PublicRoute';
import RedirectRoot from '../../@shared/Routes/RedirectRoot';
import LoginTwoFactorPage from './pages/2fa';
import LoginPage from './pages/login';
import ChooseClientPage from './pages/choose-client';

const baseUrl = '/login';
const baseUrl2fa = `${baseUrl}/2fa`;

const routes = {
  baseUrl,
  twoFactorAuthentication: {
    baseUrl: baseUrl2fa,
    authSelect: `${baseUrl2fa}/auth-select`,
  },
};

const authRoutes = () => (
  <Switch>
    <PublicRoute path="/" exact>
      <RedirectRoot notLogged={<Redirect to={routes.baseUrl} />} />
    </PublicRoute>
    <PublicRoute path={routes.baseUrl} exact>
      <RedirectRoot notLogged={<LoginPage />} />
    </PublicRoute>
    <PublicRoute path={routes.twoFactorAuthentication.baseUrl} exact>
      <LoginTwoFactorPage />
    </PublicRoute>
    <PublicRoute path={routes.twoFactorAuthentication.authSelect} exact>
      <ChooseClientPage />
    </PublicRoute>
  </Switch>
);

export default authRoutes;
