import { useEffect, useState } from 'react';
import { Category } from '../../../../modules/category/features/CategoryForm/types';
import useMonitor from '../../../Monitor/useMonitor';

interface UseCategoryAccordionItemProps {
  category?: Category | null;
}

const useCategoryAccordionItem = ({ category: categoryProp }: UseCategoryAccordionItemProps) => {
  const [category, setCategory] = useState<Category | null>(null);
  const [boxClassificationId, setBoxClassificationId] = useState<number | null>(null);
  const { error, loading, success, ...rest } = useMonitor();

  useEffect(() => {
    if (categoryProp) {
      setCategory({ ...categoryProp });
    }
  }, [categoryProp]);

  return {
    category,
    setCategory,
    boxClassificationId,
    setBoxClassificationId,
    error,
    loading,
    success,
    monitor: rest,
  };
};
export default useCategoryAccordionItem;
