import useGenericUserDataGrid from '../../../../@shared/useGenericUserDataGrid';
import adminEndpoints from '../../admin.endpoint';
import { adminPagesUrl } from '../../admin.urls';

const useAdminDataGrid = () => {
  const genericDataGrid = useGenericUserDataGrid(
    (e) => adminPagesUrl.edit(String(e.actions)),
    'admin',
    (filters) => adminEndpoints.browse(filters.name, filters.cpf, filters.email)
  );

  return {
    ...genericDataGrid,
  };
};

export default useAdminDataGrid;
