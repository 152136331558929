import ErrorDialog from '../../../../@shared/Dialogs/ErrorDialog';
import ShimmerPlaceholder from '../../../../@shared/ShimmerPlaceholders';
import { BoxClassificationItem } from '../../classification.endpoint';
import useBoxClassificationItemAccordion from './boxClassificationItemAccordion.hook';
import BoxClassificationItemAccordionPresentation from './boxClassificationItemAccordionPresentation';

export interface BoxClassificationItemAccordionProps {
  boxClassificationId: number;
  defaultActiveKey?: string;
  className?: string;
  header?: React.ReactNode;
  reload?: boolean;
  onLoading?: () => void;
  onSuccess?: () => void;
  body: (e: {
    boxClassificationId: number;
    key: number;
    boxClassificationItem: BoxClassificationItem;
    eventKey: string;
  }) => React.ReactNode;
  categoryId?: number;
}

const BoxClassificationItemAccordion = ({
  boxClassificationId,
  defaultActiveKey,
  className,
  body,
  header,
  reload,
  onLoading,
  categoryId,
  onSuccess,
}: BoxClassificationItemAccordionProps) => {
  const { error, errorMessage, loading, successData } = useBoxClassificationItemAccordion(
    boxClassificationId,
    { onLoading, reload, categoryId, onSuccess }
  );

  if (error) return <ErrorDialog show>{errorMessage}</ErrorDialog>;
  if (loading) return <ShimmerPlaceholder.According header={header} />;
  return (
    <BoxClassificationItemAccordionPresentation
      className={className}
      defaultActiveKey={defaultActiveKey}
      header={header}
      boxClassificationId={boxClassificationId}
      boxClassificationItems={successData}
      body={body}
    />
  );
};

export default BoxClassificationItemAccordion;
