import yup from '../config/yup/customYup';

export default yup
  .object({
    pin: yup
      .string()
      .max(6)
      .min(6)
      .matches(/^[0-9]+$/, 'apenas valores numéricos são permitidos')
      .required(),
  })
  .required();
