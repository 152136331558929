import { createContext } from 'react';
import { Control, FieldErrors, UseFormSetError } from 'react-hook-form';
import { TreeViewNode } from '../../../../@shared/Fields/models';
import { Monitoring } from '../../../../@shared/Monitor/useMonitor';
import { FetchingPossibleUsers } from '../../../../@shared/Requests/components/fetchPossibleUsers';
import { SetStateAction } from '../../../../@shared/models';

export interface CategoryFormConfig {
  type: 'edit' | 'add' | null;
  control: Control<any> | null;
}

export interface CategoryFormContextProps extends Monitoring, FetchingPossibleUsers {
  errors?: FieldErrors<any>;
  setErrors: UseFormSetError<any>;
  config: CategoryFormConfig | null;
  setConfig: SetStateAction<CategoryFormConfig | null>;
  parents: TreeViewNode[];
  setParents: SetStateAction<TreeViewNode[]>;
}

export const CategoryFormContext = createContext<CategoryFormContextProps | null>(null);
