import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CategoryAccordionItemContext } from './item/categoryAccordion.item.context';

interface CategoryAccordionItemTitleProps {
  children?: string | ReactNode;
}

const CategoryAccordionTitle = ({ children }: CategoryAccordionItemTitleProps) => {
  const context = useContext(CategoryAccordionItemContext);
  const { category } = context ?? {};

  if (context) {
    if (category?.categoryId) {
      return (
        <Link to={`/categories/${category?.categoryId}/edit`}>{children ?? category?.name}</Link>
      );
    }
    return <>{children ?? category?.name}</>;
  }
  return <>{children}</>;
};

export default CategoryAccordionTitle;
