import FileField, { FileFieldProps } from '../fileField';

type UploadFileFieldsetProps = FileFieldProps & {};

const UploadFileField = ({
  control,
  isInvalid,
  onChange,
  name,
  label,
  value,
  accept,
  ...props
}: UploadFileFieldsetProps) => (
  <FileField
    {...props}
    value={value}
    id="file"
    label={label}
    accept={accept}
    type="file"
    name={name}
    control={control}
    isInvalid={isInvalid}
    onChange={onChange}
  />
);

export default UploadFileField;
