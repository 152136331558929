import useDialog from '../../../../../@shared/Dialogs/useDialog';
import useRequestForm from '../../../../../@shared/Forms/RequestForm/useRequestForm';
import boxesEndpoints from '../../../box.endpoints';
import { BoxMovementCode } from '../../../box.models';

import solicitMigrationValidator from '../../solicitMigrationValidator';
import useBoxDataGridSlice from '../useBoxDataGrid';
import SolicitMigrationFormDialogPresentation from './solicitationFormDialogPresentation';

const SolicitMigrationFormDialog = () => {
  const boxDataGridRedux = useBoxDataGridSlice();

  const { showSolicitMigrationFormDialog, migrationOptions, selectedRows } =
    boxDataGridRedux.useSelector();

  const form = useRequestForm({
    validatorSchema: solicitMigrationValidator,
    submitRequest: (obj) =>
      boxesEndpoints.solicitMovement(
        obj.description,
        selectedRows,
        BoxMovementCode.SolicitMigration,
        obj.migrationType
      ),
  });
  const dialog = useDialog({
    handleOpen: () => boxDataGridRedux.openSolicitMigrationDialog(),
    handleClose: () => boxDataGridRedux.closeSolicitMigrationDialog(),
  });

  form.useHandleSuccess(() => {
    boxDataGridRedux.setAnySubmit(form.submitResponse.success);
    form.submitResponse.reset();
  });

  dialog.useOpen(showSolicitMigrationFormDialog, [showSolicitMigrationFormDialog]);
  dialog.useClose(form.submitResponse.success);

  if (!showSolicitMigrationFormDialog) return <></>;
  return (
    <SolicitMigrationFormDialogPresentation
      control={form.control}
      descriptions={selectedRows}
      title="Solicitar migração"
      onHide={() => dialog.close()}
      show={dialog.show}
      size="lg"
      options={migrationOptions}
      method="post"
      onSubmit={form.handleSubmit(form.onSubmit)}
      validated={form.validator.validated}
      errors={form.validator.errors}
    />
  );
};

export default SolicitMigrationFormDialog;
