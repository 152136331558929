import { Switch } from 'react-router';
import PublicRoute from '../../@shared/Routes/PublicRoute';
import ScannedDocumentAddPage from './pages/add';

const baseUrl = '/consult/:boxId/scanned-documents';

export const routes = {
  baseUrl: `${baseUrl}`,
  add: `${baseUrl}/add`,
};

const ScannedDocumentsRoutes = () => (
  <Switch>
    <PublicRoute path={routes.add} exact>
      <ScannedDocumentAddPage />
    </PublicRoute>
  </Switch>
);

export default ScannedDocumentsRoutes;
