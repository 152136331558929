import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import HomePage from './home';

const baseUrl = '/home';

export const homePagesRoutes = {
  baseUrl,
};
const routes = {
  baseUrl,
};

const HomeRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <HomePage />
    </PrivateRoute>
  </Switch>
);

export default HomeRoutes;
