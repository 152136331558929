import { Control } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import Dialog from '../../../../@shared/Dialogs';
import { TreeViewNode } from '../../../../@shared/Fields/models';
import CategoryChooser from '../CategoryChooser';
import CategoryAddForm from '../CategoryForm/categoryAddForm';
import { Category } from '../CategoryForm/types';
import CategorySelect, { CategoryChooserSuccessData } from '../CategorySelect';

interface CategoryChooserDialogPresentationProps {
  options: { parents: TreeViewNode[]; categories: CategoryChooserSuccessData[] | Category[] };
  show: boolean;
  onHide: () => void;
  defaultValue?: CategoryChooserSuccessData[];
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  errors?: Record<string, FormError>;
  onSelect?: (e: MultiValue<CategoryChooserSuccessData>) => void;
  control?: Control<any>;
}

const CategoryChooserDialogPresentation = ({
  show,
  onHide,
  options = { categories: [], parents: [] },
  defaultValue,
  onSubmit,
  errors,
  onSelect,
  control,
}: CategoryChooserDialogPresentationProps) => (
  <Dialog size="lg" title="Adicionar Categoria" show={show} onHide={onHide}>
    <Dialog.Header />
    <Dialog.Form onSubmit={onSubmit}>
      <Dialog.Body>
        <CategoryChooser>
          {{
            isCreating: (
              <CategoryAddForm.Fields
                parents={options.parents}
                errors={errors?.added as any}
                prefix="added"
                control={control}
                direction="vertical"
              />
            ),
            isIncludeAlreadyExist: (
              <CategorySelect
                options={options.categories}
                defaultValue={defaultValue}
                onChange={onSelect}
              />
            ),
          }}
        </CategoryChooser>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.CloseButton />
        <Dialog.ConfirmButton defaultBehavior={false} />
      </Dialog.Footer>
    </Dialog.Form>
  </Dialog>
);

export default CategoryChooserDialogPresentation;
