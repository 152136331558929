import ReactDataGrid from 'react-data-grid';
import { noRowsFallback } from '../DataGrid';
import userCategoryColumns from './userCategoryColumns';
import { UserCategoryDataGridPresentationProps } from './userCategoryDataGrid.types';

const UserCategoryDataGridPresentation = ({
  rows,
  className,
  style,
  value,
  onChange,
}: UserCategoryDataGridPresentationProps) => {
  const columns = userCategoryColumns();
  return (
    <ReactDataGrid
      columns={columns}
      headerRowHeight={50}
      rows={rows ?? []}
      className={className}
      rowHeight={50}
      rowKeyGetter={(e) => e.value}
      selectedRows={new Set(value ?? []) as any}
      onSelectedRowsChange={onChange}
      components={{ noRowsFallback }}
      style={style}
    />
  );
};

export default UserCategoryDataGridPresentation;
