import { Dispatch, SetStateAction, createContext } from 'react';
import { Category } from '../../../../modules/category/features/CategoryForm/types';
import { Monitoring } from '../../../Monitor/useMonitor';

export interface CategoryAccordionItemContextProps extends Monitoring {
  category: Category | null;
  boxClassificationId: number | null;
  setCategory: Dispatch<SetStateAction<Category | null>>;
  setBoxClassificationId: Dispatch<SetStateAction<number | null>>;
}

export const CategoryAccordionItemContext = createContext<CategoryAccordionItemContextProps | null>(
  null
);
