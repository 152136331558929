import { useState } from 'react';
import { Redirect } from 'react-router';
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';
import LoadingPage from '../../../../@shared/template/_loadingPage';

import NewPasswordForm from '../NewPasswordForm';

const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const redux = useAuthSlice();
  if (loading) return <LoadingPage />;
  if (finish) return <Redirect to="/login" />;
  return (
    <NewPasswordForm
      onClickConfirm={() => {
        setLoading(true);
        redux.resetPassword();

        setTimeout(() => {
          setFinish(true);
          setLoading(false);
        }, 1000);
      }}
    />
  );
};

export default ResetPasswordForm;
