/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { DependencyList, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';
import useRequestForm from '../../Forms/RequestForm/useRequestForm';
import { Exception, RequestPropsOmitFetch } from '../../models';
import useGetPopulateValue from './internal/useGetPopulateValue';
import useInitStep from './internal/useInitStep';
import useSharePopulateValue from './internal/useSharePopulateValue';
import useUpdateStepStatusByRequestForm from './internal/useUpdateStepStatusByRequestForm';
import useUpdateStepStatusClickNext from './internal/useUpdateStepStatusClickNext';
import StepsFormContext from './stepsForm.context';
import utils from './stepsForm.utils';

type UseInitStepParams = {
  stepIndex: number;
  defaultValues?: any;
  validatorSchema?: any | null;
};
export function useLocalForm(stepIndex: number, defaultValues: any, validatorSchema: any) {
  const context = useContext(StepsFormContext);
  useInitStep(context, stepIndex);

  const rest = useForm({
    defaultValues,
    resolver: validatorSchema ? yupResolver(validatorSchema) : undefined,
  });
  return { ...rest, stepsStatus: utils.defineStepStatus(rest) };
}

const useStepsForm = () => {
  const wizard = useWizard();
  const context = useContext<StepsFormContextValues<any>>(StepsFormContext);

  const hooks = {
    useGetPopulateValue: (name: string) => useGetPopulateValue(context, name),
    useLocalForm: ({ stepIndex, defaultValues, validatorSchema = null }: UseInitStepParams) =>
      useLocalForm(stepIndex, defaultValues, validatorSchema),
    useLocalRequestForm: ({
      stepIndex,
      defaultValues,
      validatorSchema = null,
    }: UseInitStepParams) => {
      useInitStep(context, stepIndex);
      const rest = useRequestForm({ defaultValues, validatorSchema });
      return { ...rest, stepsStatus: utils.defineStepStatus(rest) };
    },

    useUpdateStepStatusByRequestForm: (submitResponse: RequestPropsOmitFetch<any, Exception>) =>
      useUpdateStepStatusByRequestForm(context, submitResponse),
    useSharePopulateValue: <T>(obj: T, deps: DependencyList) =>
      useSharePopulateValue(context, obj, deps),
    useUpdateStepStatusClickNext: (
      submitValue: FieldValues,
      errors: any,
      submitStatus: string,
      btnClick: number
    ) => useUpdateStepStatusClickNext(context, submitValue, errors, submitStatus, wizard, btnClick),
  };

  const functions = {
    stepIsValidated: (index: number) => utils.stepIsValidated(index, context),
    isSuccessAllForms: context.successfulAllForms,
    isSubmit: context.submit,
    submit: () => {
      context?.setSubmit(true);
    },
  };

  return { ...context, ...hooks, ...functions, ...wizard };
};

export default useStepsForm;
