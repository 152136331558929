import { useState } from 'react';
import { Button, Col, Container, ToggleButton } from 'react-bootstrap';
import { Controller, FieldValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../@shared/Forms/RequestForm';

import OldConfirmationDialog from '../../../../@shared/Dialogs/oldConfirmationDialog';
import DatePicker from '../../../../@shared/Fields/datePicker';
import TextField from '../../../../@shared/Fields/textField';
import FieldsPassword from './fieldsPassword';

interface IFormProfile extends FormRegisterProps, FormControlProps {
  showDialog: boolean;
  closeDialog: Function;
  setValue: UseFormSetValue<FieldValues>;
  reset: UseFormReset<FieldValues>;
  clearErrors;
}

const ProfileForm = ({
  showDialog,
  register,
  errors,
  closeDialog,
  control,
  validated,
  setValue,
  reset,
  clearErrors,
  ...props
}: IFormProfile) => {
  const [btnChecked, setBtnChecked] = useState(false);

  return (
    <Container className="m-0">
      <Col sm="12" md="6" className="py-4">
        <RequestForm {...props} validated={validated} errors={errors}>
          <TextField control={control} isInvalid={false} disabled id="name" label="Nome" />
          <TextField disabled isInvalid={false} id="surname" label="Sobrenome" control={control} />
          <DatePicker
            disabled
            isInvalid={false}
            control={control}
            name="birthDate"
            id="birth-date"
            label="Data de Nascimento"
          />
          <TextField isInvalid={false} disabled control={control} id="occupation" label="Cargo" />

          <fieldset className="border p-3">
            <legend className="w-auto">Mudar Senha</legend>
            <TextField
              id="currentPassword"
              control={control}
              label="Senha Atual"
              type="password"
              errorMessage={errors?.currentPassword?.message}
              isInvalid={errors?.currentPassword !== undefined}
            />

            <FieldsPassword
              btnChecked={btnChecked}
              errors={errors}
              setValue={setValue}
              control={control}
            />

            <div className="d-flex">
              <Button type="submit" className="me-3">
                Confirmar
              </Button>
              <Controller
                name="btnChecked"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <ToggleButton
                    className="toggle-button"
                    id="toggle-check"
                    type="checkbox"
                    variant="secondary"
                    value={0}
                    checked={btnChecked}
                    onChange={(e) => {
                      setBtnChecked(e.currentTarget.checked);
                      field.onChange(e.currentTarget.checked);
                      setValue('checkBoxChecked', false);
                      setValue('newPassword', '');
                      setValue('newPasswordConfirm', '');
                      clearErrors(['newPassword', 'newPasswordConfirm']);
                    }}
                  >
                    {btnChecked ? 'Desabilitar Gerador de senha' : 'Habilitar Gerar Senha'}
                  </ToggleButton>
                )}
              />
            </div>

            <OldConfirmationDialog
              open={showDialog}
              title="Senha alterada com sucesso"
              text="Sua senha acabou de ser alterada com sucesso, sera necessário logar novamente no sistema
            com sua nova senha"
              onClick={closeDialog}
            />
          </fieldset>
        </RequestForm>
      </Col>
    </Container>
  );
};

export default ProfileForm;
