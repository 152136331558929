import { useParams } from 'react-router';
import useRequest from '../../../../@shared/Requests/useRequest';
import { ScannedDocument } from '../../../../@shared/models';

import { useLoadingGroup } from '../../../../@shared/LoadingGroup/useLoadingGroup';
import useFiltrate from '../../../../@shared/useFiltrate';
import scannedDocumentsEndpoints from '../../scanned-document.endpoints';
import useScannedDocumentColumns from './useScannedDocumentColumns';

function predicate(f: string, d: ScannedDocument, text: string): boolean {
  return f.trim().toLowerCase() === ''
    ? true
    : d.alias.toLowerCase().startsWith(text.toLowerCase());
}

const key = 'useScannedDocumentDataGrid';

const useScannedDocumentDataGrid = () => {
  const { setLoading } = useLoadingGroup();
  const filterForm = useFiltrate<ScannedDocument>();

  const params = useParams<ScannedDocumentParams>();
  const boxId = Number(params.boxId);
  const requestListFiles = useRequest(scannedDocumentsEndpoints.list({ boxId }));

  const { columns, request: requestOpenFile } = useScannedDocumentColumns({
    boxId,
    value: filterForm.filterText,
    filtrate: (text) => filterForm.filterData(text, (f, d) => predicate(f, d, text)),
  });

  requestListFiles.useFetchBy(boxId);
  requestListFiles.useSuccess(() => filterForm.defineData(requestListFiles.successData));
  requestListFiles.useChange(() => setLoading(key, requestListFiles.loading));

  return {
    loading: requestListFiles.loading,
    error: requestOpenFile.error,
    errorMessage: requestOpenFile.backEndErrors?.message ?? '',
    rows: filterForm.data.filteredData,
    columns,
  };
};

export default useScannedDocumentDataGrid;
