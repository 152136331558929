import { AxiosRequestConfig } from 'axios';
import { Filter, UserChangeActive, UserInput } from '../user/user';
import { AdminAddFormState, AdminEditFormState } from './admin.types';

const path = 'admins';

const adminEndpoints = {
  browse(name?: string, cpf?: string, email?: string) {
    return {
      method: 'get',
      url: `${path}/browse-admins`,
      params: {
        name,
        cpf,
        email,
      },
    } as AxiosRequestConfig;
  },

  downloadCsv(filters: Filter) {
    return {
      method: 'get',
      url: `${path}/browse-admins.csv`,
      params: {
        name: filters.name,
        cpf: filters.cpf,
        email: filters.email,
      },
      responseType: 'blob',
    } as AxiosRequestConfig;
  },

  downloadPdf(filters: Filter, active?: boolean) {
    return {
      method: 'get',
      url: `${path}/browse-admins.pdf`,
      params: {
        name: filters.name,
        cpf: filters.cpf,
        email: filters.email,
        active,
      },
      responseType: 'blob',
    } as AxiosRequestConfig;
  },

  getMyRoles() {
    return { method: 'get', url: `${path}/me/get-roles` } as AxiosRequestConfig;
  },
  add(data: AdminAddFormState) {
    return {
      method: 'post',
      url: `${path}/add-update-admin`,
      data,
    } as AxiosRequestConfig;
  },
  edit(data: AdminEditFormState) {
    return {
      method: 'post',
      url: `${path}/add-update-admin`,
      data,
    } as AxiosRequestConfig;
  },

  find(userId: number) {
    return { method: 'get', url: `${path}/${userId}` } as AxiosRequestConfig;
  },

  update(data: UserInput) {
    return { method: 'post', url: `${path}/add-update-admin`, data } as AxiosRequestConfig;
  },

  changeActive(changeActive: UserChangeActive) {
    return {
      method: 'post',
      url: `${path}/change-active-admin`,
      data: changeActive,
    } as AxiosRequestConfig;
  },
  sendEmailOfResetPasswordById(userId: number) {
    return {
      method: 'post',
      url: `${path}/send-email-of-reset-password-by-id`,
      data: userId,
    } as AxiosRequestConfig;
  },

  listCategoriesAsync(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${path}/me/browse-categories:option-tree`,
    };
  },
};

export default adminEndpoints;
