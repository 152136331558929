import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { formatDate, util } from '../utils/util';
import { FieldControlOmitChildrenProps, replaceNameFieldToField } from './Field';

interface IDatePicker extends FieldControlOmitChildrenProps {
  name: string;
}

const DatePicker = ({
  label,
  helperText = '',
  id,
  errorMessage,
  name,
  control,
  containerClassName,
  defaultValue,
  ...props
}: IDatePicker & React.ComponentProps<typeof Form.Control>) => {
  const controller = useController({ name, control });
  const formDefaultValue: string = controller.formState?.defaultValues?.[name];

  useEffect(() => {
    if (!controller.field.value) controller.field.onChange(formDefaultValue);
  }, [formDefaultValue]);

  return (
    <Form.Group className={`mb-3 ${containerClassName}`} controlId={id}>
      <Form.Label>{label}</Form.Label>
      <ReactInputMask
        mask="99/99/9999"
        value={formatDate(controller.field.value)}
        onChange={(e) => {
          if (!util.isDateFormat(e.target.value)) {
            controller.field.onChange(e.target.value);
            return;
          }

          controller.field.onChange(util.dateIsoFormat(e.target.value));
        }}
        {...(props as any)}
      >
        {(inputProps: any) => <Form.Control {...inputProps} />}
      </ReactInputMask>
      {helperText && <Form.Text className="text-muted">{helperText}</Form.Text>}
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {replaceNameFieldToField(errorMessage?.toString())}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default DatePicker;
