import yup from '../../../../config/yup/customYup';

const validator = yup
  .object({
    active: yup.boolean().required(),
    birthDate: yup.date().required(),
    cpf: yup.string().required().max(11),
    email: yup.string().email().required(),
    login: yup.string().required(),
    name: yup.string().required(),
    occupation: yup.string().required(),
    surname: yup.string().required(),
  })
  .required();

export default validator;
