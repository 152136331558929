import React from 'react';
import { Control } from 'react-hook-form/dist/types';
import { Option } from '../../../models';
import SelectColumn from '../SelectColumn';
import Thead from '../thead.presentation';

export type Column = {
  key: string;
  id: string;
  disabled?: boolean;
  options: Option[];
};
interface TheadSelectPresentationProps {
  control: Control;
  children: Column[];
  value?: string[];
}

const TheadSelectPresentation = ({ children, control, value }: TheadSelectPresentationProps) => (
  <Thead>
    {children?.map((e, i) => (
      <React.Fragment key={e.key}>
        <SelectColumn
          control={control}
          id={e.id}
          value={value?.at(i)}
          disabled={e.disabled}
          options={e.options}
        />
      </React.Fragment>
    ))}
  </Thead>
);
export default TheadSelectPresentation;
