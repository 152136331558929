import { Key, useEffect, useState } from 'react';
import useRequestFileDownload from '../../../../../@shared/Requests/useRequestFileDownload';
import { PageSize } from '../../../../../constants/constants';
import boxesEndpoints from '../../../box.endpoints';

const useFaceSheetLabelDownload = (selectedIds: Set<Key>, pageSize: PageSize) => {
  const [endpoint, setEndpoint] = useState(
    boxesEndpoints.getFaceSheetLabelPdf({
      boxes: Array.from(selectedIds).map((e) => Number(e)),
      pageSize,
    })
  );

  useEffect(() => {
    setEndpoint(
      boxesEndpoints.getFaceSheetLabelPdf({
        boxes: Array.from(selectedIds).map((e) => Number(e)),
        pageSize,
      })
    );
  }, [pageSize, selectedIds]);

  const fileSize = pageSize.toString();
  const faceSheetLabelRequest = useRequestFileDownload(`folha-de-rosto-${fileSize}`, endpoint);

  return {
    ...faceSheetLabelRequest,
  };
};

export default useFaceSheetLabelDownload;
