import yup, { parseDateString } from '../../config/yup/customYup';
import { ILinkButtonGroup as LinkButtonGroupProps } from '../../modules/auth/features/ForgotDialogues/linkButtonGroup';
import { AddBox, BoxDescriptions, YupField } from '../../modules/box/box.models';
import { InputGroup } from '../Fields/models';
import { ErrorMessage, Exception, Ref, UseRequest } from '../models';
import { IError } from '../Verifiers/verifyError';

const factory = {
  buildErrorMessage(errorMessage, dirDefault: 'top' | 'bottom'): ErrorMessage {
    return typeof errorMessage === 'object'
      ? errorMessage
      : { message: errorMessage, dir: dirDefault };
  },
  buildError(error: boolean, backEndErrors: Exception | null): IError {
    return { backEndErrors, error };
  },

  buildRequest(request: UseRequest) {
    return {
      success: request.success === true,
      error: request.error === true,
      loading: request.loading === true,
    } as UseRequest;
  },

  buildLoginLinkButtonGroup(
    refForgotPasswordOpenOrClose: Ref<() => void>,
    refForgotTwoFactorVerificationOpenOrClose: Ref<() => void>,
    refForgotPasswordAndTwoFactorVerificationDialog: Ref<() => void>
  ) {
    return {
      onClickForgotPasswordDialog: refForgotPasswordOpenOrClose.current,
      onClickForgotTwoFactorVerificationDialog: refForgotTwoFactorVerificationOpenOrClose.current,
      onClickForgotPasswordAndTwoFactorVerificationDialog:
        refForgotPasswordAndTwoFactorVerificationDialog.current,
    } as LinkButtonGroupProps;
  },

  buildBoxFormOptions(categories: UseRequest, classifications: UseRequest) {
    return {
      categories: categories.successData ?? [],
      classifications: classifications.successData ?? [],
    };
  },

  buildDynamicFields(textboxes: UseRequest) {
    return textboxes.successData ?? [];
  },

  buildAddBox(obj: any, dynamicFields: InputGroup[]): AddBox {
    const { barcode, categoryId, classificationId, location, exclusionDate, ...rest } = obj;

    const ids = Object.keys(rest);
    return {
      barcode: barcode === '' ? null : barcode,
      categoryId: Number(categoryId),
      classificationId: Number(classificationId),
      location,
      exclusionDate,
      isManualBarcode: obj.isManualBarcode,
      boxDescriptions: ids
        .map((currentId): BoxDescriptions | null => {
          const currentObj = dynamicFields.find((e) => e.id === currentId);
          if (!currentObj) return null;
          return {
            boxClassificationItemId: Number(currentId),
            archiveDescription: obj ? obj[currentId] : '',
          };
        })
        .filter((e) => e !== null) as BoxDescriptions[],
    };
  },

  buildDynamicValidators(dynamicFields: InputGroup[]): YupField | null {
    const dynamicValidators: YupField[] = dynamicFields.map((e) => {
      switch (e.type) {
        case 'date':
          return { [e.id]: yup.date().transform(parseDateString).required() };
        default:
          return { [e.id]: yup.string().required() };
      }
    });
    return dynamicFields.length === 0
      ? null
      : dynamicValidators?.reduce((p, c) => ({ ...p, ...c }));
  },
  buildKeepValuesForBoxForm(dynamicFields: InputGroup[]): string[] {
    return [
      'categoryId',
      'classificationId',
      'location',
      'barcode',
      'exclusionDate',
      ...dynamicFields.map((e) => e.id),
    ];
  },
};

export default factory;
