import { useLayoutEffect, useRef } from 'react';

const useFocusRef = <T extends HTMLElement>(isSelected: boolean) => {
  const ref = useRef<T>({} as T);
  useLayoutEffect(() => {
    if (isSelected) ref.current.focus();
  }, [isSelected]);
  return {
    ref,
  };
};

export default useFocusRef;
