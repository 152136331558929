import { AxiosRequestConfig } from 'axios';
import useRequest from '../Requests/useRequest';
import useCreateObjectUrlByBlobPart from '../useCreateObjectUrlByBlobPart';

export interface UseDownloadButtonProps {
  error: boolean;
  loading: boolean;
  handleClick: () => void;
  errorMessage: string;
  success: boolean;
}

const useDownloadButton = (
  contentType: ContentType,
  fileName: string,
  requestConfig: AxiosRequestConfig
): UseDownloadButtonProps => {
  const request = useRequest(requestConfig);
  const { finish, download } = useCreateObjectUrlByBlobPart(
    request.successData,
    fileName,
    contentType
  );

  if (request.success) {
    download();
    if (finish) request.reset();
  }

  return {
    success: request.success,
    error: request.error,
    loading: request.loading,
    handleClick: () => request.fetch(),
    errorMessage: request.backEndErrors?.message ?? 'ocorreu algum erro',
  };
};
export default useDownloadButton;
