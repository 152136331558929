import { AxiosRequestConfig } from 'axios';
import { ResetPassword } from '../../@shared/models';

const name = 'reset-password';
const resetPasswordEndpoints = {
  verifyResetPasswordToken(data: { token: string }) {
    return {
      method: 'post',
      url: `${name}/verify-reset-password-token`,
      data: data.token,
    } as AxiosRequestConfig;
  },

  reset(data: ResetPassword) {
    return {
      method: 'post',
      url: `${name}/reset`,
      data,
    } as AxiosRequestConfig;
  },
};

export default resetPasswordEndpoints;
