import { Container } from 'react-bootstrap';
import Layout from '../../layout';
import UserDataGrid from '../features/UserDataGrid';

const UserListPage = () => (
  <Layout>
    <Container>
      <UserDataGrid />
    </Container>
  </Layout>
);

export default UserListPage;
