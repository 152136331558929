import { Column, SelectColumn } from 'react-data-grid';
import { CheckBox } from '../Fields/models';

export type UserCategoryRow = {
  userId: number;
  name: string;
  checked: boolean;
};

const userCategoryColumns = () =>
  [
    {
      ...SelectColumn,
      key: 'value',
    },
    {
      key: 'label',
      name: 'Usuários',
    },
  ] as Column<CheckBox>[];

export default userCategoryColumns;
