import { Control } from 'react-hook-form';
import {
  AddBoxClassificationItem,
  BoxClassificationItem,
} from '../../../modules/classification/classification.endpoint';
import { FormError } from '../../Alerts/errorFormAlert';
import DatePicker from '../../Fields/datePicker';
import NumberField from '../../Fields/numberField';
import RadioGroup from '../../Fields/radioButtonGroup';
import SelectField from '../../Fields/selectField';
import TextField from '../../Fields/textField';
import { Option } from '../../models';
import BoxClassificationItemFormTemplate from './boxClassificationItemForm.template';

interface BoxClassificationItemFormFieldsProps<C extends Control = Control<any, any>> {
  control: C;
  errors?: Record<string, FormError>;
  type: 'edit' | 'add';
  defaultValue?: BoxClassificationItem | AddBoxClassificationItem;
  prefix?: string;
  formats: Option[];
}

function shouldPrefix(prefix: string | undefined, name: string): string {
  return prefix ? `${prefix}.${name}` : name;
}

const BoxClassificationItemFormFields = ({
  control,
  errors,
  type,
  defaultValue,
  prefix,
  formats,
}: BoxClassificationItemFormFieldsProps) => (
  <BoxClassificationItemFormTemplate
    type={type}
    name={
      <TextField
        control={control}
        label="Nome"
        name={shouldPrefix(prefix, 'name')}
        id="name"
        isInvalid={errors?.name !== undefined}
        errorMessage={errors?.name?.message}
        defaultValue={defaultValue?.name}
      />
    }
    format={
      <SelectField<Option>
        control={control}
        getOptionValue={(option) => option?.value}
        id="format"
        label="Formato"
        name={shouldPrefix(prefix, 'format')}
        isInvalid={errors?.format !== undefined}
        errorMessage={errors?.format?.message}
        defaultValue={defaultValue?.format}
        options={formats}
      />
    }
    size={
      <NumberField
        label="Tamanho (Caracteres)"
        helperText="Informe a quantidade máxima de caracteres para o item "
        control={control}
        name={shouldPrefix(prefix, 'size')}
        id="size"
        isInvalid={errors?.size !== undefined}
        errorMessage={errors?.size?.message}
        defaultValue={defaultValue?.size}
      />
    }
    barcodeRef={
      <RadioGroup
        label="É referência de código de barras ?"
        control={control}
        name={shouldPrefix(prefix, 'barcodeRef')}
        type="bool"
        errorMessage={errors?.barcodeRef?.message}
        isInvalid={errors?.barcodeRef !== undefined}
        defaultValue={defaultValue?.barcodeRef}
      >
        {[
          { key: 'barcodeRef.true', label: 'Sim', value: true },
          { key: 'barcodeRef.false', label: 'Não', value: false },
        ]}
      </RadioGroup>
    }
    orderNumber={
      <NumberField
        isInvalid={errors?.orderNumber !== undefined}
        control={control}
        label="Número da ordem"
        name={shouldPrefix(prefix, 'orderNumber')}
        id="orderNumber"
        errorMessage={errors?.orderNumber?.message}
        defaultValue={defaultValue?.orderNumber ?? 0}
      />
    }
    observation={
      <TextField
        label="Observação"
        control={control}
        as="textarea"
        rows={3}
        name={shouldPrefix(prefix, 'observation')}
        id="observation"
        isInvalid={errors?.observation !== undefined}
        errorMessage={errors?.observation?.message}
        defaultValue={defaultValue?.observation}
      />
    }
    isPrinted={
      <RadioGroup
        label="É impresso ?"
        control={control}
        name={shouldPrefix(prefix, 'isPrinted')}
        type="bool"
        errorMessage={errors?.isPrinted?.message}
        isInvalid={errors?.isPrinted !== undefined}
        defaultValue={defaultValue?.isPrinted}
      >
        {[
          { key: 'isPrinted.true', label: 'Sim', value: true },
          { key: 'isPrinted.false', label: 'Não', value: false },
        ]}
      </RadioGroup>
    }
    inclusionDate={
      type === 'edit' ? (
        <DatePicker
          label="Data de Inclusão"
          name={shouldPrefix(prefix, 'inclusionDate')}
          control={control}
          isInvalid={errors?.inclusionDate !== undefined}
          disabled
          defaultValue={(defaultValue as any)?.inclusionDate}
        />
      ) : (
        <></>
      )
    }
    cancellationDate={
      type === 'edit' ? (
        <DatePicker
          label="Data de Cancelamento"
          name={shouldPrefix(prefix, 'cancellationDate')}
          control={control}
          isInvalid={errors?.cancellationDate !== undefined}
          disabled
          defaultValue={(defaultValue as any)?.cancellationDate}
        />
      ) : (
        <></>
      )
    }
  />
);

export default BoxClassificationItemFormFields;
