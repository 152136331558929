import { Form, Stack } from 'react-bootstrap';
import { FormError } from '../../../../../@shared/Alerts/errorFormAlert';
import { InputGroup } from '../../../../../@shared/Fields/models';
import StepButtonsPresentation from '../../../../../@shared/MultiStepForm/StepButtons/stepButtons.presentation';
import Step from '../../../../../@shared/MultiStepForm/StepsForm/steps';
import TagForm from './TagForm';

interface Step2PresentationParams {
  onClickNextStep: Function;
  onClickPreviousStep: Function;
  loading: boolean;
  control;
  fields: InputGroup[];
  onSubmit;
  errors: Record<string, FormError> | undefined;
}

const Step2Presentation = ({
  onClickNextStep,
  onClickPreviousStep,
  loading,
  control,
  errors,
  fields,
  onSubmit,
}: Step2PresentationParams) => (
  <Step
    title="Definir Tags"
    middle={
      <Stack gap={2}>
        <Form.Text className="text-muted">Definas a tags que apareceram</Form.Text>
        <TagForm
          isLoading={loading}
          control={control}
          fields={fields}
          onSubmit={onSubmit}
          errors={errors}
        />
      </Stack>
    }
    bottom={
      <StepButtonsPresentation
        lastStep
        disableNextStep={false}
        disablePreviousStep={false}
        hiddenPreviousStep={false}
        onClickNextStep={onClickNextStep}
        onClickPreviousStep={onClickPreviousStep}
      />
    }
  />
);

export default Step2Presentation;
