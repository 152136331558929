import { RequestPropsOmitFetch } from '../../../models';
import utils from '../stepsForm.utils';

const useUpdateStepStatusByRequestForm = (
  context: StepsFormContextValues,
  submitResponse: RequestPropsOmitFetch
) => {
  submitResponse.useSuccess(() => {
    if (context?.currentStep !== undefined) {
      utils.updateStepValidated(context?.currentStep, context);
      utils.updateStepValue(context?.currentStep, submitResponse.successData, context);
      context.setCurrentStep(context?.currentStep + 1);
    }
  });
  submitResponse.useFailure(() => {
    if (context?.currentStep !== undefined) {
      utils.updateStepInvalided(context?.currentStep, context);
      utils.updateStepError(context?.currentStep, submitResponse?.backEndErrors, context);
    }
  });
};

export default useUpdateStepStatusByRequestForm;
