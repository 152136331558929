/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button } from 'react-bootstrap';

export interface ILinkButtonGroup {
  onClickForgotPasswordDialog: React.MouseEventHandler<HTMLButtonElement>;
  onClickForgotTwoFactorVerificationDialog: React.MouseEventHandler<HTMLButtonElement>;
  onClickForgotPasswordAndTwoFactorVerificationDialog: React.MouseEventHandler<HTMLButtonElement>;
}

interface LinkGroupPresentationProps extends ILinkButtonGroup {
  className?: string;
}

const LinkButtonGroup = ({
  className,
  onClickForgotPasswordDialog,
  onClickForgotTwoFactorVerificationDialog,
  onClickForgotPasswordAndTwoFactorVerificationDialog,
}: LinkGroupPresentationProps) => (
  <div className={`w-100 ${className}`}>
    <Button
      type="button"
      variant="link"
      className="p-0 text-start text-nowrap"
      onClick={onClickForgotPasswordDialog}
    >
      Esqueci minha senha
    </Button>
    <br />
    <Button
      type="button"
      variant="link"
      className="p-0 text-start text-nowrap"
      onClick={onClickForgotTwoFactorVerificationDialog}
    >
      Perdi minha verificação de duas etapas
    </Button>
    <br />
    <Button
      type="button"
      variant="link"
      className="p-0 text-start text-nowrap"
      onClick={onClickForgotPasswordAndTwoFactorVerificationDialog}
    >
      Perdi minha verificação de duas etapas e minha senha
    </Button>
  </div>
);

LinkButtonGroup.defaultProps = {
  className: '',
};

export default LinkButtonGroup;
