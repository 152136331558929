import { AxiosRequestConfig } from 'axios';

const name = 'token';
const layoutEndpoints = {
  getUserinfo(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/get-userinfo`,
    };
  },
};

export default layoutEndpoints;
