/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Column } from 'react-data-grid';
import { Filter, UserRow } from '../../user';
import ActionButtons from '../ActionButtons';

import ColumnFilter from '../../../../@shared/Fields/ColumnFilter/columnFilter';

export interface IUserDataGridFilter {
  name: string;
  cpf: string;
  email: string;
}

export interface IUserColumns {
  filters: IUserDataGridFilter;
  columns: Column<UserRow, any>[];
}

type Result = {
  filters: Filter;
  columns: Column<UserRow, any>[];
};

type PathEdit = string | ((e) => string);

const UserColumns = (pathEdit: PathEdit, type: 'admin' | 'user', onClickDisable: Function) => {
  const refValue = useRef<Filter>();
  const [columns, setColumns] = useState<Column<UserRow, any>[]>([]);
  const [pressEnter, setPressEnter] = useState(false);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [result, setResult] = useState<Result>({
    columns: [],
    filters: { cpf: '', email: '', name: '' },
  });

  useEffect(() => {
    if (pressEnter) {
      setPressEnter(false);
      refValue.current = { cpf, email, name };
    }
  }, [pressEnter]);

  useEffect(() => {
    setResult({ columns, filters: { name, cpf, email } });
  }, [columns, cpf, email, name]);

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: 'Nome',
        headerRenderer: (h) => (
          <ColumnFilter
            title="Nome"
            value={refValue.current?.name ?? ''}
            onPressEnter={(v) => {
              setName(v);
              setPressEnter(true);
            }}
            header={h}
          >
            <FormControl />
          </ColumnFilter>
        ),
      },
      {
        key: 'cpf',
        name: 'CPF',
        headerRenderer: (h) => (
          <ColumnFilter
            title="CPF"
            value={refValue.current?.cpf ?? ''}
            onPressEnter={(v) => {
              setCpf(v);
              setPressEnter(true);
            }}
            header={h}
          >
            <FormControl />
          </ColumnFilter>
        ),
      },
      {
        key: 'email',
        name: 'email',
        headerRenderer: (h) => (
          <ColumnFilter
            title="Email"
            value={refValue.current?.email ?? ''}
            onPressEnter={(v) => {
              setEmail(v);
              setPressEnter(true);
            }}
            header={h}
          >
            <FormControl />
          </ColumnFilter>
        ),
      },
      {
        key: 'actions',
        name: '',
        width: 178,
        formatter: ({ row }) => (
          <ActionButtons
            type={type}
            id={row.actions}
            isDisable={row.isDisable}
            pathEdit={typeof pathEdit === 'string' ? pathEdit : pathEdit(row)}
            onClickDisable={() => {
              setTimeout(() => {
                onClickDisable();
              }, 600);
            }}
          />
        ),
        cellClass: '',
      },
    ]);
  }, []);

  return result;
};

export default UserColumns;
