import { useState } from 'react';
import { InputGroup } from '../../../../../@shared/Fields/models';
import { Option } from '../../../../../@shared/models';

import useRequest from '../../../../../@shared/Requests/useRequest';
import boxesEndpoints from '../../../box.endpoints';
import { OptionCancellationDatePair } from '../../../box.models';

export interface UseBuilderBoxFormValues {
  classificationSelected: number;
  loading: boolean;
  fields: InputGroup[];
  options: {
    classifications: OptionCancellationDatePair[] | null;
    categories: Option[] | null;
  };
  errors: boolean;
  backEndErrors: any;
}

const useBuilderBoxForm = (
  categorySelected: number,
  classificationSelected: number,
  setClassificationSelected: React.Dispatch<React.SetStateAction<number | null>>,
  getCategories: boolean,
  getClassifications: boolean,
  getFields: boolean
): UseBuilderBoxFormValues => {
  const [classificationOptions, setClassificationOptions] = useState<
    OptionCancellationDatePair[] | null
  >(null);
  const [categoriesOptions, setCategoriesOptions] = useState<Option[] | null>(null);
  const [dynamicFields, setDynamicFields] = useState<InputGroup[]>([]);

  const requestFillCategory = useRequest<Option[]>(boxesEndpoints.listUserCategoryOption());
  const requestFillClassification = useRequest<OptionCancellationDatePair[]>(
    boxesEndpoints.listBoxClassificationOptionCancellationDatePair({ categoryId: categorySelected })
  );
  const requestDynamicFields = useRequest<InputGroup[]>(
    boxesEndpoints.listBoxClassificationItemsTextbox({
      boxClassificationId: classificationSelected ?? 0,
    })
  );

  const categoryValid =
    categorySelected !== null &&
    categorySelected !== undefined &&
    categorySelected !== 0 &&
    categorySelected?.toString() !== '';
  const classificationValid =
    classificationSelected !== null &&
    classificationSelected !== undefined &&
    classificationSelected !== 0 &&
    classificationSelected?.toString() !== '';

  requestFillCategory.useFetchBy(getCategories, 'init');
  requestFillClassification.useFetchBy(
    getClassifications && categoryValid,
    JSON.stringify(categorySelected)
  );

  requestDynamicFields.useFetchBy(getFields && classificationValid, classificationSelected);
  requestFillClassification.useSuccess(() => {
    setClassificationSelected(0);
  });

  requestFillClassification.useSuccess(() => {
    setClassificationOptions(requestFillClassification.successData ?? []);
  });
  requestFillCategory.useSuccess(() => {
    setCategoriesOptions(requestFillCategory.successData ?? []);
  });
  requestDynamicFields.useSuccess(() => {
    setDynamicFields(requestDynamicFields.successData ?? []);
    requestDynamicFields.reset();
  });

  return {
    errors:
      requestFillCategory.error || requestFillClassification.error || requestDynamicFields.error,
    backEndErrors:
      requestFillCategory.backEndErrors ||
      requestFillClassification.backEndErrors ||
      requestDynamicFields.backEndErrors,
    classificationSelected,
    loading:
      requestFillCategory.loading ||
      requestFillClassification.loading ||
      requestDynamicFields.loading,
    fields: dynamicFields,
    options: {
      classifications: classificationOptions ?? [],
      categories: categoriesOptions ?? [],
    },
  };
};

export default useBuilderBoxForm;
