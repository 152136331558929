import React, { createContext, useState } from 'react';
import useDialog from '../useDialog';

export type Extra = Record<string, any>;

interface DialogContextProps {
  extra: Record<string, any>;
  setExtra: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  show: boolean;
  open: () => void;
  close: () => void;
}

interface CategoryAddDialogContextProviderProps {
  children: React.ReactNode;
}

export const NewDialogContext = createContext<DialogContextProps | undefined>(undefined);

const DialogProvider = ({ children }: CategoryAddDialogContextProviderProps) => {
  const { close, show, open } = useDialog();
  const [extra, setExtra] = useState<Record<string, any>>({});

  return (
    <NewDialogContext.Provider value={{ extra, setExtra, close, open, show }}>
      {children}
    </NewDialogContext.Provider>
  );
};

export default DialogProvider;
