import { ColProps, Placeholder, PlaceholderProps, Stack } from 'react-bootstrap';

export type RadioShimmerPlaceholderProps = ColProps & PlaceholderProps & {};

const RadioShimmerPlaceholder = ({
  animation = 'glow',
  as = 'div',
  ...props
}: RadioShimmerPlaceholderProps) => (
  <Placeholder animation={animation}>
    <Stack direction="horizontal" gap={2}>
      <Placeholder
        className="mb-1"
        style={{
          height: '20px',
          width: '20px',
          borderRadius: '50%',
        }}
        xs={3}
        {...{ ...props, as }}
      />
      <Placeholder
        style={{ height: '16px', borderRadius: '.375rem' }}
        xs={3}
        {...{ ...props, as }}
      />
    </Stack>
  </Placeholder>
);

export default RadioShimmerPlaceholder;
