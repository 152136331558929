export interface MenuItemProps {
  name: string;
  permission: number;
}
export interface UserInfo {
  permissions: string[];
  roles: string[];
  clientName: string;
  subsidiaryName: string;
  clientId: number;
  subsidiaryId: number;
}

export interface MenuProps extends Array<MenuItemProps> {}

const menuService = {
  remove() {
    localStorage.removeItem('menu');
  },

  get() {
    const secretJson = JSON.parse(localStorage.getItem('menu') || '[]');
    return secretJson as UserInfo;
  },

  isNull() {
    const menu = localStorage.getItem('menu');
    return menu === '[]' || menu === '{}' || menu === null || menu === undefined;
  },

  addUpdate(menu: UserInfo) {
    if (menu) {
      localStorage.setItem('menu', JSON.stringify(menu));
    }
  },
};

export default menuService;
