import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import useEffectSetErrors from '../../../../@shared/Forms/useSetErrors';
import useRequest from '../../../../@shared/Requests/useRequest';
import { Exception } from '../../../../@shared/models';
import InputFilter from '../../../../@shared/utils/inputFilter';
import { newToast } from '../../../../redux/slices/userSuccessToastSlice';
import { UserInput } from '../../user';
import userEndpoints from '../../user.endpoints';
import createUserAddFormValidator from '../UserAddForm/validator';
import useUserToast from '../useUserToast';

function useEffectRedirectWhenFetchSuccess(
  success: boolean,
  history: any,
  dispatch: Dispatch<any>
) {
  useEffect(() => {
    if (success) {
      dispatch(newToast({ text: 'O usuário foi adicionado com sucesso', show: true }));
      history.push('/users');
    }
  }, [success]);
}

export default () => {
  const toast = useUserToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    fetch,
    loading,
    successData,
    backEndErrors,
    error,
    success,
    useSuccess: useOnSuccess,
  } = useRequest();
  const [arrayErrors, setArrayErrors] = useState({} as Record<string, FormError>);
  const [validated, setValidated] = useState(false);

  const schema = createUserAddFormValidator();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useOnSuccess(() => toast.setNewToast('Atualizado com sucesso'));
  useEffectSetErrors(errors, setValidated, setArrayErrors, backEndErrors as Exception);
  useEffectRedirectWhenFetchSuccess(success, history, dispatch);

  async function onSubmit(obj: any) {
    const userCategory: UserInput = {
      ...obj,
      roles: InputFilter.switchType(obj.roles, 'enum'),
    };

    fetch(userEndpoints.add(userCategory));
  }
  return {
    success,
    loading,
    error,
    successData,
    arrayErrors,
    validated,
    register,
    handleSubmit,
    onSubmit,
    control,
    setValue,
  };
};
