import { Redirect } from 'react-router';
import useAuthSlice from '../Auth/useAuthSlice';

const RedirectRoot = ({ notLogged: redirectNotLogged }: { notLogged: JSX.Element }) => {
  const redux = useAuthSlice();
  const { wasLogged } = redux.useState();

  return wasLogged ? <Redirect to="/home" /> : redirectNotLogged;
};

export default RedirectRoot;
