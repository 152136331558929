import { Form } from 'react-bootstrap';

export interface FieldPanelProps {
  label: string;
  children: string;
}

const FieldPanel = ({ label, children }: FieldPanelProps) => (
  <Form.Group>
    <Form.Label>{label}</Form.Label>
    <Form.Control value={children} disabled />
  </Form.Group>
);

export default FieldPanel;
