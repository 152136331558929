import { Redirect } from 'react-router';
import TwoFactorAuthenticationForm from '../../../@shared/TwoFactorAuthenticationForm';
import Layout from '../../layout';

const ResetPassword2Fa = () => (
  <Layout showLoggedComponents={false}>
    <TwoFactorAuthenticationForm
      onAdmin={<Redirect to="/reset-password/2fa/define-new-password" />}
      onSuccess={<Redirect to="/reset-password/2fa/define-new-password" />}
    />
  </Layout>
);

export default ResetPassword2Fa;
