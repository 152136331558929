import * as yup from 'yup';

const clientAddFormValidator = yup
  .object({
    cellPhone: yup.string().max(45),
    postcode: yup.string().max(11),
    city: yup.string().required(),
    commercialPhone: yup.string().max(45),
    complement: yup.string(),
    district: yup.string(),
    email: yup.string(),
    fax: yup.string().max(45),
    homePhone: yup.string().max(45),
    street: yup.string(),
    streetNumber: yup.string().max(45),
    state: yup.string().required().min(2).max(2),
    code: yup.string().max(4),
    pendingEmailAlert: yup.string(),
    isImportCsvLocation: yup.boolean().required(),
    logo: yup.string().url(),
    documentId: yup.string().max(26),
    foundingDate: yup.date().required().min('1/1/1753'),
    isLegalEntity: yup.boolean().required(),
    name: yup.string().required().min(3),
    tradingName: yup.string(),
    workCategory: yup.string(),
    migrationTypeCodes: yup.array(yup.number()),
    subsidiaryId: yup.number().required(),
  })
  .required();

export default clientAddFormValidator;
