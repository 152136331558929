import React, { HtmlHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import Field, { FieldControlOmitChildrenProps } from './Field';

interface RadioButtonNode {
  [text: string]: any;
  value: any;
  label: string;
  key: string | number;
  disable?: boolean;
  defaultChecked?: string | number | boolean;
  type?: 'bool' | 'string';
}

type RadioGroupProps = FieldControlOmitChildrenProps &
  Omit<HtmlHTMLAttributes<HTMLElement>, 'defaultValue'> & {
    inline?: boolean;
    defaultValue?: string | number | boolean;
    children: RadioButtonNode[];
    type?: 'bool' | 'string';
  };

// eslint-disable-next-line arrow-body-style
const checked = (value, defaultValue) => {
  if (defaultValue === undefined) return false;
  return String(value) === String(defaultValue);
};

const RadioGroup = ({
  label,
  name,
  inline,
  defaultValue,
  children,
  control,
  isInvalid,
  errorMessage,
  helperText,
  type,
  ...props
}: RadioGroupProps) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Field label={label} isInvalid={isInvalid} errorMessage={errorMessage} helperText={helperText}>
      <>
        {children.map((c) => {
          if (c === undefined) return <React.Fragment key={Math.random()} />;
          return (
            <Form.Check
              inline={inline}
              name={field.name}
              type="radio"
              key={c.key}
              value={c.value}
              label={c.label}
              checked={checked(c.value, field.value ?? defaultValue ?? c.defaultChecked)}
              onChange={(e) => {
                const valueText = e.target.value;
                if (type === 'bool') {
                  const valueBool = valueText === 'true';
                  field.onChange(valueBool);
                  return;
                }

                field.onChange(valueText);
              }}
              isInvalid={isInvalid}
              disabled={c.disable ?? false}
              {...props}
            />
          );
        })}
      </>
    </Field>
  );
};

export default RadioGroup;
