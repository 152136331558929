import { Form, FormGroupProps } from 'react-bootstrap';
import InputShimmerPlaceholder, { InputShimmerPlaceholderProps } from './inputShimmerPlaceholder';
import LabelShimmerPlaceholder, { LabelShimmerPlaceholderProps } from './labelShimmerPlaceholder';
import RadioShimmerPlaceholder from './radioShimmerPlaceholder';

interface FormGroupShimmerPlaceholderProps extends FormGroupProps {
  label?: LabelShimmerPlaceholderProps;
  input?: InputShimmerPlaceholderProps;
  className?: string;
  type?: 'radio' | 'input';
}

const FormGroupShimmerPlaceholder = ({
  label,
  input,
  type,
  className,
  ...rest
}: FormGroupShimmerPlaceholderProps) => {
  if (type === 'radio') {
    return (
      <Form.Group className={`mb-3 ${className}`} {...rest}>
        <LabelShimmerPlaceholder {...label} />
        <RadioShimmerPlaceholder {...input} />
        <RadioShimmerPlaceholder {...input} />
      </Form.Group>
    );
  }
  return (
    <Form.Group className={`mb-3 ${className}`} {...rest}>
      <LabelShimmerPlaceholder {...label} />
      <InputShimmerPlaceholder {...input} />
    </Form.Group>
  );
};

export default FormGroupShimmerPlaceholder;
