import { Column } from 'react-data-grid';
import { BoxHistory } from '../../box.models';

const boxMovementHistoryColumns = () =>
  [
    {
      key: 'movementDate',
      name: 'Data',
      width: 200,
      resizable: true,
    },
    {
      key: 'personName',
      name: 'Usuário',
      width: 200,
      resizable: true,
    },
    {
      key: 'movementTypeDescription',
      name: 'Movimento',
      width: 200,
      resizable: true,
    },
    {
      key: 'movementDescription',
      name: 'Descrição',
      width: 400,
      resizable: true,
    },
  ] as Column<BoxHistory>[];

export default boxMovementHistoryColumns;
