import { Row, Stack } from 'react-bootstrap';

export interface StepProps {
  title?: string;
  middle?: JSX.Element;
  bottom?: JSX.Element;
}

export type StepPropsOmitChildren = Omit<StepProps, 'children'>;

const Step = ({ title, bottom, middle }: StepProps) => (
  <>
    <Row>
      <Stack gap={2} className="min-vh-50 mt-5">
        <h2>{title}</h2>
        {middle}
      </Stack>
    </Row>
    <Row>{bottom}</Row>
  </>
);

export default Step;
