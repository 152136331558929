import { Redirect } from 'react-router';
import TwoFactorAuthenticationForm from '../../../@shared/TwoFactorAuthenticationForm';
import Layout from '../../layout';

const LoginTwoFactorPage = () => (
  <Layout showLoggedComponents={false}>
    <TwoFactorAuthenticationForm
      onSuccess={<Redirect to="/home" />}
      onAdmin={<Redirect to="/login/2fa/auth-select" />}
    />
  </Layout>
);

export default LoginTwoFactorPage;
