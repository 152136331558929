import React from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';
import Field, { FieldProps } from './Field';

export type TextFieldType = 'email' | 'password' | 'tel' | 'text' | 'search' | 'url' | 'hidden';

type Id = { id: string } | { name: string };

export type GenericField = FormControlProps &
  FieldProps & {
    control: Control | Control<any, any>;
    name?: string;
    id: string;
  } & Id;

export type TextFieldProps =
  | GenericField & {
      type?: TextFieldType;
      rows?: number;
    };

const TextField = ({
  id,
  isInvalid,
  label,
  errorMessage,
  helperText,
  type = 'text',
  name,
  control,
  defaultValue,
  containerClassName,
  ...props
}: TextFieldProps) => {
  const { field } = useController({ name: name ?? id, control, defaultValue: defaultValue ?? '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
  };

  return (
    <Field
      id={id}
      isInvalid={isInvalid ?? false}
      label={label}
      containerClassName={containerClassName}
      helperText={helperText}
      errorMessage={errorMessage}
    >
      <Form.Control
        {...props}
        value={field.value}
        type={type}
        onChange={handleChange}
        isInvalid={isInvalid}
      />
    </Field>
  );
};

export default TextField;
