import React from 'react';
import { Accordion } from 'react-bootstrap';
import AddAccordionBody from './addAccordion.body';
import AddAccordionContext from './addAccordion.context';
import AddAccordionDialog from './addAccordion.dialog';
import AddAccordionHeader from './addAccordion.header';
import useAddAccordion from './useAddAccordion';

interface AddAccordionProps<T = any> {
  className?: string;
  value?: T[];
  onChange?: (value: T[]) => void;
  children: React.ReactNode[] | React.ReactNode;
}

const AddAccordion = ({ className, children, value: valueProps, onChange }: AddAccordionProps) => {
  const context = useAddAccordion({
    value: valueProps,
    onChange,
  });
  return (
    <AddAccordionContext.Provider value={context}>
      <Accordion className={className}>{React.Children.map(children, (child) => child)}</Accordion>
    </AddAccordionContext.Provider>
  );
};
AddAccordion.Header = AddAccordionHeader;
AddAccordion.Body = AddAccordionBody;
AddAccordion.Dialog = AddAccordionDialog;

export default AddAccordion;
