/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from 'react-hook-form';
import useRequest from '../../../../@shared/Requests/useRequest';
import useVirtualPagination from '../../../../@shared/hook.virtualPagination';
import { Exception } from '../../../../@shared/models';
import boxesEndpoints from '../../box.endpoints';
import { BoxWizardFormValue } from './boxWizardForm.models';

const useBoxWizardForm = () => {
  const request = useRequest();
  const { handleSubmit } = useForm();
  const { handleNextClick, handlePrevClick, visibleData, buildNumberOfData, disabled } =
    useVirtualPagination<any>(request.successData);

  const onSubmit = (value: BoxWizardFormValue) => {
    request.fetch(
      boxesEndpoints.addParameterizedCsv({
        cloudFilePath: value[0],
        boxClassificationId: Number(value[1].classification.value),
        boxTypeId: Number(value[1].boxType.value),
        categoryId: Number(value[1].category.value),
        hasHeader: value[1].hasHeader,
        columns: Object.entries(value[1].descriptions).map(([key, v]) => v),
        tags: Object.entries(value[2]?.tags ?? {})
          .filter(([key, v]) => Boolean(v) === true)
          .map(([key]) => Number(key)),
        boxClassificationName: '',
      })
    );
  };

  return {
    disabled,
    handleNextClick,
    handlePrevClick,
    visibleData,
    handleSubmit: (value: Record<number, any>) =>
      handleSubmit(() => onSubmit(value as BoxWizardFormValue))(),
    loading: request.loading,
    error: request.error,
    errorMessage: (request?.backEndErrors as Exception)?.message ?? '',
    success: request.success,
    successData: request.successData,
    buildNumberOfData,
  };
};

export default useBoxWizardForm;
