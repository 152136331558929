import { CSSProperties } from 'styled-components';
import VerifyLoading from '../Verifiers/verifyLoading';

interface LoadingPageProps {
  style?: CSSProperties;
  className?: string;
}

const LoadingPage = ({ style, className }: LoadingPageProps) => (
  <div className={`w-100 h-100 p-4 overlay ${className}`} style={style}>
    <VerifyLoading loadings={[true]} />
  </div>
);

export default LoadingPage;
