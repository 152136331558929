import React from 'react';
import { FormProps as BootstrapFormProps, Form } from 'react-bootstrap';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import ErrorFormAlert, { FormError } from '../../Alerts/errorFormAlert';
import { JsxElements } from '../../models';
import isEmpty from '../../utils/util';

interface F extends BootstrapFormProps {}
export interface FormProps extends F {
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  errors?: Record<string, FormError>;
  noValidate?: true;
  children?: JsxElements;
  className?: string;
  submitRef?: (instance: HTMLFormElement | null) => void;
}

export type FormControlProps<T extends FieldValues = any, K = any> = FormProps & {
  control: Control<T, K>;
};
export type FormRegisterProps<T extends FieldValues = any> = FormProps & {
  register: UseFormRegister<T>;
};
export type FormPropsOmitChildren = Omit<FormProps, 'children'>;
export type FormPropsOmitForDialog = Omit<FormProps, 'children' | 'title'>;
export interface FormValidatorProps {
  errors;
  validated?: any;
}

const RequestForm = ({ errors = {}, onSubmit, submitRef, ...props }: FormProps) => (
  <>
    {!isEmpty(errors) && <ErrorFormAlert errors={errors} />}
    <Form noValidate onSubmit={onSubmit} ref={submitRef} {...props} />
  </>
);

export default RequestForm;
