import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';

import Layout from '../layout';
import { routes } from './category.routes.config';

const CategoryRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <Layout>
        <h1>Pagina não foi criada ainda</h1>
      </Layout>
    </PrivateRoute>
    <PrivateRoute path={routes.add} exact>
      <Layout>
        <h1>Pagina não foi criada ainda</h1>
      </Layout>
    </PrivateRoute>
    <PrivateRoute path={routes.edit} exact>
      <Layout>
        <h1>Pagina não foi criada ainda</h1>
      </Layout>
    </PrivateRoute>
  </Switch>
);

export default CategoryRoutes;
