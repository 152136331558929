import { Badge, Form, FormControl, Stack } from 'react-bootstrap';
import { Column } from 'react-data-grid';
import { Link } from 'react-router-dom';

import ColumnFilter from '../../../../@shared/Fields/ColumnFilter/columnFilter';
import { Option } from '../../../../@shared/models';
import { formatDate } from '../../../../@shared/utils/util';
import ActionButtons from './actionButtons';
import {
  ClassificationFilter,
  ClassificationFilterKeys,
  ClassificationRow,
} from './classificationDataGrid.types';

const classificationColumns = (
  value: ClassificationFilter,
  handleFilter: (key: ClassificationFilterKeys, value: string) => void,
  options: Option[]
) =>
  [
    {
      key: 'name',
      name: 'Nome',
      headerRenderer: (h) => (
        <ColumnFilter
          title="Nome"
          value={value.name}
          header={h}
          onPressEnter={(v) => {
            handleFilter('name', v);
          }}
        >
          <FormControl />
        </ColumnFilter>
      ),
    },
    {
      key: 'inclusionDate',
      name: 'Data de inclusão',
      formatter: ({ row }) => formatDate(row.inclusionDate) || '-',
      headerRenderer: (h) => (
        <ColumnFilter
          title="Data de inclusão"
          value={value.inclusionDate}
          header={h}
          onPressEnter={(v) => {
            handleFilter('inclusionDate', v);
          }}
        >
          <FormControl />
        </ColumnFilter>
      ),
    },
    {
      key: 'categoria',
      name: 'categories',
      headerCellClass: 'overflow-visible',
      cellClass: 'line-height-initial d-flex w-100',
      width: 400,
      formatter: ({ row }) => (
        <Stack
          className="w-100 h-100 d-flex h-100 flex-wrap align-items-center justify-content-center"
          direction="horizontal"
          gap={2}
        >
          {row.categories.map((e) => (
            <div key={`${row.name}.${e.categoryId}`}>
              <Badge key={e.categoryId} bg="secondary">
                <Link to={`/categories/${e.categoryId}/edit`}>{e.name}</Link>
              </Badge>
            </div>
          ))}
        </Stack>
      ),
      headerRenderer: (h) => (
        <ColumnFilter
          title="Categoria"
          value={value.categories}
          header={h}
          onPressEnter={(v) => {
            handleFilter('categories', v);
          }}
        >
          <Form.Select>
            <option value={0}>-- Todas --</option>
            {options.map((e) => (
              <option key={Number(e.value)} value={Number(e.value)}>
                {e.label}
              </option>
            ))}
          </Form.Select>
        </ColumnFilter>
      ),
    },
    {
      key: 'actions',
      name: '',
      width: 178,
      formatter: ({ row }) => (
        <ActionButtons classificationId={row.classificationId} categories={row.categories} />
      ),
      cellClass: '',
    },
  ] as Column<ClassificationRow, any>[];

export default classificationColumns;
