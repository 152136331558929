import { PayloadAction } from '@reduxjs/toolkit';
import { Secret, TwoStepAuthenticator } from '../../@shared/models';
import menuService, { UserInfo } from '../../@shared/storage/menuService';
import secretService from '../../@shared/storage/secretService';

import { Auth } from '../models';

export interface Path {
  path: string;
}

interface SecretWithPath extends Path {
  secret: Secret;
}

export default {
  loginSuccess: (state: Auth, action: PayloadAction<SecretWithPath>) => {
    if (action?.payload.secret) secretService.insert(action?.payload.secret);
    state.wasLogged = true;
    state.refreshFailed = false;
    state.refreshSuccess = false;
  },
  loginSuccessWithoutSecret: (state: Auth) => {
    state.wasLogged = true;
    state.haveOldToken = true;
    state.refreshFailed = false;
    state.refreshSuccess = false;
  },
  logout: (state: Auth) => {
    secretService.remove();
    menuService.remove();
    state.wasLogged = false;
    state.refreshFailed = false;
    state.refreshSuccess = false;
    state.userInfo = null;
    state.twoStepAuthenticator = null;
    state.refreshWasUsed = false;
    state.wasResetBoth = false;
    state.wasReset2Fa = false;
    state.wasResetPassword = false;
  },
  updateToken(state: Auth, action: PayloadAction<SecretWithPath>) {
    secretService.insert(action?.payload.secret);
  },
  setUserInfo(state: Auth, action: PayloadAction<UserInfo>) {
    state.userInfo = action.payload;
  },
  generateNewTokensSuccess: (state: Auth, action: PayloadAction<SecretWithPath>) => {
    secretService.insert(action?.payload.secret);
    state.wasLogged = true;
    state.refreshFailed = false;
    state.refreshSuccess = true;
  },
  generateNewTokensSuccessNotLogged: (state: Auth, action: PayloadAction<SecretWithPath>) => {
    secretService.insert(action?.payload.secret);
  },
  generateNewTokensFail: (state: Auth) => {
    secretService.remove();
    menuService.remove();
    state.wasLogged = false;
    state.refreshWasUsed = true;
    state.refreshFailed = true;
    state.refreshSuccess = false;
  },
  verifyAlreadyDoneTwoStep: (state: Auth, action: PayloadAction<TwoStepAuthenticator>) => {
    if (action.payload.token) {
      secretService.insert(action.payload.token);
    }
    state.wasLogged = action?.payload.hasByPass;
    state.twoStepAuthenticator = action?.payload;
  },
  reset2fa: (state: Auth) => {
    secretService.remove();
    state.wasLogged = false;
    state.twoStepAuthenticator = null;
    state.wasReset2Fa = true;
    state.refreshFailed = false;
    state.refreshSuccess = false;
  },
  resetPassword: (state: Auth) => {
    secretService.remove();
    state.wasLogged = false;
    state.twoStepAuthenticator = null;
    state.wasResetPassword = true;
    state.refreshFailed = false;
    state.refreshSuccess = false;
  },
};
