interface PreventProps {
  isPrevent: boolean;
  onPrevent: JSX.Element;
  children: JSX.Element;
}

const Prevent = ({ isPrevent, onPrevent, children }: PreventProps) => {
  switch (true) {
    case isPrevent:
      return onPrevent;
    default:
      return children;
  }
};

export default Prevent;
