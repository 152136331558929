import Layout from '../../layout';
import Reset2FaForm from '../features/Reset2FaForm';

const Reset2FaEditPage = () => (
  <Layout showLoggedComponents={false}>
    <Reset2FaForm />
  </Layout>
);

export default Reset2FaEditPage;
