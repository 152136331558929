import BoxClassificationAddFormStep1 from './Step1/boxClassificationAddForm.step1';
import BoxClassificationAddFormStep2 from './Step2/boxClassificationAddForm.step2';
import BoxClassificationAddFormStep3 from './Step3/boxClassificationAddForm.step3';

const BoxClassificationAddFormSteps = {
  Step1: BoxClassificationAddFormStep1,
  Step2: BoxClassificationAddFormStep2,
  Step3: BoxClassificationAddFormStep3,
};

export default BoxClassificationAddFormSteps;
