/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { EffectCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseRequest, Validator } from '../../../../@shared/models';
import { util } from '../../../../@shared/utils/util';
import { RootState } from '../../../../redux/application';
import { boxDataGridActions } from '../../../../redux/slices/boxDataGridSlice';

import { UseDownloadButtonProps } from '../../../../@shared/DownloadButton/useDownloadButton';
import { PageSize } from '../../../../constants/constants';
import { BoxRow, FilterStatus, MigrationCategory } from '../../box.models';
import validateBoxDataGrid from './validator';

const useBoxDataGridSlice = () => {
  const useSelectorBoxDataGrid = () => useSelector((states: RootState) => states.boxDataGrid);
  const dispatch = useDispatch();

  const dispatches = {
    turnNextPage() {
      dispatch(boxDataGridActions.turnNextPage());
    },
    turnPreviousPage() {
      dispatch(boxDataGridActions.turnPreviousPage());
    },
    setSelectedRows(selectedRows: Set<React.Key>) {
      dispatch(
        boxDataGridActions.setSelectedRows({
          selectedRows,
        })
      );
    },
    openSolicitConsultationDialog() {
      dispatch(boxDataGridActions.openSolicitConsultationDialog());
    },
    closeSolicitConsultationDialog() {
      dispatch(boxDataGridActions.closeSolicitConsultationDialog());
    },
    setAnySubmit(value: boolean) {
      dispatch(boxDataGridActions.setAnySubmit({ value }));
    },
    IsSelectedValueInStatusFilter() {
      dispatch(boxDataGridActions.setIsSelectedValueInStatusFilter({ value: true }));
    },
    noIsSelectedValueInStatusFilter() {
      dispatch(boxDataGridActions.setIsSelectedValueInStatusFilter({ value: false }));
    },
    openSolicitCancellationDialog() {
      dispatch(boxDataGridActions.openSolicitCancellationDialog());
    },
    closeSolicitCancellationDialog() {
      dispatch(boxDataGridActions.closeSolicitCancellationDialog());
    },
    openSolicitDigitalizationDialog() {
      dispatch(boxDataGridActions.openSolicitDigitalizationDialog());
    },
    closeSolicitDigitalizationDialog() {
      dispatch(boxDataGridActions.closeSolicitDigitalizationDialog());
    },
    openSolicitExclusionDialog() {
      dispatch(boxDataGridActions.openSolicitExclusionDialog());
    },
    closeSolicitExclusionDialog() {
      dispatch(boxDataGridActions.closeSolicitExclusionDialog());
    },
    openSolicitMigrationDialog() {
      dispatch(boxDataGridActions.openSolicitMigrationDialog());
    },
    closeSolicitMigrationDialog() {
      dispatch(boxDataGridActions.closeSolicitMigrationDialog());
    },
  };

  const hooks = {
    useInitialize(
      requestRows: UseRequest,
      requestMigrationOptions: UseRequest,
      requestDownloadCsv: UseDownloadButtonProps,
      faceSheetLabelRequest: UseRequest
    ) {
      const useSetIsFetchingRows = (request: UseRequest, isFetching: boolean) => {
        useEffect(() => {
          if (request.success && isFetching === true) {
            dispatch(boxDataGridActions.fetchingRowsCompleted());
          } else if (!request.success && isFetching === false && isFetching !== request.loading) {
            dispatch(boxDataGridActions.fetchingRowsIncomplete());
          }
        }, [JSON.stringify(request)]);
      };
      const useSetIsFetchingFaceSheetLabel = (request: UseRequest) => {
        useEffect(() => {
          if (request.success) {
            dispatch(boxDataGridActions.faceSheetLabelCompleted());
          } else if (!request.success && !request.loading) {
            dispatch(boxDataGridActions.faceSheetLabelIncomplete());
          }
        }, [JSON.stringify(request)]);
      };
      const useSetIsFetchingCsv = (request: UseDownloadButtonProps) => {
        useEffect(() => {
          if (request.success) {
            dispatch(boxDataGridActions.setIsDownloadingCsv({ isDownloading: true }));
          } else if (!request.success && !request.loading) {
            dispatch(boxDataGridActions.setIsDownloadingCsv({ isDownloading: false }));
            dispatch(boxDataGridActions.reset());
          }
        }, [JSON.stringify(request)]);
      };

      const {
        page,
        wasSearchButtonClicked,
        totalPageChanges,
        anySubmit,
        isSelectedValueInStatusFilter,
        totalStatusFilterChange,
        statusFilter,
        isFetchingRows,
        isDownloadingCsv,
        isFetchingFaceSheetLabel,
      } = useSelectorBoxDataGrid();

      requestRows.useFetchBy(page && totalPageChanges > 0, totalPageChanges);
      requestRows.useFetchBy(wasSearchButtonClicked);
      requestRows.useFetchBy(anySubmit);
      requestMigrationOptions.useFetchBy(true, 'init');
      requestRows.useFetchBy(
        !isSelectedValueInStatusFilter && totalStatusFilterChange > 0,
        statusFilter
      );
      faceSheetLabelRequest.useFetchBy(isFetchingFaceSheetLabel);

      useEffect(() => {
        if (isDownloadingCsv) requestDownloadCsv.handleClick();
      }, [isDownloadingCsv]);

      useSetIsFetchingRows(requestRows, isFetchingRows);
      useSetIsFetchingCsv(requestDownloadCsv);
      useSetIsFetchingFaceSheetLabel(faceSheetLabelRequest);

      useMemo(() => {
        dispatch(boxDataGridActions.initialize());
      }, []);
    },
    useDestroy(fn: EffectCallback) {
      // eslint-disable-next-line arrow-body-style
      useEffect(() => {
        return () => {
          fn();
        };
      }, []);
    },
    useCloseAllDialogs(condition: boolean) {
      useEffect(() => {
        if (condition) boxDataGridActions.closeAllDialogs();
      }, [condition]);
    },
    useTurnInvalid(condition: boolean, validator: Validator, deps?: React.DependencyList) {
      useEffect(() => {
        if (condition) dispatch(boxDataGridActions.setIsInvalid(validator));
      }, deps);
    },
    useIsInvalid(currentBoxMovement: number | null, deps?: React.DependencyList) {
      const s = useSelectorBoxDataGrid();

      useEffect(() => {
        const f = async () => {
          try {
            const c = await validateBoxDataGrid.validate({
              selectedRows: s.selectedRows,
              currentBoxMovement,
              showSolicitDigitalizationFormDialog: s.showSolicitDigitalizationFormDialog,
            });

            dispatch(boxDataGridActions.setIsInvalid({ errorMessage: '', isInvalid: false }));
          } catch (e: any) {
            dispatch(boxDataGridActions.setIsInvalid({ errorMessage: e.message, isInvalid: true }));
          }
        };

        f();
      }, deps);
    },
    useDeselectAllRows(condition: any, trigger?: any) {
      useEffect(() => {
        if (condition) dispatch(boxDataGridActions.deselectAllRows());
      }, [trigger ?? condition]);
    },
    useBackLastSelectedRows(condition: boolean, deps?: React.DependencyList) {
      useEffect(() => {
        if (condition) {
          dispatch(boxDataGridActions.backLastSelectedRows());
        }
      }, deps);
    },
    useSetRowsByNewRows(rows: BoxRow[] | null, filter?: any) {
      const s = useSelectorBoxDataGrid();
      useEffect(() => {
        if (rows && !util.isEqualDeep(rows, s.allRows) && (filter || filter === undefined)) {
          dispatch(boxDataGridActions.setRows({ allRows: rows, currentRows: rows }));
        }
      }, [JSON.stringify(rows), filter]);
    },
    useSetMergeRowsBySuccess(request: UseRequest) {
      const { allRows, rowsId } = useSelectorBoxDataGrid();
      useEffect(() => {
        if (request?.success && request.successData) {
          if (rowsId) {
            dispatch(
              boxDataGridActions.setRows({
                currentRows: request.successData,
                allRows: util.merge(allRows, request.successData, rowsId),
              })
            );
          }
        }
      }, [
        request?.success,
        request?.error,
        request?.loading,
        JSON.stringify(request?.successData),
      ]);
    },
    useSetMigrationOptions(migrationOptions: MigrationCategory[] | null) {
      useEffect(() => {
        if (migrationOptions) dispatch(boxDataGridActions.setMigrationOptions(migrationOptions));
      }, [migrationOptions]);
    },
    closeAllDialogs() {
      dispatch(boxDataGridActions.closeAllDialogs());
    },
    clickedSearch() {
      dispatch(boxDataGridActions.setWasSearchButtonClicked({ wasSearchButtonClicked: true }));
    },
    invalid(msg?: string) {
      dispatch(boxDataGridActions.setIsInvalid({ errorMessage: msg ?? '', isInvalid: true }));
    },
    valid() {
      dispatch(boxDataGridActions.setIsInvalid({ errorMessage: '', isInvalid: false }));
    },
    notClickedSearch() {
      dispatch(boxDataGridActions.setWasSearchButtonClicked({ wasSearchButtonClicked: false }));
    },
    downloadingCsv() {
      dispatch(boxDataGridActions.setIsDownloadingCsv({ isDownloading: true }));
    },
    notDownloadingCsv() {
      dispatch(boxDataGridActions.setIsDownloadingCsv({ isDownloading: true }));
    },
    downloadingFaceSheetLabelPdf() {
      dispatch(boxDataGridActions.faceSheetLabelCompleted());
    },
    setFaceSheetLabelSizePage(pageSize: PageSize) {
      dispatch(boxDataGridActions.setFaceSheetLabelSizePage(pageSize));
    },
    notDownloadingFaceSheetLabelPdf() {
      dispatch(boxDataGridActions.faceSheetLabelIncomplete());
    },
    addFilter(
      isBtnSearchWasClicked: boolean,
      descriptionFilter: string,
      locationFilter: string,
      statusFilter: FilterStatus,
      initialDate: string,
      finalDate: string
    ) {
      dispatch(
        boxDataGridActions.setFilters({
          isBtnSearchWasClicked,
          descriptionFilter,
          statusFilter,
          totalStatusFilterChange: 0,
          initialDate,
          finalDate,
          locationFilter,
        })
      );
    },
    useFilter(
      isBtnSearchWasClicked: boolean,
      descriptionFilter: string,
      locationFilter: string,
      statusFilter: FilterStatus,
      totalStatusFilterChange: number,
      initialDate: string,
      finalDate: string
    ) {
      useEffect(() => {
        if (isBtnSearchWasClicked || descriptionFilter || totalStatusFilterChange > 0) {
          dispatch(
            boxDataGridActions.setFilters({
              isBtnSearchWasClicked,
              descriptionFilter,
              statusFilter,
              totalStatusFilterChange,
              initialDate,
              finalDate,
              locationFilter,
            })
          );
        }
      }, [
        locationFilter,
        descriptionFilter,
        isBtnSearchWasClicked,
        statusFilter,
        totalStatusFilterChange,
      ]);
    },

    useResetByAnySubmit() {
      const { anySubmit } = useSelectorBoxDataGrid();
      useEffect(() => {
        if (anySubmit === true) {
          dispatch(boxDataGridActions.reset());
        }
      }, [anySubmit]);
    },
  };
  return { useSelector: useSelectorBoxDataGrid, ...hooks, ...dispatches };
};

export default useBoxDataGridSlice;
