import { useContext } from 'react';
import ToolBar from '../../ToolBars/toolbar';
import AddAccordionContext from './addAccordion.context';
import AddButton from '../../Buttons/AddButton';

interface AddAccordionHeaderProps {
  title: string;
  onClick?: () => void;
  className?: string;
}

const AddAccordionHeader = ({ onClick, title, className }: AddAccordionHeaderProps) => {
  const context = useContext(AddAccordionContext);
  return (
    <ToolBar title={title} className={className}>
      <AddButton
        onClick={() => {
          if (onClick) onClick();
          context?.open();
        }}
      />
    </ToolBar>
  );
};
export default AddAccordionHeader;
