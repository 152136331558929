import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormError } from '../../../../../../@shared/Alerts/errorFormAlert';
import boxClassificationFormValidator from '../../../../../../@shared/BoxClassifications/Forms/boxClassificationForm.validator';
import { BoxClassificationAddForm } from '../boxClassificationAddFormSteps.types';

interface UseBoxClassificationAddFormStep1Props {
  defaultValue?: BoxClassificationAddForm | BoxClassificationAddForm.Step1;
  onSubmit?: (value: BoxClassificationAddForm.Step1) => void;
}

const useBoxClassificationAddFormStep1 = (props?: UseBoxClassificationAddFormStep1Props) => {
  const { handleSubmit, control, formState } = useForm<BoxClassificationAddForm.Step1>({
    resolver: yupResolver(boxClassificationFormValidator),
    defaultValues: (props?.defaultValue as any)?.step1 ?? props?.defaultValue ?? {},
  });

  const onSubmit = (e: BoxClassificationAddForm.Step1) => {
    if (props?.onSubmit) props.onSubmit(e);
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    success: formState.isSubmitSuccessful,
    errors: formState.errors as Record<string, FormError> | undefined,
  };
};

export default useBoxClassificationAddFormStep1;
