import React, { ReactNode } from 'react';

import { Badge, Row } from 'react-bootstrap';

interface FieldsetOldProps {
  legend?: string;
  children: ReactNode;
  className?: string;
}

function FieldsetOld({ children, className, legend }: FieldsetOldProps) {
  return (
    <div className={`${className}`}>
      <div className="h-100">
        <Row className="">
          <Badge bg="secondary" text="dark" className="rounded-0 rounded-top">
            {legend}
          </Badge>
        </Row>

        <Row className="border border-1 p-3 h-100 overflow-auto">
          {React.Children.map(children, (e) => e)}
        </Row>
      </div>
    </div>
  );
}

export default FieldsetOld;
