import { useContext } from 'react';
import { ButtonProps } from 'react-bootstrap';
import AddButton from '../../../../@shared/Buttons/AddButton';
import DialogProvider, {
  NewDialogContext,
} from '../../../../@shared/Dialogs/Context/dialogContext';
import { TreeViewNode } from '../../../../@shared/Fields/models';
import { Category } from '../CategoryForm/types';
import { CategoryChooserSuccessData } from '../CategorySelect';
import CategoryChooserDialogPresentation from './categoryChooserDialogPresentation';
import useCategoryChooserDialog from './useCategoryChooserDialog';

export interface CategoryChooserDialogProps {
  options: { parents: TreeViewNode[]; categories: CategoryChooserSuccessData[] | Category[] };
  show: boolean;
  onHide: () => void;
  defaultValue?: CategoryChooserSuccessData[];
  onSubmit: (e: CategoryChooserSuccessData[]) => void;
}

const CategoryChooserDialog = ({
  show,
  onHide,
  options = { categories: [], parents: [] },
  defaultValue,
  onSubmit,
}: CategoryChooserDialogProps) => {
  const { handleSubmit, errors, control, onSelect } = useCategoryChooserDialog({
    onHide,
    onSubmit,
    defaultValue,
    show,
    options,
  });

  return (
    <CategoryChooserDialogPresentation
      show={show}
      options={options}
      control={control}
      errors={errors as any}
      defaultValue={defaultValue}
      onSubmit={handleSubmit}
      onSelect={onSelect}
      onHide={onHide}
    />
  );
};

CategoryChooserDialog.Provider = DialogProvider;
CategoryChooserDialog.AddButton = ({ ...props }: ButtonProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(NewDialogContext);

  return (
    <AddButton
      {...props}
      onClick={() => {
        if (context) {
          context.open();
        }
      }}
    >
      Adicionar Categoria
    </AddButton>
  );
};

export default CategoryChooserDialog;
