import { createSlice } from '@reduxjs/toolkit';
import React from 'react';
import { PageSize } from '../../constants/constants';
import { FilterStatus } from '../../modules/box/box.models';
import { BoxDataGrid } from '../models';
import boxDataGridReducer from '../reducers/boxDataGridReducer';

const nameSlice = 'box-datagrid';
export type Table = {
  resetPagination: Function;
  resetSelectRows: Function;
  currentPage: number;
  deselectLastRow: Function;
  updateRows: Function;
};

const initialState: BoxDataGrid = {
  anySubmit: false,
  showSolicitConsultationFormDialog: false,
  showSolicitCancellationFormDialog: false,
  showSolicitDigitalizationFormDialog: false,
  showSolicitExclusionFormDialog: false,
  showSolicitMigrationFormDialog: false,
  statusFilter: FilterStatus.All,
  descriptionFilter: '',
  isSelectedValueInStatusFilter: false,
  wasSearchButtonClicked: false,
  migrationOptions: [],
  isFetchingRows: false,
  totalStatusFilterChange: 0,
  isFirstPage: true,
  rowsId: 'boxId',
  currentRows: [],
  allRows: [],
  selectedIds: new Set<React.Key>(),
  selectedRows: [],
  isInvalid: false,
  errorMessage: '',
  alreadyInit: false,
  page: 1,
  totalPageChanges: 0,
  lastSelectedRows: [],
  initialDate: '',
  finalDate: '',
  isDownloadingCsv: false,
  locationFilter: '',
  isFetchingFaceSheetLabel: false,
  faceSheetLabelPageSize: PageSize.A4,
};
export interface ISetTable {
  currentPage: number;
  totalPageChange: number;
}
export const slice = createSlice({
  name: nameSlice,
  initialState,
  reducers: boxDataGridReducer,
});

export const boxDataGridActions = slice.actions;

export default slice.reducer;
