import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import yup from '../../../../config/yup/customYup';
import { AddBoxClassificationItem } from '../../../../modules/classification/classification.endpoint';
import { FormError } from '../../../Alerts/errorFormAlert';
import FormDialog from '../../../Dialogs/formDialog';
import useDialog from '../../../Dialogs/useDialog';
import BoxClassificationItemFormFields from '../boxClassificationItemForm.fields';
import { formats } from '../../../../constants/constants';

interface BoxClassificationItemAddDialogFormControllerProps {
  show: boolean;
  onHide?: Function;
  onSuccess?: (e: { successData: AddBoxClassificationItem }) => void;
}

const BoxClassificationItemAddDialogFormController = ({
  show: showProps,
  onHide,
  onSuccess,
}: BoxClassificationItemAddDialogFormControllerProps) => {
  const { control, handleSubmit, formState, reset } = useForm<AddBoxClassificationItem>({
    resolver: yupResolver(
      yup
        .object({
          format: yup.string().required().trim(),
          name: yup.string().required().min(3),
          size: yup.number().required(),
          observation: yup.string(),
          orderNumber: yup.number(),
          isPrinted: yup.bool().required(),
          barcodeRef: yup.bool().required(),
        })
        .required()
    ),
  });
  const { show, close, useOpen, useHandleClose } = useDialog({ handleClose: onHide });
  const errors = (formState.errors as Record<string, FormError>) ?? {};

  useOpen(showProps);
  useHandleClose(() => {
    reset();
  });

  const onSubmit = (e: AddBoxClassificationItem) => {
    if (e && onSuccess) {
      onSuccess({ successData: e });
      close();
    }
  };

  return (
    <FormDialog
      show={show}
      title="Adicionar Item"
      errors={errors}
      autoClose={false}
      onHide={close}
      onSubmit={handleSubmit(onSubmit)}
    >
      <BoxClassificationItemFormFields
        formats={formats}
        type="add"
        control={control}
        errors={errors}
      />
    </FormDialog>
  );
};

export default BoxClassificationItemAddDialogFormController;
