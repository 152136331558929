import { AxiosRequestConfig } from 'axios';
import { ChangePassword } from '../../@shared/models';

const name = 'profile';
const profileEndpoints = {
  findAsync() {
    return { method: 'get', url: `${name}/find` } as AxiosRequestConfig;
  },

  changePasswordAsync(data: ChangePassword) {
    return {
      method: 'post',
      url: `${name}/change-password`,
      data,
    } as AxiosRequestConfig;
  },
};

export default profileEndpoints;
