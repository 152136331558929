import { createSlice } from '@reduxjs/toolkit';
import toastReducer from '../reducers/toastReducer';

const nameSlice = 'userSuccessToast';
interface ISlice {
  text: string;
  show: boolean;
}

const initialState: ISlice = {
  text: '',
  show: false,
};

export const slice = createSlice({
  name: nameSlice,
  initialState,
  reducers: toastReducer,
});

export const { newToast, resetToast } = slice.actions;

export default slice.reducer;
