import { useContext } from 'react';
import { Control } from 'react-hook-form';
import RadioGroup from '../../../../../../@shared/Fields/radioButtonGroup';
import { shouldPrefix } from '../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../categoryForm.context';
import { CategoryFormRequiredFieldsProps } from '../types';

const CategoryFormIsMigrationField = ({
  readonly,
  prefix,
  errors,
  control: controlProps,
}: CategoryFormRequiredFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProps ?? context?.config?.control ?? ({} as Control);

  return (
    <RadioGroup
      label="É migração"
      control={control}
      name={shouldPrefix(prefix, 'isMigration')}
      type="bool"
      errorMessage={errors?.isMigration?.message}
      isInvalid={errors?.isMigration !== undefined}
    >
      {[
        {
          key: 'isMigration.true',
          label: 'Sim',
          value: true,
          disable: readonly,
        },
        {
          key: 'isMigration.false',
          label: 'Não',
          value: false,
          disable: readonly,
        },
      ]}
    </RadioGroup>
  );
};

export default CategoryFormIsMigrationField;
