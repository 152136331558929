import useAuthSlice from '../../../@shared/Auth/useAuthSlice';
import ErrorPage from '../../../@shared/template/_errorPage';
import useSideBar from '../useSideBar';
import Menu from './menu';

export default () => {
  const authSlice = useAuthSlice();
  const { canShow, lines, request, errorMessage } = useSideBar();

  if (request.error) {
    return <ErrorPage onClickLink={() => authSlice.logout()} text={errorMessage} />;
  }

  if (canShow) {
    return (
      <Menu>
        <Menu.Lines>{lines}</Menu.Lines>
      </Menu>
    );
  }

  return <></>;
};
