import { ReactNode } from 'react';
import { Accordion, ColProps, PlaceholderProps } from 'react-bootstrap';
import AccordingItemShimmerPlaceholder from './accordingItemShimmerPlaceholder';

export type AccordingShimmerPlaceholderProps = ColProps &
  PlaceholderProps & {
    header?: ReactNode;
  };

const AccordingShimmerPlaceholder = ({ header }: AccordingShimmerPlaceholderProps) => (
  <Accordion>
    {header}
    <AccordingItemShimmerPlaceholder
      className="collapsed"
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0 }}
    />
    <AccordingItemShimmerPlaceholder />
    <AccordingItemShimmerPlaceholder />
    <AccordingItemShimmerPlaceholder />
  </Accordion>
);

export default AccordingShimmerPlaceholder;
