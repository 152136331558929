import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Control, useController } from 'react-hook-form';

interface HasHeaderCheckboxProps {
  control: Control;
  value?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HasHeaderCheckbox = ({ control, value, disabled }: HasHeaderCheckboxProps) => {
  const { field } = useController({ control, name: 'hasHeader' });

  useEffect(() => {
    if (value !== undefined) field.onChange(value);
  }, [JSON.stringify(value)]);

  return (
    <Form.Group>
      <Form.Check
        label="Pular primeira linha do cabeçalho no arquivo ?"
        checked={field.value ?? false}
        onChange={(e) => field.onChange(e.target.checked)}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default HasHeaderCheckbox;
