import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubsidiaryClient, UseRequest } from '../../../../@shared/models';
import { RootState } from '../../../../redux/application';
import { selectClientFormSliceActions } from '../../../../redux/slices/selectClientFormSlice';

const useSelectClientFormSlice = () => {
  const dispatch = useDispatch();

  const hooks = {
    useState() {
      return useSelector((states: RootState) => states.selectClientForm);
    },
    useSetData(request: UseRequest<SubsidiaryClient[]>) {
      useEffect(() => {
        if (request.success && !request.loading) {
          dispatch(selectClientFormSliceActions.setData(request.successData ?? []));
        }
      }, [request.loading, request.success, request.successData]);
      return true;
    },
    useSelectClient(value: any) {
      useEffect(() => {
        if (value) dispatch(selectClientFormSliceActions.setClientSelected(true));
      }, [value]);
    },
  };
  return { ...hooks };
};

export default useSelectClientFormSlice;
