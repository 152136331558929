import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/application';
import { authActions } from '../../redux/slices/authSlice';
import { RequestPropsOmitFetch, UseRequest } from '../models';
import { Secret } from '../storage/model';
import isEmpty from '../utils/util';

const useAuthSlice = () => {
  const dispatch = useDispatch();
  const [secretUpdateCount, setSecretUpdateCount] = useState(0);
  const [hasSecretBeenUpdated, setHasSecretBeenUpdated] = useState(false);

  useEffect(() => {
    setHasSecretBeenUpdated(secretUpdateCount > 0);
  }, [secretUpdateCount]);

  const hooks = {
    useState() {
      return useSelector((states: RootState) => states.auth);
    },
    useLoginWithoutUpdateSecret() {
      useEffect(() => {
        dispatch(authActions.loginSuccessWithoutSecret());
      }, []);
    },
    useLogin(path: string, secret: Secret | null) {
      useEffect(() => {
        if (secret !== undefined && secret !== null) {
          dispatch(authActions.loginSuccess({ secret, path }));
        }
      }, [JSON.stringify(secret)]);
    },
    useUpdateSecret(
      request: UseRequest | RequestPropsOmitFetch,
      resetRequest: boolean,
      path: string
    ) {
      const authenticator: Secret = request.successData;

      useEffect(() => {
        if (request.success && !request.loading && !isEmpty(authenticator)) {
          dispatch(authActions.updateToken({ secret: request.successData, path }));
          setSecretUpdateCount(secretUpdateCount + 1);
          if (resetRequest) request.reset();
        }
      }, [JSON.stringify(request)]);
      return secretUpdateCount;
    },
    logout(reload?: boolean) {
      dispatch(authActions.logout());
      if (reload)
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    },
    reset2fa() {
      dispatch(authActions.reset2fa());
    },
    resetPassword() {
      dispatch(authActions.resetPassword());
    },
    generateNewTokensSuccess(secret: Secret, path: string) {
      if (!isEmpty(secret)) dispatch(authActions.generateNewTokensSuccess({ secret, path }));
    },
    generateNewTokensSuccessNotLogged(secret: Secret, path: string) {
      dispatch(authActions.generateNewTokensSuccessNotLogged({ secret, path }));
    },
    generateNewTokensFail() {
      dispatch(authActions.generateNewTokensFail());
    },
    useVerifyAlreadyDoneTwoStep(
      request: UseRequest | RequestPropsOmitFetch,
      resetRequest: boolean
    ) {
      useEffect(() => {
        if (request.success && !isEmpty(request.successData)) {
          dispatch(authActions.verifyAlreadyDoneTwoStep(request.successData));
          if (resetRequest) request.reset();
          setSecretUpdateCount(secretUpdateCount + 1);
        }
      }, [request, resetRequest]);
    },
  };
  return {
    hasSecretBeenUpdated,
    setHasSecretBeenUpdated,
    ...hooks,
  };
};

export default useAuthSlice;
