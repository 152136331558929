import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { UserDataGridContextValues } from '../modules/user/features/UserDataGrid/context';
import UserColumns from '../modules/user/features/UserDataGrid/userColumns';
import useUserToast from '../modules/user/features/useUserToast';
import { Filter, UserRow } from '../modules/user/user';
import useRequest from './Requests/useRequest';

const useGenericUserDataGrid = (
  pathEdit: string | ((e: any) => string),
  type: 'admin' | 'user',
  endpoint: (filter: Filter) => AxiosRequestConfig<any>
) => {
  const toast = useUserToast();
  const [rows, setRows] = useState<UserRow[]>([]);

  const { columns, filters } = UserColumns(pathEdit, type, () =>
    toast.setNewToast('Atualizado com sucesso')
  );
  const requestList = useRequest(endpoint(filters));

  requestList.useFetchBy(true, JSON.stringify(filters));
  requestList.useFetchBy(true, 'init');
  requestList.useSuccess(() => {
    setRows(requestList.successData);
    requestList.reset();
  });

  return {
    setRequestConfig: requestList.setRequestConfig,
    context: { refresh: () => requestList.fetch() } as unknown as UserDataGridContextValues,
    rows,
    columns,
    toast,
    loading: requestList.loading,
    error: requestList.error,
    filters,
    errorMessage: requestList.backEndErrors?.message ?? '',
  };
};
export default useGenericUserDataGrid;
