import Panel from '../../../../@shared/Panel';
import { formatDate } from '../../../../@shared/utils/util';
import useBoxPanel from './useBoxPanel';

const BoxPanel = () => {
  const { data } = useBoxPanel();
  const inclusionDate = formatDate(data.inclusionDate);
  const exclusionDate = data?.exclusionDate ? formatDate(data?.exclusionDate) : null;
  const estimatedExclusionDate = formatDate(data.estimatedExclusionDate);

  return (
    <Panel>
      <Panel.Field label="Categoria">{data.categoryName}</Panel.Field>
      <Panel.Field label="Classificação">{data.boxClassificationName}</Panel.Field>

      <Panel.FieldCollection>
        {data?.boxDescriptions.map((e) => ({
          label: e.name,
          value: e.value,
          key: Math.random().toString(),
        }))}
      </Panel.FieldCollection>

      <Panel.Field label="Data estimada para exclusão">
        {estimatedExclusionDate?.toString()}
      </Panel.Field>
      <Panel.Field label="Data inclusão">{inclusionDate?.toString()}</Panel.Field>
      <Panel.Field label="Data exclusão">{exclusionDate?.toString() ?? 'não excluída'}</Panel.Field>
      <Panel.Field label="Localização">{data.locationDescription?.toString()}</Panel.Field>
    </Panel>
  );
};

export default BoxPanel;
