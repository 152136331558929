import DataGrid from '../../../../@shared/DataGrid';

interface BoxMovementHistoryDataGridPresentationProps {
  rows: any[];
  columns: any[];
}

const BoxMovementHistoryDataGridPresentation = ({
  rows,
  columns,
}: BoxMovementHistoryDataGridPresentationProps) => (
  <DataGrid className="">
    <DataGrid.Main rowId="id" rows={rows} columns={columns} />
  </DataGrid>
);

export default BoxMovementHistoryDataGridPresentation;
