import { useState } from 'react';

type FilterState<T extends any[]> = { filteredData: T; allData: T };

const useFiltrate = <T>() => {
  const [filterText, setFilter] = useState<string>('');
  const [data, setData] = useState<FilterState<T[]>>({
    filteredData: [] as any,
    allData: [] as any,
  });

  const defineData = (newRows: T[]) => {
    setData({ allData: newRows, filteredData: newRows });
  };

  const filterData = (text: string, p: (textFilter: string, data: T) => boolean) => {
    setFilter(text);
    setData({
      allData: data.allData,
      filteredData: data.allData.filter((d) => p(text, d)) as any,
    });
  };

  return {
    filterText,
    data,
    defineData,
    filterData,
  };
};

export default useFiltrate;
