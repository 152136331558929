import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

interface BoxClassificationTemplateProps {
  name: ReactNode;
  day: ReactNode;
  moth: ReactNode;
  year: ReactNode;
  inclusionDate: ReactNode;
  cancellationDate: ReactNode;
  button: ReactNode;
}

const BoxClassificationFormTemplate = ({
  cancellationDate,
  day,
  inclusionDate,
  moth,
  name,
  year,
  button,
}: BoxClassificationTemplateProps) => (
  <>
    <Row sm={12}>
      <Col>
        <Row>
          <Col sm="12">{name}</Col>
        </Row>
        <Row>
          <Col sm="12" md="4">
            {day}
          </Col>
          <Col sm="12" md="4">
            {moth}
          </Col>
          <Col sm="12" md="4">
            {year}
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4">
            {inclusionDate}
          </Col>
          <Col sm="12" md="4">
            {cancellationDate}
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col>{button}</Col>
    </Row>
  </>
);

export default BoxClassificationFormTemplate;
