import { AxiosRequestConfig } from 'axios';
import { Button, Dropdown } from 'react-bootstrap';
import LoadingModal from '../../modules/auth/features/ForgotDialogues/LoadingModal';
import ErrorDialog from '../Dialogs/ErrorDialog';
import useDownloadButton from './useDownloadButton';

const DownloadButton = ({
  children,
  requestConfig,
  contentType,
  fileName,
  className,
  title,
  as: asProps = 'button',
}: {
  title: string;
  className?: string;
  children: string;
  fileName: string;
  contentType: ContentType;
  requestConfig: AxiosRequestConfig;
  as?: 'dropdown-item' | 'button';
}) => {
  const {
    error,
    loading,
    handleClick: onClick,
    errorMessage,
  } = useDownloadButton(contentType, fileName, requestConfig);
  return (
    <>
      {error && <ErrorDialog show>{errorMessage}</ErrorDialog>}
      {loading && <LoadingModal />}
      {asProps === 'button' ? (
        <Button title={title} className={className} onClick={onClick}>
          <strong>{children}</strong>
        </Button>
      ) : (
        <Dropdown.Item title={title} className={className} onClick={onClick}>
          {children}
        </Dropdown.Item>
      )}
    </>
  );
};

export default DownloadButton;
