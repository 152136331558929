import CategoryFormCancellationDateField from './Optional/Dates/cancellationDate';
import CategoryFormCancellationDescriptionField from './Optional/Dates/cancellationDescription';
import CategoryFormInclusionDateField from './Optional/Dates/inclusionDate';
import CategoryFormGrantUsers, {
  CategoryFormGrantUsersProps,
} from './Optional/GrantUsers/grantUsers';
import CategoryFormParentsField, {
  CategoryFormParentsFieldProps,
} from './Optional/Parents/parents';
import CategoryFormRequiredFields from './Required';
import CategoryFormIsDocumentCategoryField from './Required/IsDocumentCategory';
import CategoryFormIsMigrationField from './Required/isMigration';
import CategoryFormNameField from './Required/name';
import CategoryFieldsTemplate from './template';
import { CategoryFormFieldsBaseProps, TemplateBaseProps } from './types';

export interface CategoryFormFieldsProps
  extends CategoryFormFieldsBaseProps,
    CategoryFormGrantUsersProps,
    CategoryFormParentsFieldProps,
    TemplateBaseProps {}

const CategoryFormFields = ({ users, parents, direction, ...rest }: CategoryFormFieldsProps) => (
  <CategoryFormFields.Template
    direction={direction}
    name={<CategoryFormFields.Name {...rest} />}
    isDocumentCategory={<CategoryFormFields.IsDocumentCategory {...rest} />}
    isMigration={<CategoryFormFields.IsMigration {...rest} />}
    parentId={<CategoryFormFields.Parents parents={parents} {...rest} />}
    users={<CategoryFormFields.Users users={users} {...rest} />}
    inclusionDate={<CategoryFormFields.InclusionDate {...rest} />}
    cancellationDate={<CategoryFormFields.CancellationDate {...rest} />}
    cancellationDescription={<CategoryFormFields.CancellationDescription {...rest} />}
  />
);

CategoryFormFields.Required = CategoryFormRequiredFields;
CategoryFormFields.Template = CategoryFieldsTemplate;
CategoryFormFields.Name = CategoryFormNameField;
CategoryFormFields.IsMigration = CategoryFormIsMigrationField;
CategoryFormFields.IsDocumentCategory = CategoryFormIsDocumentCategoryField;
CategoryFormFields.InclusionDate = CategoryFormInclusionDateField;
CategoryFormFields.CancellationDate = CategoryFormCancellationDateField;
CategoryFormFields.CancellationDescription = CategoryFormCancellationDescriptionField;
CategoryFormFields.Users = CategoryFormGrantUsers;
CategoryFormFields.Parents = CategoryFormParentsField;

export default CategoryFormFields;
