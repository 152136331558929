import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';

const AccountCircleNavBar = () => {
  const redux = useAuthSlice();
  const history = useHistory();

  const handleProfile = () => {
    history.push('/profile');
  };
  const handleLogoutItem = () => {
    redux.logout();
  };

  return (
    <>
      <NavDropdown
        title={<FontAwesomeIcon icon={faUserCircle} size="2x" />}
        id="navbarScrollingDropdown"
        drop="start"
      >
        <NavDropdown.Item onClick={handleProfile}>Perfil</NavDropdown.Item>
        <NavDropdown.Item onClick={handleLogoutItem}>Sair</NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default AccountCircleNavBar;
