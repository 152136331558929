import { useEffect, useState } from 'react';
import { Exception, RequestPropsOmitFetch } from '../models';
import ErrorPage from './_errorPage';
import LoadingPage from './_loadingPage';

interface SharedProps {
  [name: string]: any;
  type?: 'request' | 'manual';

  onLoading?: JSX.Element;

  onError?: JSX.Element;
  onSuccess: JSX.Element;
}

type RequestProps = SharedProps & {
  type?: 'request';
  resetRequest?: boolean;
  requests?: RequestPropsOmitFetch<any, Exception>[];
};
type ManualProps = SharedProps & {
  type?: 'manual';
  isLoading?: boolean;
};

type RequestPageProps = RequestProps | ManualProps;

const Mount = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type = 'request',
  requests,
  onSuccess,
  onLoading = <LoadingPage />,
  onError,
  isLoading,
  resetRequest = false,
}: RequestPageProps) => {
  const [anySuccess, setAnySuccess] = useState<boolean | null>(null);
  const [anyError, setAnyError] = useState<RequestPropsOmitFetch<any, Exception> | null>(null);
  const [anyLoading, setAnyLoading] = useState<boolean>(false);

  useEffect(() => {
    setAnySuccess(requests?.every((e) => e.success === true) ?? false);
    setAnyError(requests?.filter((e) => e.error === true)[0] ?? null);
    setAnyLoading(requests?.some((e) => e.loading === true)[0] !== undefined);
  }, [JSON.stringify(requests)]);

  useEffect(() => {
    if (anySuccess && resetRequest) {
      requests?.map((e) => e.reset());
    }
  }, [anySuccess]);

  switch (true) {
    case anyLoading || isLoading:
      return onLoading;
    case anyError?.error && !anyLoading:
      return onError ?? <ErrorPage text={anyError?.backEndErrors?.message} />;
    default:
      return onSuccess;
  }
};

export default Mount;
