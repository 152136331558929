/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IUseButton {
  onClick;
  totalClicks;
  useTriggerOnClick;
  useHandleClick: (f: Function) => void;
  clicked: boolean;
  resetClicked: () => void;
  useResetClicked: (trigger) => void;
  reset: () => void;
  useReset: (trigger) => void;
}
export default (): IUseButton => {
  const [clickCount, setClickCount] = useState<number>(0);
  const [clicked, setClicked] = useState(false);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setClickCount(clickCount + 1);
    },
    [clickCount]
  );

  useEffect(() => {
    if (clickCount > 0) {
      setClicked(true);
    }
  }, [onClick]);

  useEffect(() => {
    setClicked(false);
  }, [clicked]);

  const useHandleClick = (f: Function) => {
    useEffect(() => {
      if (clickCount > 0) f();
    }, [clickCount]);
  };

  const useTriggerOnClick = (f: () => void) => {
    useMemo(() => {
      f();
    }, [clickCount]);
  };

  const resetClicked = () => {
    setClicked(false);
  };

  const useResetClicked = (trigger) => {
    useEffect(() => {
      setClicked(false);
    }, [trigger]);
  };

  const reset = () => {
    setClicked(false);
    setClickCount(0);
  };

  const useReset = (trigger) => {
    useEffect(() => {
      setClicked(false);
      setClickCount(0);
    }, [trigger]);
  };

  return {
    useReset,
    reset,

    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(e),
    totalClicks: clickCount,
    useTriggerOnClick,
    clicked,
    useHandleClick,
    resetClicked,
    useResetClicked,
  };
};
