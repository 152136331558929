import { useState } from 'react';
import { UseLoadingContextValueProps } from './useLoadingGroup';

const useLoadingProvider = (): UseLoadingContextValueProps => {
  const [state, setState] = useState<Map<string, boolean>>(new Map<string, boolean>());

  return {
    loading: !Array.from(state.values()).every((e) => e === false),
    setLoading(name, value) {
      const newState = new Map<string, boolean>(state.entries());
      newState.set(name, value);
      setState(newState);
    },
  };
};

export default useLoadingProvider;
