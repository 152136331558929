import { FieldControlProps } from '../../../../@shared/Fields/Field';
import SelectField from '../../../../@shared/Fields/selectField';
import useInput from '../../../../@shared/Fields/useInput';
import useRequest from '../../../../@shared/Requests/useRequest';
import Template from '../../../../@shared/template/template';
import authEndpoints from '../../auth.endpoints';
import useSelectClientFormSlice from '../ChooseClientForm/useSelectClientFormSlice';

const ClientSelect = ({
  control,
  errorMessage,
  isInvalid,
}: {
  errorMessage?: string;
  isInvalid: boolean;
} & Omit<FieldControlProps, 'name' | 'id' | 'label' | 'children'>) => {
  const fillRequest = useRequest(authEndpoints.listClient());
  const selectClientFormRedux = useSelectClientFormSlice();
  const selectClientState = selectClientFormRedux.useState();
  const select = useInput<number>();

  selectClientFormRedux.useSetData(fillRequest);
  fillRequest.useFetchBy(selectClientState.data.length === 0, 'init');

  switch (true) {
    case fillRequest.loading || (selectClientState?.options?.length ?? 0) <= 0:
      return <Template.Pages.LoadingPage />;

    case fillRequest.error:
      return <Template.Pages.ErrorPage text={fillRequest?.backEndErrors?.message} />;

    default:
      return (
        <SelectField
          getOptionLabel={(option) => option.clientName}
          isMulti={false}
          name="subsidiaries"
          control={control}
          id="login-subsidiaries"
          label="Cliente"
          optionsClassName="text-truncate"
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          options={selectClientState.data ?? []}
          onChange={(e) => {
            select.setValue(e);
          }}
        />
      );
  }
};

export default ClientSelect;
