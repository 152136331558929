/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import { CheckBox, TreeViewNode } from '../../../../@shared/Fields/models';
import FetchPossibleUsers from '../../../../@shared/Requests/components/fetchPossibleUsers';
import CategoryFormFields from './Fields';
import { CategoryFormContext } from './categoryForm.context';
import useCategoryForm from './categoryForm.hook';
import CategoryFormButton from './categoryFormButton';
import CategoryFormErrorAlert from './categoryFormErrorAlert';
import CategoryFormLoading from './categoryFormLoading';
import { Category, CategoryBaseForm } from './types';

export interface CategoryFormBaseProps<T extends CategoryBaseForm> {
  defaultValue?: Category | null;
  children?: React.ReactNode | React.ReactNode[];
  parents?: TreeViewNode[];
  onSubmit?: (e: T) => void;
  users?: CheckBox[];
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  errorData?: Record<string, FormError>;
}

export interface CategoryFormProps<T extends CategoryBaseForm> extends CategoryFormBaseProps<T> {
  type?: 'edit' | 'add';
}

const CategoryForm = <T extends CategoryBaseForm>(props?: CategoryFormProps<T>) => {
  const { children, ...propsRest } = props ?? {};
  const { handleSubmit, ...rest } = useCategoryForm<T>(propsRest);

  return (
    <CategoryForm.Context.Provider value={rest}>
      <Form onSubmit={handleSubmit}>{React.Children.map(children, (e) => e)}</Form>
    </CategoryForm.Context.Provider>
  );
};

CategoryForm.ErrorAlert = CategoryFormErrorAlert;
CategoryForm.Loading = CategoryFormLoading;
CategoryForm.Context = CategoryFormContext;
CategoryForm.Fields = CategoryFormFields;
CategoryForm.Button = CategoryFormButton;
CategoryForm.FetchPossibleUsers = <FetchPossibleUsers context={CategoryFormContext} />;
export default CategoryForm;
