import { useParams } from 'react-router';
import { BoxParams } from '../../box.models';
import useQueryBoxDetail from '../useQueryBoxDetail';

const BoxTitle = () => {
  const { boxId } = useParams<BoxParams>();
  const requestDetail = useQueryBoxDetail(boxId);

  return (
    <>
      <div>
        <h3>
          Arquivo nº{' '}
          {(requestDetail.isLoading || requestDetail.error) && requestDetail.data?.fileNumber}
        </h3>
      </div>
    </>
  );
};

export default BoxTitle;
