import { Form as BootstrapForm } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { FieldControlProps } from '../../../../@shared/Fields/Field';

interface ISendEmailCheckBox
  extends Omit<
    FieldControlProps,
    'name' | 'id' | 'label' | 'isInvalid' | 'children' | 'helperText' | 'errorMessage'
  > {}

const SendEmailCheckBox = ({ control }: ISendEmailCheckBox) => (
  <BootstrapForm.Group className="mb-3">
    <BootstrapForm.Label>Configurações</BootstrapForm.Label>
    <Controller
      name="isSendEmailOfNewPassword"
      control={control}
      render={({ field }) => (
        <BootstrapForm.Check
          type="checkbox"
          label="Enviar token de mudança de senha"
          name="isSendEmailOfNewPassword"
          defaultChecked={false}
          onChange={(e) => field.onChange(e)}
        />
      )}
    />
  </BootstrapForm.Group>
);

export default SendEmailCheckBox;
