import { useEffect, useState } from 'react';

const useCreateObjectUrlByBlobPart = (
  blobParts: string | null,
  fileName: string,
  contentType: ContentType
) => {
  const [finish, setFinish] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  function downloadClick() {
    if (url !== '') {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      setFinish(true);
    }
  }

  function createObjectURL() {
    if (blobParts != null) {
      const blob = new File([blobParts], fileName, { type: contentType });

      const objectURL = window.URL.createObjectURL(blob);
      setUrl(objectURL);
    }
  }

  useEffect(() => {
    createObjectURL();
  }, [blobParts]);

  return { download: () => downloadClick(), finish, url };
};

export default useCreateObjectUrlByBlobPart;
