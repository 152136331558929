import { Control } from 'react-hook-form';
import useStepsForm from '../../../../../@shared/MultiStepForm/StepsForm/stepsForm.hook';
import useUploadFile, { UseUploadFileValue } from '../../../../../@shared/useUploadFile';
import boxesEndpoints from '../../../box.endpoints';
import { VALUE_FILE } from '../boxWizardForm.constants';

export interface Step0Value {
  success: boolean;
  control: Control<
    {
      file: any;
    },
    any
  >;
  value: any;
  requester: UseUploadFileValue;
  errors: any;
  disableNextStep: boolean;
  disablePreviousStep: boolean;
  onClickNextStep: () => Promise<any>;
  onClickPreviousStep: () => void;
  // loading: boolean;
}

const useStep0 = (): Step0Value => {
  const {
    useLocalRequestForm,
    useUpdateStepStatusByRequestForm,
    nextStep,
    previousStep,
    stepIsValidated,
  } = useStepsForm();
  const uploadFile = useUploadFile(boxesEndpoints.uploadCsv);
  const localForm = useLocalRequestForm({ stepIndex: 0 });

  useUpdateStepStatusByRequestForm(uploadFile.requestFile);

  return {
    success: localForm.stepsStatus?.at(0) === 'validated' || uploadFile.requestFile.success,
    control: localForm.control,
    value: localForm.getValues(VALUE_FILE),
    requester: uploadFile,
    errors: uploadFile.requestFile.backEndErrors as any,
    disableNextStep: !stepIsValidated(0),
    disablePreviousStep: false,
    onClickNextStep: nextStep,
    onClickPreviousStep: previousStep,
  };
};

export default useStep0;
