import { formats } from '../../../../constants/constants';
import classificationEndpoints, {
  AddBoxClassificationItem,
} from '../../../../modules/classification/classification.endpoint';
import useRequestForm from '../../../Forms/RequestForm/useRequestForm';

const useBoxClassificationItemAddForm = (boxClassificationId: number) => {
  const { handleSubmit, onSubmit, ...props } = useRequestForm<AddBoxClassificationItem>({
    submitRequest: (e) =>
      classificationEndpoints.addItems(boxClassificationId, { ...e, boxClassificationId }),
    validatorSchema: undefined,
  });

  return { ...props, formats, handleSubmit: handleSubmit(onSubmit) };
};

export default useBoxClassificationItemAddForm;
