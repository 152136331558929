import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCreateObjectUrlByBlob = (blob: Blob, fileName: string) => {
  const [finish, setFinish] = useState<boolean | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  function downloadClick() {
    if (blob != null) {
      const objectURL = window.URL.createObjectURL(blob);
      setUrl(objectURL);

      const a = document.createElement('a');
      a.href = objectURL;
      a.download = fileName;
      a.click();

      setFinish(true);
    }
  }

  useEffect(() => {
    if (url) window.URL.revokeObjectURL(url);
    setUrl('');
    setFinish(false);
  }, [blob]);

  return { download: () => downloadClick(), finish, url };
};

export default useCreateObjectUrlByBlob;
