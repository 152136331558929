/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useRequest from '../../../../@shared/Requests/useRequest';
import userEndpoints from '../../user.endpoints';
import UserDataGridContext from '../UserDataGrid/context';
import ActiveButton from './activeButton';
import EditButton from './editButton';
import InactiveButton from './inactiveButton';

interface IActionButtons {
  id: number;
  isDisable: boolean;
  onClickDisable: Function;
  pathEdit: string;
  type: 'admin' | 'user';
}

const ActionButtons = ({
  id,
  isDisable,
  onClickDisable,
  pathEdit,
  type: typeProp,
}: IActionButtons) => {
  const history = useHistory();
  const request = useRequest();
  const context = useContext(UserDataGridContext);
  const type = typeProp === 'user' ? 'usuários' : 'administradores';

  const edit = () => {
    history.push(pathEdit, id);
  };

  const disable = (active: boolean) => {
    request.fetch(userEndpoints.changeActive({ userId: id, active }));
  };

  request.useSuccess(() => context.refresh());

  return (
    <div>
      <ButtonGroup className="w-100 gap-3">
        <div className="w-50 d-flex justify-content-center">
          <EditButton title={`editar ${type}`} data-id={id} onClick={() => edit()} />
        </div>
        <div className="w-50 d-flex justify-content-center">
          {isDisable ? (
            <InactiveButton
              data-id={id}
              title={`desabilitar ${type}`}
              onClick={() => {
                disable(true);
                onClickDisable();
              }}
            />
          ) : (
            <ActiveButton
              data-id={id}
              title={`habilitar ${type}`}
              onClick={() => {
                disable(false);
                onClickDisable();
              }}
            />
          )}
        </div>
      </ButtonGroup>
    </div>
  );
};

export default ActionButtons;
