import {
  BoxSeamFill,
  BriefcaseFill,
  Diagram2Fill,
  GearFill,
  HouseFill,
  PeopleFill,
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import Line from './line';

interface LinesProps {
  children: string[];
}

const Lines = ({ children }: LinesProps) => {
  const history = useHistory();

  const has = (name: string) => {
    if (name) {
      return children?.includes(name);
    }
    return false;
  };

  return (
    <>
      {has('home:read') && (
        <Line Icon={HouseFill} onClick={() => history.push('/home')}>
          Home
        </Line>
      )}
      {has('users:read') && (
        <Line Icon={PeopleFill} onClick={() => history.push('/users')}>
          Usuários
        </Line>
      )}
      {has('boxes:read') && (
        <Line Icon={BoxSeamFill} onClick={() => history.push('/consult')}>
          Consulta
        </Line>
      )}
      {has('box-classifications:read') && (
        <Line Icon={Diagram2Fill} onClick={() => history.push('/classifications')}>
          Classificações
        </Line>
      )}
      {has('admins:read') && (
        <Line Icon={BriefcaseFill} onClick={() => history.push('/admins')}>
          Administradores
        </Line>
      )}
      {has('settings:read') && (
        <Line Icon={GearFill} onClick={() => history.push('/settings')}>
          Configurações
        </Line>
      )}
    </>
  );
};

export default Lines;
