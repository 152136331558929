import AlertRedirect from '../Alerts/redirectAlert';

const InfoPage = ({
  text: message,
  onClickLink,
  linkText,
  linkUrl,
  hiddenLink,
}: {
  text?: string;
  onClickLink?: Function;
  linkText?: string;
  linkUrl?: string;
  hiddenLink?: boolean;
}) => (
  <div className="w-100 h-100 p-4">
    <AlertRedirect
      title="Informação"
      text={message ?? 'não definido'}
      variant="info"
      hiddenLink={hiddenLink}
      linkText={linkText}
      linkUrl={linkUrl}
      onClick={onClickLink}
    />
  </div>
);

export default InfoPage;
