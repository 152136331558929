import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

interface BoxClassificationItemFormTemplateProps {
  name: ReactNode;
  format: ReactNode;
  size: ReactNode;
  observation: ReactNode;
  orderNumber: ReactNode;
  isPrinted: ReactNode;
  barcodeRef: ReactNode;
  inclusionDate?: ReactNode;
  cancellationDate?: ReactNode;
  type: 'add' | 'edit';
}

const BoxClassificationItemFormTemplate = ({
  name,
  format,
  size,
  orderNumber,
  observation,
  isPrinted,
  barcodeRef,
  inclusionDate,
  cancellationDate,
  type,
}: BoxClassificationItemFormTemplateProps) => (
  <>
    {type === 'edit' ? (
      <Row className="justify-content-center">
        <Col sm={6} md={3}>
          {name}
        </Col>

        <Col sm={6} md={3}>
          {format}
        </Col>

        <Col sm={6} md={3}>
          {size}
        </Col>

        <Col sm={6} md={3}>
          {orderNumber}
        </Col>

        <Col md={6}>
          <Row className="h-100">
            <Col>{isPrinted}</Col>
            <Col>{barcodeRef}</Col>
          </Row>
        </Col>
        <Col md={6}>{observation}</Col>

        <Col>{inclusionDate}</Col>

        <Col>{cancellationDate}</Col>
      </Row>
    ) : (
      <Row className="justify-content-center">
        <Col sm={6}>{name}</Col>

        <Col sm={6}>{format}</Col>

        <Col sm={6}>{size}</Col>

        <Col sm={6}>{orderNumber}</Col>

        <Col md={12}>{observation}</Col>
        <Col md={12}>
          <Row className="h-100">
            <Col>{isPrinted}</Col>
            <Col>{barcodeRef}</Col>
          </Row>
        </Col>

        <Col>{inclusionDate}</Col>
        <Col>{cancellationDate}</Col>
      </Row>
    )}
  </>
);

export default BoxClassificationItemFormTemplate;
