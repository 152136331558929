import { useEffect } from 'react';
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';

import ConfirmationDialog from '../../../../@shared/Dialogs/confirmationDialog';
import useRequest from '../../../../@shared/Requests/useRequest';
import TwoFactorAuthenticationForm from '../../../../@shared/TwoFactorAuthenticationForm';
import Template from '../../../../@shared/template/template';
import reset2FaEndpoints from '../../reset-2fa.endpoints.urls';

const Reset2FaForm = () => {
  const redux = useAuthSlice();
  const authState = redux.useState();
  const resetRequest = useRequest(reset2FaEndpoints.reset());

  useEffect(() => {
    if (!authState.wasReset2Fa) resetRequest.fetch();
  }, []);

  if (resetRequest.error)
    return <Template.Pages.ErrorPage text={resetRequest.backEndErrors?.message} />;
  if (resetRequest.success)
    return (
      <>
        <TwoFactorAuthenticationForm
          authenticator={resetRequest.successData}
          onSuccessType="component"
          onSuccess={
            <ConfirmationDialog
              show
              title="Alterado com sucesso autenticação de dois fatores"
              bodyText="Autenticação de dois fatores foi atualizada com sucesso, agora você sera redirecionado para login"
              handleClose={() => redux.reset2fa()}
            />
          }
          onAdmin={
            <Template.Pages.InfoPage
              text="usuários do tipo administrador do sistema não possui autenticação de duas etapas"
              onClickLink={() => redux.logout()}
            />
          }
        />
      </>
    );
  return <Template.Pages.LoadingPage />;
};

export default Reset2FaForm;
