import { ReactNode } from 'react';
import { Accordion, Stack } from 'react-bootstrap';

const CategoryAccordionButton = ({ children }: { children: string | ReactNode | ReactNode[] }) => (
  <Stack direction="horizontal" className="justify-content-center">
    <Accordion.Button as="span">{children}</Accordion.Button>
  </Stack>
);

export default CategoryAccordionButton;
