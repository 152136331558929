/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import DataGrid from '../../../../@shared/DataGrid';
import DownloadButton from '../../../../@shared/DownloadButton';
import SuccessToast from '../../../../@shared/Toasts/successToast';
import VerifyLoading from '../../../../@shared/Verifiers/verifyLoading';
import Template from '../../../../@shared/template/template';
import userEndpoints from '../../user.endpoints';
import UserDataGridContext from './context';
import useUserDataGrid from './useUserDataGrid';

const UserDataGrid = () => {
  const { columns, error, loading, errorMessage, rows, toast, context, filters } =
    useUserDataGrid();
  const errorText = 'ops.. houve uma falha ao tentar acessar os usuários';

  if (error) {
    return <Template.Pages.ErrorPage text={errorMessage ?? errorText} />;
  }
  if (loading) {
    return <VerifyLoading loadings={[true]} />;
  }
  return (
    <UserDataGridContext.Provider value={context}>
      <DataGrid>
        <DataGrid.Header className="p-2">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle id="dropdown-autoclose-true" className="w-100">
              Imprimir
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Header>CSV</Dropdown.Header>
              <DownloadButton
                title="baixar csv de usuários"
                contentType="text/csv"
                fileName="usuários"
                as="dropdown-item"
                requestConfig={userEndpoints.downloadCsv(filters)}
              >
                Todos
              </DownloadButton>

              <Dropdown.Divider />
              <Dropdown.Header>PDF</Dropdown.Header>
              <DownloadButton
                title="baixar pdf de usuários"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="usuários"
                requestConfig={userEndpoints.downloadPdf(filters)}
              >
                Todos
              </DownloadButton>
              <DownloadButton
                title="baixar pdf de usuários ativos"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="usuários-ativos"
                requestConfig={userEndpoints.downloadPdf(filters, true)}
              >
                Ativos
              </DownloadButton>
              <DownloadButton
                title="baixar pdf de usuários desativados"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="usuários-desativados"
                requestConfig={userEndpoints.downloadPdf(filters, false)}
              >
                Desativados
              </DownloadButton>
            </Dropdown.Menu>
          </Dropdown>

          <DataGrid.AddButton to="users/add" title="adicionar usuário" />
        </DataGrid.Header>
        <DataGrid.Main
          rowId="actions"
          rows={rows}
          columns={columns}
          headerRowHeight={100}
          className="datagrid-lg"
        />
      </DataGrid>
      <SuccessToast show={toast.show} text={toast.text} onShow={toast.reset} />
    </UserDataGridContext.Provider>
  );
};

export default UserDataGrid;
