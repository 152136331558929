import { useDispatch, useSelector } from 'react-redux';
import useRequest from '../../@shared/Requests/useRequest';
import { UserInfo } from '../../@shared/storage/menuService';
import { RootState } from '../../redux/application';
import { authActions } from '../../redux/slices/authSlice';
import layoutEndpoints from './layout.endpoints';

const useSideBar = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const request = useRequest<UserInfo>(layoutEndpoints.getUserinfo());
  const canShow = auth?.userInfo !== null;

  request.useFetchBy(!auth.userInfo, 'init');
  request.useSuccess(
    () => request.successData && dispatch(authActions.setUserInfo(request.successData))
  );

  return {
    handleErrorClick: () => dispatch(authActions.logout()),
    request,
    lines: auth.userInfo?.permissions ?? [],
    canShow,
    errorMessage: request.backEndErrors?.message ?? 'Houve um error ao carregar menu lateral',
  };
};

export default useSideBar;
