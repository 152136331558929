import React, { useState } from 'react';
import { HeaderRendererProps } from 'react-data-grid';
import useFocusRef from '../../focusRef.hook';
import ColumnFilterCell from './columnFilterCell';
import ColumnFilterTitle from './columnFilterTitle';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Children =
  | React.ReactElement
  | React.InputHTMLAttributes<HTMLInputElement>
  | React.TextareaHTMLAttributes<HTMLTextAreaElement>
  | React.SelectHTMLAttributes<HTMLSelectElement>
  | React.HTMLAttributes<HTMLDivElement>;

interface IContainer {
  onPressEnter?: (v: string) => void;
  title?: string;
  value?: any;
  header?: React.PropsWithChildren<HeaderRendererProps<any, unknown>>;
  className?: string;
  children: Children;
}

const ColumnFilter = ({ title, header, onPressEnter, value, className, children }: IContainer) => {
  const { ref } = useFocusRef<HTMLElement>(header?.isCellSelected ?? false);
  const [localData, setLocalData] = useState('');

  let childProps: any = {};

  if (React.isValidElement(children)) {
    childProps = children.props;
  }

  const { onKeyPress, onChange, ...rest } = childProps;

  return (
    <div className="h-100 w-100">
      <ColumnFilterCell className={className}>
        {React.cloneElement(children as React.ReactElement, {
          placeholder: `Filtro de ${title?.toLowerCase()}`,
          'aria-describedby': 'basic-addon2',
          ref,
          defaultValue: value,
          onChange: (event) => {
            setLocalData(event.target.value);
            if (onChange) {
              onChange(event);
            }
          },
          onKeyPress: (event) => {
            if (event.key === 'Enter') {
              if (onPressEnter) onPressEnter(localData);
            }
            if (onKeyPress) {
              onKeyPress(event);
            }
          },
          ...rest,
        })}
        <ColumnFilterTitle>{title}</ColumnFilterTitle>
      </ColumnFilterCell>
    </div>
  );
};

export default ColumnFilter;
