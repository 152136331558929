import CategoryFormHorizontalTemplate from './template.horizontal';
import CategoryFormVerticalTemplate from './template.vertical';
import { CategoryFormTemplateBaseProps, TemplateBaseProps } from './types';

export interface CategoryFieldsTemplateProps
  extends CategoryFormTemplateBaseProps,
    TemplateBaseProps {}

const CategoryFieldsTemplate = ({ direction, ...props }: CategoryFieldsTemplateProps) => {
  if (direction === 'horizontal') {
    return <CategoryFormHorizontalTemplate {...props} />;
  }
  return <CategoryFormVerticalTemplate {...props} />;
};

CategoryFieldsTemplate.Horizontal = CategoryFormHorizontalTemplate;
CategoryFieldsTemplate.Vertical = CategoryFormVerticalTemplate;
export default CategoryFieldsTemplate;
