import { ColProps, Placeholder, PlaceholderProps } from 'react-bootstrap';

export type InputShimmerPlaceholderProps = ColProps & PlaceholderProps & {};

const InputShimmerPlaceholder = ({
  xs = 12,
  animation = 'glow',
  as = 'div',
  ...props
}: InputShimmerPlaceholderProps) => (
  <Placeholder animation={animation}>
    <Placeholder style={{ height: '38px', borderRadius: '.375rem' }} {...{ ...props, xs, as }} />
  </Placeholder>
);

export default InputShimmerPlaceholder;
