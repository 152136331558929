import { Col, ModalProps, Row } from 'react-bootstrap';
import FormDialog from '../../../../@shared/Dialogs/formDialog';
import TextField from '../../../../@shared/Fields/textField';
import { FormControlProps, FormPropsOmitChildren } from '../../../../@shared/Forms/RequestForm';
import { BoxRow } from '../../box.models';
import BoxDescriptionAlert from './boxDescriptionAlert';

export interface IBoxSolicitationDescription {
  boxClassificationId: number;
  name: string;
  fileDescription: string;
  size: number;
  shouldPrint: boolean;
}

interface IDialog extends ModalProps {
  title: string;
  onHide: () => void;
  onShow?: any;
  show: boolean;
  children?: JSX.Element[];
}
interface ISolicitationDialog {
  descriptions?: BoxRow[];
  className?: string;
}

type SolicitationDialogProps = FormPropsOmitChildren &
  ISolicitationDialog &
  IDialog &
  FormControlProps;

const SolicitationFormDialogPresentation = (props: SolicitationDialogProps) => {
  const { descriptions, errors, control } = props;
  return (
    <>
      <FormDialog {...props} bodyClassName="dialog-body">
        <Row>
          <Col sm={6}>
            <TextField
              control={control}
              name="description"
              label="Breve Descrição do movimento"
              id="description"
              as="textarea"
              rows={6}
              errorMessage={errors?.description?.message}
              isInvalid={errors?.description != null}
            />
          </Col>
          <Col sm={6}>
            {descriptions?.map((e: BoxRow) => (
              <BoxDescriptionAlert key={e?.boxId} values={e} />
            ))}
          </Col>
        </Row>
      </FormDialog>
    </>
  );
};

export default SolicitationFormDialogPresentation;
