import { configureStore } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';
import RootReducer from './RootReducer';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
// export const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore({
  reducer: RootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const store = (history: any) => reducers;
export default store;
