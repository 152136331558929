/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Control,
  FieldValues,
  UseFormGetFieldState,
  UseFormRegister,
  UseFormUnregister,
} from 'react-hook-form';
import { filterUniqueOptionsFromColumnSelect } from '../../../Filters/useFilterUniqueOptions';

import { Option } from '../../../models';
import { util } from '../../../utils/util';
import { optionBlankNotHidden } from '../../createOptions';
import { prepare } from '../../Hooks/prepareArray.hook';
import { SelectColumn } from '../../models';
import createColumns from '../SelectThead/theadSelect.hook.createColumns';
import selectTheadUtil from '../SelectThead/theadSelect.utils';
import { optionHeader, staticOptions } from './csvTableField.constants';
import csvTableFieldUtil from './csvTableField.util';

type ControlRedux = {
  register: UseFormRegister<FieldValues> | null;
  unregister: UseFormUnregister<FieldValues>;
  getFieldState: UseFormGetFieldState<FieldValues>;
};

interface Column {
  disabled: boolean;
  id: string;
  key: string;
  options: Option[];
}

export interface CsvSelectableHeaderTableState {
  id: string;
  hasHeader: boolean;
  columnSize: number;
  control: ControlRedux | null;
  rows: string[][];
  rowsFiltered: string[][];
  columns: Column[];
  options: Option[];
  value: string[];
}

function valueChange(values: string[], index: number, value: string) {
  const newValue = [...values];
  newValue[index] = value;
  // eslint-disable-next-line no-param-reassign
  values = newValue;
  return newValue;
}

function defineColumnSize(state) {
  return Math.max(...state.rows.map((arr) => arr.length));
}

function disableByIndex(columns: Column[], index: number) {
  const newColumns = [...columns];
  if (newColumns[index]) newColumns[index].disabled = true;
  return { columns: newColumns };
}

function enableByIndex(columns: Column[], index: number) {
  const newColumns = [...columns];
  if (newColumns[index]) newColumns[index].disabled = false;
  return { columns: newColumns };
}

const createCustomColumns = (
  id: string,
  options: Option[],
  columnSize?: number,
  defaultValue?: Column[],
  disabled?: boolean
): Column[] => {
  const d: SelectColumn[] =
    defaultValue?.map((e) => ({
      disabled: e.disabled,
      key: e.key,
      options: e.options,
    })) ?? [];

  const selectColumns = createColumns(d, {
    columnSize: columnSize ?? 0,
    id,
    options,
    key: Array(columnSize).map((e) => Math.random().toString()),
  });

  const result = selectTheadUtil.createColumns(id, selectColumns, disabled ?? false) as Column[];

  return result;
};

export const initialState: CsvSelectableHeaderTableState = {
  id: '',
  columnSize: 0,
  hasHeader: false,
  control: null,
  options: [],
  columns: [],
  rows: [],
  rowsFiltered: [],
  value: [],
};

type Initial = {
  control: Control;
  rows: string[][];
  options: Option[];
  defaultValue: string[];
  select?: { disabled?: boolean };
};

const slice = createSlice({
  name: Math.random().toString(),
  initialState,
  reducers: {
    initial(state, action: PayloadAction<Initial>) {
      const { register, getFieldState, unregister } = action.payload.control;
      const control = { register, getFieldState, unregister };
      state.control = control;
      state.hasHeader = false;

      state.options =
        action.payload.options && action.payload.options.length > 0
          ? [...action.payload.options, optionHeader, ...staticOptions, optionBlankNotHidden]
          : [optionHeader, ...staticOptions, optionBlankNotHidden];

      state.rows = action.payload.rows;
      state.rowsFiltered = action.payload.rows;

      state.id = 'descriptions';

      state.columnSize = Math.max(...action.payload.rows.map((arr) => arr.length)) ?? 0;
      state.value = prepare({ size: state.columnSize, data: '' });

      state.columns = createCustomColumns(
        state.id,
        state.options,
        state.columnSize,
        undefined,
        action.payload?.select?.disabled ?? false
      );
    },

    filterHasHeader(state) {
      state.hasHeader = true;
      state.rowsFiltered = csvTableFieldUtil.turnFirstRowInHeader({
        default: state.rows,
        filtered: state.rowsFiltered,
      }).filtered;

      const { columns } = disableByIndex(state.columns, 0);
      state.columnSize += 1;
      state.columns = createCustomColumns(state.id, state.options, state.columnSize, columns);

      state.value = [...valueChange(state.value, 0, 'header'), ''];
    },
    filterNotHasHeader(state) {
      state.hasHeader = false;
      state.rowsFiltered = state.rows;

      const { columns } = enableByIndex(state.columns, 0);

      if (state.columnSize !== defineColumnSize(state)) {
        state.columnSize -= 1;
      }

      state.columns = createCustomColumns(state.id, state.options, state.columnSize, columns);

      const value = valueChange(state.value, 0, '');
      const valueRemoved = [...value];
      valueRemoved.pop();

      state.value = valueRemoved;
    },
    updateOptions(state, action: PayloadAction<{ options: Option[] }>) {
      state.options =
        action.payload.options && action.payload.options.length > 0
          ? [...action.payload.options, optionHeader, ...staticOptions, optionBlankNotHidden]
          : [optionHeader, ...staticOptions, optionBlankNotHidden];

      state.columns = createCustomColumns(state.id, state.options, state.columnSize, state.columns);
    },
    updateDisable(state, action: PayloadAction<boolean>) {
      state.columns = state.columns.map((e) => ({ ...e, disabled: action.payload }));
    },
    setValue(state, action: PayloadAction<string[]>) {
      if (action.payload) {
        const d: SelectColumn[] =
          state.columns?.map((e) => ({
            disabled: e.disabled,
            key: e.key,
            options: e.options,
          })) ?? [];

        const selectColumns = filterUniqueOptionsFromColumnSelect(
          d,
          action.payload,
          state.options,
          [optionBlankNotHidden]
        );

        const columns = selectColumns.map((e, i) => ({
          disabled: e.disabled,
          id: `${state.id}.${i}`,
          key: e.key,
          options: e.options,
        }));
        if (!util.isEqualDeep(state.columns, columns)) state.columns = columns;

        state.value = action.payload.map((e) => (e === undefined ? '' : e));
      }
    },
  },
});

export const { reducer, actions, caseReducers, getInitialState, name } = slice;
