import { formats } from '../../../../constants/constants';
import classificationEndpoints, {
  BoxClassificationItem,
} from '../../../../modules/classification/classification.endpoint';
import useRequestForm from '../../../Forms/RequestForm/useRequestForm';
import { formatDate } from '../../../utils/util';

const useBoxClassificationItemEditForm = (
  boxClassificationId: number,
  boxClassificationItemId: number,
  defaultValue?: BoxClassificationItem
) => {
  const { handleSubmit, onSubmit, submitResponse, ...props } =
    useRequestForm<BoxClassificationItem>({
      submitRequest: (e) =>
        classificationEndpoints.editItem(boxClassificationId, boxClassificationItemId, { ...e }),
      defaultValues: defaultValue,
      validatorSchema: undefined,
      transformDefaultValues(obj) {
        return {
          ...obj,
          format: formats.find((f) => f.value === obj?.format),
          inclusionDate: formatDate(String(obj?.inclusionDate)),
          cancellationDate: formatDate(String(obj?.cancellationDate)),
        };
      },
    });

  return {
    ...props,
    formats,
    loading: submitResponse.loading,
    error: submitResponse.error,
    handleSubmit: handleSubmit(onSubmit),
  };
};

export default useBoxClassificationItemEditForm;
