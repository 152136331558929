import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm, useWatch } from 'react-hook-form';
import { TreeViewNode } from '../../../../../../@shared/Fields/models';
import useRequest from '../../../../../../@shared/Requests/useRequest';
import yup from '../../../../../../config/yup/customYup';
import categoryEndpoints from '../../../../../category/category.endpoints';
import { Category } from '../../../../../category/features/CategoryForm/types';
import classificationEndpoints from '../../../../classification.endpoint';
import { BoxClassificationAddForm } from '../boxClassificationAddFormSteps.types';

interface UseBoxClassificationAddFormStep3Props {
  defaultValue?: BoxClassificationAddForm | BoxClassificationAddForm.Step3;
  onSubmit?: (e: BoxClassificationAddForm.Step3) => void;
}

const useBoxClassificationAddFormStep3 = (props?: UseBoxClassificationAddFormStep3Props) => {
  const r = useRequest();
  const { control, formState, handleSubmit } = useForm<BoxClassificationAddForm.Step3>({
    defaultValues: (props?.defaultValue as any)?.step3 ?? props?.defaultValue ?? { categories: [] },
    resolver: yupResolver(
      yup.object({
        categories: yup
          .array(
            yup.object({
              name: yup.string().required(),
              isMigration: yup.boolean().required(),
              isDocumentCategory: yup.bool().nullable(),
              parentId: yup.object().nullable(),
            })
          )
          .required()
          .min(1, 'é necessario escolher associar ao menos categoria'),
      })
    ),
  });
  const requestTreeView = useRequest<{ categories: Category; parents: TreeViewNode[] }>(
    categoryEndpoints.listCategoriesCreateOrSelectOptions()
  );
  requestTreeView.useFetchBy('init');

  const controller = useController({ name: 'categories', control });
  const categories = useWatch({
    control,
    name: 'categories',
  });

  const onSubmit = (data: BoxClassificationAddForm.Step3) => {
    const a = props?.defaultValue as any;
    r.setRequestConfig(
      classificationEndpoints.add({
        ...(a.step1 ?? {}),
        ...(a.step2 ?? {}),
        categories: data.categories,
      } as any)
    );
  };

  r.useFetchBy(r.requestConfig);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isEmpty: Array.isArray(formState.errors.categories)
      ? formState.errors.categories.length === 0
      : formState.errors.categories,
    errors: formState.errors,
    success: r.success,
    successData: r.successData,
    parents: requestTreeView.successData?.parents ?? [],
    categories: requestTreeView.successData?.categories ?? [],
    fields: { categories, onCategoriesChange: controller.field.onChange },
  };
};

export default useBoxClassificationAddFormStep3;
