import JSZip from 'jszip';
import _ from 'lodash';
import { FieldValues } from 'react-hook-form';
import { InputGroup, TreeViewNode } from '../Fields/models';

import { Option, Ref } from '../models';

export const firstNotNullAndNotUndefined = <T>(...array: T[]) =>
  array.find((e) => e !== null && e !== undefined) as T;

export async function compressFile(file: File) {
  const zip = new JSZip();

  zip.file(file.name, file, { compression: 'DEFLATE' });

  const compressedBlob = await zip.generateAsync({ type: 'blob' });

  return compressedBlob;
}

export async function compressFormData(formData: FormData) {
  const zip = new JSZip();

  formData.forEach((value, key) => {
    if (value instanceof File) {
      zip.file(key, value, { compression: 'DEFLATE' });
    } else {
      zip.file(key, value.toString());
    }
  });

  const compressedBlob = await zip.generateAsync({ type: 'blob' });

  return compressedBlob;
}

export function shouldPrefix(prefix: string | undefined, name: string): string {
  return prefix ? `${prefix}.${name}` : name;
}

export default function isEmpty(obj: object | undefined | null | string) {
  if (obj === null || obj === undefined) return true;
  if (obj === '') return true;
  return Object?.keys(obj)?.length === 0;
}

export function flat(nodes: TreeViewNode[]): TreeViewNode[] {
  return nodes.reduce((acc: TreeViewNode[], node: TreeViewNode): TreeViewNode[] => {
    acc.push(node);
    if (node.children) {
      // eslint-disable-next-line no-param-reassign
      acc = acc.concat(flat(node.children));
    }
    return acc;
  }, []);
}

export function formatDate(date: string) {
  return Date.parse(date)
    ? new Intl.DateTimeFormat(navigator.language, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date))
    : date;
}

export function toCamelCase(str: string) {
  // eslint-disable-next-line no-param-reassign
  str = str.toLowerCase();
  return str.replace(/\s+(.)/g, (match, chr) => chr.toUpperCase());
}

export const setRef = <T>(ref: Ref<T>, f: T) => {
  // eslint-disable-next-line no-param-reassign
  ref.current = f;
};

export const extractValueTreeView = (treeView: TreeViewNode[]) => {
  if (treeView?.length === 0 || treeView?.length === undefined) return [];
  const values = new Array<string>();
  const getChildren = (z: TreeViewNode) => {
    if (z.checked === true) values.push(z.value);
    if (z.children === null) return;
    z.children.map((e) => getChildren(e));
  };
  treeView?.forEach((elem) => {
    if (elem.checked === true) values.push(elem.value);
    elem.children.map((e) => getChildren(e));
  });

  return values;
};
export type Verifier = {
  isInvalid: boolean;
  message: string;
};

export const util = {
  dateIsoFormat(value: string) {
    if (!value) return null;
    const date = new Date();
    const iso = date.toISOString();
    const isoSplit = iso.split('T');

    const splitValue = value.split('/');

    if (splitValue && splitValue.length === 3 && (splitValue?.at(2)?.length ?? 0) === 4) {
      const result = `${splitValue.at(2)}-${splitValue.at(1)}-${splitValue.at(0)}T${isoSplit.at(
        1
      )}`;

      return result;
    }

    return null;
  },
  isDate(value: string | null | undefined, nullable?: boolean) {
    if (!value) return nullable ?? false;
    const date = new Date(value).toString().toLowerCase();
    const dateValid = date !== 'Invalid Date'.trim().toLowerCase();
    return dateValid;
  },
  isDateFormat(value: string | null | undefined, nullable?: boolean) {
    if (!value) return nullable ?? false;
    const regex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

    if (!regex.test(value)) {
      return false;
    }

    const [day, month] = value.split('/');
    const d = Number(day);
    const m = Number(month);
    if (d > 31) return false;
    if (m > 12) return false;

    return true;
  },
  parseToOptions(inputGroup: InputGroup[]): Option[] {
    return inputGroup.map((e) => ({ label: e.label, value: e.id }));
  },
  parseToInputGroup(options: Option[] | null | undefined): InputGroup[] {
    if (options) {
      return options?.map((e) => ({
        defaultValue: null,
        disable: false,
        helperText: null,
        id: String(e.value),
        label: e.label,
        placeholder: null,
        tag: 'unknown',
        type: 'text',
      }));
    }
    return [];
  },
  addUniqueValues<V extends Array<any>, A extends Array<any>>(newValues: V, array: A) {
    const b = _.union(array, [...newValues]);
    return b;
  },
  addUniqueValue<V, A extends Array<any>>(newValue: V, array: A) {
    _.union(array, [newValue]);
    return array;
  },
  removeByDiference(v1: Array<any>, v2: Array<any>) {
    const diference = _.intersection(v1, v2);
    return diference;
  },
  isInvalid(v: Array<Verifier>) {
    return !v.every((e) => e.isInvalid);
  },
  errorMessage(v: Array<Verifier>) {
    return v.filter((e) => e.isInvalid).at(0)?.message ?? '';
  },
  parseToEnum(currentData: Array<any>) {
    const reducerForEnum = (accumulator: string, curr: string) =>
      (Number(accumulator) + Number(curr)).toString();

    if (!Array.isArray(currentData)) return undefined;

    return currentData === undefined || currentData?.length === 0
      ? 0
      : Number(currentData?.reduce(reducerForEnum));
  },
  onChangeCheckBoxGroup(
    e: React.ChangeEvent<HTMLInputElement>,
    currentData: any[],
    type?: 'number' | 'string'
  ) {
    const addValue = (value: string) => {
      if (currentData !== undefined) currentData?.push(value);
      return currentData;
    };
    const removeValue = (value: any) => currentData?.filter((k) => String(k) !== String(value));

    let newData = new Array<any>();
    const { value } = e.target;
    newData = e.target.checked === true ? addValue(value) : removeValue(value);

    switch (type) {
      case 'number':
        return newData.map((t) => Number(t));

      default:
        return newData;
    }
  },
  isNotNullable(value: any) {
    return value !== null && value !== undefined;
  },
  isNullable(value: any) {
    return value === null || value === undefined;
  },
  isEqualDeep(v1, v2) {
    const v1Json = JSON.stringify(v1);
    const v2Json = JSON.stringify(v2);
    return v1Json === v2Json;
  },
  replace<T>(array: Array<T>, obj: T, predicate: (value: T, index: number, obj: T[]) => boolean) {
    let v = array.find(predicate);
    if (v) v = obj;
    return array;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  merge<T>(oldValue: T[], newValue: T[], compareField: string) {
    if (!compareField) throw new Error('compare Field cannot be undefined or null');

    if (oldValue.length === 0) {
      return [...newValue];
    }
    if (newValue.length === 0) {
      return [...oldValue];
    }

    const mergeValue = oldValue.map((e) => {
      const newValueVersion: T | undefined = newValue.find((k) => {
        if (k[compareField] === undefined || e[compareField] === undefined) {
          throw new Error(
            `comparisons cannot have one or more fields as undefined
             filed: ${compareField}
             newValue: ${k[compareField]}
             oldValue: ${e[compareField]}`
          );
        }

        return k[compareField] === e[compareField];
      });

      if (newValueVersion !== undefined) return newValueVersion as T;
      return e;
    });
    const valueNotExist = newValue.filter(
      (e) => !oldValue.some((k) => e[compareField] === k[compareField])
    );

    return [...mergeValue, ...valueNotExist];
  },
  mergeByObj(oldValueObj: object, newValueObj: object): any {
    const oldValueEntires = Object.entries(oldValueObj);
    const newValueEntires = Object.entries(newValueObj);

    if (oldValueEntires.length === 0) {
      return { ...newValueObj } as any;
    }
    if (newValueEntires.length === 0) {
      return { ...oldValueEntires } as any;
    }

    const mergeValue = oldValueEntires
      .map(([oldKey, oldValue]) => {
        const newValueVersion = newValueEntires.find(([Newkey]) => Newkey === oldKey)?.[1];
        if (newValueVersion !== undefined) return { [oldKey]: newValueVersion };
        return { [oldKey]: oldValue };
      })
      .reduce((p, c) => ({ ...p, ...c }));

    const valueNotExist = Object.fromEntries(
      newValueEntires.filter(([newKey]) => !oldValueEntires.some(([oldKey]) => newKey === oldKey))
    );

    return { ...mergeValue, ...valueNotExist } as any;
  },
  removeFields(keepFields: string[], allValues: FieldValues) {
    const value = Object.keys(allValues).filter((v) => !keepFields.some((v2) => v === v2));
    return value;
  },
  csvToArrayWithHeader(str, delimiter = ','): any[] {
    const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const arr = rows.map((row) => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, header, index) => {
        // eslint-disable-next-line no-param-reassign
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  },
  csvToArray(str, delimiter = ','): any[] {
    const rows = str.split('\n');
    const arr = rows.map((row) => {
      const values = row.split(delimiter);
      return values;
    });
    return arr;
  },
  onCsvChange({ e, set, withHeader, delimiter = ',' }: CsvChangeProps): FileReader {
    const input = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (k: any) => {
      const text = k.target.result;
      set(
        withHeader ? util.csvToArrayWithHeader(text, delimiter) : util.csvToArray(text, delimiter)
      );
    };
    reader.readAsText(input);

    return reader;
  },
};

type CsvChangeProps = {
  e: any;
  set: (k: any[]) => void;
  withHeader: boolean;
  delimiter: string;
};
