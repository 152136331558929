/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'react-bootstrap';

interface IResetButton {
  fetch: Function;
}

const resetButton = ({ fetch }: IResetButton) => (
  <div className="mb-3">
    <label className="form-label">Senha</label>
    <br />
    <Button onClick={() => fetch()}>Resetar Senha</Button>
  </div>
);

export default resetButton;
