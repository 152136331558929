import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';

const ActiveButtonBase = ({ className, ...props }: ButtonProps) => (
  <Button variant="success" className={`rounded  ${className}`} {...props}>
    <FontAwesomeIcon icon={faUserCheck} />
  </Button>
);

const ActiveButton = styled(ActiveButtonBase)`
  :hover {
    color: var(--bs-success);
    background-color: transparent;
    border-color: var(--bs-success);
  }
`;

export default ActiveButton;
