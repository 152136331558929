import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Loading from '../Loading/index';
import { JsxElements } from '../models';

interface IVerifyLoading {
  // eslint-disable-next-line react/require-default-props
  children?: JsxElements;
  loadings: boolean[];
  // eslint-disable-next-line react/require-default-props
}

const VerifyLoading = ({ children = <></>, loadings: data }: IVerifyLoading) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const convertData = data.map((l) => {
      if (l === undefined) return false;
      return l;
    });
    const isThereAnyLoading = convertData?.filter((l) => l === true).length > 0;
    setIsLoading(isThereAnyLoading);
  }, [data, data?.map((l) => l)]);

  return isLoading ? (
    <>
      <Loading />
    </>
  ) : (
    <>{children}</>
  );
};

export default VerifyLoading;
