import { ButtonGroup } from 'react-bootstrap';
import ShimmerPlaceholder from '../../../ShimmerPlaceholders';
import BoxClassificationFormTemplate from '../boxClassificationForm.template';

const BoxClassificationEditFormLoading = () => (
  <BoxClassificationFormTemplate
    key="edit"
    name={<ShimmerPlaceholder.FormGroup />}
    day={<ShimmerPlaceholder.FormGroup />}
    moth={<ShimmerPlaceholder.FormGroup />}
    year={<ShimmerPlaceholder.FormGroup />}
    inclusionDate={<ShimmerPlaceholder.FormGroup />}
    cancellationDate={<ShimmerPlaceholder.FormGroup />}
    button={
      <ButtonGroup className="d-flex justify-content-end">
        <div>
          <ShimmerPlaceholder.Button style={{ width: '70px' }} />
        </div>
      </ButtonGroup>
    }
  />
);

export default BoxClassificationEditFormLoading;
