export const baseUrl = '/categories';

export const routes = {
  baseUrl,
  add: `${baseUrl}/add`,
  edit: `${baseUrl}/:categoryId/edit`,
};

export const categoryUrls = {
  baseUrl: routes.baseUrl,
  add: routes.add,
  edit: (categoryId: number) => `${baseUrl}/${categoryId}/edit`,
};
