import { Control, FieldValues } from 'react-hook-form';
import RadioGroup from '../../../../../@shared/Fields/radioButtonGroup';
import { MigrationCategory } from '../../../box.models';

interface ISelectMigration {
  options: MigrationCategory[];
  control: Control<FieldValues, object>;
  isInvalid: boolean;
  errorMessage: string | undefined;
}

const MigrationRadioButtonGroup = ({
  options,
  control,
  isInvalid,
  errorMessage,
}: ISelectMigration) => {
  if (options?.length <= 0 || options === undefined) return <p>Cliente não tem migrações</p>;
  return (
    <>
      <RadioGroup
        inline
        id="migrationType"
        name="migrationType"
        control={control}
        label="Selecione o destino do arquivo"
        errorMessage={errorMessage}
        isInvalid={isInvalid}
      >
        {options.map((e) => ({ key: e.categoryId, label: e.name, value: e.categoryId }))}
      </RadioGroup>
    </>
  );
};

export default MigrationRadioButtonGroup;
