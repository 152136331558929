import ErrorDialog from '../../../Dialogs/ErrorDialog';
import { FormProps } from '../../../Forms/RequestForm';
import BoxClassificationForm from '../boxClassificationForm';
import useBoxClassificationEditForm from './boxClassificationEditForm.hook';
import BoxClassificationEditFormLoading from './boxClassificationEditForm.loading';

interface BoxClassificationEditFormProps extends FormProps {
  boxClassificationId: number;
}

const BoxClassificationEditForm = ({
  boxClassificationId,
  ...rest
}: BoxClassificationEditFormProps) => {
  const { control, loading, defaultValueError, handleSubmit, defaultValueErrorMessage, errors } =
    useBoxClassificationEditForm(boxClassificationId);

  if (defaultValueError) return <ErrorDialog show>{defaultValueErrorMessage}</ErrorDialog>;
  if (loading) return <BoxClassificationEditFormLoading />;
  return (
    <BoxClassificationForm
      errors={errors}
      type="edit"
      control={control}
      {...rest}
      onSubmit={handleSubmit}
    />
  );
};

export default BoxClassificationEditForm;
