/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Field from './Field';

type Id = { id: string } | { name: string };
export type FileFieldProps = Omit<React.ComponentProps<typeof Form.Control>, 'value'> & {
  control?: any;
  label: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value: FileList;
  name: string;
  accept?: string;
  onFileChange?: (v: any[]) => void;
} & Id;

function FileListItems(files) {
  const b = new ClipboardEvent('').clipboardData || new DataTransfer();
  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
  return b.files;
}

const FileField = ({
  control,
  label,
  value,
  onChange,
  name,
  id,
  isInvalid,
  helperText,
  accept,
  errorMessage,
  ...props
}: FileFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (value !== undefined) {
      ref.current!.files = value;
    }
  }, [JSON.stringify(value)]);

  const handleChange = (e: any, field?: any) => {
    const dataTransfer = new DataTransfer();
    if (onChange) onChange(e);

    [...e.target.files].map((f) => dataTransfer.items.add(f));
    if (field) field.onChange(dataTransfer.files);
  };

  return (
    <Controller
      defaultValue={value}
      name={name ?? id}
      control={control}
      render={({ field }) => (
        <Field
          id={name ?? id}
          isInvalid={isInvalid ?? false}
          label={label}
          helperText={helperText}
          errorMessage={errorMessage}
        >
          <>
            <Form.Control
              {...props}
              ref={ref}
              type="file"
              accept={accept}
              onChange={(e: any) => handleChange(e, field)}
            />
          </>
        </Field>
      )}
    />
  );
};

export default FileField;
