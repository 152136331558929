import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import ClientCreatePage from './pages/create';
import ClientEditPage from './pages/edit';

const baseUrl = '/clients';
const routes = {
  baseUrl,
  create: `${baseUrl}/create`,
  edit: `${baseUrl}/edit`,
};

const ClientRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <></>
    </PrivateRoute>
    <PrivateRoute path={routes.create} exact>
      <ClientCreatePage />
    </PrivateRoute>
    <PrivateRoute path={routes.edit} exact>
      <ClientEditPage />
    </PrivateRoute>
  </Switch>
);

export default ClientRoutes;
