import { Col, Container } from 'react-bootstrap';
import UploadFileForm from '../../../../../@shared/UploadFileForm';
import SuccessPage from '../../../../../@shared/template/_successPage';
import boxesUrls from '../../../../box/box.urls';
import useScannedDocumentAddForm from './useScannedDocumentAddForm';

const ScannedDocumentAddForm = () => {
  const { control, requester, success, value, boxId, errorMessage } = useScannedDocumentAddForm();

  return (
    <Container className="mt-5">
      <UploadFileForm
        accept="text/csv,application/pdf"
        id="file"
        label="Arquivo digitalizado"
        errors={
          errorMessage ? { exception: { message: errorMessage, type: 'exception' } } : undefined
        }
        control={control}
        value={value}
        requester={requester}
        success={success}
        whapper={<Col sm="12" md="6" />}
        onSuccess={
          <SuccessPage
            text="arquivo enviado com sucesso"
            linkUrl={boxesUrls.detail(boxId)}
            linkText="voltar para listagem de documentos digitalizados"
          />
        }
      />
    </Container>
  );
};

export default ScannedDocumentAddForm;
