import useRequestGetBoxClassificationItem from '../../../../@shared/Requests/useRequestGetBoxClassificationItem';

interface UseBoxClassificationItemAccordionOption {
  reload?: boolean;
  onLoading?: () => void;
  onSuccess?: () => void;
  categoryId?: number;
}

const useBoxClassificationItemAccordion = (
  boxClassificationId: number,
  option?: UseBoxClassificationItemAccordionOption
) => {
  const { successData, useFetchBy, useLoading, loading, backEndErrors, error, useSuccess } =
    useRequestGetBoxClassificationItem(boxClassificationId, { categoryId: option?.categoryId });

  useFetchBy(option?.reload);
  useLoading(() => {
    if (option?.onLoading) option?.onLoading();
  });
  useSuccess(() => {
    if (option?.onSuccess) option?.onSuccess();
  });

  return {
    loading,
    error,
    errorMessage:
      backEndErrors?.message ??
      'Ops! Houve algum problema na conexão. Por favor, tente acessar a pagina novamente ',
    successData,
  };
};

export default useBoxClassificationItemAccordion;
