/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { enableMapSet } from 'immer';
import React from 'react';
import { RecordRowsCheckBoxes } from '../../@shared/DataGrid/useCheckRows';
import { util } from '../../@shared/utils/util';
import { GenericDataGrid, SelectRows } from '../models';

// Enable the MapSet plugin
enableMapSet();

type UpdateRowCheckedProps = {
  selectedRows: Set<React.Key>;
};

const invertCheck = (obj: RecordRowsCheckBoxes): RecordRowsCheckBoxes => {
  const value = Object.entries(obj).map((e) => {
    const invert = e;

    invert[1] = !e[1];

    return invert;
  });

  return Object.fromEntries(value);
};

const RowsSelectorReducer = {
  deselectLastSelectedRows: (state: SelectRows) => {
    const transformValue = Array.from(state.selectedIds);
    const removedLastValue = transformValue.splice(0, transformValue.length - 1);
    const transformedValueFinal = Array.from(removedLastValue);
    state.selectedIds = new Set(transformedValueFinal);
    state.selectedRows = state.selectedRows.splice(0, state.selectedRows.length - 1);
  },
  backLastSelectedRows: (state: SelectRows) => {
    state.selectedIds = new Set(state.lastSelectedRows.map((e) => e[state?.rowsId ?? '']));
    state.selectedRows = state.lastSelectedRows;
  },
  deselectAllRows: (state: SelectRows & GenericDataGrid) => {
    state.currentRows = [];
    state.selectedIds = new Set();
    state.selectedRows = [];
  },
  setRowsId: (state: SelectRows, action: PayloadAction<string | null>) => {
    state.rowsId = action.payload;
  },
  setSelectedRows: (
    state: SelectRows & GenericDataGrid,
    action: PayloadAction<UpdateRowCheckedProps>
  ) => {
    state.lastSelectedRows = state.selectedRows;
    state.selectedIds = action.payload.selectedRows;
    state.selectedRows = state.allRows.filter((e) =>
      Array.from(action.payload.selectedRows).some((s) => util.isEqualDeep(s, e.boxId))
    );
  },
};

export default RowsSelectorReducer;
