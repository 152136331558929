import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import UserAddPage from './pages/add';
import UserEditPage from './pages/edit';
import UserListPage from './pages/users';

const baseUrl = '/users';
export const userPagesUrl = {
  baseUrl,
  add: `${baseUrl}/add`,
  edit: `${baseUrl}/edit`,
};
const routes = {
  baseUrl,
  add: `${baseUrl}/add`,
  edit: `${baseUrl}/edit`,
};

const UsersRoutes = () => (
  <Switch>
    <PrivateRoute path={baseUrl} exact>
      <UserListPage />
    </PrivateRoute>
    <PrivateRoute path={routes.add} exact>
      <UserAddPage />
    </PrivateRoute>
    <PrivateRoute path={routes.edit} exact>
      <UserEditPage />
    </PrivateRoute>
  </Switch>
);

export default UsersRoutes;
