/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import { Button, Col, Container } from 'react-bootstrap';
import NodeCheckBox from '../../../@shared/Fields/TreeView/nodeCheckBox';
import TreeView from '../../../@shared/Fields/TreeView/treeView';
import CheckBoxGroup from '../../../@shared/Fields/checkBoxGroup';
import DatePicker from '../../../@shared/Fields/datePicker';
import { CheckBox, TreeViewNode } from '../../../@shared/Fields/models';
import RadioGroup from '../../../@shared/Fields/radioButtonGroup';
import TextField from '../../../@shared/Fields/textField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../@shared/Forms/RequestForm/index';
import ResetButton from './ResetButton';
import SendEmailCheckBox from './SendEmailCheckBox';

export interface FillUserProps {
  categories: TreeViewNode[];
  roles: CheckBox[];
}

interface UserFormProps extends FormRegisterProps, FormControlProps, Record<string, any> {
  type: 'edit' | 'add';
  fill: FillUserProps;
}

interface UserEditFormProps extends UserFormProps {
  type: 'edit';
  userId: number;
}

const UserForm = ({
  type,
  errors,
  register,
  control,
  userId,
  fill = { categories: [], roles: [] } as FillUserProps,
  ...props
}: UserFormProps | UserEditFormProps) => (
  <Container className="m-0">
    <Col sm="12" md="6" className="py-4">
      <div className="max-vh-100 overflow-auto w-100">
        <RequestForm {...props} errors={errors} className="h-100">
          {type === 'edit' ? <input {...register('id')} type="hidden" /> : <></>}

          <TextField
            id="name"
            label="Nome"
            control={control}
            errorMessage={errors?.name?.message}
            isInvalid={errors?.name !== undefined}
          />
          <TextField
            id="surname"
            label="Sobrenome"
            control={control}
            errorMessage={errors?.surname?.message}
            isInvalid={errors?.surname !== undefined}
          />
          <TextField
            id="cpf"
            label="CPF"
            control={control}
            errorMessage={errors?.cpf?.message}
            isInvalid={errors?.cpf !== undefined}
          />
          <DatePicker
            id="birthDate"
            label="Data de nascimento"
            name="birthDate"
            control={control}
            errorMessage={errors?.birthDate?.message}
            isInvalid={errors?.birthDate !== undefined}
          />
          <TextField
            id="occupation"
            label="Ocupação"
            control={control}
            errorMessage={errors?.occupation?.message}
            isInvalid={errors?.occupation !== undefined}
          />
          <TextField
            id="login"
            label="Login"
            control={control}
            errorMessage={errors?.login?.message}
            isInvalid={errors?.login !== undefined}
          />
          {type === 'edit' ? <ResetButton userId={Number(userId)} /> : <></>}
          <RadioGroup
            inline
            id="isAdmin"
            name="isAdmin"
            control={control}
            label="Perfil tipo administrador"
            errorMessage={errors?.isAdmin?.message}
            isInvalid={errors?.isAdmin !== undefined}
          >
            {[
              { key: 'sim', label: 'Sim', value: true },
              { key: 'sao', label: 'Não', value: false },
            ]}
          </RadioGroup>
          <TextField
            id="email"
            label="Email"
            control={control}
            errorMessage={errors?.email?.message}
            isInvalid={errors?.email !== undefined}
          />
          <RadioGroup
            inline
            id="active"
            name="active"
            control={control}
            label="Perfil esta ativo"
            errorMessage={errors?.active?.message}
            isInvalid={errors?.active !== undefined}
          >
            {[
              { key: 'sim', label: 'Sim', value: true },
              { key: 'sao', label: 'Não', value: false },
            ]}
          </RadioGroup>
          <RadioGroup
            inline
            id="isAdminSubsidiary"
            name="isAdminSubsidiary"
            control={control}
            label="Perfil tipo administrador da filial"
            errorMessage={errors?.isAdminSubsidiary?.message}
            isInvalid={errors?.isAdminSubsidiary !== undefined}
          >
            {[
              { key: 'sim', label: 'Sim', value: true },
              { key: 'sao', label: 'Não', value: false },
            ]}
          </RadioGroup>
          <TreeView
            id="categories"
            name="categories"
            label="Categoria"
            control={control}
            type={NodeCheckBox}
            fill={_.uniqBy(fill.categories, 'value')}
          />
          <CheckBoxGroup
            id="roles"
            name="roles"
            control={control}
            defaultValue={['1']}
            label="Função Despenhada"
            inline={false}
            errorMessage={errors?.roles?.message}
            isInvalid={errors?.roles !== undefined}
          >
            {fill?.roles?.map((e) => ({
              name: e.value,
              key: e.value,
              label: e.label,
              value: Number(e.value),
              disable: e.disable,
              defaultChecked: e.checked,
            }))}
          </CheckBoxGroup>
          {type === 'add' ? <SendEmailCheckBox control={control} /> : <></>}
          <Button type="submit">{type === 'add' ? 'Adicionar' : 'Editar'}</Button>
        </RequestForm>
      </div>
    </Col>
  </Container>
);

export default UserForm;
