import { ColProps, Form, Placeholder, PlaceholderProps } from 'react-bootstrap';

export type ButtonShimmerPlaceholderProps = ColProps & PlaceholderProps & {};

const ButtonShimmerPlaceholder = ({
  xs = 3,
  animation = 'glow',
  as = Form.Label,
  ...props
}: ButtonShimmerPlaceholderProps) => (
  <Placeholder animation={animation}>
    <Placeholder.Button {...{ ...props, xs, as }} />
  </Placeholder>
);

export default ButtonShimmerPlaceholder;
