import { PayloadAction } from '@reduxjs/toolkit';
import { Validator } from '../../@shared/models';

const validatorReducer = {
  setIsInvalid: (state: Validator, action: PayloadAction<Validator>) => {
    state.isInvalid = action.payload.isInvalid;
    state.errorMessage = action.payload.errorMessage;
  },
};

export default validatorReducer;
