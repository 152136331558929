import { Form } from 'react-bootstrap';
import { TreeNodeProps } from './treeView';

const NodeRadioButton = ({
  control,
  field,
  id,
  parent,
  typeValue,
  isInvalid,
  disabled,
  className,
}: TreeNodeProps) => {
  const { label, value, children, disabled: d } = parent;

  const checked = Array.isArray(field.value)
    ? [...field.value].some((e) => String(e) === String(value))
    : String(field.value) === String(value);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (typeValue === 'number') {
      if (Array.isArray(e.target.value)) field?.onChange(e.target.value.map((k) => Number(k)));
      else field?.onChange(Number(e.target.value));
    } else {
      field?.onChange(e.target.value);
    }
  }

  return (
    <ul key={id} className={className}>
      <Form.Check
        type="radio"
        label={label}
        disabled={disabled ?? d}
        value={typeValue ? Number(value) : value}
        checked={checked}
        isInvalid={isInvalid}
        onChange={(e) => handleChange(e)}
      />
      <li>
        {children?.map((c) => (
          <NodeRadioButton
            control={control}
            field={field}
            parent={c}
            disabled={c.disabled}
            key={c.value}
            id={c.value}
            isInvalid={isInvalid}
          />
        ))}
      </li>
    </ul>
  );
};

export default NodeRadioButton;
