import { createContext, useContext } from 'react';

export interface UseErrorContextValueProps {
  error: boolean;
  message: string | null | undefined;
  setError: (name: string, message: string | null | undefined, value: boolean) => void;
}

export const ErrorGroupContext = createContext<UseErrorContextValueProps>({
  error: false,
  message: '',
  setError: () => {},
});

export const useErrorGroup = () => useContext(ErrorGroupContext);
