import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { classificationUrls } from '../../classification.routes.config';
import { CategoryIdAndNamePair } from '../../classification.types';

const ActionButtons = ({
  classificationId,
  categories,
}: {
  classificationId: number;
  categories: CategoryIdAndNamePair[];
}) => (
  <div>
    <ButtonGroup className="w-100 gap-3 d-block">
      <Stack direction="horizontal" className="d-flex justify-content-evenly align-items-center">
        <Link to={{ pathname: classificationUrls.edit(classificationId), state: { categories } }}>
          <Button title="editar classificação" variant="primary" className="mw-25 rounded">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Link>
      </Stack>
    </ButtonGroup>
  </div>
);

export default ActionButtons;
