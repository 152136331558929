import { useCallback, useMemo, useState } from 'react';

export interface IUseInput<J = string> {
  value: J;
  setValue: (v: J) => void;
  useTriggerChangeValue: (f: () => void) => void;
  totalValueChange: number;
  isCurrentValue: (option: J) => boolean;
}

export default <J>(defaultValue?: J | undefined): IUseInput<J> => {
  const [value, setValue] = useState<J>(defaultValue as J);
  const [countChangeValue, setCountChangeValue] = useState<number>(0);

  const changeValue = useCallback(
    (v: J) => {
      setValue(v);
      setCountChangeValue(countChangeValue + 1);
    },
    [value, countChangeValue]
  );

  const useChangeValue = (f: (value: J) => void) => {
    useMemo(() => {
      if (countChangeValue > 0) f(value);
    }, [countChangeValue]);
  };

  const isOptionSelected = (option: J) => value === option;

  return {
    value,
    setValue: changeValue,
    useTriggerChangeValue: useChangeValue,
    totalValueChange: countChangeValue,
    isCurrentValue: isOptionSelected,
  };
};
