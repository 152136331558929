import { Container, Stack } from 'react-bootstrap';
import Layout from '../../layout';
import ClassificationDataGrid from '../features/ClassificationDataGrid/classificationDataGrid';

const ClassificationListPage = () => (
  <Layout>
    <Container>
      <Stack className="py-4 h-100 w-100">
        <ClassificationDataGrid />
      </Stack>
    </Container>
  </Layout>
);
export default ClassificationListPage;
