import { useContext } from 'react';
import categoryEndpoints from '../../../modules/category/category.endpoints';
import { TreeViewNode } from '../../Fields/models';
import { Monitoring } from '../../Monitor/useMonitor';
import useInitialize from '../../hook.initialize';
import { SetStateAction } from '../../models';
import useRequest from '../useRequest';

export interface FetchingParents extends Monitoring {
  parents: TreeViewNode[];
  setParents: SetStateAction<TreeViewNode[]>;
}

export interface FetchParentsProps<T = FetchingParents> {
  context: React.Context<T | null>;
  onSuccess?: (date: TreeViewNode[]) => void;
  reload?: boolean;
  onLoading?: (isLoading: boolean) => void;
}

const FetchParents = <T extends FetchingParents>({
  context: contextProp,
  onSuccess,
  reload,
  onLoading,
}: FetchParentsProps<T>) => {
  const context = useContext(contextProp);
  const request = useRequest<TreeViewNode[]>(categoryEndpoints.browseTreeView());
  const key = 'parents';

  useInitialize(() => {
    if (context) {
      context.monitor.reset(key);
    }
  });

  request.useFetchBy(reload === true);
  request.useFetchBy('init');
  request.useLoading(() => {
    if (context) {
      context.monitor.setLoading(key);
    }
    if (onLoading) {
      onLoading(true);
    }
  });
  request.useSuccess(() => {
    if (context) {
      context.monitor.setSuccess(key);
      context.setParents(request.successData ?? []);
    }
    if (onSuccess) {
      onSuccess(request.successData ?? []);
    }
    if (onLoading) {
      onLoading(false);
    }
  });
  request.useFailure(() => {
    if (context) {
      context.monitor.setFailure(key);
      context.setParents(request.successData ?? []);
    }
    if (onLoading) {
      onLoading(false);
    }
  });

  return <></>;
};

export default FetchParents;
