import { Route } from 'react-router';
import { CustomRouteProps } from './models';

function PublicRoute<TState = any, TParams = any>({ render, children, ...rest }: CustomRouteProps) {
  return (
    <Route
      {...rest}
      render={(e) =>
        render &&
        render({
          params: e.match.params as any,
          state: e.location.state as any,
          refresh: () => e.history.go(0),
          path: e.location.pathname,
          redirect: (path, extra) => {
            if (!extra) {
              return e.history.push(path);
            }

            return e.history.push({
              pathname: path,
              state: extra.state,
              search: extra.params,
            });
          },

          setParams: (params: TParams) => e.history.replace(e.location.pathname, params),
          setState: (state: TState) =>
            e.history.replace(e.location.pathname, {
              ...(e.location.state as any),
              ...state,
            }),
        })
      }
    >
      {children}
    </Route>
  );
}

export default PublicRoute;
