import { ReactNode } from 'react';
import useRoles from './useRolesRedirect';

interface RolesRedirectProps {
  clientUser: ReactNode;
  clientAdministrator: ReactNode;
  superUser: ReactNode;
}

const RolesRedirect = ({ clientAdministrator, clientUser, superUser }: RolesRedirectProps) => {
  const { isClientAdministrator, isSuperUser } = useRoles();

  if (isSuperUser) {
    return <>{superUser}</>;
  }

  if (isClientAdministrator) {
    return <>{clientAdministrator}</>;
  }

  return <>{clientUser}</>;
};

export default RolesRedirect;
