import useBuilderBoxForm, { UseBuilderBoxFormValues } from './useBuilderBoxForm';

export interface BuilderBoxFormProps {
  fetch?: { categories?: boolean; classifications?: boolean; fields?: boolean };
  render: (b: UseBuilderBoxFormValues) => JSX.Element;
  classification: number;
  setClassificationSelected: React.Dispatch<React.SetStateAction<number | null>>;
  category: number;
}

const BuilderBoxForm = ({
  render,
  classification,
  fetch,
  category,
  setClassificationSelected,
}: BuilderBoxFormProps) => {
  const builder = useBuilderBoxForm(
    category,
    classification,
    setClassificationSelected,
    fetch?.categories ?? false,
    fetch?.classifications ?? false,
    fetch?.fields ?? false
  );

  return render(builder);
};

export default BuilderBoxForm;
