import { Col, Row } from 'react-bootstrap';

const SelectedBoxesCounter = ({ total }: { total: number }) => (
  <>
    {total > 0 && (
      <Row className="w-100">
        <Col className="p-0">
          <h5>
            <strong>Total de caixas selecionando: {total}</strong>
          </h5>
        </Col>
      </Row>
    )}
  </>
);

export default SelectedBoxesCounter;
