import Layout from '../../layout';
import EditUserForm from '../features/UserEditForm';

const UserEditPage = () => (
  <Layout>
    <EditUserForm />
  </Layout>
);

export default UserEditPage;
