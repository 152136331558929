import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Exception } from '../../../@shared/models';
import api from '../../../config/api/api';
import boxesEndpoints from '../box.endpoints';
import { BoxDetail } from '../box.models';

const request = (boxId: string) =>
  api
    .request(boxesEndpoints.detail(Number(boxId)))
    .then((e) => e.data)
    .catch((e: AxiosError) => e.response?.data.backEndErrors);

const useQueryBoxDetail = (boxId: string) =>
  useQuery<BoxDetail, Exception>('AAA', () => request(boxId), {
    staleTime: 4000,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export default useQueryBoxDetail;
