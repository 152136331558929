import { useContext } from 'react';
import DatePicker from '../../../../../../../@shared/Fields/datePicker';
import { shouldPrefix } from '../../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../../categoryForm.context';
import { CategoryFormDatesFieldsProps } from '../../types';

const CategoryFormCancellationDateField = ({
  prefix,
  errors,
  control: controlProp,
}: CategoryFormDatesFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProp ?? context?.config?.control;

  return (
    <DatePicker
      disabled
      name={shouldPrefix(prefix, 'cancellationDate')}
      id={shouldPrefix(prefix, 'cancellationDate')}
      label="Data de Cancelamento"
      control={control}
      errorMessage={errors?.cancellationDate?.message}
      isInvalid={errors?.cancellationDate !== undefined}
    />
  );
};

export default CategoryFormCancellationDateField;
