import React, { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { IDialog } from '../Dialogs';

export type ShowAndClose = { show: boolean; close: () => void };

interface DialogButtonProps {
  button?: ((onClick: () => void) => React.ReactNode) | React.ComponentType<ButtonProps>;
  dialog: ((e: ShowAndClose) => React.ReactNode) | React.ComponentType<IDialog>;
}

const DialogButton: React.FC<DialogButtonProps> = ({ button, dialog }) => {
  const [show, setShow] = useState<boolean>(false);

  const handleButtonClick = () => {
    setShow(true);
  };

  const handleDialogClose = () => {
    setShow(false);
  };

  const renderComponent = (
    component:
      | React.ReactNode
      | React.ComponentType<any>
      | ((...args: any[]) => React.ReactNode | React.ComponentType<any>),
    ...args: any[]
  ) => {
    if (React.isValidElement(component)) {
      return React.cloneElement(component as React.ReactElement<any>, ...args);
    }
    if (typeof component === 'function') {
      return (component as Function)(...args);
    }
    return React.createElement(component as React.ComponentType<any>, ...args);
  };

  const renderButton = renderComponent(button, { onClick: handleButtonClick });
  const renderDialog = renderComponent(dialog, { show, onHide: handleDialogClose });

  return (
    <>
      {renderButton}
      {renderDialog}
    </>
  );
};

export default DialogButton;
