import { Switch } from 'react-router';
import { CategorySelectable } from '../../@shared/Accordions/Categories/categoryAccordion.context';
import { TreeViewNode } from '../../@shared/Fields/models';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import { routes } from './classification.routes.config';
import { BoxClassificationAddForm } from './features/ClassificationForm/Add/boxClassificationAddFormSteps.types';
import ClassificationAddPage from './pages/add';
import ClassificationListPage from './pages/classifications';
import ClassificationEditPage from './pages/edit';

const ClassificationRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <ClassificationListPage />
    </PrivateRoute>
    <PrivateRoute<BoxClassificationAddForm>
      path={routes.add.step3}
      exact
      render={(e) => (
        <ClassificationAddPage.Step3
          defaultValue={e.state}
          onNext={(k) => {
            e.redirect(routes.add.step3, {
              state: { step1: e.state?.step1, step2: e.state?.step2, step3: k },
            });
          }}
          onPrevious={(k) => {
            e.redirect(routes.add.step2, {
              state: { step1: e.state?.step1, step2: e.state?.step2, step3: k },
            });
          }}
        />
      )}
    />
    <PrivateRoute<BoxClassificationAddForm>
      path={routes.add.step2}
      exact
      render={(e) => (
        <ClassificationAddPage.Step2
          defaultValue={e.state?.step2}
          onNext={(k) => {
            e.redirect(routes.add.step3, {
              state: { step1: e.state?.step1, step2: k, step3: e.state?.step3 },
            });
          }}
          onPrevious={(k) => {
            e.redirect(routes.add.step1, {
              state: { step1: e.state?.step1, step2: k, step3: e.state?.step3 },
            });
          }}
        />
      )}
    />

    <PrivateRoute<BoxClassificationAddForm>
      path={routes.add.step1}
      exact
      render={(e) => (
        <ClassificationAddPage.Step1
          defaultValue={e.state?.step1}
          onNext={(k) => {
            e.redirect(routes.add.step2, {
              state: { step1: k, step2: e.state?.step2, step3: e.state?.step3 },
            });
          }}
        />
      )}
    />
    <PrivateRoute<{
      categories: CategorySelectable;
      itemsReload: boolean;
      parents: TreeViewNode[];
      categoriesReload: boolean;
      parentsReload: boolean;
      categoriesLoading: boolean;
    }>
      path={routes.edit}
      exact
      render={(e) => (
        <ClassificationEditPage
          categoriesLoading={e.state?.categoriesLoading ?? false}
          categories={e.state?.categories ?? ({} as CategorySelectable)}
          parents={e.state?.parents ?? []}
          boxClassificationId={Number(e.params.boxClassificationId)}
          itemsReload={e.state?.itemsReload ?? false}
          categoriesReload={e.state?.categoriesReload ?? false}
          parentsReload={e.state?.parentsReload ?? false}
          onCategoriesChange={(k) => {
            e.setState({ ...(e.state ?? ({} as any)), categoriesReload: false, categories: k });
          }}
          onParentsChange={(k) => {
            e.setState({ ...(e.state ?? ({} as any)), parents: k, parentsReload: false });
          }}
          onItemChange={() => {
            e.setState({ ...(e.state ?? ({} as any)), itemsReload: false });
          }}
          onAddItem={() => {
            e.setState({
              ...(e.state ?? ({} as any)),
              itemsReload: true,
            });
          }}
          onAddCategory={(t) => {
            if (t.loading) {
              e.setState({
                ...(e.state ?? ({} as any)),
                categoriesLoading: true,
                categoriesReload: false,
                parentsReload: false,
              });
            }
            if (t.error) {
              e.setState({
                ...(e.state ?? ({} as any)),
                categoriesLoading: false,
                categoriesReload: false,
                parentsReload: false,
              });
            }
            if (t.success) {
              e.setState({
                ...(e.state ?? ({} as any)),
                categoriesLoading: false,
                categoriesReload: true,
                parentsReload: true,
              });
            }
          }}
        />
      )}
    />
  </Switch>
);

export default ClassificationRoutes;
