import Layout from '../../layout';
import BoxWizardForm from '../features/BoxWizardForm/boxWizardForm.index';

const BoxCsvAddPage = () => (
  <Layout>
    <BoxWizardForm />
  </Layout>
);

export default BoxCsvAddPage;
