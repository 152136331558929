import { useParams } from 'react-router';
import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import adminEndpoints from '../../admin.endpoint';
import { AdminEditFormState } from '../../admin.types';
import validator from './adminAddForm.validator';

const useAdminEditForm = () => {
  const params = useParams<{ userId: string }>();

  const form = useRequestForm<AdminEditFormState>({
    submitRequest: (e) => adminEndpoints.edit({ ...e }),
    validatorSchema: validator,
    defaultValueRequest: () => adminEndpoints.find(Number(params.userId)),
  });
  return { ...form, userId: Number(params.userId) };
};

export default useAdminEditForm;
