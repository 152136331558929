import { useEffect } from 'react';

const useDefaultValue = <T>(
  defaultValue: T,
  setValue: (value: React.SetStateAction<T>) => void
) => {
  useEffect(() => {
    setValue(defaultValue);
  }, []);
};

export default useDefaultValue;
