import { SelectColumn } from '../../models';

const selectTheadUtil = {
  createColumns(theadId: string, children: SelectColumn[], disabled: boolean) {
    return children?.map((e, i) => ({
      disabled: e.disabled || disabled,
      id: `${theadId}.${i}`,
      key: e.key,
      options: e.options,
    }));
  },
  replaceColumn(index: number, columns: SelectColumn[], data: SelectColumn): SelectColumn[] {
    return (
      columns?.map((e, i) => {
        if (i !== index) return e;
        return data;
      }) ?? []
    );
  },
  replaceValue(index: number, currentValue: string[], data: string) {
    const result = currentValue ? [...currentValue] : [];
    result[index] = data;
    return result;
  },
  disableColumn(index: number, currentColumns: SelectColumn[]) {
    const result = currentColumns ? [...currentColumns] : [];
    result[index] = { ...result[index], disabled: true };
    return result;
  },
  enableColumn(index: number, currentColumns: SelectColumn[]) {
    const result = currentColumns ? [...currentColumns] : [];
    result[index] = { ...result[index], disabled: true };
    return result;
  },
  mergeDefaultValueWithData(id: number, data: string) {
    const emptyResult = Array(id).fill('');
    emptyResult[id] = data;
    return emptyResult;
  },
};

export default selectTheadUtil;
