import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';

const InactiveButtonBase = ({ className, ...props }: ButtonProps) => (
  <Button variant="danger" className={`rounded btn btn-success  ${className}`} {...props}>
    <FontAwesomeIcon icon={faUserSlash} />
  </Button>
);

const InactiveButton = styled(InactiveButtonBase)`
  :hover {
    color: var(--bs-danger);
    background-color: transparent;
    border-color: var(--bs-danger);
  }
`;

export default InactiveButton;
