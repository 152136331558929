import { useEffect, useRef } from 'react';
import { WindowDocumentProps } from './windowDocument';

function gerarUUID(): string {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(d + Math.random() * 16) % 16;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function useWindowDocument({ show, name, blob, onFinish, onClose }: WindowDocumentProps) {
  const externalWindow = useRef<Window | null>(null);

  const createNewWindow = () => {
    function downloadClick(url: string, name2: string) {
      const a = document.createElement('a');
      a.href = url;
      a.download = name2;
      a.click();
    }
    function buildNewWindow(url: string, name2: string) {
      externalWindow.current = window.open(url, '', 'width=+w,height=+h');
      if (externalWindow.current) externalWindow.current.document.title = name2;
    }
    function createObjectURL() {
      if (blob) {
        const name2 = name ?? gerarUUID();

        const file = new File([blob], name2, { type: blob.type });
        const url = window.URL.createObjectURL(file);
        return { url, name: name2 };
      }
      throw new Error('blob invalido');
    }

    if (show && blob) {
      const { url, name: name2 } = createObjectURL();
      if (blob.type === 'application/pdf') {
        buildNewWindow(url, name2);
        if (onFinish) onFinish();
      } else {
        downloadClick(url, name2);
        if (onClose) onClose();
        if (onFinish) onFinish();
      }
    }
  };

  const handleClose = () => {
    if (onClose && externalWindow.current) {
      externalWindow.current.addEventListener(
        'beforeunload',
        (e) => {
          e.preventDefault();
          onClose();
        },
        false
      );
    }
  };

  handleClose();
  useEffect(() => createNewWindow(), [blob]);

  return externalWindow;
}

export default useWindowDocument;
