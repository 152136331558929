import { ButtonGroup, Dropdown } from 'react-bootstrap';
import DataGrid from '../../../../@shared/DataGrid';
import DownloadButton from '../../../../@shared/DownloadButton';
import SuccessToast from '../../../../@shared/Toasts/successToast';
import ErrorPage from '../../../../@shared/template/_errorPage';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import UserDataGridContext from '../../../user/features/UserDataGrid/context';
import userEndpoints from '../../../user/user.endpoints';
import useAdminDataGrid from './useAdminDataGrid';
import { adminPagesUrl } from '../../admin.urls';

const AdminDataGrid = () => {
  const { columns, error, loading, errorMessage, rows, toast, context, filters } =
    useAdminDataGrid();

  if (error) {
    return <ErrorPage text={errorMessage} />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <UserDataGridContext.Provider value={context}>
      <DataGrid>
        <DataGrid.Header className="p-2">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle id="dropdown-autoclose-true" className="w-100">
              Imprimir
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Header>CSV</Dropdown.Header>
              <DownloadButton
                title="baixar csv de administradores"
                contentType="text/csv"
                fileName="administradores"
                as="dropdown-item"
                requestConfig={userEndpoints.downloadCsv(filters)}
              >
                Todos
              </DownloadButton>

              <Dropdown.Divider />
              <Dropdown.Header>PDF</Dropdown.Header>
              <DownloadButton
                title="baixar pdf de administradores"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="administradores"
                requestConfig={userEndpoints.downloadPdf(filters)}
              >
                Todos
              </DownloadButton>
              <DownloadButton
                title="baixar pdf de administradores ativos"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="administradores-ativos"
                requestConfig={userEndpoints.downloadPdf(filters, true)}
              >
                Ativos
              </DownloadButton>
              <DownloadButton
                title="baixar pdf de administradores desativados"
                contentType="application/pdf"
                as="dropdown-item"
                fileName="administrador-desativados"
                requestConfig={userEndpoints.downloadPdf(filters, false)}
              >
                Desativados
              </DownloadButton>
            </Dropdown.Menu>
          </Dropdown>

          <DataGrid.AddButton to={adminPagesUrl.add} title="adicionar administrador" />
        </DataGrid.Header>
        <DataGrid.Main
          rowId="actions"
          rows={rows}
          columns={columns}
          headerRowHeight={100}
          className="datagrid-lg"
        />
      </DataGrid>
      <SuccessToast show={toast.show} text={toast.text} onShow={toast.reset} />
    </UserDataGridContext.Provider>
  );
};

export default AdminDataGrid;
