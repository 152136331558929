import axios from 'axios';
import { util } from '../utils/util';
import { Secret } from './model';

const newBaseUrl = `${window.location.origin}/api/v1`;

const secretService = {
  insert(secret: Secret) {
    if (util.isNullable(secret))
      throw Error('secret cannot be null or undefined, use remove method');
    localStorage.setItem('secret', JSON.stringify(secret));
  },

  remove() {
    localStorage.removeItem('secret');
  },

  get() {
    const secretJson = JSON.parse(localStorage.getItem('secret') || '{}');
    return secretJson as Secret;
  },

  thereIsToken() {
    const secret = localStorage.getItem('secret');
    if (secret != null || secret !== undefined) return true;
    return false;
  },

  async generateNewTokensAsync() {
    const secretOld = JSON.parse(localStorage.getItem('secret') || '{}') as Secret;

    const { refreshToken } = secretOld;

    const axiosInstance = axios.create({
      baseURL: newBaseUrl,
      headers: { Authorization: `Bearer ${refreshToken}` },
    });
    const response = await axiosInstance.get('/token/refresh');
    return response;
  },
};

export default secretService;
