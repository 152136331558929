import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import AdminAddPage from './pages/add';
import AdminListPage from './pages/admins';
import AdminEditPage from './pages/edit';
import { baseUrl } from './admin.urls';

const routes = {
  baseUrl,
  add: `${baseUrl}/add`,
  edit: `${baseUrl}/:userId/edit`,
};

const AdminRoutes = () => (
  <Switch>
    <PrivateRoute path={baseUrl} exact>
      <AdminListPage />
    </PrivateRoute>
    <PrivateRoute path={routes.add} exact>
      <AdminAddPage />
    </PrivateRoute>
    <PrivateRoute path={routes.edit} exact>
      <AdminEditPage />
    </PrivateRoute>
  </Switch>
);

export default AdminRoutes;
