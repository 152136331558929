import useGenericUserDataGrid from '../../../../@shared/useGenericUserDataGrid';
import userEndpoints from '../../user.endpoints';

const useUserDataGrid = () => {
  const genericDatagrid = useGenericUserDataGrid('users/edit', 'user', (filters) =>
    userEndpoints.browse(filters.name, filters.cpf, filters.email)
  );

  return {
    ...genericDatagrid,
  };
};

export default useUserDataGrid;
