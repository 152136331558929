import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import useUploadFile from '../../../../../@shared/useUploadFile';
import scannedDocumentsEndpoints from '../../../scanned-document.endpoints';

const useScannedDocumentAddForm = () => {
  const { boxId } = useParams<{ boxId: string }>();
  const form = useForm();
  const requestFile = useUploadFile(
    (f) => scannedDocumentsEndpoints.upload({ boxId: Number(boxId) }, f),
    true
  );

  return {
    boxId: Number(boxId),
    control: form.control,
    value: requestFile.value,
    requester: requestFile,
    success: requestFile.requestFile.success,
    error: requestFile.requestFile.error,
    loading: requestFile.requestFile.loading,
    errorMessage: requestFile.requestFile.backEndErrors?.message,
    errorException: requestFile.requestFile.backEndErrors,
  };
};

export default useScannedDocumentAddForm;
