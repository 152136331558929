import { Stack } from 'react-bootstrap';
import { Control } from 'react-hook-form';
import TheadSelectPresentation, { Column } from '../SelectThead/theadSelect.presentation';
import TBody from '../tBody.presentation';
import TableField from '../tableField.presentation';

import HasHeaderCheckbox from './hasHeaderCheckbox';
import './index.css';

type Value = { thead: string[]; hasHeader: boolean };

interface CsvSelectableTablePresentationProps {
  control: Control;
  isInvalid: boolean;
  errorMessage: string;
  children: string[][];
  columns: Column[];
  className?: string;
  value?: Value;
  disabled?: { checkBox?: boolean; select?: boolean };
}

const CsvTableFieldPresentation = ({
  control,
  isInvalid,
  columns,
  errorMessage,
  children,
  className = '',
  value,
  disabled,
}: CsvSelectableTablePresentationProps) => (
  <TableField
    label="Colunas do CSV"
    className={`${className}`}
    helperText="seleciones opção referente a coluna"
    isInvalid={isInvalid}
    errorMessage={errorMessage}
    top={
      <Stack direction="vertical" gap={1}>
        <HasHeaderCheckbox
          control={control}
          value={value?.hasHeader}
          disabled={disabled?.checkBox}
        />
      </Stack>
    }
  >
    <TheadSelectPresentation control={control} value={value?.thead}>
      {columns}
    </TheadSelectPresentation>
    <TBody>{children}</TBody>
  </TableField>
);

export default CsvTableFieldPresentation;
