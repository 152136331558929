import { Control } from 'react-hook-form';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import SuccessPage from '../../../../@shared/template/_successPage';
import { adminPagesUrl } from '../../admin.urls';
import AdminForm from './adminForm';
import useAdminEditForm from './useAdminEditForm';

const AdminEditForm = () => {
  const {
    control,
    onSubmit,
    handleSubmit,
    validator,
    submitResponse,
    defaultValueRequest,
    userId,
  } = useAdminEditForm();
  if (submitResponse.loading || defaultValueRequest?.loading) {
    return <LoadingPage />;
  }
  if (submitResponse.success) {
    return (
      <SuccessPage
        text="Administrador editado com sucesso"
        linkText="voltar para tela de listagem administradores"
        linkUrl={adminPagesUrl.baseUrl}
      />
    );
  }
  return (
    <>
      <AdminForm
        userId={userId}
        errors={validator.errors as any}
        onSubmit={handleSubmit(onSubmit)}
        control={control as Control<any, any>}
        register={control.register}
        type="edit"
      />
    </>
  );
};

export default AdminEditForm;
