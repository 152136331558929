import { Button, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import useButton from '../../../../../@shared/Buttons/useButton';
import { boxDataGridActions } from '../../../../../redux/slices/boxDataGridSlice';

import { BoxMovementCode } from '../../../box.models';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const SolicitationButtonGroup = ({
  currentBoxMovement: currentStatus,
  btnDigitizationDisable,
  btnMigrationDisable,
  btnExclusionDisable,
  btnConsultationDisable,
  btnCancellationDisable,
}: {
  currentBoxMovement: BoxMovementCode | null;
  btnDigitizationDisable: boolean;
  btnMigrationDisable: boolean;
  btnExclusionDisable: boolean;
  btnConsultationDisable: boolean;
  btnCancellationDisable: boolean;
}) => {
  const dispatch = useDispatch();
  const btnDigitization = useButton();
  const btnMigration = useButton();
  const btnExclusion = useButton();
  const btnConsultation = useButton();
  const btnCancellation = useButton();

  btnDigitization.useHandleClick(() =>
    dispatch(boxDataGridActions.openSolicitDigitalizationDialog())
  );
  btnMigration.useHandleClick(() => dispatch(boxDataGridActions.openSolicitMigrationDialog()));
  btnExclusion.useHandleClick(() => dispatch(boxDataGridActions.openSolicitExclusionDialog()));
  btnDigitization.useHandleClick(() =>
    dispatch(boxDataGridActions.openSolicitDigitalizationDialog())
  );
  btnConsultation.useHandleClick(() =>
    dispatch(boxDataGridActions.openSolicitConsultationDialog())
  );
  btnCancellation.useHandleClick(() =>
    dispatch(boxDataGridActions.openSolicitCancellationDialog())
  );

  switch (currentStatus) {
    case BoxMovementCode.SolicitConsultation:
    case BoxMovementCode.SolicitDigitalization:
    case BoxMovementCode.SolicitExclusion:
    case BoxMovementCode.SolicitMigration:
      return (
        <div className="h-100 w-100">
          <Col sm={12} className="d-flex p-0">
            <Button
              className="h-100"
              onClick={() => btnCancellation.onClick()}
              disabled={btnCancellationDisable}
            >
              Solicitar Cancelamento
            </Button>
          </Col>
        </div>
      );
    default:
      return (
        <div className="h-100 d-flex align-items-stretch gap-sm-1 gap-md-1 gap-lg-2 flex-sm-row flex-md-column flex-lg-row">
          <div className="flex-1">
            <Button
              className="w-100 h-100"
              onClick={() => btnConsultation.onClick()}
              disabled={btnConsultationDisable}
            >
              Solicitar para consulta
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-100 h-100"
              onClick={() => btnDigitization.onClick()}
              disabled={btnDigitizationDisable}
            >
              Solicitar Digitalização
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-100 h-100"
              onClick={() => btnMigration.onClick()}
              disabled={btnMigrationDisable}
            >
              Solicitar Migração
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-100 h-100"
              onClick={() => btnExclusion.onClick()}
              disabled={btnExclusionDisable}
            >
              Solicitar Exclusão
            </Button>
          </div>
        </div>
      );
  }
};

export default SolicitationButtonGroup;
