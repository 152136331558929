import yup from '../../../../config/yup/customYup';

export default function createFormProfileValidator() {
  const regexUppercase = new RegExp('[A-Z]');
  const regexLowercase = new RegExp('[a-z0-9]');
  const regexEspecialCharacter = new RegExp('[\\W]');

  const messageUppercase = 'o campo deve conter no mínimo um carácter maiúsculo';
  const messageLowerCaseOrNumeric =
    'o campo deve conter no mínimo um carácter minúsculo ou numérico';
  const messageEspecialCharacter = 'o campo deve conter no mínimo um carácter especial';

  return yup
    .object({
      btnChecked: yup.bool().default(false),
      checkBoxChecked: yup
        .bool()
        .default(false)
        .oneOf([yup.ref('btnChecked'), null], 'o campo precisa ser marcado'),
      currentPassword: yup.string().required(),
      newPassword: yup
        .string()
        .required()
        .min(6)
        .matches(regexUppercase, { message: messageUppercase })
        .matches(regexLowercase, {
          message: messageLowerCaseOrNumeric,
        })
        .matches(regexEspecialCharacter, {
          message: messageEspecialCharacter,
        }),
      newPasswordConfirm: yup
        .string()
        .required()
        .min(6)
        .oneOf([yup.ref('newPassword'), null], 'nova senha deve ser igual senha de confirmação')
        .matches(regexUppercase, { message: messageUppercase })
        .matches(regexLowercase, {
          message: messageLowerCaseOrNumeric,
        })
        .matches(regexEspecialCharacter, {
          message: messageEspecialCharacter,
        }),
    })
    .required();
}
