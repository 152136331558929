import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useErrorGroup } from '../../../../@shared/ErrorGroup/useErrorGroup';
import { useLoadingGroup } from '../../../../@shared/LoadingGroup/useLoadingGroup';
import { BoxDetail, BoxParams } from '../../box.models';
import useQueryBoxDetail from '../useQueryBoxDetail';

const defaultValue: BoxDetail = {
  boxClassificationName: '',
  boxDescriptions: [],
  categoryName: '',
  estimatedExclusionDate: '',
  fileNumber: '',
  inclusionDate: '',
  locationDescription: '',
  exclusionDate: '',
};

const key = 'useBoxPanel';

const useBoxPanel = () => {
  const { boxId } = useParams<BoxParams>();
  const requestDetail = useQueryBoxDetail(boxId);
  const { setLoading } = useLoadingGroup();
  const { setError } = useErrorGroup();

  useEffect(() => setLoading(key, requestDetail.isLoading), [requestDetail.isLoading]);
  useEffect(
    () => setError(key, requestDetail.error?.message, requestDetail.isError),
    [requestDetail.isLoading]
  );

  return {
    data: requestDetail.data ?? defaultValue,
    loading: requestDetail.isLoading,
    error: requestDetail.isError,
    errorMessage: requestDetail?.error?.message,
  };
};
export default useBoxPanel;
