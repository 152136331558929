import descriptionValidator from '../../../../../@shared/descriptionValidator';
import useDialog from '../../../../../@shared/Dialogs/useDialog';
import useRequestForm from '../../../../../@shared/Forms/RequestForm/useRequestForm';
import LoadingModal from '../../../../auth/features/ForgotDialogues/LoadingModal';
import boxesEndpoints from '../../../box.endpoints';
import { BoxMovementCode } from '../../../box.models';
import SolicitationFormDialogPresentation from '../solicitationDialog';
import useBoxDataGridSlice from '../useBoxDataGrid';

const SolicitDigitalizationFormDialog = () => {
  const boxDataGridRedux = useBoxDataGridSlice();

  const { showSolicitDigitalizationFormDialog, selectedRows, isInvalid } =
    boxDataGridRedux.useSelector();

  const form = useRequestForm({
    validatorSchema: descriptionValidator,
    submitRequest: (obj) =>
      boxesEndpoints.solicitMovement(
        obj.description,
        selectedRows,
        BoxMovementCode.SolicitDigitalization
      ),
  });
  const dialog = useDialog({
    handleOpen: () => boxDataGridRedux.openSolicitDigitalizationDialog(),
    handleClose: () => boxDataGridRedux.closeSolicitDigitalizationDialog(),
  });

  form.useHandleSuccess(() => {
    boxDataGridRedux.setAnySubmit(form.submitResponse.success);
    form.submitResponse.reset();
  });
  dialog.useOpen(!isInvalid && showSolicitDigitalizationFormDialog, [
    showSolicitDigitalizationFormDialog,
    isInvalid,
  ]);
  dialog.useClose(form.submitResponse.success);
  dialog.useClose(isInvalid);

  if (form.submitResponse.loading) return <LoadingModal />;
  return (
    <SolicitationFormDialogPresentation
      control={form.control}
      descriptions={selectedRows}
      title="Solicitação para digitalização"
      onHide={() => dialog.close()}
      show={dialog.show}
      size="lg"
      method="post"
      onSubmit={form.handleSubmit(form.onSubmit)}
      validated={form.validator.validated}
      errors={form.validator.errors}
    />
  );
};

export default SolicitDigitalizationFormDialog;
