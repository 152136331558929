import yup from '../config/yup/customYup';

export default yup
  .object({
    newPassword: yup.string().required(),
    newPasswordConfirm: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null], 'Senha de confirmação não é igual a nova senha'),
  })
  .required();
