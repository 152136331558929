import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { WizardValues } from 'react-use-wizard';
import utils from '../stepsForm.utils';

const useUpdateStepStatusClickNext = (
  context: StepsFormContextValues,
  submitValue: FieldValues,
  errors: any,
  submitStatus: string,
  wizard: WizardValues,
  btnClick: number
) => {
  useEffect(() => {
    if (context?.currentStep) {
      if (submitStatus === 'successful') {
        const v = utils.updateStepValidated(context?.currentStep, context);
        utils.updateStepValue(context?.currentStep, submitValue, context);
        wizard.nextStep();
        context.setCurrentStep(context?.currentStep + 1);

        if (v.every((e) => e === 'validated')) utils.successfulAllForms(context);
      } else if (submitStatus === 'error') {
        const { isLastStep } = wizard;
        const v = utils.updateStepInvalided(context?.currentStep, context);
        utils.updateStepError(context?.currentStep, errors, context);
        if (!v.every((e) => e === 'validated')) {
          utils.notSuccessfulAllForms(context);
        }
        if (isLastStep) {
          context.setSubmit(true);
        }
      }
    }
  }, [btnClick, submitStatus]);
};

export default useUpdateStepStatusClickNext;
