import useRequest from '../../../../@shared/Requests/useRequest';
import VerifyLoading from '../../../../@shared/Verifiers/verifyLoading';
import useBoxDataGridSlice from './useBoxDataGrid';

import Template from '../../../../@shared/template/template';
import boxesEndpoints from '../../box.endpoints';
import Bundled from './Bundled';
import './index.css';

// eslint-disable-next-line import/order
import { util } from '../../../../@shared/utils/util';

import useDownloadButton from '../../../../@shared/DownloadButton/useDownloadButton';
import { FilterStatus, MigrationCategory } from '../../box.models';
import useFaceSheetLabelDownload from './FilterForm/faceSheetLabelDownload.hook';
import SolicitCancellationFormDialog from './SolicitCancellationFormDialog';
import SolicitConsultationFormDialog from './SolicitConsultationFormDialog';
import SolicitDigitalizationFormDialog from './SolicitDigitalizationFormDialog';
import SolicitExclusionFormDialog from './SolicitExclusionFormDialog';
import SolicitMigrationFormDialog from './SolicitMigrationFormDialog';

const useFetchListBoxes = () => {
  const boxServiceList = (
    currentPage: number,
    statusFilter: FilterStatus,
    descriptionFilter: string,
    locationFilter?: string | null,
    initialDate?: string | null,
    finalDate?: string | null
  ) =>
    boxesEndpoints.list({
      currentPage,
      boxMovementDescription: statusFilter,
      description: descriptionFilter,
      sizePage: 15,
      finalDate: finalDate ? util.dateIsoFormat(finalDate) : null,
      initialDate: initialDate ? util.dateIsoFormat(initialDate) : null,
      location: locationFilter,
    });

  const boxDataGridRedux = useBoxDataGridSlice();
  const { page, statusFilter, descriptionFilter, initialDate, finalDate, locationFilter } =
    boxDataGridRedux.useSelector();
  const request = useRequest(
    boxServiceList(page, statusFilter, descriptionFilter, locationFilter, initialDate, finalDate)
  );

  return { request };
};

const useFetchListMigrations = () => {
  const requestMigrationTypes = useRequest<MigrationCategory[]>(
    boxesEndpoints.listMigrationCategories()
  );
  return requestMigrationTypes;
};

const BoxDataGrid = () => {
  const boxDataGridRedux = useBoxDataGridSlice();
  const {
    isSelectedValueInStatusFilter,
    anySubmit,
    selectedRows,
    isInvalid,
    statusFilter,
    descriptionFilter,
    finalDate,
    initialDate,
    selectedIds,
    locationFilter,
    faceSheetLabelPageSize,
  } = boxDataGridRedux.useSelector();

  const { request: requestRows } = useFetchListBoxes();
  const requestMigrationOptions = useFetchListMigrations();
  const requestDownloadCsv = useDownloadButton(
    'text/csv',
    'caixas',
    boxesEndpoints.listByCsv({
      boxMovementDescription: statusFilter,
      description: descriptionFilter,
      currentPage: null,
      sizePage: null,
      finalDate: util.dateIsoFormat(finalDate),
      initialDate: util.dateIsoFormat(initialDate),
      location: locationFilter,
    })
  );
  const faceSheetLabelRequest = useFaceSheetLabelDownload(selectedIds, faceSheetLabelPageSize);

  boxDataGridRedux.useInitialize(
    requestRows,
    requestMigrationOptions,
    requestDownloadCsv,
    faceSheetLabelRequest
  );

  boxDataGridRedux.useDestroy(() => {
    requestRows.abortSignal.abort();
  });

  boxDataGridRedux.useIsInvalid(Array.from(selectedRows)?.pop()?.status ?? 0, [selectedIds]);
  boxDataGridRedux.useSetMergeRowsBySuccess(requestRows);
  boxDataGridRedux.useSetRowsByNewRows(selectedRows, isSelectedValueInStatusFilter);
  boxDataGridRedux.useSetMigrationOptions(requestMigrationOptions.successData);

  boxDataGridRedux.useDeselectAllRows(anySubmit);
  boxDataGridRedux.useBackLastSelectedRows(isInvalid, [isInvalid]);
  boxDataGridRedux.useResetByAnySubmit();
  boxDataGridRedux.useCloseAllDialogs(isInvalid);

  const requests = [
    requestMigrationOptions,
    requestRows,
    faceSheetLabelRequest,
    requestDownloadCsv,
  ] as any[];

  const requestFailure = requests.find((r) => r?.error);

  if (requestFailure?.error) {
    const message = requestFailure?.backEndErrors?.message ?? requestFailure?.errorMessage;

    return (
      <Template.Pages.ErrorPage text={message ?? 'Ocorreu um erro'} linkText="Voltar para Home" />
    );
  }

  if (
    requestMigrationOptions.loading ||
    requestRows.loading ||
    faceSheetLabelRequest.loading ||
    requestDownloadCsv.loading
  ) {
    return <VerifyLoading loadings={[true]} />;
  }

  return (
    <>
      <Bundled />
      <SolicitConsultationFormDialog />
      <SolicitDigitalizationFormDialog />
      <SolicitExclusionFormDialog />
      <SolicitCancellationFormDialog />
      <SolicitMigrationFormDialog />
    </>
  );
};

export default BoxDataGrid;
