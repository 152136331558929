import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from 'react-bootstrap';

const AddButton = ({ ...props }: ButtonProps) => (
  <Button {...props}>
    <FontAwesomeIcon icon={faPlus} />
  </Button>
);

export default AddButton;
