import { Switch } from 'react-router';
import PublicRoute from '../../@shared/Routes/PublicRoute';
import ResetPassword2Fa from './pages/2fa';
import ResetPasswordEditPage from './pages/define-new-password';
import ResetPasswordVerifyTokenPage from './pages/reset-password';

const baseUrl = '/reset-password';
const baseUrl2fa = '/reset-password/2fa';

export const resetPasswordPagesRoutes = {
  baseUrlToken: (token?: string) => (token ? `${baseUrl}/${token}` : `${baseUrl}`),
  twoFactorAuthentication: baseUrl2fa,
  defineNewPassword: `${baseUrl2fa}/define-new-password`,
};
const routes = {
  baseUrlToken: `${baseUrl}/:token?`,
  twoFactorAuthentication: baseUrl2fa,
  defineNewPassword: `${baseUrl2fa}/define-new-password`,
};

const ResetPasswordRoutes = () => (
  <Switch>
    <PublicRoute path={routes.twoFactorAuthentication} exact>
      <ResetPassword2Fa />
    </PublicRoute>
    <PublicRoute path={routes.defineNewPassword} exact>
      <ResetPasswordEditPage />
    </PublicRoute>
    <PublicRoute path={routes.baseUrlToken} exact>
      <ResetPasswordVerifyTokenPage />
    </PublicRoute>
  </Switch>
);

export default ResetPasswordRoutes;
