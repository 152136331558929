import { createContext, useContext } from 'react';

export interface UseLoadingContextStateProps {
  loading: boolean;
}

export interface UseLoadingContextValueProps {
  loading: boolean;
  setLoading: (name: string, value: boolean) => void;
}

export const LoadingContext = createContext<UseLoadingContextValueProps>({
  loading: false,
  setLoading: () => {},
});

export const useLoadingGroup = () => useContext(LoadingContext);
