import { useContext, useEffect, useState } from 'react';
import AddAccordionContext, { AddAccordionActions } from './addAccordion.context';

interface AddAccordionBodyProps<T = any> {
  children?: (
    value: T,
    actions: AddAccordionActions<T>,
    index: number
  ) => React.ReactNode | React.ReactNode;
}

const AddAccordionBody = <T = any,>({ children }: AddAccordionBodyProps<T>) => {
  const context = useContext(AddAccordionContext);
  const [value, setValue] = useState<T[]>([]);

  useEffect(() => {
    setValue(context?.value ?? []);
  }, [context?.value]);

  if (typeof children === 'function' && context) {
    return (
      <>
        {value.map((e, i) =>
          children(
            e,
            {
              add: context?.add,
              remove: context?.remove,
              set: context?.set,
              show: context?.show,
              open: context?.open,
              close: context?.close,
            },
            i
          )
        )}
      </>
    );
  }
  return <>{children}</>;
};

export default AddAccordionBody;
