import { useSelector } from 'react-redux';
import Roles from '../../../../@shared/Auth/roles';
import { RootState } from '../../../../redux/application';

const useRoles = () => {
  const roles = useSelector((state: RootState) => state.auth.userInfo?.roles);

  return {
    roles: roles ?? [],
    isClientUser: roles?.includes(Roles.CLIENT_USER),
    isClientAdministrator: roles?.includes(Roles.CLIENT_ADMINISTRATOR),
    isSuperUser: roles?.includes(Roles.SUPER_USER),
  };
};

export default useRoles;
