import AccordingItemShimmerPlaceholder from './accordingItemShimmerPlaceholder';
import AccordingShimmerPlaceholder from './accordingShimmerPlaceholder';
import ButtonShimmerPlaceholder from './buttonShimmerPlaceholder';
import FormGroupShimmerPlaceholder from './formGroupShimmerPlaceholder';
import InputShimmerPlaceholder from './inputShimmerPlaceholder';
import LabelShimmerPlaceholder from './labelShimmerPlaceholder';
import RadioShimmerPlaceholder from './radioShimmerPlaceholder';
import ShimmerPlaceholdersDataGrid from './shimmerPlaceholdersDataGrid';

const ShimmerPlaceholder = {
  FormGroup: FormGroupShimmerPlaceholder,
  Input: InputShimmerPlaceholder,
  Label: LabelShimmerPlaceholder,
  Button: ButtonShimmerPlaceholder,
  According: AccordingShimmerPlaceholder,
  AccordingItem: AccordingItemShimmerPlaceholder,
  RadioButton: RadioShimmerPlaceholder,
  DataGrid: ShimmerPlaceholdersDataGrid,
};

export default ShimmerPlaceholder;
