import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';

import { routes } from './complex.routes.config';
import Layout from '../layout';

const ComplexRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.edit} exact>
      <Layout>
        <h1>Pagina não foi criada ainda</h1>
      </Layout>
    </PrivateRoute>
  </Switch>
);

export default ComplexRoutes;
