import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import SelectField from '../../../../@shared/Fields/selectField';
import { Category, CategoryAddFormState } from '../CategoryForm/types';

export type CategoryChooserSuccessData = CategoryAddFormState & {
  categoryId?: number;
  readonly: boolean;
};

const CategorySelect = ({
  options = [],
  defaultValue,
  onChange,
}: {
  options: CategoryChooserSuccessData[] | Category[];
  defaultValue?: CategoryChooserSuccessData[];
  onChange?: (e: MultiValue<CategoryChooserSuccessData>) => void;
}) => {
  const [value, setValue] = useState<MultiValue<CategoryChooserSuccessData>>(
    defaultValue
      ?.filter((e) => e.categoryId)
      ?.map((e) => ({ ...e, label: e?.name, value: e?.categoryId })) || []
  );

  const op = options.map((e) => ({ ...e, label: e.name, value: e.categoryId }));

  useEffect(() => {
    if (onChange) onChange(value);
  }, [JSON.stringify(value)]);

  return (
    <SelectField<Category>
      name="categories"
      getOptionLabel={(e) => e.name}
      label="Categorias à selecionar"
      isMulti
      placeholder="Selecione"
      value={value}
      onChange={(e) => setValue(e as any)}
      options={op as any}
    />
  );
};

export default CategorySelect;
