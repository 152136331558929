import { AxiosError } from 'axios';
import { Redirect, Route, useHistory } from 'react-router';
import useAuthSlice from '../Auth/useAuthSlice';
import ConfirmationDialog from '../Dialogs/confirmationDialog';
import { CustomRouteProps } from './models';

function PrivateRoute<TState = any, TParams = any>({
  children,
  render,
  ...rest
}: CustomRouteProps<TState, TParams>) {
  const history = useHistory();
  const redux = useAuthSlice();

  try {
    const { wasLogged, refreshFailed, refreshSuccess } = redux.useState();

    if (!wasLogged) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { showSessionDialog: refreshFailed },
          }}
        />
      );
    }

    if (render) {
      return (
        <Route
          {...rest}
          render={(e) => (
            <>
              <ConfirmationDialog
                show={refreshSuccess}
                title="Ops.."
                handleClose={() => history.go(0)}
                body={
                  <p key="ConfirmationRefreshTokenDialog.body">
                    Sua sessão precisou ser renovada devido à inatividade. Caso estivesse
                    registrando informações, é possível que os dados tenham sido perdidos. Por
                    favor, inicie o processo de cadastro novamente.
                  </p>
                }
              />
              {render({
                params: e.match.params as any,
                state: history.location.state as any,
                refresh: () => history.go(0),
                path: history.location.pathname,
                redirect: (path, extra) => {
                  if (!extra) {
                    return history.push(path);
                  }

                  return history.push({
                    pathname: path,
                    state: extra.state,
                    search: extra.params,
                  });
                },
                setParams: (params: TParams) =>
                  history.replace(
                    `${e.location.pathname}?${new URLSearchParams(params ?? {}).toString()}`
                  ),
                setState: (state: TState) =>
                  history.replace(e.location.pathname, {
                    ...(e.location.state as any),
                    ...state,
                  }),
              })}
            </>
          )}
        />
      );
    }

    return (
      <Route {...rest}>
        <>
          <ConfirmationDialog
            show={refreshSuccess}
            title="Ops.."
            handleClose={() => history.go(0)}
            body={
              <p key="ConfirmationRefreshTokenDialog.body">
                Sua sessão precisou ser renovada devido à inatividade. Caso estivesse registrando
                informações, é possível que os dados tenham sido perdidos. Por favor, inicie o
                processo de cadastro novamente.
              </p>
            }
          />
          {children}
        </>
      </Route>
    );
  } catch (error) {
    if (error as AxiosError) {
      return <Redirect to={{ pathname: '/login', state: { showSessionDialog: true } }} />;
    }
    if (render) {
      return <Route {...rest} />;
    }
    return <Route {...rest}>{children}</Route>;
  }
}

export default PrivateRoute;
