import FieldPanel from './fieldPanel';

interface FieldCollectionPanelProps {
  children: { label: string; value: string; key: string }[];
}

const FieldCollectionPanel = ({ children }: FieldCollectionPanelProps) => (
  <>
    {children?.map((e) => (
      <FieldPanel key={e.key} label={e.label}>
        {e.value}
      </FieldPanel>
    ))}
  </>
);

export default FieldCollectionPanel;
