import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import useRequest from '../../../../@shared/Requests/useRequest';
import categoryEndpoints from '../../category.endpoints';
import { CategoryEditFormState } from './types';

export interface UseUseUserCategoryEditFormProps {}

const useCategoryEditForm = () => {
  const { useFetchBy, setRequestConfig, loading, requestConfig, success, error, backEndErrors } =
    useRequest();

  const handleSubmit = (e: CategoryEditFormState) => {
    if (e) {
      setRequestConfig(
        categoryEndpoints.edit({ categoryId: e.categoryId }, {
          ...e,
          users: Array.from(e.users),
        } as any)
      );
    }
  };

  useFetchBy(requestConfig);

  return {
    handleSubmit,
    loading,
    success,
    error,
    errorData: backEndErrors?.message
      ? {
          exception: { message: backEndErrors?.message ?? '', type: 'exception' },
        }
      : ({} as Record<string, FormError>),
  };
};

export default useCategoryEditForm;
