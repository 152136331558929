import { useState } from 'react';
import { useParams } from 'react-router';
import { useErrorGroup } from '../../../../@shared/ErrorGroup/useErrorGroup';
import { useLoadingGroup } from '../../../../@shared/LoadingGroup/useLoadingGroup';
import useRequest from '../../../../@shared/Requests/useRequest';
import boxesEndpoints from '../../box.endpoints';
import { BoxHistory, BoxParams } from '../../box.models';
import boxMovementHistoryColumns from './boxMovementHistoryColumns';

const key = 'useBoxMovementHistoryDataGrid';

const useBoxMovementHistoryDataGrid = () => {
  const { boxId } = useParams<BoxParams>();
  const [data, setData] = useState<BoxHistory[]>();
  const requestHistory = useRequest(boxesEndpoints.history(Number(boxId)));
  const { setLoading } = useLoadingGroup();
  const { setError } = useErrorGroup();

  requestHistory.useFetchBy('init');
  requestHistory.useSuccess(() => setData(requestHistory.successData));
  requestHistory.useChange(() => setLoading(key, requestHistory.loading));
  requestHistory.useChange(() =>
    setError(key, requestHistory.backEndErrors?.message, requestHistory.error)
  );
  return {
    rows: data?.map((e) => ({ ...e, id: Math.random() })) ?? [],
    columns: boxMovementHistoryColumns(),
    loading: requestHistory.loading,
    error: requestHistory.error,
    errorMessage: requestHistory.backEndErrors?.message,
  };
};
export default useBoxMovementHistoryDataGrid;
