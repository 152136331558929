import LoadingPage from '../../../../../@shared/template/_loadingPage';
import CsvBoxAddFormPresentation from './CsvBoxAddForm/csvBoxAddForm.presentation';
import useStep1 from './step1.hook';
import Step1Presentation from './step1.presentation';

const Step1 = () => {
  const {
    handleClickNextStep,
    handleClickPreviousStep,
    handleSubmit,
    loading,
    rows,
    control,
    options,
    register,
    classificationDisable,
    errors,
    boxTypeDisable,
    csvTableFieldDisable,
  } = useStep1();

  if (loading) return <LoadingPage />;
  return (
    <Step1Presentation
      middle={
        <CsvBoxAddFormPresentation
          csvTableFieldDisable={csvTableFieldDisable}
          rows={rows}
          errors={errors as any}
          classificationDisable={classificationDisable}
          boxTypeDisable={boxTypeDisable}
          register={register}
          control={control}
          options={options}
          onSubmit={handleSubmit}
        />
      }
      onClickNextStep={handleClickNextStep}
      onClickPreviousStep={handleClickPreviousStep}
    />
  );
};

export default Step1;
