import { Row } from 'react-bootstrap';
import Dialog, { IDialog } from '.';
import { FormPropsOmitChildren } from '../Forms/RequestForm';
import { JsxElements } from '../models';

type Omit2 = Omit<FormPropsOmitChildren, 'title'>;

type FormDialogProps = IDialog &
  Omit2 & {
    bodyClassName?: string;
    children: JsxElements;
    autoClose?: boolean;
    onConfirm?: () => void;
  };

const FormDialog = ({ children, onConfirm, autoClose = false, ...props }: FormDialogProps) => {
  const {
    onHide,
    show,
    title,
    animation,
    backdropClassName,
    bsPrefix,
    centered,
    contentClassName,
    dialogAs,
    dialogClassName,
    fullscreen,
    scrollable,
    size,
    bodyClassName,
    ...formProps
  } = props;

  const dialogProps = {
    onHide,
    show,
    title,
    animation,
    backdropClassName,
    bsPrefix,
    centered,
    contentClassName,
    dialogAs,
    dialogClassName,
    fullscreen,
    scrollable,
    size,
  };

  return (
    <Dialog {...dialogProps}>
      <Dialog.Header />
      <Dialog.Form {...formProps}>
        <Dialog.Body>
          <Row className="p-2">
            <Dialog.ErrorAlert />
          </Row>
          <Row>{children}</Row>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CloseButton />
          <Dialog.ConfirmButton
            defaultBehavior={autoClose}
            onClick={() => onConfirm && onConfirm()}
          />
        </Dialog.Footer>
      </Dialog.Form>
    </Dialog>
  );
};

export default FormDialog;
