import Layout from '../../layout';
import ChooseClientForm from '../features/ChooseClientForm';

const ChooseClientPage = () => (
  <Layout showLoggedComponents={false}>
    <ChooseClientForm />
  </Layout>
);

export default ChooseClientPage;
