import { Button, ButtonGroup, Stack, Table } from 'react-bootstrap';
import ErrorFormAlert from '../../../../@shared/Alerts/errorFormAlert';
import StepsForm from '../../../../@shared/MultiStepForm/StepsForm';
import SuccessPage from '../../../../@shared/template/_successPage';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import useBoxWizardForm from './boxWizardForm.hook';

const BoxWizardForm = () => {
  const {
    handleSubmit,
    errorMessage,
    error,
    loading,
    success,
    handleNextClick,
    handlePrevClick,
    visibleData,
    buildNumberOfData,
    disabled,
  } = useBoxWizardForm();

  return (
    <StepsForm
      loading={loading}
      success={success}
      error={error}
      errorMessage={errorMessage}
      onSubmit={(value) => handleSubmit(value)}
      onError={(errors) => <ErrorFormAlert errors={errors} className="mt-4" />}
      onSuccess={
        <SuccessPage
          text={
            <>
              <p>Registrado com sucesso</p>
              <Table
                variant="success"
                className="border-success border-bottom-0 border-end-0 border-start-0"
                responsive
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Código de barras</th>
                    <th>Número</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleData?.map((box, i) => (
                    <tr key={box?.boxId}>
                      <td>{buildNumberOfData(i)}</td>
                      <td>{box?.boxName}</td>
                      <td>{box?.barcodeNumber}</td>
                      <td>{box?.boxNumber}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="p-0 border-0">
                    <td className="p-0 border-0" colSpan={4}>
                      <Stack>
                        <ButtonGroup>
                          <Button
                            variant="success"
                            className="rounded-top-0"
                            onClick={handlePrevClick}
                            disabled={disabled?.prev}
                          >
                            &laquo; Anterior
                          </Button>
                          <Button
                            className="rounded-top-0"
                            variant="success"
                            onClick={handleNextClick}
                            disabled={disabled?.next}
                          >
                            Proxima &raquo;
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </>
          }
          linkUrl="/consult"
          linkText="voltar para pagina de consulta"
        />
      }
    >
      <Step0 />
      <Step1 />
      <Step2 />
    </StepsForm>
  );
};

export default BoxWizardForm;
