const EnumerableFormat = {
  OPTION: 'option',
  TEXTBOX: 'textbox',
  DEFAULT: 'default',
  TABLE: 'table',
  CHECKBOX: 'checkbox',
  ROWS: 'rows',
};

export default EnumerableFormat;
