import { useEffect, useMemo, useState } from 'react';
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  useController,
  useForm,
} from 'react-hook-form';

import useDisable from '../../../../../@shared/useDisable';
import { PageSize } from '../../../../../constants/constants';
import { BoxRow } from '../../../box.models';
import useBoxDataGridSlice from '../useBoxDataGrid';
import useChosePrintSizeModal from './ChosePrintSizeModal/chosePrintSizeModal.hook';
import useFilterBySelected from './filterBySelected.hooks';
import submitType from './filterform.constants';
import useFilterFormValidator from './filterform.validator.hook';

const useFilterForm = () => {
  const boxDataGridRedux = useBoxDataGridSlice();
  const { descriptionFilter, statusFilter, initialDate, finalDate, locationFilter } =
    boxDataGridRedux.useSelector();
  const { resolver, useSubmitSuccessful, setSchema } = useFilterFormValidator();
  const form = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: {
      description: descriptionFilter,
      status: statusFilter,
      initialDate,
      finalDate,
      location: locationFilter,
    },
  });
  const [currentBoxMovement, setCurrentBoxMovement] = useState<number | null>(null);
  const chosePrintSizeModal = useChosePrintSizeModal();

  const inputDescription = useController({ name: 'description', control: form.control });
  const selectStatus = useController({ name: 'status', control: form.control });
  const inputInitialDate = useController({ name: 'initialDate', control: form.control });
  const inputLocation = useController({ name: 'location', control: form.control });
  const inputFinalDate = useController({ name: 'finalDate', control: form.control });
  const buttonSubmit = useController({ name: 'submitType', control: form.control });
  const selectRowsController = useController({ name: 'selectedRows', control: form.control });

  const { selectedRows, migrationOptions } = boxDataGridRedux.useSelector();
  const disableAll = useDisable((selectedRows.length ?? 0) <= 0, true);

  const onSubmit = (e: FieldValues) => {
    boxDataGridRedux.addFilter(
      false,
      e.description,
      e.location,
      e.status,
      e.initialDate,
      e.finalDate
    );
  };
  const onInvalid = (e?: FieldErrorsImpl<DeepRequired<FieldValues>>) => {
    if (e) boxDataGridRedux.invalid();
  };
  const handleSubmit = (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    form.handleSubmit(onSubmit, onInvalid)(e);
  };

  useEffect(() => {
    selectRowsController.field.onChange(selectedRows);
  }, [selectedRows]);
  useFilterBySelected(selectStatus.field.value, boxDataGridRedux);
  useMemo(() => setCurrentBoxMovement((selectedRows[0] as BoxRow)?.status ?? null), [selectedRows]);
  useSubmitSuccessful(form, () => {
    if (buttonSubmit.field.value === submitType.CONSULT) {
      boxDataGridRedux.valid();
      boxDataGridRedux.clickedSearch();
    } else if (buttonSubmit.field.value === submitType.EXPORT_FACE_SHEET_LABEL_A4) {
      boxDataGridRedux.setFaceSheetLabelSizePage(PageSize.A4);
      boxDataGridRedux.valid();
      boxDataGridRedux.downloadingFaceSheetLabelPdf();
    } else if (buttonSubmit.field.value === submitType.EXPORT_FACE_SHEET_LABEL_A5) {
      boxDataGridRedux.valid();
      boxDataGridRedux.downloadingFaceSheetLabelPdf();
    } else {
      boxDataGridRedux.valid();
      boxDataGridRedux.downloadingCsv();
    }
  });

  return {
    chosePrintSizeModal,
    buttonSubmit: {
      ...buttonSubmit,
      field: {
        name: buttonSubmit.field.name,
        ref: buttonSubmit.field.ref,
        onBlur: buttonSubmit.field.onBlur,
        value: buttonSubmit.field.value,
        onChange: (e: string) => {
          buttonSubmit.field.onChange(e);

          if (e) {
            if (e === submitType.EXPORT_FACE_SHEET_LABEL_A4) {
              boxDataGridRedux.setFaceSheetLabelSizePage(PageSize.A4);
            }

            if (e === submitType.EXPORT_FACE_SHEET_LABEL_A5) {
              boxDataGridRedux.setFaceSheetLabelSizePage(PageSize.A5);
            }

            setSchema(e);
            form.trigger().then(() => {
              handleSubmit();
            });
          }
        },
      },
    },
    inputLocation,
    handleSubmit,
    errors: form.formState.errors,
    currentBoxMovement,
    btnCancellationDisable: disableAll,
    btnConsultationDisable: disableAll,
    btnDigitizationDisable: disableAll,
    btnExclusionDisable: disableAll,
    btnMigrationDisable: disableAll && migrationOptions.length === 0,
    inputInitialDate,
    inputFinalDate,
    selectStatus,
    inputDescription,
    total: selectedRows.length ?? 0,
  };
};

export default useFilterForm;
