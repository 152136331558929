import { Redirect } from 'react-router';
import VerifyResetToken from '../../../@shared/Verifiers/verifyResetToken';
import Layout from '../../layout';

const ResetPasswordVerifyTokenPage = () => (
  <Layout showLoggedComponents={false}>
    <VerifyResetToken
      tokenType="reset-password"
      onSuccess={<Redirect to="/reset-password/2fa" />}
    />
  </Layout>
);

export default ResetPasswordVerifyTokenPage;
