import { createContext } from 'react';
import { Category } from '../../../modules/category/features/CategoryForm/types';
import { TreeViewNode } from '../../Fields/models';
import { Monitoring } from '../../Monitor/useMonitor';
import { FetchingParents } from '../../Requests/components/fetchParents';
import { FetchingPossibleUsers } from '../../Requests/components/fetchPossibleUsers';
import { SetStateAction } from '../../models';

export interface CategorySelectable {
  all: Category[];
  selected: Category[];
  unselected: Category[];
}

export interface CategoryAccordionContextProps
  extends Monitoring,
    FetchingPossibleUsers,
    FetchingParents {
  categories: CategorySelectable | null;
  boxClassificationId: number | null;
  setCategories: React.Dispatch<React.SetStateAction<CategorySelectable | null>>;
  setBoxClassificationId: React.Dispatch<React.SetStateAction<number | null>>;
  parents: TreeViewNode[];
  setParents: SetStateAction<TreeViewNode[]>;
}

export const CategoryAccordionContext = createContext<CategoryAccordionContextProps | null>(null);
