/* eslint-disable arrow-body-style */
import yup from '../../../../../config/yup/customYup';
import { BoxRow } from '../../../box.models';

export const filterFormValidatorConsult = yup.object().shape(
  {
    submitType: yup.string().required(),
    status: yup.string().nullable(),
    // eslint-disable-next-line react/forbid-prop-types
    selectedRows: yup.array<BoxRow>(),
    showSolicitDigitalizationFormDialog: yup.bool(),
    currentPage: yup.number(),
    sizePage: yup.number(),
    initialDate: yup.string().matchesDate().notRequiredWhenNullOrEmpty('finalDate'),
    finalDate: yup
      .string()
      .matchesDate()
      .notRequiredWhenNullOrEmpty('initialDate')
      .minDateRange('initialDate'),
  },
  [['initialDate', 'finalDate']]
);

export const filterFormValidatorExport = yup.object().shape(
  {
    submitType: yup.string().required(),
    status: yup.string().nullable(),
    // eslint-disable-next-line react/forbid-prop-types
    selectedRows: yup.array<BoxRow>(),
    showSolicitDigitalizationFormDialog: yup.bool(),
    currentPage: yup.number(),
    sizePage: yup.number(),
    initialDate: yup.string().nullable().matchesDate().required(),
    finalDate: yup.string().nullable().matchesDate().required(),
  },
  [
    ['initialDate', 'finalDate'],
    ['status', 'initialDate'],
    ['status', 'finalDate'],
  ]
);

export const filterFormValidatorFace = yup.object().shape(
  {
    submitType: yup.string().required(),
    status: yup.string().nullable(),
    // eslint-disable-next-line react/forbid-prop-types
    selectedRows: yup.array<BoxRow>().min(1).default([]),
    showSolicitDigitalizationFormDialog: yup.bool(),
    currentPage: yup.number(),
    sizePage: yup.number(),
    initialDate: yup.string().nullable().matchesDate(),
    finalDate: yup.string().nullable().matchesDate(),
  },
  [
    ['initialDate', 'finalDate'],
    ['status', 'initialDate'],
    ['status', 'finalDate'],
  ]
);
