import CategoryFieldsTemplate from '../template';
import { CategoryFormFieldsBaseProps } from '../types';
import CategoryFormIsDocumentCategoryField from './IsDocumentCategory';
import CategoryFormIsMigrationField from './isMigration';
import CategoryFormNameField from './name';

interface CategoryFormFieldsProps extends CategoryFormFieldsBaseProps {}

const CategoryFormRequiredFields = ({ ...rest }: CategoryFormFieldsProps) => (
  <CategoryFormRequiredFields.Template
    {...rest}
    name={<CategoryFormRequiredFields.Name />}
    isDocumentCategory={<CategoryFormRequiredFields.IsMigration />}
    isMigration={<CategoryFormRequiredFields.IsMigration />}
  />
);

CategoryFormRequiredFields.Template = CategoryFieldsTemplate;
CategoryFormRequiredFields.Name = CategoryFormNameField;
CategoryFormRequiredFields.IsMigration = CategoryFormIsMigrationField;
CategoryFormRequiredFields.IsDocumentCategory = CategoryFormIsDocumentCategoryField;

export default CategoryFormRequiredFields;
