import useRequestForm from '../../../@shared/Forms/RequestForm/useRequestForm';
import { extractValueTreeView } from '../../../@shared/utils/util';
import userEndpoints from '../user.endpoints';
import userEditFormValidator from './UserEditForm/validator';

const useEditUserForm = (userId: number) => {
  const form = useRequestForm({
    validatorSchema: userEditFormValidator,
    defaultValueRequest: () => userEndpoints.find(userId),
    transformDefaultValues: (user) => ({
      ...user,
      roles: user.roles ? user.roles.flatMap((e) => (e.checked ? [e.value] : [])) : [],
      categories: extractValueTreeView(user.categories),
    }),
    submitRequest: (obj) =>
      userEndpoints.update({ ...obj, roles: obj.roles.map((e) => Number(e)) }),
  });

  return {
    fill: {
      categories: form?.defaultValueRequest?.successData?.categories ?? [],
      roles: form?.defaultValueRequest?.successData?.roles ?? [],
    },
    loading: form.defaultValueRequest?.loading || form.submitResponse.loading,
    error: form.defaultValueRequest?.error && form.submitResponse.error,
    success: form.submitResponse.success,
    ...form,
  };
};

export default useEditUserForm;
