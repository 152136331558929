import yup from '../../../config/yup/customYup';

const boxClassificationFormValidator = yup
  .object({
    name: yup.string().min(2).required(),
    day: yup.number().min(0).required(),
    month: yup.number().min(0).required(),
    year: yup.number().min(0).required(),
  })
  .required();

export default boxClassificationFormValidator;
