import { AxiosRequestConfig } from 'axios';

const path = 'scanned-documents';
const pathWithBoxes = (boxId: number) => `boxes/${boxId}/scanned-documents`;

const scannedDocumentsEndpoints = {
  download(url: { scannedDocumentId: number }): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${path}/${url.scannedDocumentId}/download`,
      responseType: 'blob',
    };
  },

  upload(url: { boxId: number }, data: FormData): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${pathWithBoxes(url.boxId)}/upload`,
      data,
    };
  },

  list(url: { boxId: number }): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${pathWithBoxes(url.boxId)}`,
    };
  },
};
export default scannedDocumentsEndpoints;
