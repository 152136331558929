export const baseUrl = '';

export const routes = {
  baseUrl,
  edit: `/descriptions/edit`,
};

export const complexUrls = {
  baseUrl: routes.baseUrl,
  edit: (categoryId?: number, boxClassificationId?: number) =>
    `/descriptions/edit?categoryId=${categoryId}&boxClassificationId=${boxClassificationId}`,
};
