import { useEffect, useReducer } from 'react';
import { Control, useWatch } from 'react-hook-form';
import useInitialize from '../../../hook.initialize';
import { Option } from '../../../models';
import { actions, getInitialState, reducer } from './CsvTableField.reducer';
import useNewValueSelected from './csvTableField.hook.newValueSelected';

import useHasHeaderCheckbox from './hasHeaderCheckbox.hook';

interface UseTableSelectCsvParameters {
  id: string;
  rows: string[][];
  options: Option[];
  control: Control;
  select?: { disabled?: boolean };
}

const useCsvTableField = ({
  rows = [],
  options,
  control,
  id,
  select,
}: UseTableSelectCsvParameters) => {
  const watch = useWatch({ control, name: id }) as string[];
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { useHas, useHasNot, value } = useHasHeaderCheckbox({
    ...control,
    ...state.control,
  } as Control);

  useInitialize(() => {
    dispatch(
      actions.initial({
        control,
        options,
        rows,
        defaultValue: watch,
        select,
      })
    );
  });

  useEffect(() => {
    dispatch(
      actions.updateOptions({
        options,
      })
    );
  }, [options]);

  useEffect(() => {
    dispatch(actions.updateDisable(select?.disabled ?? true));
  }, [JSON.stringify(select?.disabled)]);

  useNewValueSelected(id, control, (v) => dispatch(actions.setValue(v)));
  useHas(() => dispatch(actions.filterHasHeader()));
  useHasNot(() => dispatch(actions.filterNotHasHeader()));

  return {
    className: state.hasHeader ? 'firstColumnIsHeader' : '',
    value: { thead: state.value, hasHeader: value },
    columns: state.columns,
    rows: state.rowsFiltered,
  };
};

export default useCsvTableField;
