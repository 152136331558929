import { useContext } from 'react';

import { CheckBox } from '../../Fields/models';
import { SetStateAction } from '../../models';
import { Monitoring } from '../../Monitor/useMonitor';
import useRequestGetPotentialUsersForCategoryCheckBox from '../requestGetPotentialUsersForCategoryCheckBox.hook';

export interface FetchingPossibleUsers extends Monitoring {
  users: CheckBox[];
  setUsers: SetStateAction<CheckBox[]>;
}

interface FetchPossibleUsersProps<T = FetchingPossibleUsers> {
  context: React.Context<T | null>;
}

const FetchPossibleUsers = <T extends FetchingPossibleUsers>({
  context: contextProp,
}: FetchPossibleUsersProps<T>) => {
  const context = useContext(contextProp);
  const request = useRequestGetPotentialUsersForCategoryCheckBox();
  const key = 'possible users';

  request.useLoading(() => {
    if (context) {
      context.monitor.setLoading(key);
      context?.setUsers([]);
    }
  });
  request.useSuccess(() => {
    if (context) {
      context.monitor.setSuccess(key);
      context.setUsers(request.successData ?? []);
    }
  });
  request.useFailure(() => {
    if (context) {
      context.monitor.setFailure(key);
      context.setUsers(request.successData ?? []);
    }
  });

  return <></>;
};

export default FetchPossibleUsers;
