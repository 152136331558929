import { staticOptions } from '../../../../../../@shared/Fields/TableField/CsvTableField/csvTableField.constants';
import yup from '../../../../../../config/yup/customYup';

const v = staticOptions.filter((z) => z.required).map((e) => String(e?.value));
const required = (value: (string | undefined)[] | undefined) =>
  v?.some((k) => value?.find((e) => e === k));

export default (descriptionsSize: number) =>
  yup
    .object({
      descriptions: yup
        .array()
        .of(yup.string())
        .test('descriptions', 'algum campo obrigatório não foi definido', (e) => required(e))
        .min(descriptionsSize, 'é necessário definir todas as classificações')
        .required(),
      category: yup.object().required(),
      classification: yup.object().required(),
      boxType: yup.object().required(),
    })
    .required();
