import useRequest from '../../../../@shared/Requests/useRequest';
import VerifyError from '../../../../@shared/Verifiers/verifyError';
import VerifyLoading from '../../../../@shared/Verifiers/verifyLoading';
import userEndpoints from '../../user.endpoints';

import useAddUser from '../UserDataGrid/useAddUser';
import UserForm, { FillUserProps } from '../userForm';

export default () => {
  const requestCategories = useRequest(userEndpoints.listCategoriesAsync());
  const requestPermissions = useRequest(userEndpoints.getMyRoles());

  const {
    loading: loadingAddUser,
    handleSubmit,
    onSubmit,
    arrayErrors: addError,
    register,
    control,
    validated,
  } = useAddUser();

  requestPermissions.useFetchBy(true, 'init');
  requestCategories.useFetchBy(true, 'init');

  return (
    <VerifyError
      errors={[
        { error: requestCategories.error, backEndErrors: requestCategories.backEndErrors },
        { error: requestPermissions.error, backEndErrors: requestPermissions.backEndErrors },
      ]}
      defaultText="Ocorreu um erro ao carregar o formulário"
    >
      <VerifyLoading
        loadings={[requestCategories.loading, loadingAddUser, requestPermissions.loading]}
      >
        <UserForm
          type="add"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={addError}
          validated={validated}
          control={control}
          fill={
            {
              categories: requestCategories.successData ?? [],
              roles: requestPermissions.successData ?? [],
            } as FillUserProps
          }
          noValidate
        />
      </VerifyLoading>
    </VerifyError>
  );
};
