import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import Field from './Field';
import { GenericField } from './textField';

type NumberFieldProps = GenericField & {
  type?: 'number';
  min?: number;
  max?: number;
  defaultValue?: number;
};

const NumberField = ({
  control,
  name,
  defaultValue,
  id,
  isInvalid,
  label,
  helperText,
  errorMessage,
  min,
  max,
  ...props
}: NumberFieldProps) => {
  const { field } = useController({
    name: name ?? id,
    control,
    defaultValue: defaultValue ?? '',
  });
  const [lastValidValue, setLastValidValue] = useState<number | undefined>(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const number = value !== '' ? Number(value) : undefined;
    let isValid = true;

    const minValidation = min !== undefined && number !== undefined;
    const maxValidation = max !== undefined && number !== undefined;

    if (minValidation) {
      isValid = number >= min;
    }

    if (isValid && maxValidation) {
      isValid = number <= max;
    }

    if (!Number.isNaN(value) && isValid) {
      setLastValidValue(number);
      field.onChange(number);
    } else {
      field.onChange(lastValidValue ?? '');
    }
  };

  return (
    <Field
      id={id}
      isInvalid={isInvalid ?? false}
      label={label}
      helperText={helperText}
      errorMessage={errorMessage}
    >
      <Form.Control
        {...props}
        type="number"
        value={field.value}
        onChange={handleChange}
        isInvalid={isInvalid}
      />
    </Field>
  );
};

export default NumberField;
