import { Option } from '../@shared/models';

export const SCANNED_DOCUMENT = 'SCANNED_DOCUMENT';
export const CONTENT_TYPE = {
  TEXT_CSV: 'text/csv',
  APPLICATION_JSON: 'application/json',
  MULTIPART_FORM_DATA: 'multipart/form-data',
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_OCTET_STREAM: 'application/octet-stream',
};

export enum PageSize {
  A4 = 'A4',
  A5 = 'A5',
}

export const formats: Option[] = [
  { label: 'Texto', value: 'text' },
  { label: 'Numérico', value: 'numeric' },
  { label: 'Data', value: 'date' },
];

export const BrazilianStates = [
  {
    abbreviation: 'AC',
    name: 'Acre',
  },
  {
    abbreviation: 'AL',
    name: 'Alagoas',
  },
  {
    abbreviation: 'AM',
    name: 'Amazonas',
  },
  {
    abbreviation: 'AP',
    name: 'Amapa',
  },
  {
    abbreviation: 'BA',
    name: 'Bahia',
  },
  {
    abbreviation: 'CE',
    name: 'Ceara',
  },
  {
    abbreviation: 'DF',
    name: 'Distrito Federal',
  },
  {
    abbreviation: 'ES',
    name: 'Espirito Santo',
  },
  {
    abbreviation: 'GO',
    name: 'Goias',
  },
  {
    abbreviation: 'MA',
    name: 'Maranhao',
  },
  {
    abbreviation: 'MG',
    name: 'Minas Gerais',
  },
  {
    abbreviation: 'MS',
    name: 'Mato Grosso Do Sul',
  },
  {
    abbreviation: 'MT',
    name: 'Mato Grosso',
  },
  {
    abbreviation: 'PA',
    name: 'Para',
  },
  {
    abbreviation: 'PB',
    name: 'Paraiba',
  },
  {
    abbreviation: 'PE',
    name: 'Pernambuco',
  },
  {
    abbreviation: 'PI',
    name: 'Piaui',
  },
  {
    abbreviation: 'PR',
    name: 'Parana',
  },
  {
    abbreviation: 'RJ',
    name: 'Rio De Janeiro',
  },
  {
    abbreviation: 'RN',
    name: 'Rio Grande Do Norte',
  },
  {
    abbreviation: 'RO',
    name: 'Rondonia',
  },
  {
    abbreviation: 'RR',
    name: 'Roraima',
  },
  {
    abbreviation: 'RS',
    name: 'Rio Grande Do Sul',
  },
  {
    abbreviation: 'SC',
    name: 'Santa Catarina',
  },
  {
    abbreviation: 'SE',
    name: 'Sergipe',
  },
  {
    abbreviation: 'SP',
    name: 'Sao Paulo',
  },
  {
    abbreviation: 'TO',
    name: 'Tocantins',
  },
];
