import { RequiredDateSchema } from 'yup/lib/date';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { CloudFilePath, Submit } from '../../@shared/Fields/models';
import { Option, RefForm } from '../../@shared/models';
import yup from '../../config/yup/customYup';

export interface BoxDescriptions {
  boxClassificationItemId: number;
  archiveDescription: string;
  isTag?: boolean;
}

export interface CsvBoxAddFormProps {
  defaultValues;
  refForm: ((instance: RefForm | null) => void) | null;

  onSubmit: (v: Submit) => void;
}

export interface CsvBoxAddFormOptions {
  classifications: Option[];
  categories: Option[];
  boxTypes: Option[];
  descriptions: Option[];
}

export interface CsvBoxAddFormDisabled {
  classification: boolean;
}

export type CsvBoxAddFormHidden = { descriptions?: boolean } | undefined;

export interface AddBox {
  barcode?: string | null;
  categoryId: number;
  classificationId: number;
  location: string;
  exclusionDate: Date;
  boxDescriptions: BoxDescriptions[];
  isManualBarcode: boolean;
}

export type YupFieldReturn =
  | yup.DateSchema<Date | undefined, AnyObject, Date | undefined>
  | RequiredDateSchema<Date | undefined, AnyObject>
  | RequiredStringSchema<string | undefined, AnyObject>;

export type YupField = {
  [x: string]: YupFieldReturn;
};

export interface CsvBoxForm {
  file: FileList;
  classification: number;
  category: number;
}

export interface BoxForm {
  classificationId: number;
  categoryId: number;
  isManualBarcode: boolean;
  barcode: string;
  location: string;
  exclusionDate: string;
}

export interface RequestPropsListBoxParams {
  description?: string | null;
  boxMovementDescription?: string | null;
  currentPage?: number | null;
  sizePage?: number | null;
  initialDate?: string | null;
  finalDate?: string | null;
  location?: string | null;
}
export interface RequestPropsListDescriptions {
  boxId: number;
}

export interface BoxMovement {
  boxId: number;
  boxMovementId: number;
  boxMovementDescription: string;
  boxMovementCod: number;
  boxMigrationTypeId: number;
}

export enum BoxMovementCode {
  SolicitInclusion = 1,
  Available = 2,
  SolicitCancellation = 2,
  SolicitConsultation = 3,
  InTransit = 4,
  SolicitDevolution = 5,
  InConsult = 6,
  SolicitExclusion = 7,
  Excluded = 8,
  SolicitDigitalization = 9,
  InDigitalization = 10,
  InclusionCanceled = 11,
  MigratedToAnotherFile = 12,
  SolicitMigration = 13,
}

export interface OptionsBoxForm {
  categories: Option[] | null;
  classifications: OptionCancellationDatePair[] | null;
}
export interface BoxLinkProps {
  options: OptionsBoxForm;
}
export interface OptionCancellationDatePair {
  option: Option;
  cancelationDate: string;
}

export enum FilterStatus {
  All = '',
  Selected = 'Selecionados',
  Available = 'Disponível',
  Excluded = 'Excluída',
  InConsultation = 'Em Consulta',
  RequestedForConsultation = 'Solicitada para a consulta',
}

export type BoxRow = {
  boxId: number;
  barCode: string;
  number: number;
  classification: string;
  description: string;
  inclusionDate: string;
  status: number;
  statusDescription: string;
  location: string;
  category: string;
  boxMovementId: number;
};

export interface MigrationCategory {
  categoryId: number;
  clientId: number;
  subsidiaryId: number;
  parentId: number | undefined;
  userCategoryUserId: number | undefined;
  name: string;
  inclusionDate: Date;
  cancellationDate: Date | undefined;
  cancellationDescription: string | undefined;
  flagDocumentCategory: boolean | undefined;
  flagIsMigration: boolean | undefined;
  userCategoryInsertionDate: Date | undefined;
}

export type AddParameterizedCsv = {
  columns: string[];
  categoryId: number;
  boxClassificationId: number;
  boxClassificationName: string;
  tags: number[];
  hasHeader?: boolean;
  delimiter?: string;
  cloudFilePath: CloudFilePath;
  boxTypeId: number;
};

export interface BoxHistory {
  personName: string;
  clientName: string;
  movementDate: string;
  movementTypeDescription: string;
  movementDescription: string;
}

export interface BoxDetail {
  fileNumber: string;
  categoryName: string;
  boxClassificationName: string;
  boxDescriptions: { name: string; value: string }[];
  estimatedExclusionDate: string;
  inclusionDate: string;
  exclusionDate?: string;
  locationDescription: string;
}

export type BoxParams = { boxId: string };
