const csvTableFieldUtil = {
  turnFirstRowInHeader(rowsInternal: Rows) {
    const r = { ...rowsInternal };
    const header = r.default.at(0);
    const rest = r.default.filter((e, i) => i !== 0);

    r.filtered = rest.map((k, i) => {
      const v = header?.at(i);
      return [v ?? '', ...k];
    });
    return r;
  },
  turnFirstRowInDefault(rowsInternal: Rows, rowsExternal: string[][]) {
    const r = { ...rowsInternal };
    r.filtered = rowsExternal;
    r.default = rowsExternal;
    return r;
  },
};

export default csvTableFieldUtil;
