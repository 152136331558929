import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import isEmpty from '../utils/util';

export type FormError = {
  message: string;
  ref?: HTMLInputElement;
  type: string;
};

interface IErrorFormAlert {
  className?: string;
  errors?: Record<string, FormError>;
  fullSize?: boolean;
  disableDefaultHeading?: boolean;
  dismissible?: boolean;
  style?: React.CSSProperties;
}

const ErrorFormAlert = ({
  className,
  errors,
  disableDefaultHeading = true,
  fullSize = false,
  dismissible = true,
  style,
}: IErrorFormAlert) => {
  const [show, setShow] = useState(true);
  const f = fullSize ? 'm-0' : '';
  const c = `${className as string} ${f}`;

  const message = errors?.exception?.message || '';
  const isExceptionEmpty = isEmpty(errors);
  useEffect(() => {
    setShow(!isExceptionEmpty);
  }, [isExceptionEmpty]);

  return (
    <>
      {show && (
        <Alert
          className={`${c} rounded-0`}
          key="alertError"
          variant="danger"
          onClose={() => setShow(false)}
          dismissible={dismissible}
          style={style}
        >
          {disableDefaultHeading && <Alert.Heading>Ocorreu um erro</Alert.Heading>}
          <p>{message}</p>
        </Alert>
      )}
    </>
  );
};

export default ErrorFormAlert;
