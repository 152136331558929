import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';

export const useTrue = (value: boolean, fun: Function) => {
  useEffect(() => {
    if (value === true && fun) fun();
  }, [value]);
};
export const useFalse = (value: boolean, fun: Function) => {
  useEffect(() => {
    if (value === false && fun) fun();
  }, [value]);
};

const useFieldBooleanObserver = (id: string, control: Control) => {
  const watch = useWatch({ control, name: id }) as boolean;

  return {
    value: watch,
    useTrue: (fun: Function) => useTrue(watch, fun),
    useFalse: (fun: Function) => useFalse(watch, fun),
  };
};

export default useFieldBooleanObserver;
