import { Form } from 'react-bootstrap';
import InputFilter from '../../utils/inputFilter';
import { TreeNodeProps } from './treeView';

const NodeCheckBox = ({ control, field, id, parent, isInvalid, disabled }: TreeNodeProps) => {
  const { label, value, children } = parent;

  const checked = Array.isArray(field.value)
    ? [...field.value].some((e) => String(e) === String(value))
    : field.value === value;

  return (
    <ul key={id}>
      <Form.Check
        type="checkbox"
        label={label}
        value={value}
        checked={checked}
        disabled={disabled}
        isInvalid={isInvalid}
        onChange={(e) => {
          field?.onChange(InputFilter.onChangeCheckBoxGroup(e, field.value));
        }}
      />
      <li>
        {children?.map((c) => (
          <NodeCheckBox
            control={control}
            field={field}
            parent={c}
            disabled={c.disabled}
            key={c.value}
            id={c.value}
            isInvalid={isInvalid}
          />
        ))}
      </li>
    </ul>
  );
};

export default NodeCheckBox;
