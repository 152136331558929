interface TBodyProps {
  children: string[][];
  className?: string;
  rowClassName?: string;
}

const TBody = ({ children, className, rowClassName }: TBodyProps) => (
  <tbody className={className}>
    {children.map((r) => (
      <tr key={Math.random()} className="b-gray">
        {Object.entries(r).map((c) => (
          <td key={Math.random()} className={`rows ${rowClassName}`}>
            {c[1] as string}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default TBody;
