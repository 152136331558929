import { useContext } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { useParams } from 'react-router';
import CategoryChooserDialog, { CategoryChooserDialogProps } from '.';
import AddButton from '../../../../@shared/Buttons/AddButton';
import DialogProvider, {
  NewDialogContext,
} from '../../../../@shared/Dialogs/Context/dialogContext';
import useRequest from '../../../../@shared/Requests/useRequest';
import { Exception } from '../../../../@shared/models';
import classificationEndpoints from '../../../classification/classification.endpoint';
import { CategoryChooserSuccessData } from '../CategorySelect';

export type CategoryChooserDialogServerSubmit = {
  success: boolean;
  error: boolean;
  loading: boolean;
  successData: CategoryChooserSuccessData[];
  errorData: Exception | null;
};

interface CategoryChooserDialogServerProps
  extends Omit<CategoryChooserDialogProps, 'onSubmit' | 'show' | 'onHide'> {
  show?: boolean;
  onSubmit?: (e: CategoryChooserDialogServerSubmit) => void;
  onHide?: () => void;
}

const CategoryChooserDialogServer = ({
  show,
  onHide,
  defaultValue,
  options = { categories: [], parents: [] },
  onSubmit,
}: CategoryChooserDialogServerProps) => {
  const request = useRequest();
  const context = useContext(NewDialogContext);
  // get url params
  const params = useParams<{ boxClassificationId: string }>();

  request.useChange(() => {
    if (onSubmit) {
      onSubmit({
        error: request.error,
        errorData: request.backEndErrors,
        loading: request.loading,
        success: request.success,
        successData: request.successData,
      });
    }
  });

  return (
    <CategoryChooserDialog
      show={context?.show ?? show ?? false}
      options={options}
      defaultValue={defaultValue}
      onSubmit={(e) => {
        // get value not in defaultValue in e
        request.fetch(classificationEndpoints.addCategories(Number(params.boxClassificationId), e));
      }}
      onHide={() => {
        context?.close();
        onHide?.();
      }}
    />
  );
};

CategoryChooserDialogServer.Provider = DialogProvider;
CategoryChooserDialogServer.AddButton = ({ ...props }: ButtonProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(NewDialogContext);

  return (
    <AddButton
      {...props}
      onClick={() => {
        if (context) {
          context.open();
        }
      }}
    >
      Adicionar Categoria
    </AddButton>
  );
};

export default CategoryChooserDialogServer;
