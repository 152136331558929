import { Button, Col, Container } from 'react-bootstrap';
import DatePicker from '../../../../@shared/Fields/datePicker';
import RadioButtonGroup from '../../../../@shared/Fields/radioButtonGroup';
import SelectField from '../../../../@shared/Fields/selectField';
import TextField from '../../../../@shared/Fields/textField';
import RequestForm, {
  FormControlProps,
  FormRegisterProps,
} from '../../../../@shared/Forms/RequestForm/index';
import { BrazilianStates } from '../../../../constants/constants';
import useClientForm from './useClientForm';

interface ClientFormProps extends FormRegisterProps, FormControlProps {
  type: 'edit' | 'add';
  subsidiaries?: [{ subsidiaryId: number; name: string }];
  migrationTypes?: [{ migrationTypeId: number; name: string }];
}

const ClientForm = ({
  subsidiaries,
  errors,
  register,
  control,
  type,
  migrationTypes,
  ...props
}: ClientFormProps) => {
  const clientForm = useClientForm({ type, subsidiaries, migrationTypes });

  return (
    <Container className="m-0">
      <Col sm="12" md="6" className="py-4">
        <div className="h-100 w-100">
          <RequestForm {...props} errors={errors} className="h-100">
            <TextField
              id="logo"
              label="Logo"
              control={control}
              errorMessage={errors?.logo?.message}
              isInvalid={errors?.logo !== undefined}
              helperText="para mudar a logo é necessário o uma url valida, ex: www.img.com.br/minhaImagem"
            />
            <TextField
              id="pendingEmailAlert"
              label="Email Para Alerta de Pendência"
              control={control}
              errorMessage={errors?.pendingEmailAlert?.message}
              isInvalid={errors?.pendingEmailAlert !== undefined}
              helperText="Se deseja enviar um email quando um status passa de disponível para pendente, adicione o endereço de email aqui.  Se nao deseja enviar email algum com a mudança de espaço, deixe o valor vazio."
            />
            <SelectField
              isMulti
              getOptionValue={(option) => option?.migrationTypeId}
              getOptionLabel={(option) => option?.name}
              id="migrationTypes"
              label="Tipo de migração"
              disabled={type === 'edit'}
              control={control}
              errorMessage={errors?.migrationTypes?.message}
              isInvalid={errors?.migrationTypes !== undefined}
              options={clientForm.migrationTypes}
            />
            <SelectField
              getOptionValue={(option) => option.subsidiaryId}
              getOptionLabel={(option) => option.name}
              disabled={type === 'edit'}
              id="subsidiaryId"
              label="Filial"
              control={control}
              errorMessage={errors?.subsidiaryId?.message}
              isInvalid={errors?.subsidiaryId !== undefined}
              options={clientForm.subsidiaries}
            />
            <RadioButtonGroup
              type="bool"
              id="isImportCsvLocation"
              name="isImportCsvLocation"
              control={control}
              label="importação de localização em csv"
              errorMessage={errors?.isImportCsvLocation?.message}
              isInvalid={errors?.isImportCsvLocation !== undefined}
            >
              {[
                { key: 'sim', label: 'Sim', value: true },
                { key: 'sao', label: 'Não', value: false },
              ]}
            </RadioButtonGroup>

            <TextField
              id="email"
              label="Email"
              control={control}
              errorMessage={errors?.email?.message}
              isInvalid={errors?.email !== undefined}
            />
            <TextField
              id="street"
              label="Logradouro"
              control={control}
              errorMessage={errors?.street?.message}
              isInvalid={errors?.street !== undefined}
            />
            <TextField
              id="complement"
              label="Complemento"
              control={control}
              errorMessage={errors?.complement?.message}
              isInvalid={errors?.complement !== undefined}
            />
            <TextField
              id="district"
              label="Bairro"
              control={control}
              errorMessage={errors?.district?.message}
              isInvalid={errors?.district !== undefined}
            />
            <SelectField
              getOptionValue={(option) => option.abbreviation}
              getOptionLabel={(option) => option.name}
              id="state"
              label="Estado"
              control={control}
              errorMessage={errors?.state?.message}
              isInvalid={errors?.state !== undefined}
              options={BrazilianStates}
            />
            <TextField
              id="city"
              label="Município"
              control={control}
              errorMessage={errors?.city?.message}
              isInvalid={errors?.city !== undefined}
            />
            <TextField
              id="postcode"
              label="CEP"
              control={control}
              errorMessage={errors?.postcode?.message}
              isInvalid={errors?.postcode !== undefined}
            />
            <TextField
              id="streetNumber"
              label="Número"
              control={control}
              errorMessage={errors?.streetNumber?.message}
              isInvalid={errors?.streetNumber !== undefined}
            />
            <TextField
              id="homePhone"
              label="Telefone residencial"
              control={control}
              errorMessage={errors?.homePhone?.message}
              isInvalid={errors?.homePhone !== undefined}
              type="tel"
            />
            <TextField
              id="commercialPhone"
              label="Telefone comercial"
              control={control}
              errorMessage={errors?.commercialPhone?.message}
              isInvalid={errors?.commercialPhone !== undefined}
              type="tel"
            />
            <TextField
              id="fax"
              label="Fax"
              control={control}
              errorMessage={errors?.fax?.message}
              isInvalid={errors?.fax !== undefined}
            />
            <TextField
              id="cellPhone"
              label="Celular"
              control={control}
              errorMessage={errors?.cellPhone?.message}
              isInvalid={errors?.cellPhone !== undefined}
              type="tel"
            />
            <TextField
              id="code"
              label="Código"
              control={control}
              errorMessage={errors?.code?.message}
              isInvalid={errors?.code !== undefined}
            />
            <TextField
              id="name"
              label="Nome"
              control={control}
              errorMessage={errors?.name?.message}
              isInvalid={errors?.name !== undefined}
            />
            <TextField
              id="tradingName"
              label="Nome Fantasia"
              control={control}
              errorMessage={errors?.tradingName?.message}
              isInvalid={errors?.tradingName !== undefined}
            />

            <TextField
              id="documentId"
              label="CPF/CNPJ"
              control={control}
              errorMessage={errors?.documentId?.message}
              isInvalid={errors?.documentId !== undefined}
            />
            <RadioButtonGroup
              type="bool"
              id="isLegalEntity"
              name="isLegalEntity"
              control={control}
              label="Jurídico"
              errorMessage={errors?.isLegalEntity?.message}
              isInvalid={errors?.isLegalEntity !== undefined}
            >
              {[
                { key: 'sim', label: 'Sim', value: true },
                { key: 'sao', label: 'Não', value: false },
              ]}
            </RadioButtonGroup>
            <TextField
              id="workCategory"
              label="Ramo de atividade"
              control={control}
              errorMessage={errors?.workCategory?.message}
              isInvalid={errors?.workCategory !== undefined}
            />
            <DatePicker
              name="foundingDate"
              id="foundingDate"
              label="Data de fundação da empresa"
              control={control}
              errorMessage={errors?.foundingDate?.message}
              isInvalid={errors?.foundingDate !== undefined}
            />
            <Button type="submit">{type === 'edit' ? 'Editar' : 'Criar'}</Button>
          </RequestForm>
        </div>
      </Col>
    </Container>
  );
};

export default ClientForm;
