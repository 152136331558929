/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Wizard, WizardProps } from 'react-use-wizard';
import { FormError } from '../../Alerts/errorFormAlert';
import { useFalse, useTrue } from '../../Fields/useFieldBooleanObserver';

import LoadingModal from '../../../modules/auth/features/ForgotDialogues/LoadingModal';
import isEmpty from '../../utils/util';
import StepsFormContext from './stepsForm.context';

interface StepsFormProps {
  children?: React.ReactElement<WizardProps>[];
  className?: string;
  onSuccess?: JSX.Element;
  onError?: (errors: Record<string, FormError>) => JSX.Element;
  wrapper?: JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
  onSubmit?: <T extends Record<number, any> = Record<number, any>>(value: T) => void;
  errorMessage: string;
  error: boolean;
  loading: boolean;
  success: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StepsForm = ({
  children,
  className,
  wrapper,
  header,
  footer,
  onSuccess,
  onError,
  onSubmit,
  loading,
  error,
  errorMessage,
  success,
}: StepsFormProps) => {
  const numberOfSteps = children?.length ?? 0;
  const [submit, setSubmit] = useState<boolean>(false);
  const [stepsStatus, setStepsStatus] = useState<StepStatus[]>([]);
  const [canGoToNextStep, setCanGoToNextStep] = useState<boolean>(false);
  const [disabledSteps, setDisabledSteps] = useState<boolean[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [value, setValue] = useState<Record<number, string>>({});
  const [errors, setErrors] = useState<Record<string, FormError>>({});
  const [populate, setPopulate] = useState<Record<string, any>>({});
  const [successfulAllForms, setSuccessfulAllForms] = useState<boolean>(false);

  useEffect(() => {
    setStepsStatus(Array(numberOfSteps).fill('init'));
    setCanGoToNextStep(false);
    setDisabledSteps(Array(numberOfSteps).fill(true));
  }, []);

  useTrue(error, () => {
    setErrors({ ...errors, exception: { type: 'exception', message: errorMessage } as FormError });
    setSubmit(false);
  });

  useFalse(error, () => {
    const er: Record<string, FormError> = Object.entries(errors)
      .filter(([key]) => key === 'exception')
      .map(([key, v]) => ({ [key]: v }))
      .reduce((p, c) => ({ ...p, ...c }), {});

    setErrors({ ...er });
  });

  useTrue(submit, () => {
    if (onSubmit) onSubmit(value);
  });

  useEffect(() => {
    const currentStepHasBeenVerified = stepsStatus.at(currentStep) === 'validated';
    setCanGoToNextStep(currentStepHasBeenVerified);
  }, [currentStep, stepsStatus]);

  useEffect(
    () => setDisabledSteps(stepsStatus.map((e) => e === 'invalided' || e === 'init')),
    [JSON.stringify(stepsStatus), canGoToNextStep]
  );

  useEffect(() => {
    const keys = Object.keys(errors);
    const st = [...stepsStatus];

    keys.forEach((k) => {
      st[k] = 'invalided';
    });
    if (!isEmpty(st)) setStepsStatus(st);
  }, [errors]);

  if (success && onSuccess) {
    return onSuccess;
  }
  return (
    <StepsFormContext.Provider
      value={{
        errors,
        setErrors,
        setStepsStatus,
        numberOfSteps,
        stepsStatus,
        canGoToNextStep,
        setCanGoToNextStep,
        disabledSteps,
        setDisabledSteps,
        currentStep,
        setCurrentStep,
        value,
        setValue,
        populate,
        setPopulate,
        successfulAllForms,
        setSuccessfulAllForms,
        setSubmit,
        submit,
      }}
    >
      {loading && <LoadingModal />}
      <Container className={className}>
        {onError && onError(errors)}
        <Wizard startIndex={0} wrapper={wrapper} header={header} footer={footer}>
          {children}
        </Wizard>
      </Container>
    </StepsFormContext.Provider>
  );
};

export default StepsForm;
