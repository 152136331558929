import Layout from '../../layout';
import BoxDataGrid from '../features/BoxDataGrid';

const ConsultPage = () => (
  <Layout>
    <BoxDataGrid />
  </Layout>
);

export default ConsultPage;
