import { useState } from 'react';
import { FormError } from '../Alerts/errorFormAlert';
import { Exception } from '../models';
import useSetErrors from './useSetErrors';

export interface IValidator {
  validated: boolean;
  errors: Record<string, FormError>;
}

type Dictionary = { [x: string]: any };

export default (frontEndErrors: Dictionary, backEndErrors: {} | Exception | undefined | null) => {
  const [validated, setValidated] = useState(false);
  const [arrayErrors, setArrayErrors] = useState({} as Record<string, FormError>);

  useSetErrors(frontEndErrors, setValidated, setArrayErrors, backEndErrors);
  return { validated, errors: arrayErrors } as IValidator;
};
