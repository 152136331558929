import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';

const EditButtonBase = ({ className, ...props }: ButtonProps) => (
  <Button variant="primary" className={`mw-25 rounded  ${className}`} {...props}>
    <FontAwesomeIcon icon={faUserEdit} />
  </Button>
);

const EditButton = styled(EditButtonBase)``;

export default EditButton;
