import { createSlice } from '@reduxjs/toolkit';
import { Open } from '../../@shared/models';

import openReducers from '../reducers/openReducers';

const nameSlice = 'sideBar';

const initialState: Open = {
  isOpen: false,
};

export const slice = createSlice({
  name: nameSlice,
  initialState,
  reducers: openReducers,
});

export const { open, close } = slice.actions;

export default slice.reducer;
