import StepButtonsPresentation from '../../../../../@shared/MultiStepForm/StepButtons/stepButtons.presentation';
import Step from '../../../../../@shared/MultiStepForm/StepsForm/steps';
import UploadFileForm from '../../../../../@shared/UploadFileForm';
import { Step0Value } from './step0.hook';

const Step0Presentation = ({
  control,
  disableNextStep,
  disablePreviousStep,
  errors,
  onClickNextStep,
  onClickPreviousStep,
  requester,
  success,
  value,
}: Step0Value) => (
  <Step
    title="Enviar CSV"
    middle={
      <UploadFileForm
        id="file"
        label="Arquivo CSV"
        accept=".csv"
        success={success}
        control={control}
        value={value}
        requester={requester}
        errors={errors}
      />
    }
    bottom={
      <StepButtonsPresentation
        disableNextStep={disableNextStep}
        disablePreviousStep={disablePreviousStep}
        hiddenPreviousStep
        onClickNextStep={onClickNextStep}
        onClickPreviousStep={onClickPreviousStep}
      />
    }
  />
);

export default Step0Presentation;
