import { useState } from 'react';
import useDialog from '../../Dialogs/useDialog';
import useChangeValue from '../../useChangeValue';
import useDefaultValue from '../../useDefaultValue';

interface UseAddAccordion<TValue = any> {
  value?: TValue[];
  defaultValue?: TValue[];
  onChange?: (value: TValue[]) => void;
}

const useAddAccordion = <TValue = any[],>(props?: UseAddAccordion<TValue>) => {
  const [valueState, setValueState] = useState<any[]>([]);
  const { show, close, open } = useDialog();

  useDefaultValue(props?.defaultValue ?? [], setValueState);
  useChangeValue(props?.value ?? [], setValueState);
  useChangeValue<TValue[]>(valueState ?? [], (e) => props?.onChange?.(e));

  const add = (value: TValue) => {
    const newValue = valueState.length > 0 ? [...valueState, value] : [value];
    setValueState(newValue);
  };

  const remove = (index: number) => {
    const newValue = [...valueState];
    newValue.splice(index, 1);
    setValueState(newValue);
  };

  const set = (value: TValue[]) => {
    setValueState(value);
  };

  return {
    set,
    remove,
    value: valueState,
    add,
    show,
    close,
    open,
    isEmpty: valueState.length === 0,
  };
};

export default useAddAccordion;
