/* eslint-disable @typescript-eslint/no-unused-vars */
import 'reset-css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import useAuthSlice from './@shared/Auth/useAuthSlice';
import secretService from './@shared/storage/secretService';
import Routes from './routes';
import { GlobalStyle } from './styles';

function App() {
  const queryClient = new QueryClient();
  const redux = useAuthSlice();
  if (secretService.get().accessToken) redux.useLoginWithoutUpdateSecret();

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <Routes />
    </QueryClientProvider>
  );
}

export default App;
