import { Placeholder } from 'react-bootstrap';

const ShimmerPlaceholdersDataGrid = () => (
  <div className="rounded mb-3" style={{ height: '300px' }}>
    <Placeholder className="w-100 h-100 rounded" animation="glow">
      <Placeholder className="w-100 h-100 rounded" xs={12} />
    </Placeholder>
  </div>
);

export default ShimmerPlaceholdersDataGrid;
