import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    /* Theme */
    html,
    body,
    #root {
      height: 100%;
      width: 100%;
    };

    :root {
      --bs-primary-rgb: 54,83,89;
      --bs-primary: #f15c27;
      --bs-secondary: #dcdcdc;
      --bs-secondary-rgb: 220, 220, 220;     
      --bs-btn-color: #f15c27
    };

    .btn-outline-primary {
    --bs-btn-color: #f15c27;
    --bs-btn-border-color: #f15c27;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #f15c27;
    --bs-btn-hover-border-color: #f15c27;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #f15c27;
    --bs-btn-active-border-color: #f15c27;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f15c27;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f15c27;
    --bs-gradient: none;
}

    * {
      box-sizing: border-box;
    };

    .m-0-important{
      margin: 0 !important;
    }
    .w-40{
      width: 40%;
    }

    .bg-disable{
      background:#e9ecef
    }

    .flex-0-important{
      flex: 0;
    }

    .panel-bg-light{
      background: rgb(246, 246, 246);
    }

    .dropdown-toggle-remove .dropdown-toggle::after {
      border: 0 !important;
    }

    .line-height-initial {
      line-height: initial;   
    }

    .d-inherit {
      display: inherit;
    }
    .d-contents {
      display: contents;
    }

    @media (min-width: 992px){
      .rounded-lg-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .rounded-lg-start-0 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rounded-lg-end-0 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      
    }


    @media (min-width: 768px) and (max-width: 992px){
      .rounded-md-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .rounded-md-start-0 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rounded-md-end-0 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .rounded-md-bottom-0{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rounded-md-top-0{
         border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    

    @media (min-width: 576px) and (max-width: 768px){
      .rounded-sm-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .rounded-sm-start-0 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rounded-sm-end-0 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .rounded-sm-bottom-0{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .rounded-sm-top-0{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    

    .rounded-top-0 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .rounded-start-0 {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .rounded-end-0 {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .rounded-bottom-0{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .rounded-top-0{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .b-light-gray{
      border-color: rgba(0, 0, 0, 0.08);
    };

    .bg-light-gray{
          border-color: rgba(0, 0, 0, 0.08);
      color: #424242;
      background-color: #f0f0f0;
      height: fit-content;
      border-top: 1px solid rgb(222, 222, 222);
      border-left: 1px solid rgb(222, 222, 222);
      border-right: 1px solid rgb(222, 222, 222);
    };

    .h-min{
      height: min-content;
    }
    .h-fit-content{
      height: fit-content;
    }

    .json-break-line{
      white-space: pre-line;
      vertical-align: bottom;
    }
    
    /* *:has(.h-fit-content) > * > * > * > * > [role=columnheader] {
      height: fit-content;
    } */

    .btn-primary {
      color: #fff;
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    };

    .btn-primary.disabled, .btn-primary:disabled{
      color: #fff;
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }

    .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
      color: var(--bs-primary);
      background-color: transparent;
      border-color: var(--bs-primary); /*set the color you want here*/
    };

    .btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle{
      background-color: #47494c !important;
    }

    .form-check-input:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    };
    /* Custom class */
    #navbarScrollingDropdown {
      padding: 0px !important;
    };

    @media (min-width: 576px){
      /* .flex-sm-column {
        display: column;
      } */
    }

    @media (min-width: 768px){
      /* .flex-md-column {
        display: column;
      } */
    }

    @media (min-width: 992px){
      /* .flex-lg-row {
        display: row;
      } */
    }

    .overlay{
      //position: absolute;
      //left: 0px;
      //top: 0px;
      //z-index: -100;
    }

    .invisible{
      visibility: collapse;
    }

    .mw-fit-content{
      max-width: fit-content;
    }
    .mh-fit-content{
      max-height: fit-content;
    }

    .vh-50{
      height: 50vh;
    }

    .min-vh-50{
      min-height: 50vh;
    }

    .mw-50{
      max-width: 50%;
    }

    .h-90{
      height: 90%;
    };

    .h-95{
      height: 95%;
    };

    .h-10{
      height: 10%;
    };

    .datagrid-md{
      min-height:  400px;
    };

    .datagrid-lg{
      height: 697px;
      width: 100%;
    };

    .min-h-100 {
      min-height: 100%;
    };
    
    .min-w-100{
      min-width: 100%;
    };

    .text-justify{
      text-align: justify;
    }

    .form-group{
      
    }

    .d-contents{
      display: contents;
    }

    .flex-1{
      flex: 1;
    }
    .flex-2{
      flex: 2;
    }
    .flex-3{
      flex: 3;
    }

    html,
    body,
    #root {
      display: flex;
    };

    .rdg-cell {
      box-sizing: border-box;
      box-shadow: none;
      contain: initial;
    };



    
`;

export const CenterAligner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DialogGroup = styled.div`
  display: flex;
`;

export const Reticence = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
