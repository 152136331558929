import { createContext } from 'react';

export interface UserDataGridContextValues {
  refresh: Function;
}

const UserDataGridContext = createContext<UserDataGridContextValues>({
  refresh: Function,
});
export default UserDataGridContext;
