import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Loading from '../../../../@shared/Loading';

const StyledBody = styled(Modal.Body)`
  height: 353px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingModal = () => (
  <Modal show size="lg">
    <StyledBody>
      <Loading />
    </StyledBody>
  </Modal>
);

export default LoadingModal;
