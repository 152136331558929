import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import ConsultBoxAddPage from './pages/add';
import BoxCsvAddPage from './pages/add-csv';
import ConsultPage from './pages/consult';
import BoxDetailPage from './pages/detail';

const baseUrl = '/consult';
const routes = {
  baseUrl,
  add: `${baseUrl}/add`,
  addCsv: `${baseUrl}/add-csv`,
  detail: `${baseUrl}/:boxId/detail`,
};

const ConsultRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <ConsultPage />
    </PrivateRoute>
    <PrivateRoute path={routes.add} exact>
      <ConsultBoxAddPage />
    </PrivateRoute>
    <PrivateRoute path={routes.addCsv} exact>
      <BoxCsvAddPage />
    </PrivateRoute>
    <PrivateRoute path={routes.detail} exact>
      <BoxDetailPage />
    </PrivateRoute>
  </Switch>
);

export default ConsultRoutes;
