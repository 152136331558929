import { EffectCallback, useEffect, useMemo, useState } from 'react';

export interface UsePagination {
  currentPage: number;
  nextPageDisabled: boolean;
  previousPageDisabled: boolean;
  turnNextPage: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  turnPreviousPage: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  changePageCount: number;
  reset: () => void;
  isFirstPage: boolean;
  useReset: (filter?: boolean, trigger?: React.DependencyList) => void;
  useOnTurnNextPage: (f: EffectCallback) => void;
  useOnTurnPreviousPage: (f: EffectCallback) => void;
}

export default <T>(newRows: T[], actualPage?: number, totalPageChange?: number): UsePagination => {
  const [rows, setRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(actualPage ?? 1);
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(true);
  const [previousPageDisabled, setPreviousPageDisabled] = useState<boolean>(true);
  const [changePageCount, setChangePageCount] = useState<number>(totalPageChange ?? 0);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [action, setAction] = useState<'next' | 'previous' | null>(null);

  const disableBoth = () => {
    setPreviousPageDisabled(true);
    setNextPageDisabled(true);
  };

  const disableOnlyPrevious = () => {
    setPreviousPageDisabled(true);
    setNextPageDisabled(false);
  };

  const enableBoth = () => {
    setPreviousPageDisabled(false);
    setNextPageDisabled(false);
  };

  const turnNextPage =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setCurrentPage(currentPage + 1);
      setChangePageCount(changePageCount + 1);
      setAction('next');
    };

  const turnPreviousPage =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setCurrentPage(currentPage - 1);
      setChangePageCount(changePageCount + 1);
      setAction('previous');
    };

  const reset = () => {
    setIsFirstPage(true);
    setCurrentPage(1);
    setNextPageDisabled(true);
    setPreviousPageDisabled(true);
    setChangePageCount(0);
    setAction(null);
  };

  const useReset = (filter?: boolean, trigger?: React.DependencyList) => {
    useMemo(() => {
      if (filter || filter === undefined) reset();
    }, trigger ?? [filter]);
  };

  const useOnTurnNextPage = (f: EffectCallback) => {
    useEffect(() => {
      if (action === 'next') {
        f();
      }
    }, [action]);
  };

  const useOnTurnPreviousPage = (f: EffectCallback) => {
    useEffect(() => {
      if (!isFirstPage && action === 'previous') {
        f();
      }
    }, [action]);
  };

  useEffect(() => {
    setRows(newRows);
  }, [newRows]);

  useEffect(() => {
    setIsFirstPage(currentPage === 1);
  }, [currentPage]);

  useEffect(() => {
    if (rows?.length <= 0 || rows === undefined) disableBoth();
    else if (currentPage === 1 && rows?.length > 0) disableOnlyPrevious();
    else enableBoth();
  }, [JSON.stringify(rows), currentPage]);

  return {
    useReset,
    changePageCount,
    currentPage,
    nextPageDisabled,
    useOnTurnNextPage,
    previousPageDisabled,
    turnNextPage,
    turnPreviousPage,
    useOnTurnPreviousPage,
    reset,
    isFirstPage,
  };
};
