import Layout from '../../layout';
import AdminEditForm from '../features/Forms/adminEditForm';

const AdminEditPage = () => (
  <Layout>
    <AdminEditForm />
  </Layout>
);

export default AdminEditPage;
