import { useState } from 'react';

const useVirtualPagination = <T>(data: T[]) => {
  const [startIndex, setStartIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState(5);

  const endIndex = startIndex + pageSize;
  const disabled = { prev: startIndex === 0 ?? false, next: endIndex >= data?.length ?? false };

  const visibleData = data?.slice(startIndex, endIndex);

  const handlePrevClick = () => {
    setStartIndex(Math.max(startIndex - pageSize, 0));
  };

  const handleNextClick = () => {
    setStartIndex(Math.min(startIndex + pageSize, data?.length - pageSize));
  };

  const buildNumberOfData = (index: number) => startIndex + index + 1;

  return {
    handlePrevClick,
    handleNextClick,
    visibleData,
    buildNumberOfData,
    disabled,
  };
};

export default useVirtualPagination;
