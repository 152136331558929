import { createSlice } from '@reduxjs/toolkit';
import { Auth } from '../models';
import authReducer from '../reducers/authReducer';

const nameSlice = 'auth';

const initialState: Auth = {
  haveOldToken: false,
  wasLogged: false,
  refreshWasUsed: false,
  twoStepAuthenticator: null,
  wasReset2Fa: false,
  wasResetPassword: false,
  wasResetBoth: false,
  refreshFailed: false,
  refreshSuccess: false,
  userInfo: null,
};

export const slice = createSlice({
  name: nameSlice,
  initialState,
  reducers: authReducer,
});

export const authActions = slice.actions;

export default slice.reducer;
