import Layout from '../../layout';
import ClientAddForm from '../features/ClientForm/Add';

const ClientCreatePage = () => (
  <Layout>
    <ClientAddForm />
  </Layout>
);

export default ClientCreatePage;
