import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormProps, UseFormReturn, useForm } from 'react-hook-form';

export interface UseInitFormProps<T extends FieldValues> extends UseFormReturn<T> {
  frontEndErros: { [x: string]: any };
}

export default <T extends FieldValues>(
  validatorSchema: any,
  defaultValues?: any | undefined
): UseInitFormProps<T> => {
  const [config, setConfig] = useState<UseFormProps>();

  useEffect(() => {
    const resolver =
      validatorSchema === undefined || validatorSchema === null
        ? {}
        : { resolver: yupResolver(validatorSchema) };

    const d = { defaultValues };
    setConfig({ ...resolver, ...d, mode: defaultValues ? 'onChange' : 'onSubmit' });
  }, [validatorSchema, JSON.stringify(defaultValues)]);

  const form = useForm<T>({ ...config } as any);

  useEffect(() => {
    form.reset(defaultValues); // Use reset para atualizar os valores padrão
  }, [JSON.stringify(defaultValues)]);

  return {
    frontEndErros: form.formState.errors,
    ...form,
  };
};
