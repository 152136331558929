import RolesRedirect from '../../auth/features/RolesRedirect';
import ClientEditForm from '../../client/features/ClientForm/Edit';
import Layout from '../../layout';
import SettingsExplore from '../features/SettingsExplore';

const SettingsPage = () => (
  <Layout>
    <RolesRedirect
      superUser={<SettingsExplore />}
      clientAdministrator={<ClientEditForm />}
      clientUser={<></>}
    />
  </Layout>
);

export default SettingsPage;
