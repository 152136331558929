import Layout from '../../layout';
import ProfileForm from '../features/ProfileForm';

const ProfileEditPage = () => (
  <Layout>
    <ProfileForm />
  </Layout>
);

export default ProfileEditPage;
