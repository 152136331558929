import { useEffect, useState } from 'react';
import OldConfirmationDialog from '../../../../@shared/Dialogs/oldConfirmationDialog';

const ExplanationFormDialog = ({ onClose }: { onClose: Function }) => {
  const title = 'Esqueci minha senha e a verificação de duas etapas';
  const supportEmail = 'suporte@arquivolocal.com.br';
  const text = `Por favor encaminhe o email com a explicação para email ${supportEmail}`;
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show) onClose();
  }, [onClose, show]);

  return (
    <OldConfirmationDialog onClick={() => setShow(!show)} title={title} open={show} text={text} />
  );
};

export default ExplanationFormDialog;
