import { useState } from 'react';
import useRequest from '../../../../@shared/Requests/useRequest';
import { Option } from '../../../../@shared/models';
import useUnique from '../../../../@shared/unique.hook';
import { formatDate } from '../../../../@shared/utils/util';
import classificationEndpoints from '../../classification.endpoint';
import classificationColumns from './classificationColumns';
import {
  ClassificationFilter,
  ClassificationFilterKeys,
  ClassificationRow,
} from './classificationDataGrid.types';

const useClassificationDataGrid = () => {
  const request = useRequest<ClassificationRow[]>(classificationEndpoints.browse());

  request.useFetchBy('init');

  const [filterValues, setFilterValues] = useState<ClassificationFilter>({
    name: '',
    inclusionDate: '',
    cancelationDate: '',
    categories: 0,
  });

  const handleFilter = (key: ClassificationFilterKeys, value: any) => {
    if (key === 'name') {
      setFilterValues({ ...filterValues, name: value });
    }
    if (key === 'inclusionDate') {
      setFilterValues({ ...filterValues, inclusionDate: value });
    }
    if (key === 'categories') {
      setFilterValues({ ...filterValues, categories: Number(value) });
    }
  };

  const filteredRows = request.successData?.filter((row: ClassificationRow) => {
    const rowName = row.name?.toLowerCase().trim();
    const rowInclusionDate = formatDate(row.inclusionDate ?? '')
      .toString()
      .toLowerCase()
      .trim();

    const filterName = filterValues.name?.trim().toLowerCase();
    const filterInclusionDate = filterValues.inclusionDate?.trim().toLowerCase();

    const conditionName = filterName?.length === 0 || rowName.startsWith(filterName);
    const conditionInclusionDate =
      filterInclusionDate?.length === 0 || rowInclusionDate.startsWith(filterInclusionDate);
    const conditionCategories =
      filterValues.categories !== 0
        ? row.categories.some((e) => e.categoryId === filterValues.categories)
        : true;

    return conditionName && conditionInclusionDate && conditionCategories;
  });

  const categories = (request.successData?.map((e) => e.categories) ?? []).flat();
  const filteredOption = useUnique(categories, (e) => e.categoryId);
  const options: Option[] = filteredOption.map((e) => ({ label: e.name, value: e.categoryId }));

  return {
    columns: classificationColumns(filterValues, handleFilter, options),
    rows: filteredRows,
    loading: request.loading,
    errorText: '',
    error: request.error,
    success: request.success,
  };
};

export default useClassificationDataGrid;
