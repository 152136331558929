import { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import useRequest from './Requests/useRequest';
import { Exception, UploadResult, UseRequest } from './models';
import { compressFile } from './utils/util';

export interface UseUploadFileValue {
  requestFile: UseRequest<UploadResult, Exception>;
  firstValue: File;
  value: FileList;
  onChange: (e: any) => void;
}

const useUploadFile = (
  endpoint: (params: FormData) => AxiosRequestConfig<any>,
  shouldCompress?: boolean
): UseUploadFileValue => {
  const [firstValue, setFirstValue] = useState<File>({} as File);
  const [value, setValue] = useState<FileList>(new DataTransfer().files);
  const requestCsv = useRequest<UploadResult>();
  const [compressFileLoading, setCompressFileLoading] = useState<boolean>(false);

  const onChange = useCallback(
    async (e: any) => {
      const files = e.target?.files;
      const input = files?.item(0) as File;
      if (e.target?.files) setValue(files);

      const formData = new FormData();
      if (shouldCompress) {
        setCompressFileLoading(true);
        const c = await compressFile(input);
        formData.append('file', c);
        setCompressFileLoading(false);
      } else {
        formData.append('file', input);
      }

      requestCsv.fetch(endpoint(formData));
      setFirstValue(input);
    },
    [endpoint, requestCsv]
  );

  return {
    requestFile: { ...requestCsv, loading: requestCsv.loading || compressFileLoading },
    firstValue,
    onChange,
    value,
  };
};

export default useUploadFile;
