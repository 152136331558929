import { Option } from '../models';

export const optionBlankNotHidden = { label: 'Selecione', value: '', hidden: false };
const optionBlank: Option = { label: 'Selecione', value: -0, hidden: true };
const optionBlank2: Option = { label: 'Selecione', value: '', hidden: true };
const optionBlank3: Option = { label: 'Selecione', value: undefined as any, hidden: true };

export const optionBlanks = [optionBlank, optionBlank2, optionBlank3];

const createOptions = (
  options: Option[],
  optionsClassName: string,
  disableBlankOptions?: boolean
) => {
  let jsxElements;
  const opt = !options || options?.length <= 0 ? [] : options;
  const optionBankConditional = disableBlankOptions ?? false ? [] : optionBlanks;

  const optionsWithBlank =
    opt.length > 0 ? [...optionBankConditional, ...opt] : [...optionBankConditional];

  const isArray = Array.isArray(optionsWithBlank);
  if (isArray) {
    jsxElements = optionsWithBlank
      .map((op) => {
        if (op === null || op === undefined) return null;
        return (
          <option
            key={`options.${Math.random()}`}
            value={op.value}
            hidden={op.hidden}
            disabled={op.disabled}
            className={optionsClassName}
          >
            {op.label}
          </option>
        );
      })
      .filter((e) => e !== null);
  }
  return jsxElements;
};

export default createOptions;
