import { useContext, useEffect } from 'react';
import classificationEndpoints from '../../../modules/classification/classification.endpoint';
import useRequest from '../../Requests/useRequest';
import useInitialize from '../../hook.initialize';
import { CategoryAccordionContext } from './categoryAccordion.context';
import { CategoryAccordionItemContext } from './item/categoryAccordion.item.context';
import { Category } from '../../../modules/category/features/CategoryForm/types';

interface CategoryAccordionFetchCategoriesProps {
  reload?: boolean;
  onLoading?: () => void;
  onSuccess?: (categories: Category[]) => void;
}

const CategoryAccordionFetchCategories = ({
  reload,
  onLoading,
  onSuccess,
}: CategoryAccordionFetchCategoriesProps) => {
  const context = useContext(CategoryAccordionItemContext);
  const parentContext = useContext(CategoryAccordionContext);
  const request = useRequest();
  const id = parentContext?.boxClassificationId ?? context?.boxClassificationId;
  const key = 'categories';

  useInitialize(() => {
    if (parentContext) {
      parentContext?.setCategories(null);
      parentContext.monitor?.track(key, { error: false, loading: false, success: false });
    }
  });

  request.useFetchBy(reload === true);
  request.useFetchBy(request.requestConfig);
  request.useLoading(() => {
    if (parentContext) {
      parentContext?.setCategories(null);
      parentContext.monitor.setFailure(key);
    }
    if (onLoading) {
      onLoading();
    }
  });
  request.useSuccess(() => {
    if (parentContext) {
      parentContext?.setCategories(request.successData);
      parentContext.monitor.setSuccess(key);
    }
    if (onSuccess) {
      onSuccess(request.successData);
    }
  });
  request.useFailure(() => {
    if (parentContext) {
      parentContext?.setCategories(null);
      parentContext.monitor.setFailure(key);
    }
  });

  useEffect(() => {
    if (id) {
      request.setRequestConfig(
        classificationEndpoints.listEditOptions({
          boxClassificationId: id,
        })
      );
    }
  }, [id]);

  return <></>;
};

export default CategoryAccordionFetchCategories;
