import useStep0 from './step0.hook';
import Step0Presentation from './step0.presentation';

const Step0 = () => {
  const {
    control,
    disableNextStep,
    disablePreviousStep,
    errors,
    onClickNextStep,
    onClickPreviousStep,
    requester,
    success,
    value,
  } = useStep0();

  return (
    <Step0Presentation
      disableNextStep={disableNextStep}
      disablePreviousStep={disablePreviousStep}
      onClickNextStep={onClickNextStep}
      onClickPreviousStep={onClickPreviousStep}
      control={control}
      errors={errors}
      requester={requester}
      success={success}
      value={value}
    />
  );
};

export default Step0;
