import { FiletypeCsv } from 'react-bootstrap-icons';
import RedirectButton from '../../../@shared/Buttons/RedirectButton';
import Layout from '../../layout';
import BoxAddForm from '../features/BoxAddForm';

const ConsultBoxAddPage = () => (
  <Layout>
    <BoxAddForm
      top={(props) => (
        <RedirectButton to={{ pathname: 'add-csv', state: props }}>
          <FiletypeCsv />
        </RedirectButton>
      )}
    />
  </Layout>
);

export default ConsultBoxAddPage;
