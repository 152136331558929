import { useParams } from 'react-router';
import useAuthSlice from '../Auth/useAuthSlice';
import secretService from '../storage/secretService';
import ResetTokenValidator, { ResetTokens } from './resetTokenValidator';

const useParamsToken = () => {
  const initToken = (paramsToken) => {
    if (paramsToken !== undefined) {
      return paramsToken;
    }
    return undefined;
  };
  const { token: paramsToken } = useParams<{ token?: string }>();
  return initToken(paramsToken);
};

interface IVerifyResetToken {
  onSuccess: JSX.Element;
  tokenType: ResetTokens;
}

const VerifyResetToken = ({ onSuccess, tokenType }: IVerifyResetToken) => {
  const token = useParamsToken();
  const redux = useAuthSlice();
  const authState = redux.useState();

  if (authState.twoStepAuthenticator && secretService.get()) {
    return onSuccess;
  }
  return <ResetTokenValidator token={token} tokenType={tokenType} />;
};

export default VerifyResetToken;
