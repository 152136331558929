import ClientForm from '..';
import ErrorFormAlert from '../../../../../@shared/Alerts/errorFormAlert';
import useRequestForm from '../../../../../@shared/Forms/RequestForm/useRequestForm';
import VerifyLoading from '../../../../../@shared/Verifiers/verifyLoading';
import SuccessPage from '../../../../../@shared/template/_successPage';
import ClientsEndpoint from '../../../clients.endpoints';
import clientEditFormValidator, { ClientEditFormState } from './clientEditForm.validator';

const ClientEditForm = () => {
  const form = useRequestForm<ClientEditFormState>({
    defaultValueRequest: () => ClientsEndpoint.findClient(),
    submitRequest: (obj) =>
      ClientsEndpoint.editMyClient({
        ...obj,
        subsidiaryId: obj.subsidiaryId,
        migrationTypes: obj.migrationTypes,
      }),
    validatorSchema: clientEditFormValidator,
    transformDefaultValues: (obj) => ({
      ...obj,
      subsidiaryId: obj?.subsidiaryId ?? 0,
      migrationTypes: obj?.migrationTypes?.map((e) => e.migrationTypeId) ?? [],
    }),
  });

  if (form.submitResponse.loading || form.defaultValueRequest?.loading)
    return <VerifyLoading loadings={[true]} />;

  if (form.defaultValueRequest?.error) {
    return <ErrorFormAlert errors={form.defaultValueRequest.backEndErrors as any} />;
  }
  if (form.submitResponse.success) {
    return <SuccessPage text="Cliente foi editado com sucesso" />;
  }

  const sub = {
    subsidiaryId: form.defaultValueRequest?.successData?.subsidiaryId ?? 0,
    name: form.defaultValueRequest?.successData?.subsidiaryName ?? '',
  };

  return (
    <ClientForm
      migrationTypes={form.defaultValueRequest?.successData?.migrationTypes}
      subsidiaries={[sub]}
      type="edit"
      method="post"
      register={form.register}
      errors={form.validator.errors}
      control={form.control}
      noValidate
      onSubmit={form.handleSubmit(form.onSubmit)}
    />
  );
};

export default ClientEditForm;
