import { Form } from 'react-bootstrap';
import useFocusRef from '../../../../../@shared/focusRef.hook';

const Search = ({
  isCellSelected,
  filtrate,
  value,
}: {
  isCellSelected: boolean;
  value: string;
  filtrate: (name: string) => void;
}) => {
  const { ref } = useFocusRef<HTMLInputElement>(isCellSelected);
  return (
    <div className="d-flex justify-content-end h-100  align-items-center">
      <Form.Control
        placeholder="filtrar pelo nome do arquivo"
        className="h-min"
        ref={ref}
        value={value}
        onChange={(e) => {
          filtrate(e.target.value);
        }}
      />
    </div>
  );
};

export default Search;
