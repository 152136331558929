interface TitlePanelProps {
  children: string[] | string | JSX.Element;
  className?: string;
}

const TitlePanel = ({ children, className }: TitlePanelProps) => (
  <h3 className={className}>{children}</h3>
);

export default TitlePanel;
