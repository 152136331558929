import { AxiosRequestConfig } from 'axios';

import { Filter, UserChangeActive, UserInput } from './user';

const path = 'users';

const userEndpoints = {
  downloadCsv(filters: Filter) {
    return {
      method: 'get',
      url: `${path}/browse-users.csv`,
      params: {
        name: filters.name,
        cpf: filters.cpf,
        email: filters.email,
      },
      responseType: 'blob',
    } as AxiosRequestConfig;
  },

  downloadPdf(filters: Filter, active?: boolean) {
    return {
      method: 'get',
      url: `${path}/browse-users.pdf`,
      params: {
        name: filters.name,
        cpf: filters.cpf,
        email: filters.email,
        active,
      },
      responseType: 'blob',
    } as AxiosRequestConfig;
  },

  getMyRoles() {
    return { method: 'get', url: `${path}/me/get-roles` } as AxiosRequestConfig;
  },

  browse(name?: string, cpf?: string, email?: string) {
    return {
      method: 'get',
      url: `${path}/browse-users`,
      params: {
        name,
        cpf,
        email,
      },
    } as AxiosRequestConfig;
  },

  add(data: UserInput) {
    return { method: 'post', url: `${path}/add-update`, data } as AxiosRequestConfig;
  },

  find(userId: number) {
    return { method: 'get', url: `${path}/${userId}/detail` } as AxiosRequestConfig;
  },

  update(data: UserInput) {
    return { method: 'post', url: `${path}/add-update`, data } as AxiosRequestConfig;
  },

  changeActive(changeActive: UserChangeActive) {
    return {
      method: 'post',
      url: `${path}/change-active`,
      data: changeActive,
    } as AxiosRequestConfig;
  },

  sendEmailOfResetPasswordById(userId: number) {
    return {
      method: 'post',
      url: `${path}/send-email-of-reset-password-by-id`,
      data: userId,
    } as AxiosRequestConfig;
  },

  listCategoriesAsync(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${path}/me/browse-categories:option-tree`,
    };
  },
};

export default userEndpoints;
