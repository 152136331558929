import { useEffect, useState } from 'react';
import classificationEndpoints, {
  BoxClassificationItem,
} from '../../modules/classification/classification.endpoint';
import { UseRequestOption } from './requests.type';
import useRequest from './useRequest';

export type UseRequestGetBoxClassificationItemOption = UseRequestOption & {
  categoryId?: number;
};

const useRequestGetBoxClassificationItem = (
  boxClassificationId: number,
  options?: UseRequestGetBoxClassificationItemOption
) => {
  const [params, setParams] = useState<null | { boxClassificationId: number; categoryId?: number }>(
    null
  );
  const [items, setItems] = useState<BoxClassificationItem[]>([]);
  const requestItem = useRequest<BoxClassificationItem[]>(
    classificationEndpoints.listBoxClassificationItem({
      boxClassificationId: params?.boxClassificationId as number,
      categoryId: params?.categoryId,
    })
  );

  useEffect(() => {
    if (boxClassificationId?.toString() !== params?.toString() && boxClassificationId) {
      setParams({
        boxClassificationId: Number(boxClassificationId),
        categoryId: options?.categoryId,
      });
    }
  }, [boxClassificationId]);

  requestItem.useFetchBy(
    options?.disableDefaultFetch !== true &&
      (options?.extraCondition !== undefined && options.extraCondition !== null
        ? params && options.extraCondition
        : params),
    params
  );
  requestItem.useSuccess(() => {
    setItems(requestItem.successData ?? []);
  });

  return {
    ...requestItem,
    params,
    successData: items,
  };
};

export default useRequestGetBoxClassificationItem;
