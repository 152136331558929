import React from 'react';
import Layout from '../layout';

const HomePage = () => (
  <Layout>
    <div>Home</div>
  </Layout>
);

export default HomePage;
