import { useEffect } from 'react';

import { FormError } from '../Alerts/errorFormAlert';
import { Exception, SetStateAction } from '../models';
import isEmpty from '../utils/util';

export default (
  errors: { [x: string]: any },
  setValidated: SetStateAction<boolean>,
  setArrayErrors: SetStateAction<Record<string, FormError>>,
  exception: undefined | null | Exception | {}
) => {
  const setErrors = () => setArrayErrors(errors);

  const setException = () => {
    if (!isEmpty(exception)) {
      const ex = exception as Exception;
      setArrayErrors({
        exception: { message: ex?.message, type: ex?.data?.typeError } as FormError,
      } as Record<string, any>);
    }
  };

  useEffect(() => {
    if (!errors) setValidated(true);
    else if (!isEmpty(errors)) {
      setErrors();
    } else if (!isEmpty(exception)) {
      setException();
    }
  }, [errors, exception]);
};
