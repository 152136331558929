import FormDialog from '../../../Dialogs/formDialog';
import useDialog from '../../../Dialogs/useDialog';

import useBoxClassificationItemAddForm from '../Add/boxClassificationItemAddForm.hook';
import BoxClassificationItemFormFields from '../boxClassificationItemForm.fields';

interface BoxClassificationItemAddDialogFormProps {
  boxClassificationId: number;
  show: boolean;
  onHide?: Function;
  onSuccess?: (success: boolean) => void;
}

const BoxClassificationItemAddDialogForm = ({
  boxClassificationId,
  show: showProps,
  onHide,
  onSuccess,
}: BoxClassificationItemAddDialogFormProps) => {
  const { control, validator, submitResponse, handleSubmit, formats } =
    useBoxClassificationItemAddForm(boxClassificationId);
  const { show, useClose, close, useOpen } = useDialog({ handleClose: onHide });

  useOpen(showProps);
  useClose(submitResponse.success);
  submitResponse.useSuccess(() => onSuccess && onSuccess(submitResponse.success));

  return (
    <FormDialog
      autoClose={false}
      show={show}
      title="Adicionar Item"
      onHide={close}
      onSubmit={handleSubmit}
    >
      <BoxClassificationItemFormFields
        formats={formats}
        type="add"
        control={control}
        errors={validator.errors}
      />
    </FormDialog>
  );
};

export default BoxClassificationItemAddDialogForm;
