import BoxMovementHistoryDataGridPresentation from './boxMovementHistoryDataGridPresentation';
import useBoxMovementHistoryDataGrid from './useBoxMovementHistoryDataGrid';

const BoxMovementHistoryDataGrid = () => {
  const { columns, rows } = useBoxMovementHistoryDataGrid();

  return <BoxMovementHistoryDataGridPresentation rows={rows} columns={columns} />;
};

export default BoxMovementHistoryDataGrid;
