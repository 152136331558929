import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/application';
import { newToast, resetToast } from '../../../redux/slices/userSuccessToastSlice';

export interface IToast {
  show: boolean;
  reset: () => void;
  text: string;
  setNewToast: (text: string) => void;
}

const useUserToast = () => {
  const dispatch = useDispatch();
  const s = useSelector((state: RootState) => state.userSuccessToast.show);
  const t = useSelector((state: RootState) => state.userSuccessToast.text);

  const reset = () => {
    dispatch(resetToast());
  };

  const setNewToast = (text: string) => {
    dispatch(newToast({ text, show: true }));
  };

  return { reset, setNewToast, show: s, text: t } as IToast;
};

export default useUserToast;
