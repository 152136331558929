import React from 'react';
import { Control, ControllerRenderProps, FieldValues, useController } from 'react-hook-form';
import Field, { FieldControlOmitChildrenProps } from '../Field';
import { TreeViewNode } from '../models';

type Props = FieldControlOmitChildrenProps & FieldControlOmitChildrenProps;

export interface TreeNodeProps {
  id: string;
  parent: TreeViewNode;
  control: Control<FieldValues, object>;
  field: ControllerRenderProps<FieldValues, string>;
  typeValue?: 'number';
  isInvalid?: boolean;
  disabled?: boolean;
  className?: string;
}
interface TreeViewProps extends Omit<Props, 'isInvalid'> {
  type: React.JSXElementConstructor<TreeNodeProps>;
  fill: TreeViewNode[];
  typeValue?: 'number';
  isInvalid?: boolean;
  defaultValue?: number[];
  disabled?: boolean;
}

function defaultValue(fill: TreeViewNode[]): any {
  const a = fill.filter((e) => e.checked === true).map((e) => String(e.value));
  if (a.length === 0) return undefined;
  return a;
}

function TreeView({
  name,
  id,
  control,
  label,
  type,
  fill,
  typeValue,
  containerClassName: className,
  errorMessage,
  defaultValue: defaultValueProps,
  isInvalid,
  disabled,
}: TreeViewProps) {
  const { field } = useController({
    name: id ?? name,
    control,
    defaultValue: defaultValueProps ?? defaultValue(fill),
  });

  return (
    <Field
      className={`mb-3 ${className}`}
      label={label}
      errorMessage={errorMessage}
      isInvalid={isInvalid}
    >
      <div key={id ?? name}>
        {fill?.map((c) =>
          React.createElement(type, {
            id: c.value,
            parent: c,
            control,
            field,
            key: c.value,
            typeValue,
            isInvalid,
            disabled: disabled ?? c.disabled,
            className: 'ps-0',
          })
        )}
      </div>
    </Field>
  );
}

TreeView.Node = Node;
export default TreeView;
