import { useEffect } from 'react';
import useStepsForm from '../../../../../@shared/MultiStepForm/StepsForm/stepsForm.hook';
import { POPULATE_DESCRIPTIONS } from '../boxWizardForm.constants';

const useStep2 = () => {
  const index = 2;
  const {
    useLocalForm,
    useGetPopulateValue,
    previousStep,
    useUpdateStepStatusClickNext,
    isSubmit,
  } = useStepsForm();
  const populateDescriptions = useGetPopulateValue(POPULATE_DESCRIPTIONS);
  const localForm = useLocalForm({ stepIndex: index });

  useUpdateStepStatusClickNext(
    localForm.getValues(),
    localForm.formState.errors,
    localForm.stepsStatus,
    0
  );

  useEffect(() => {
    if (!isSubmit) localForm.reset();
  }, [isSubmit]);

  return {
    control: localForm.control,
    register: localForm.register,
    fields: populateDescriptions,
    handleSubmit: localForm.handleSubmit,
    loading: false,
    errors: localForm.formState.errors as any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleClickNextStep: (v: any) => localForm.handleSubmit(() => {})(),
    handleClickPreviousStep: () => previousStep(),
  };
};

export default useStep2;
