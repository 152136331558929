import RolesRedirect from '../../auth/features/RolesRedirect';
import Layout from '../../layout';
import ClientEditForm from '../features/ClientForm/Edit';

const ClientEditPage = () => (
  <Layout>
    <RolesRedirect superUser={<ClientEditForm />} clientAdministrator={<></>} clientUser={<></>} />
  </Layout>
);

export default ClientEditPage;
