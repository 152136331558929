import StepButtonsPresentation from '../../../../../@shared/MultiStepForm/StepButtons/stepButtons.presentation';
import Step from '../../../../../@shared/MultiStepForm/StepsForm/steps';

export interface Step1PresentationProps {
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
  middle: JSX.Element;
}

const Step1Presentation = ({
  onClickNextStep,
  onClickPreviousStep,
  middle,
}: Step1PresentationProps) => (
  <Step
    title="Definir Colunas CSV"
    middle={middle}
    bottom={
      <StepButtonsPresentation
        disableNextStep={false}
        disablePreviousStep={false}
        hiddenPreviousStep={false}
        onClickNextStep={onClickNextStep}
        onClickPreviousStep={onClickPreviousStep}
      />
    }
  />
);

export default Step1Presentation;
