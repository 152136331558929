import { Badge } from 'react-bootstrap';

const CategoryAccordionBadge = ({ type }: { type?: 'include' | 'new' }) => {
  if (!type) return <></>;
  return (
    <div className="ps-1">
      {type === 'new' && (
        <span className="badge" style={{ backgroundColor: '#f15c27' }}>
          Novo
        </span>
      )}
      {type === 'include' && <Badge bg="primary">Incluído</Badge>}
    </div>
  );
};

export default CategoryAccordionBadge;
