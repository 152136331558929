/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Container, Stack } from 'react-bootstrap';
import CategoryAccordion from '../../../@shared/Accordions/Categories/categoryAccordion';
import { CategorySelectable } from '../../../@shared/Accordions/Categories/categoryAccordion.context';
import BoxClassificationItemAddDialogForm from '../../../@shared/BoxClassificationItems/Forms/AddDialog/boxClassificationItemAddDialogForm';
import BoxClassificationItemEditForm from '../../../@shared/BoxClassificationItems/Forms/Edit/boxClassificationItemEditForm';
import BoxClassificationEditForm from '../../../@shared/BoxClassifications/Forms/Edit/boxClassificationEditForm';
import AddButton from '../../../@shared/Buttons/AddButton';
import DialogButton from '../../../@shared/Buttons/dialogButton';
import { TreeViewNode } from '../../../@shared/Fields/models';
import ToolBar from '../../../@shared/ToolBars/toolbar';
import CategoryChooserDialogServer, {
  CategoryChooserDialogServerSubmit,
} from '../../category/features/CategoryChooserDialog/categoryChooserDialog.server';
import CategoryEditForm from '../../category/features/CategoryForm/categoryEditForm';
import { Category } from '../../category/features/CategoryForm/types';
import Layout from '../../layout';
import BoxClassificationItemAccordion from '../features/ClassificationForm/boxClassificationItemAccordion';
import BoxClassificationItemAccordionItem from '../features/ClassificationForm/boxClassificationItemAccordionItem';

export interface ClassificationEditPageProps {
  boxClassificationId: number;
  parents: TreeViewNode[];
  categories: CategorySelectable;
  categoriesLoading: boolean;

  itemsReload: boolean;
  onItemChange: () => void;

  categoriesReload: boolean;
  onCategoriesChange: (e: Category[]) => void;

  parentsReload: boolean;
  onParentsChange: (e: TreeViewNode[]) => void;

  onAddCategory: (e: CategoryChooserDialogServerSubmit) => void;
  onAddItem: () => void;
}

const ClassificationEditPage = ({
  boxClassificationId,
  itemsReload,
  onItemChange,
  parentsReload,
  onCategoriesChange,
  parents,
  categoriesReload,
  onParentsChange,
  onAddCategory,
  onAddItem,
  categories,
  categoriesLoading,
}: ClassificationEditPageProps) => (
  <Layout>
    <Container className="mt-3">
      <h2>Editar Classificação</h2>
      <Stack gap={3} className="justify-content-between flex-column flex-wrap p-5">
        <Card>
          <Card.Header>
            <Card.Title>Classificação</Card.Title>
          </Card.Header>
          <Card.Body>
            <BoxClassificationEditForm boxClassificationId={boxClassificationId} />
          </Card.Body>
        </Card>

        <BoxClassificationItemAccordion
          boxClassificationId={boxClassificationId}
          reload={itemsReload}
          onSuccess={() => onItemChange()}
          header={
            <ToolBar title="Itens da Classificação">
              <DialogButton
                button={AddButton}
                dialog={(e) => (
                  <BoxClassificationItemAddDialogForm
                    boxClassificationId={boxClassificationId}
                    show={e.show}
                    onHide={() => {
                      e.onHide();
                      onAddItem();
                    }}
                  />
                )}
              />
            </ToolBar>
          }
          body={(e) => (
            <BoxClassificationItemAccordionItem
              key={e.key}
              boxClassificationItemId={e.boxClassificationItem.boxClassificationItemId}
              title={e.boxClassificationItem.name}
              eventKey={e.eventKey}
            >
              <BoxClassificationItemEditForm
                defaultValue={e.boxClassificationItem}
                boxClassificationId={boxClassificationId}
                boxClassificationItemId={e.boxClassificationItem.boxClassificationItemId}
              />
            </BoxClassificationItemAccordionItem>
          )}
        />

        <CategoryChooserDialogServer.Provider>
          <CategoryAccordion boxClassificationId={boxClassificationId}>
            <CategoryAccordion.FetchCategories
              reload={categoriesReload}
              onSuccess={(e) => onCategoriesChange(e)}
            />
            <CategoryAccordion.FetchParents
              reload={parentsReload}
              onSuccess={(e) => {
                onParentsChange(e);
              }}
            />
            <CategoryAccordion.FetchPossibleUsers />
            <ToolBar title="Categorias">
              <CategoryChooserDialogServer.AddButton />
            </ToolBar>
            <CategoryAccordion.Loading isLoading={categoriesLoading}>
              <CategoryAccordion.Children>
                {(e) => (
                  <CategoryAccordion.Item key={e.key} category={e.category} eventKey={e.eventKey}>
                    <CategoryAccordion.Button>
                      <CategoryAccordion.Title />
                    </CategoryAccordion.Button>
                    <CategoryAccordion.Body>
                      <CategoryEditForm
                        direction="horizontal"
                        users={e.users}
                        parents={e.parents}
                        defaultValue={e.category}
                      />
                    </CategoryAccordion.Body>
                  </CategoryAccordion.Item>
                )}
              </CategoryAccordion.Children>
            </CategoryAccordion.Loading>
          </CategoryAccordion>

          <CategoryChooserDialogServer
            options={{ categories: categories.unselected, parents }}
            onSubmit={(e) => {
              onAddCategory(e);
            }}
          />
        </CategoryChooserDialogServer.Provider>
      </Stack>
    </Container>
  </Layout>
);
export default ClassificationEditPage;
