import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import SettingsPage from './pages/settings';

const baseUrl = '/settings';
const routes = {
  baseUrl,
};

const SettingsRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <SettingsPage />
    </PrivateRoute>
  </Switch>
);

export default SettingsRoutes;
