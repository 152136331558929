import { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Exception, JsxElements } from '../models';

export interface IError {
  error: boolean;
  backEndErrors: Exception | null;
}

interface VerifyErrorProps {
  errors: IError[];
  defaultText: string;
  children?: JsxElements;
}

const VerifyError = ({ children = <></>, defaultText, errors: data }: VerifyErrorProps) => {
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState([] as Array<IError>);

  useEffect(() => {
    if (data !== undefined) {
      const f = data?.filter((er: IError) => er?.error === true);
      if (f.length > 0) setErrors(f);
    }

    const isThereAnyError = errors?.length > 0;
    setIsError(isThereAnyError);
  }, [data]);

  return isError ? (
    <>
      <Container className="w-100">
        <Row className="d-flex justify-content-center">
          <Col>
            <Alert key="alertError" variant="danger" className="w-100 mt-4">
              <Alert.Heading>Ocorreu um error</Alert.Heading>
              <p>
                {errors.map((er) => er.backEndErrors?.message?.toString())?.join('/n') ??
                  defaultText}
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <>{children}</>
  );
};

export default VerifyError;
