import { Column } from 'react-data-grid';
import DataGrid from '../../../../@shared/DataGrid';
import { ScannedDocument } from '../../../../@shared/models';

interface BoxDetailProps {
  rows: ScannedDocument[];
  columns: Column<any, any>[];
}

const ScannedDocumentDataGridPresentation = ({ rows, columns }: BoxDetailProps) => (
  <DataGrid className="">
    <DataGrid.Main rowId="scannedDocumentId" rows={rows} columns={columns} />
  </DataGrid>
);

export default ScannedDocumentDataGridPresentation;
