import { ColProps, Form, Placeholder, PlaceholderProps } from 'react-bootstrap';

export type LabelShimmerPlaceholderProps = ColProps & PlaceholderProps & {};

const LabelShimmerPlaceholder = ({
  xs = 3,
  animation = 'glow',
  as = Form.Label,
  ...props
}: LabelShimmerPlaceholderProps) => (
  <Placeholder animation={animation}>
    <Placeholder style={{ height: '24px', borderRadius: '.375rem' }} {...{ ...props, xs, as }} />
  </Placeholder>
);

export default LabelShimmerPlaceholder;
