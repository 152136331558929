import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import isEmpty from '../../../../../@shared/utils/util';
import {
  filterFormValidatorConsult,
  filterFormValidatorExport,
  filterFormValidatorFace,
} from './filterForm.validator';
import submitType from './filterform.constants';

const useFilterFormValidator = () => {
  const [validator, setValidator] = useState<any>(null);

  const setSchema = (e: string) => {
    if (e === submitType.EXPORT_CSV) {
      setValidator(filterFormValidatorExport);
    }
    if (e === submitType.CONSULT) {
      setValidator(filterFormValidatorConsult);
    }
    if (
      e === submitType.EXPORT_FACE_SHEET_LABEL_A4 ||
      e === submitType.EXPORT_FACE_SHEET_LABEL_A5
    ) {
      setValidator(filterFormValidatorFace);
    }
  };

  const useSubmitSuccessful = (form: UseFormReturn<FieldValues, any>, fn: Function) => {
    useEffect(() => {
      if (isEmpty(form.formState.errors) && form.formState.isSubmitSuccessful) {
        fn();
      }
    }, [form.formState.errors, form.formState.isSubmitSuccessful]);
  };

  return {
    useSubmitSuccessful,
    setSchema,
    resolver: validator ? yupResolver(validator) : undefined,
  };
};
export default useFilterFormValidator;
