import { AxiosRequestConfig } from 'axios';
import { AddClient, EditClient, EditMyClient } from '../setting/settings.models';

const name = 'clients';
const client = 'client';
const subsidiaries = 'subsidiaries';
const ClientsEndpoint = {
  findClient(): AxiosRequestConfig {
    return { method: 'get', url: `/me/find-${client}` };
  },

  editClient(data: EditClient): AxiosRequestConfig {
    return { method: 'post', url: `me/edit-${client}`, data };
  },
  editMyClient(data: EditMyClient): AxiosRequestConfig {
    return { method: 'post', url: `me/edit-${client}`, data };
  },

  addClient(data: AddClient): AxiosRequestConfig {
    return { method: 'post', url: `add-${client}`, data };
  },
  listClients(): AxiosRequestConfig {
    return { method: 'get', url: `${name}/me/list-${client}` };
  },

  listSubsidiaries(): AxiosRequestConfig {
    return { method: 'get', url: `list-${subsidiaries}` };
  },
};
export default ClientsEndpoint;
