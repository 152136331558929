import { AxiosRequestConfig } from 'axios';

const name = 'reset-two-step-verification';
const reset2FaEndpoints = {
  verifyReset2FaToken(data: { token: string }) {
    return {
      method: 'post',
      url: `${name}/verify-reset-2fa-token`,
      data: data.token,
    } as AxiosRequestConfig;
  },

  reset() {
    return {
      method: 'get',
      url: `${name}/reset`,
    } as AxiosRequestConfig;
  },
};

export default reset2FaEndpoints;
