import { AxiosRequestConfig } from 'axios';
import useCreateObjectUrlByBlob from '../useCreateObjectUrlByBlob';
import useCreateObjectUrlByBlobPart from '../useCreateObjectUrlByBlobPart';
import useRequest from './useRequest';

const useRequestFileDownload = (
  fileName: string,
  requestConfig: AxiosRequestConfig,
  contentType?: ContentType
) => {
  const request = useRequest(requestConfig);
  const linkByBlobPart = useCreateObjectUrlByBlobPart(
    request.successData,
    fileName,
    contentType ?? 'application/octet-stream'
  );
  const linkByBlob = useCreateObjectUrlByBlob(request.successData, fileName);

  request.useSuccess(() => {
    if (request.successData instanceof Blob) {
      linkByBlob.download();
      request.reset();
    } else {
      linkByBlobPart.download();
      request.reset();
    }
  });

  return request;
};

export default useRequestFileDownload;
