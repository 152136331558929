import { useContext } from 'react';
import DatePicker from '../../../../../../../@shared/Fields/datePicker';
import { shouldPrefix } from '../../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../../categoryForm.context';
import { CategoryFormDatesFieldsProps } from '../../types';

const CategoryFormInclusionDateField = ({
  prefix,
  errors,
  control: controlProp,
}: CategoryFormDatesFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProp ?? context?.config?.control;

  return (
    <DatePicker
      disabled
      name={shouldPrefix(prefix, 'inclusionDate')}
      id={shouldPrefix(prefix, 'inclusionDate')}
      label="Data de inclusão"
      control={control}
      errorMessage={errors?.inclusionDate?.message}
      isInvalid={errors?.inclusionDate !== undefined}
    />
  );
};

export default CategoryFormInclusionDateField;
