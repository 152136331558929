import { useEffect } from 'react';
import reset2FaEndpoints from '../../modules/reset-2fa/reset-2fa.endpoints.urls';
import resetPasswordEndpoints from '../../modules/reset-password/reset-password.endpoints';

import useAuthSlice from '../Auth/useAuthSlice';
import useRequest from '../Requests/useRequest';
import secretService from '../storage/secretService';
import Template from '../template/template';
import VerifyLoading from './verifyLoading';

export type ResetTokens = 'reset-password' | 'reset-2fa' | 'reset-2fa-and-password';

interface IResetTokenValidator {
  token: string;
  tokenType: ResetTokens;
}

const ResetTokenValidator = ({ token, tokenType }: IResetTokenValidator) => {
  const redux = useAuthSlice();
  const fetchVerifyToken = useRequest();
  const defaultText = 'Ops... ocorreu um erro ao acessar o verificador de token';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const name = token === 'reset-password' ? 'verifyResetPasswordToken' : 'verifyReset2FaToken';

  useEffect(() => {
    secretService.remove();
  }, []);

  redux.useVerifyAlreadyDoneTwoStep(fetchVerifyToken, true);

  useEffect(() => {
    if (token) {
      switch (tokenType) {
        case 'reset-password':
          fetchVerifyToken.fetch(resetPasswordEndpoints.verifyResetPasswordToken({ token }));
          break;
        case 'reset-2fa':
          fetchVerifyToken.fetch(reset2FaEndpoints.verifyReset2FaToken({ token }));
          break;

        default:
          throw Error(`tokenType invalid: ${token}`);
      }
    }
  }, [token, tokenType]);

  if (fetchVerifyToken.error) {
    return (
      <Template.Pages.ErrorPage text={fetchVerifyToken.backEndErrors?.message ?? defaultText} />
    );
  }
  return <VerifyLoading loadings={[true]} />;
};

export default ResetTokenValidator;
