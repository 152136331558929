import { ReactNode } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Control, FieldError } from 'react-hook-form';
import { BoxClassification } from '../../../modules/classification/classification.types';
import DatePicker from '../../Fields/datePicker';
import NumberField from '../../Fields/numberField';
import TextField from '../../Fields/textField';
import BoxClassificationFormTemplate from './boxClassificationForm.template';

interface BoxClassificationFormFieldsProps {
  control: Control | Control<BoxClassification>;
  errors: Record<string, FieldError>;
  type: 'edit' | 'add';
  button?: ReactNode;
}

const BoxClassificationFormFields = ({
  control,
  errors,
  button,
  type,
}: BoxClassificationFormFieldsProps) => (
  <BoxClassificationFormTemplate
    name={
      <TextField
        label="Nome"
        control={control}
        id="name"
        isInvalid={!!errors.name}
        errorMessage={errors.name?.message}
      />
    }
    day={
      <NumberField
        defaultValue={0}
        id="day"
        label="Dia"
        control={control}
        min={0}
        isInvalid={!!errors.day}
        errorMessage={errors.day?.message}
      />
    }
    moth={
      <NumberField
        defaultValue={0}
        id="month"
        label="Mês"
        control={control}
        min={0}
        isInvalid={!!errors.month}
        errorMessage={errors.month?.message}
      />
    }
    year={
      <NumberField
        defaultValue={0}
        id="year"
        label="Ano"
        control={control}
        min={0}
        isInvalid={!!errors.year}
        errorMessage={errors.year?.message}
      />
    }
    inclusionDate={
      type === 'edit' ? (
        <DatePicker
          disabled
          label="Data de inclusão"
          control={control}
          name="inclusionDate"
          isInvalid={!!errors.inclusionDate}
          errorMessage={errors.inclusionDate?.message}
        />
      ) : (
        <></>
      )
    }
    cancellationDate={
      type === 'edit' ? (
        <DatePicker
          disabled
          label="Data de cancelamento"
          control={control}
          name="cancellationDate"
          isInvalid={!!errors.cancellationDate}
          errorMessage={errors.cancellationDate?.message}
        />
      ) : (
        <></>
      )
    }
    button={
      button ?? (
        <ButtonGroup className="d-flex justify-content-end">
          <div>
            <Button type="submit">{type === 'add' ? 'Adicionar' : 'Editar'}</Button>
          </div>
        </ButtonGroup>
      )
    }
  />
);

export default BoxClassificationFormFields;
