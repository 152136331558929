import Layout from '../../layout';
import AdminAddForm from '../features/Forms/adminAddForm';

// eslint-disable-next-line arrow-body-style
const AdminAddPage = () => {
  return (
    <Layout>
      <AdminAddForm />
    </Layout>
  );
};

export default AdminAddPage;
