import { useEffect } from 'react';

type F = <T>(data: T[]) => void;
type Config<T> = { data: T; size: number };

export function prepare<T>(config: Config<T>): any[] {
  return Array(config.size).fill(config.data);
}
const usePrepareArray = <T>(config: Config<T>, f: F) => {
  useEffect(() => {
    if (config.size > 0) {
      f(prepare<T>(config));
    }
  }, [JSON.stringify(config.data), JSON.stringify(f), JSON.stringify(config.size)]);
};

export default usePrepareArray;
