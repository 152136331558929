import Layout from '../../layout';
import ScannedDocumentAddForm from '../features/ScannedDocumentForm/Add';

const ScannedDocumentAddPage = () => (
  <Layout>
    <ScannedDocumentAddForm />
  </Layout>
);

export default ScannedDocumentAddPage;
