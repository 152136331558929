import { AxiosRequestConfig } from 'axios';
import { CategoryChooserSuccessData } from '../category/features/CategorySelect';
import { BoxClassification } from './classification.types';

export interface AddBoxClassificationItem {
  boxClassificationId: number;
  format: string;
  name: string;
  size: number;
  observation: string;
  orderNumber?: number | null;
  isPrinted: boolean;
  barcodeRef: boolean;
}

export interface BoxClassificationItem {
  boxClassificationItemId: number;
  boxClassificationId: number;
  format: string;
  name: string;
  size: number;
  observation: string;
  orderNumber?: number | null;
  isPrinted: boolean;
  barcodeRef: boolean;
  inclusionDate: Date;
  cancellationDate?: Date;
}

const pathBoxClassification = 'box-classifications';

const classificationEndpoints = {
  browse() {
    return {
      method: 'get',
      url: `${pathBoxClassification}/me/browse:rows`,
    } as AxiosRequestConfig;
  },
  add(data: BoxClassification & { categories: any[]; items: any[] }) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/me/add`,
      data,
    } as AxiosRequestConfig;
  },
  addCategories(boxClassificationId: number, data: CategoryChooserSuccessData[]) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/${boxClassificationId}/add-categories`,
      data: { categories: data },
    } as AxiosRequestConfig;
  },
  edit(data: BoxClassification) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/me/edit`,
      data,
    } as AxiosRequestConfig;
  },
  find(boxClassificationId: number) {
    return {
      method: 'get',
      url: `${pathBoxClassification}/me/${boxClassificationId}`,
    } as AxiosRequestConfig;
  },
  addItems(boxClassificationId: number, data: AddBoxClassificationItem) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/${boxClassificationId}/items/me/add`,
      data,
    } as AxiosRequestConfig;
  },
  editItem(
    boxClassificationId: number,
    boxClassificationItemId: number,
    data: AddBoxClassificationItem
  ) {
    return {
      method: 'post',
      url: `${pathBoxClassification}/${boxClassificationId}/items/${boxClassificationItemId}/edit`,
      data,
    } as AxiosRequestConfig;
  },
  listBoxClassificationItem(params: { boxClassificationId: number; categoryId?: number }) {
    return {
      method: 'get',
      url: `${pathBoxClassification}/${params?.boxClassificationId}/items/browse`,
      params: { categoryId: params?.categoryId },
    } as AxiosRequestConfig;
  },
  listCategories(params: { boxClassificationId?: number }) {
    return {
      method: 'get',
      url: `clients/me/list-categories`,
      params: { boxClassificationId: params?.boxClassificationId },
    } as AxiosRequestConfig;
  },
  listEditOptions(params: { boxClassificationId?: number }) {
    return {
      method: 'get',
      url: `clients/me/list-categories:edit-options`,
      params: { boxClassificationId: params?.boxClassificationId },
    } as AxiosRequestConfig;
  },
  findCategory(
    props:
      | { boxClassificationId?: number; categoryId: number }
      | { boxClassificationId: number; categoryId?: number }
  ) {
    if (
      (props.boxClassificationId && props?.categoryId === undefined) ||
      props?.categoryId === null
    ) {
      return {
        method: 'get',
        url: `clients/me/list-categories`,
        params: { boxClassificationId: props?.boxClassificationId },
      } as AxiosRequestConfig;
    }
    if (!props.boxClassificationId) {
      return {
        method: 'get',
        url: `categories/me/${props.categoryId}/detail`,
      } as AxiosRequestConfig;
    }
    return {
      method: 'get',
      url: `clients/me/list-categories`,
      params: { categoryId: props?.categoryId },
    } as AxiosRequestConfig;
  },
};

export default classificationEndpoints;
