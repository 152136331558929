import { Accordion, CloseButton, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface BoxClassificationItemAccordionItemProps {
  eventKey: string;
  boxClassificationItemId?: number;
  title: string;
  children: React.ReactNode;
  onRemove?: () => void;
}

const BoxClassificationItemAccordionItem = ({
  title: name,
  eventKey,
  boxClassificationItemId,
  children,
  onRemove,
}: BoxClassificationItemAccordionItemProps) => (
  <Accordion.Item eventKey={eventKey}>
    <Stack direction="horizontal" className="justify-content-center">
      <Accordion.Button as="span">
        {onRemove && <CloseButton className="me-2" onClick={onRemove} />}
        {boxClassificationItemId ? (
          <Link to={`box-classification-items/${boxClassificationItemId}/edit`}>{name}</Link>
        ) : (
          name
        )}
      </Accordion.Button>
    </Stack>
    <Accordion.Body>{children}</Accordion.Body>
  </Accordion.Item>
);

export default BoxClassificationItemAccordionItem;
