import { useContext } from 'react';
import { Control } from 'react-hook-form';
import TextField from '../../../../../../@shared/Fields/textField';
import { shouldPrefix } from '../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../categoryForm.context';
import { CategoryFormRequiredFieldsProps } from '../types';

const CategoryFormNameField = ({
  readonly,
  prefix,
  errors,
  control: controlProps,
}: CategoryFormRequiredFieldsProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProps ?? context?.config?.control ?? ({} as Control);

  return (
    <TextField
      id={shouldPrefix(prefix, 'name')}
      name={shouldPrefix(prefix, 'name')}
      label="Nome"
      control={control}
      errorMessage={errors?.name?.message}
      isInvalid={errors?.name !== undefined}
      disabled={readonly}
    />
  );
};

export default CategoryFormNameField;
