import { useContext } from 'react';
import ErrorFormAlert from '../../../../@shared/Alerts/errorFormAlert';
import { CategoryFormContext } from './categoryForm.context';

const CategoryFormErrorAlert = () => {
  const context = useContext(CategoryFormContext);

  if (!context || Object.keys(context).length === 0) {
    return <></>;
  }

  return <ErrorFormAlert errors={context.errors as any} />;
};

export default CategoryFormErrorAlert;
