import { useEffect } from 'react';

const updateStepsStatus = (context: StepsFormContextValues, index: number, value: StepStatus) => {
  const v = [...context!.stepsStatus];
  v[index] = value;
  context?.setStepsStatus(v);
};

const useInitStep = (context: StepsFormContextValues, stepIndex: number) => {
  useEffect(() => {
    context?.setCurrentStep(stepIndex);

    if (context?.stepsStatus?.at(stepIndex) === 'init') {
      updateStepsStatus(context, stepIndex, '');
    }
  }, [stepIndex, JSON.stringify(context?.stepsStatus)]);
};
export default useInitStep;
