import { useEffect, useState } from 'react';

export default (
  filter: boolean,
  defaultValue: boolean,
  trigger?: React.DependencyList | undefined
) => {
  const [disabled, setDisabled] = useState(defaultValue);
  useEffect(() => {
    setDisabled(filter);
  }, trigger ?? [filter]);

  return disabled;
};
