import { SelectColumn } from '../Fields/models';
import { Option } from '../models';

export function filterUniqueOptions(
  options: Option[],
  values: string[],
  currentValue: string,
  allOptions: Option[],
  ignoreOptions?: Option[]
): Option[] {
  const ignoreSelect = ignoreOptions ?? [];
  const selectedCurrent = allOptions.find((e) => e.value === currentValue);
  const selected = allOptions.filter((e) => values.some((k) => String(e.value) === k));
  const notSelected = allOptions.filter((e) => !values.some((k) => String(e.value) === k));

  if (currentValue !== '' && selected !== null) {
    if (selectedCurrent) return [...ignoreSelect, selectedCurrent, ...notSelected];
    return [...ignoreSelect, ...notSelected];
  }
  return [...ignoreSelect, ...notSelected];
}

export function filterUniqueOptionsFromColumnSelect(
  columns: SelectColumn[],
  value: string[],
  allOptions: Option[],
  ignoreOptions: Option[]
) {
  return columns?.map((e, i) => {
    const r = e;

    r.options = filterUniqueOptions(
      e.options,
      value,
      value?.at(i) ?? '',
      allOptions,
      ignoreOptions
    );
    return r;
  });
}
