/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, ButtonGroup, Col, Container, Dropdown, Form, Row, Stack } from 'react-bootstrap';

import ErrorDialog from '../../../../../@shared/Dialogs/ErrorDialog';
import Field from '../../../../../@shared/Fields/Field';
import { FilterStatus } from '../../../box.models';
import SolicitationButtonGroup from '../SolicitationButtonGroup';
import ChosePrintSize from './ChosePrintSizeModal';
import useFilterForm from './filterForm.hook';
import submitType from './filterform.constants';
import SelectedBoxesCounter from './selectedBoxesCounter';

const FilterForm = () => {
  const {
    handleSubmit,
    total,
    currentBoxMovement,
    btnCancellationDisable,
    btnConsultationDisable,
    btnDigitizationDisable,
    btnExclusionDisable,
    btnMigrationDisable,
    inputDescription,
    inputFinalDate,
    inputInitialDate,
    inputLocation,
    selectStatus,
    errors,
    buttonSubmit,
    chosePrintSizeModal,
  } = useFilterForm();
  return (
    <Form
      onSubmit={handleSubmit}
      className="w-100 h-100 p-0"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <Container>
        <Row>
          <Col>
            <Stack direction="vertical" className="h-100 justify-content-around">
              <Stack direction="horizontal" className="w-100 m-0-important" gap={3}>
                <Field
                  label="Descrição"
                  containerClassName="w-100 m-0-important"
                  isInvalid={errors.description !== undefined}
                  errorMessage={errors?.description?.message as any}
                >
                  <Form.Control
                    isInvalid={errors?.description !== undefined}
                    placeholder="Entre com a descrição"
                    {...inputDescription.field}
                  />
                </Field>
                <Field
                  label="Localização"
                  containerClassName="w-100 m-0-important"
                  isInvalid={errors.location !== undefined}
                  errorMessage={errors?.location?.message as any}
                >
                  <Form.Control
                    isInvalid={errors?.location !== undefined}
                    placeholder="Entre com a localização"
                    {...inputLocation.field}
                  />
                </Field>

                <Field
                  label="Status"
                  containerClassName="w-100 m-0-important"
                  isInvalid={errors.status !== undefined}
                  errorMessage={errors?.status?.message as any}
                >
                  <Form.Select
                    id="status"
                    title="Status"
                    aria-label="Status"
                    isInvalid={errors.status !== undefined}
                    {...selectStatus.field}
                  >
                    <option value={FilterStatus.All}>-- Mostrar Todos --</option>
                    <option value={FilterStatus.Selected}>-- Selecionados --</option>
                    <option value={FilterStatus.Available}>Disponível</option>
                    <option value={FilterStatus.Excluded}>Excluída</option>
                    <option value={FilterStatus.InConsultation}>Em consulta</option>
                    <option value={FilterStatus.RequestedForConsultation}>
                      Solicitada para a consulta
                    </option>
                  </Form.Select>
                </Field>
              </Stack>
              <Stack direction="horizontal" className="w-100" gap={3}>
                <Field
                  label="Data inicial"
                  containerClassName="w-100 m-0-important"
                  isInvalid={errors.initialDate !== undefined}
                  errorMessage={errors?.initialDate?.message as any}
                >
                  <Form.Control
                    isInvalid={errors.initialDate !== undefined}
                    placeholder="dd/mm/aaaa"
                    {...inputInitialDate.field}
                  />
                </Field>

                <Field
                  label="Data final"
                  containerClassName="w-100 m-0-important"
                  isInvalid={errors?.finalDate !== undefined}
                  errorMessage={errors?.finalDate?.message as any}
                >
                  <Form.Control
                    isInvalid={errors?.finalDate !== undefined}
                    placeholder="dd/mm/aaaa"
                    {...inputFinalDate.field}
                  />
                </Field>
              </Stack>
            </Stack>
          </Col>
          <Col className="h-100">
            <Stack className="d-flex justify-content-between">
              <fieldset className="d-flex flex-column align-items-center border h-100 pb-3 px-3 border-bottom-0">
                <legend className=" float-none w-auto px-2">Solicitações</legend>
                <SelectedBoxesCounter total={total} />
                <SolicitationButtonGroup
                  currentBoxMovement={currentBoxMovement}
                  btnCancellationDisable={btnCancellationDisable}
                  btnConsultationDisable={btnConsultationDisable}
                  btnDigitizationDisable={btnDigitizationDisable}
                  btnExclusionDisable={btnExclusionDisable}
                  btnMigrationDisable={btnMigrationDisable}
                />
              </fieldset>
              <Stack direction="horizontal">
                <Button
                  type="submit"
                  className="w-50 rounded-top-0 rounded-end-0"
                  name={buttonSubmit.field.name}
                  ref={buttonSubmit.field.ref}
                  onBlur={buttonSubmit.field.onBlur}
                  value={buttonSubmit.field.value}
                  onClick={() => buttonSubmit.field.onChange(submitType.CONSULT)}
                >
                  Consultar
                </Button>
                <Stack className="w-50" direction="horizontal">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-true"
                      className="rounded-top-0 rounded-start-0 w-100"
                    >
                      Imprimir
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item
                        type="submit"
                        name={buttonSubmit.field.name}
                        ref={buttonSubmit.field.ref}
                        onBlur={() => {
                          buttonSubmit.field.onChange(submitType.EXPORT_CSV);
                        }}
                        value={buttonSubmit.field.value}
                        onClick={() => {
                          buttonSubmit.field.onChange(submitType.EXPORT_CSV);
                        }}
                      >
                        CSV
                      </Dropdown.Item>
                      <Dropdown.Item
                        name={buttonSubmit.field.name}
                        ref={buttonSubmit.field.ref}
                        value={buttonSubmit.field.value}
                        onClick={() => {
                          chosePrintSizeModal.open();
                        }}
                        onBlur={() => {
                          chosePrintSizeModal.open();
                        }}
                      >
                        Folha de Rosto
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Container>
      <ChosePrintSize
        show={chosePrintSizeModal.show}
        handleClose={() => chosePrintSizeModal.close()}
      >
        <ChosePrintSize.A4Button
          onClick={() => {
            buttonSubmit.field.onChange(submitType.EXPORT_FACE_SHEET_LABEL_A4);
            chosePrintSizeModal.close();
          }}
        />
        <ChosePrintSize.A5Button
          onClick={() => {
            buttonSubmit.field.onChange(submitType.EXPORT_FACE_SHEET_LABEL_A5);
            chosePrintSizeModal.close();
          }}
        />
      </ChosePrintSize>
      <ErrorDialog
        show={errors?.selectedRows?.message != null || errors?.selectedRows?.message !== undefined}
      >
        para utilizar essa funcionalidade, é necessário selecionar pelo menos 1 item
      </ErrorDialog>
    </Form>
  );
};

export default FilterForm;
