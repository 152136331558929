import { FieldValues } from 'react-hook-form';
import useDialog, { IDialog, IDialogParams } from '../Dialogs/useDialog';
import useRequestForm, { UseRequestForm, UseRequestFormParams } from './RequestForm/useRequestForm';

export interface UseFormDialog<T extends FieldValues = any> extends UseRequestForm<T>, IDialog {}
export interface UseFormDialogProps<T = any> extends UseRequestFormParams<T>, IDialogParams {}

const useRequestFormDialog = <T extends FieldValues = any>({
  validatorSchema,
  defaultValueRequest,
  handleClose,
  handleOpen,
  submitRequest,
}: UseFormDialogProps<T>): UseFormDialog<T> => {
  const form = useRequestForm<T>({
    submitRequest,
    validatorSchema,
    defaultValueRequest,
  });
  const dialog = useDialog({
    handleClose,
    handleOpen,
  });

  return { ...form, ...dialog };
};

export default useRequestFormDialog;
