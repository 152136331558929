import React, { ReactNode, SVGAttributes } from 'react';
import { Nav } from 'react-bootstrap';

interface Icon extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

interface ISideBarLine {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  Icon: React.ComponentType<Icon>;
  children: string | ReactNode;
}

const Line = ({ onClick, Icon, children }: ISideBarLine) => (
  <Nav.Link onClick={(e) => onClick(e)} className="d-flex align-items-center text-muted">
    <Icon size={40} className="pe-2" />
    {children}
  </Nav.Link>
);

export default Line;
