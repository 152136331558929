/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { FormError } from '../../../../@shared/Alerts/errorFormAlert';
import useEffectSetErrors from '../../../../@shared/Forms/useSetErrors';
import useRequest from '../../../../@shared/Requests/useRequest';
import { ChangePassword, Exception } from '../../../../@shared/models';
import secretService from '../../../../@shared/storage/secretService';

import profileEndpoints from '../../profile.endpoints';
import createFormProfileValidator from './createFormProfileValidator';

export default () => {
  const history = useHistory();
  const { fetch, loading, successData, backEndErrors, error, success } = useRequest();
  const [arrayErrors, setArrayErrors] = useState({} as Record<string, FormError>);
  const [validated, setValidated] = useState(false);
  const schema = createFormProfileValidator();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffectSetErrors(errors, setValidated, setArrayErrors, backEndErrors as Exception);

  async function onSubmit(obj: object) {
    const changePassword = obj as ChangePassword;

    fetch(profileEndpoints.changePasswordAsync(changePassword));
  }

  const accountLogout = () => {
    secretService.remove();
    window.location.reload();
  };

  return {
    reset,
    loading,
    success,
    error,
    arrayErrors,
    validated,
    register,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    successData,
    clearErrors,
    logout: accountLogout,
  };
};
