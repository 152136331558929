import React, { HTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { util } from '../utils/util';
import Field, { FieldControlOmitChildrenProps } from './Field';

interface CheckBoxNode {
  [text: string]: any;
  value: any;
  label: string;
  key: string | number;
  disable?: boolean;
  defaultChecked?: boolean;
  name: string;
}

export type CheckBoxGrouProps = FieldControlOmitChildrenProps &
  HTMLAttributes<HTMLElement> & {
    inline?: boolean;
    defaultValue?: boolean[] | number[] | string[];
    children: CheckBoxNode[];
  };

const defineDefaultChecked = (elementValue: any, controllerValue: any) => {
  if (elementValue === undefined) return false;

  return Array.isArray(controllerValue)
    ? controllerValue.some((e) => String(e) === String(elementValue))
    : String(elementValue) === String(controllerValue);
};

const CheckBoxGroup = ({
  label,
  name,
  inline,
  defaultValue,
  children,
  control,
  isInvalid,
  errorMessage,
  helperText,
  ...props
}: CheckBoxGrouProps) => (
  <Field label={label} isInvalid={isInvalid} errorMessage={errorMessage} helperText={helperText}>
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ validate: { isInvalid: () => isInvalid as boolean } }}
      render={({ field }) => (
        <>
          {children.map((c) => {
            if (c === undefined) return <React.Fragment key={Math.random()} />;
            return (
              <Form.Check
                inline={inline}
                disabled={c.disable ?? false}
                name={c.name ?? name}
                type="checkbox"
                key={c.key}
                value={c.value}
                label={c.label}
                defaultChecked={
                  c.defaultChecked !== undefined
                    ? c.defaultChecked
                    : defineDefaultChecked(c.value, field.value)
                }
                onChange={(e) => field.onChange(util.onChangeCheckBoxGroup(e, field.value))}
                isInvalid={isInvalid}
                ref={(r) => field.ref(r)}
                {...props}
              />
            );
          })}
        </>
      )}
    />
  </Field>
);

export default CheckBoxGroup;
