import { isDate, parse } from 'date-fns';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';
import { AnyObject } from 'yup/lib/types';
import isEmpty from '../../@shared/utils/util';

export function parseStringNumber(value: any, originalValue: any) {
  if (originalValue === '' || originalValue === null) return null;
  const finalVale: Array<number> = value.map((e) => Number(e));
  return finalVale;
}

export function parseDateString(value: any, originalValue: any) {
  if (originalValue?.length === 0 || originalValue === '' || !originalValue) return null;
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd/mm/yyyy', new Date());

  return parsedDate;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsNullable = (params: yup.TestContext<AnyObject>) => params.schema?.spec?.nullable;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, arrow-body-style
const IsNullableBy = (params: yup.BaseSchema) => {
  return params.spec.nullable;
};
yup.setLocale({
  array: pt.array,
  boolean: pt.boolean,
  date: pt.date,
  string: pt.string,
  number: pt.number,
  object: pt.object,
  mixed: {
    default: pt.mixed.default,
    defined: pt.mixed.defined,
    notOneOf: pt.mixed.notOneOf,
    oneOf: pt.mixed.oneOf,
    required: pt.mixed.required,
    notType: (e) => {
      switch (e.type) {
        case 'number':
          return `${e.label} deve conter um valor numérico`;
        case 'date':
          return `${e.label} campo deve conter um valor do tipo data`;
        case 'array':
          return `${e.label} campo deve conter um valor do tipo lista`;
        default:
          return `${e.label} campo deve respeitar o seu tipo definido`;
      }
    },
  },
});

yup.addMethod(yup.string, 'matchesDate', function fn() {
  return this.when({
    is: (e) => IsNullableBy(this) && e?.length > 0,
    then: (s) =>
      s.withMutation((k) =>
        k
          .matches(
            /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/,
            'o campo não contem uma data valida. Certifique-se de inserir a data no formato dd/mm/aaaa'
          )
          .min(10)
          .max(10)
          .test('maxDaysAndMoth', 'o campo não contem uma data valida', (e) => {
            if (!e) return true;
            const [day, month] = e.split('/');
            const d = Number(day);
            const m = Number(month);
            if (d > 31) return false;
            if (m > 12) return false;
            return true;
          })
      ),
  });
});

yup.addMethod(yup.string, 'notRequiredWhenNullOrEmpty', function fn(field: string) {
  return this.when(field, {
    is: (d) => isEmpty(d),
    then(schema) {
      return schema.withMutation((e) => e.notRequired());
    },
    otherwise(schema) {
      return schema.withMutation((e) => e.required());
    },
  });
});

yup.addMethod(yup.mixed, 'requiredWhenNullOrEmpty', function fn(field: string) {
  return this.when(field, {
    is: (d) => isEmpty(d),
    then(schema) {
      return schema.withMutation((e) => e.required());
    },
    otherwise(schema) {
      return schema.withMutation((e) => e.notRequired());
    },
  });
});

yup.addMethod(yup.string, 'minDateRange', function fn(field: string) {
  return this.when(field, {
    is: (d) => isEmpty(d),
    then(schema) {
      return schema.test(
        'minDateRange',
        'o campo deve ser maior ou igual ao campo "Data Inicial"',
        (e, k) => {
          if (!k.parent.initialDate) return true;
          if (!e) return false;

          const [d1, m1, y1] = e?.split('/');
          const [d2, m2, y2] = k.parent.initialDate?.split('/');
          const date1Obj: Date = new Date(`${y1}-${m1}-${d1}`);
          const date2Obj: Date = new Date(`${y2}-${m2}-${d2}`);
          return date1Obj?.getTime() >= date2Obj?.getTime();
        }
      );
    },
    otherwise(schema) {
      return schema;
    },
  });
});

export default yup;
