import React from 'react';
import { FormError } from '../Alerts/errorFormAlert';
import UploadFileField from '../Fields/UploadFileField';
import RequestForm from '../Forms/RequestForm';
import LoadingPage from '../template/_loadingPage';
import SuccessPage from '../template/_successPage';
import { UseUploadFileValue } from '../useUploadFile';

interface UploadCsvFormProps {
  requester: UseUploadFileValue;
  errors?: Record<string, FormError> | undefined;
  control: any;
  value: FileList;
  label: string;
  success: boolean;
  id: string;
  onSuccess?: JSX.Element;
  onLoading?: JSX.Element;
  whapper?: JSX.Element;
  accept?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UploadFileForm = ({
  id,
  requester,
  errors,
  control,
  value,
  success,
  label,
  accept,
  onSuccess = <SuccessPage hiddenLink text="arquivo enviado com sucesso" />,
  onLoading = <LoadingPage />,
  whapper,
}: UploadCsvFormProps) => {
  const component = (
    <RequestForm errors={errors} noValidate validated={false} method="post">
      <UploadFileField
        accept={accept}
        value={value}
        label={label}
        id={id}
        name={id}
        control={control}
        isInvalid={false}
        onChange={requester.onChange}
      />
    </RequestForm>
  );

  if (success) return onSuccess;
  if (requester.requestFile.loading) return onLoading;
  return whapper ? React.cloneElement(whapper, { children: component }) : component;
};

export default UploadFileForm;
