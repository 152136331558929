import { Button } from 'react-bootstrap';
import TextField from '../../Fields/textField';
import RequestForm, { FormControlProps } from '../../Forms/RequestForm/index';

interface IPinForm extends FormControlProps {}

const PinFormPresentation = ({
  errors,
  onSubmit,
  method,
  validated,
  control,
  ...props
}: IPinForm) => (
  <RequestForm {...props} errors={errors} method={method} validated={validated} onSubmit={onSubmit}>
    <TextField
      id="qr-code-pin"
      label="Pin"
      control={control}
      name="pin"
      errorMessage={errors?.pin?.message}
      isInvalid={errors?.pin !== undefined}
    />
    <Button type="submit">Confirma</Button>
  </RequestForm>
);

export default PinFormPresentation;
