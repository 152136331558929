import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { TreeViewNode } from '../../../../@shared/Fields/models';
import yup from '../../../../config/yup/customYup';
import { Category } from '../CategoryForm/types';
import { CategoryChooserSuccessData } from '../CategorySelect';

interface CategoryChooserDialogState {
  selected: CategoryChooserSuccessData[];
  added?: CategoryChooserSuccessData;
}

interface UseCategoryChooserDialogProps {
  onSubmit?: (e: CategoryChooserSuccessData[]) => void;
  onHide?: () => void;
  show?: boolean;
  options: { parents: TreeViewNode[]; categories: Category[] | CategoryChooserSuccessData[] };
  defaultValue?: CategoryChooserSuccessData[];
}

const useCategoryChooserDialog = ({
  onSubmit: onSubmitProp,
  onHide,
  defaultValue,
  show,
  options,
}: UseCategoryChooserDialogProps) => {
  const { control, handleSubmit, reset, formState } = useForm<CategoryChooserDialogState>({
    resolver: yupResolver(
      yup.object({
        selected: yup.array(),
        added: yup.object().when('selected', {
          is: (selected) => selected === undefined,
          then: yup.object({
            name: yup.string().required(),
            isMigration: yup.boolean().required(),
            isDocumentCategory: yup.bool().required(),
            parentId: yup.number(),
          }),
          otherwise: yup.object().notRequired(),
        }),
      })
    ),
  });

  const categorySelect = useController<any>({
    control,
    name: 'selected',
  });

  const onSubmit = (e: CategoryChooserDialogState) => {
    if (e.selected) {
      const valueNotOptions = (defaultValue ?? []).filter(
        (k) =>
          !k.categoryId && (options.categories ?? []).some((t) => t.categoryId !== k.categoryId)
      );

      if (onSubmitProp) onSubmitProp([...valueNotOptions, ...e.selected]);
      reset();
      onHide?.();
      return;
    }
    if (e.added) {
      if (onSubmitProp) onSubmitProp([...(defaultValue ?? []), { ...e.added, readonly: false }]);
      reset();
      onHide?.();
    }
  };

  return {
    show: show ?? false,
    errors: formState.errors,
    onSelect: (e: MultiValue<CategoryChooserSuccessData>) => {
      categorySelect.field.onChange(
        e?.map((k) => {
          const { value, label, ...rest } = k;
          return { ...rest, readonly: true };
        })
      );
    },
    control,
    handleSubmit: handleSubmit(onSubmit),
  };
};

export default useCategoryChooserDialog;
