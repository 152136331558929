/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IDialogConfirm {
  open: boolean;
  title: string;
  text: string;
  buttonText?: string;
  onClick: Function;
}

const OldConfirmationDialog = ({
  title,
  text,
  buttonText,
  onClick,
  open = false,
}: IDialogConfirm) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOnClick = () => {
    onClick();
    setShow(false);
  };

  useEffect(() => {
    if (open) setShow(true);
  }, [open]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleOnClick}>
          {buttonText || 'Confirma'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OldConfirmationDialog;
