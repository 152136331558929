import yup from '../../../../config/yup/customYup';

const userEditFormValidator = yup
  .object({
    active: yup.boolean().required(),
    birthDate: yup.date().required(),
    cpf: yup.string().required().max(11),
    email: yup.string().email(),
    isAdmin: yup.boolean().required(),
    isAdminSubsidiary: yup.boolean().required(),
    login: yup.string().required(),
    name: yup.string().required(),
    occupation: yup.string().required(),
    surname: yup.string().required(),
    userId: yup.number(),
  })
  .required();

export default userEditFormValidator;
