import React, { useEffect, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage, OmitChildren } from '../../models';
import factory from '../../utils/factory';

export interface IChildren {
  children: JSX.Element;
}

export interface FieldProps {
  id?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string | undefined;
  helperText?: string;
  isInvalid?: boolean;
  errorMessage?: string | ErrorMessage | undefined;
  containerClassName?: string;
  className?: string;
  right?: JSX.Element;
}

export type FieldChildrenProps = FieldProps & IChildren & {};

export type FieldControlProps = FieldProps & { name: string } & {
  control: any;
};
export type FieldControlOmitChildrenProps = OmitChildren<FieldControlProps>;
export type FieldRegisterProps = FieldProps & { id: string } & { register: UseFormRegisterReturn };
export type FieldRegisterOmitChildrenProps = OmitChildren<FieldRegisterProps>;

export const replaceNameFieldToField = (text: string) => {
  if (text) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const indexOfIs = text.indexOf(' é ');
    const indexOfShould = text.indexOf('deve');

    if (indexOfIs >= 0) {
      return `campo ${text.slice(indexOfIs)}`;
    }

    if (indexOfShould >= 0) {
      return `campo ${text.slice(indexOfShould)}`;
    }
  }

  return text;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyClassName = (children: JSX.Element, right?: JSX.Element) => {
  switch (true) {
    case right !== undefined:
      return React.cloneElement(children, {
        className: `${children.props.className} rounded-end-0 `,
      });

    default:
      return children;
  }
};

const buildClassName = (isInvalid, er) =>
  `${isInvalid ? 'd-block' : 'd-none'} ${er.className ?? ''}`;
const Field = ({
  children,
  isInvalid = false,
  label,
  errorMessage,
  helperText,
  id,
  containerClassName = '',
  className = '',
  right,
}: FieldChildrenProps) => {
  const [er, setEr] = useState<ErrorMessage>({} as ErrorMessage);
  const [erClassName, setErClassName] = useState<string>('');

  useEffect(
    () => setEr(factory.buildErrorMessage(errorMessage, 'bottom')),
    [errorMessage, isInvalid]
  );
  useEffect(() => setErClassName(buildClassName(isInvalid, er)), [er, errorMessage, isInvalid]);

  return (
    <Form.Group className={`mb-3 ${containerClassName}`} controlId={id}>
      <Stack direction="vertical">
        <Form.Label>{label}</Form.Label>

        <div className={isInvalid ? 'is-invalid' : `${className}`}>
          {er.dir === 'top' && (
            <Form.Control.Feedback className={erClassName} type="invalid">
              {replaceNameFieldToField(er.message)}
            </Form.Control.Feedback>
          )}

          {children}

          {right}

          {er.dir === 'bottom' && (
            <Form.Control.Feedback className={erClassName} type="invalid">
              {replaceNameFieldToField(er.message)}
            </Form.Control.Feedback>
          )}
        </div>
        {helperText && <Form.Text className="text-muted">{helperText}</Form.Text>}
      </Stack>
    </Form.Group>
  );
};

export default Field;
