import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

interface InfoCardProps {
  children: ReactNode;
  onClick?: () => void;
  title?: string;
  text?: string;
  style?: React.CSSProperties;
}

const InfoCard = ({ children, onClick, text, title, style }: InfoCardProps) => (
  <Card
    className="flex-1 m-4 align-items-center justify-content-center cursor-p"
    style={style}
    onClick={onClick}
  >
    <div className="icon-card">{children}</div>
    <Card.Body>
      <Card.Title className="text-center">{title}</Card.Title>
      <Card.Text className="text-justify">{text}</Card.Text>
    </Card.Body>
  </Card>
);
export default InfoCard;
