import { Button, Modal, Stack } from 'react-bootstrap';

interface ChosePrintSizeProps {
  children: JSX.Element[];
  show: boolean;
  handleClose?: () => void;
}

const ChosePrintSize = ({
  children,
  show: showProps,
  handleClose: handleCloseProps,
}: ChosePrintSizeProps) => {
  const handleClose = () => {
    if (handleCloseProps) handleCloseProps();
  };

  return (
    <Modal onHide={() => handleClose()} show={showProps}>
      <Modal.Header closeButton>
        <Modal.Title>Escolha Tamanho do Papel</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Stack direction="horizontal" className="justify-content-evenly">
          {children.map((e) => e)}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose()}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

ChosePrintSize.A4Button = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => (
  <Button size="lg" onClick={onClick}>
    A4
  </Button>
);

ChosePrintSize.A5Button = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => (
  <Button size="lg" onClick={onClick}>
    A5
  </Button>
);

export default ChosePrintSize;
