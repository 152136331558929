import { useState } from 'react';

const useChosePrintSizeModal = () => {
  const [show, setShow] = useState<boolean>(false);

  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  return { show, close, open };
};

export default useChosePrintSizeModal;
