import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import adminEndpoints from '../../admin.endpoint';
import { AdminAddFormState } from '../../admin.types';
import validator from './adminAddForm.validator';

const useAdminAddForm = () => {
  const form = useRequestForm<AdminAddFormState>({
    submitRequest: (e) => adminEndpoints.add({ ...e }),
    validatorSchema: validator,
  });
  return { ...form };
};

export default useAdminAddForm;
