import { Control } from 'react-hook-form';
import { FormDialogProps } from '../../../../@shared/Dialogs';
import FormDialog from '../../../../@shared/Dialogs/formDialog';
import TextField from '../../../../@shared/Fields/textField';

export interface IEmailDialogPresentation extends FormDialogProps {
  bodyText: string;
  control: Control;
}

const EmailDialog = ({ bodyText, ...props }: IEmailDialogPresentation) => {
  const { register, errors, control } = props;
  return (
    <FormDialog register={register} {...props}>
      <p>{bodyText}</p>
      <TextField
        control={control}
        id="email"
        type="email"
        label="Email"
        errorMessage={errors?.email?.message}
        isInvalid={errors?.email != null}
      />
    </FormDialog>
  );
};

export default EmailDialog;
