import { AxiosRequestConfig } from 'axios';
import { SubsidiaryClient } from '../../@shared/models';

import { Email, Login, Pin } from './auth.models';

const name = 'token';
const authEndpoints = {
  auth(data: Login): AxiosRequestConfig {
    return { method: 'post', url: `${name}`, data };
  },

  verify(): AxiosRequestConfig {
    return { method: 'get', url: `${name}/verify` };
  },
  listClient(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/list-clients`,
    };
  },

  chooseClient(data: SubsidiaryClient): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/choose-client`,
      data,
    };
  },

  generateQrCode(): AxiosRequestConfig {
    return {
      method: 'get',
      url: `${name}/2fa/generate-qrcode`,
    };
  },

  verifyPin(data: Pin): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/2fa/verify-pin`,
      data,
    };
  },

  verifyEmail(obj: Email): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/verify-email`,
      data: obj.email,
    };
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendEmailOfResetPasswordByEmail(obj: Email): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/send-email-of-reset-password-by-email`,
      data: obj.email,
    };
  },

  sendEmailOfResetTwoStepVerificationByEmail(obj: Email): AxiosRequestConfig {
    return {
      method: 'post',
      url: `${name}/send-email-of-reset-two-step-verification-by-email`,
      data: obj.email,
    };
  },
};

export default authEndpoints;
