import { Row } from 'react-bootstrap';
import Dialog, { IDialog } from '.';
import { JsxElements } from '../models';
import useDialog from './useDialog';

type DialogOmit = Omit<IDialog, 'onHide' | 'show'>;

interface ConfirmationDialogProps {
  show: boolean;
  bodyText?: string;
  title: string;
  body?: JsxElements;
  handleClose?: Function;
  onClickConfirm?: JSX.Element;
}

const ConfirmationDialog = (dialogsProps: ConfirmationDialogProps & DialogOmit) => {
  const defaultProps = useDialog();
  const { onClickConfirm, show, body, bodyText, handleClose, ...dialogProps } = dialogsProps as any;

  const c = Array.isArray(body) ? [...body] : [body];

  defaultProps.useOpen(show);
  defaultProps.useHandleClose(() => {
    if (handleClose) handleClose();
    if (onClickConfirm) return onClickConfirm;
    return true;
  });

  return (
    <Dialog onHide={defaultProps.close} show={defaultProps.show} {...dialogProps}>
      <Dialog.Header />

      <Dialog.Body>
        {<p>{bodyText}</p> ?? <></>}
        <Row>{c?.map((e) => e)}</Row>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.ConfirmButton />
      </Dialog.Footer>
    </Dialog>
  );
};

export default ConfirmationDialog;
