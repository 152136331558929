import { PayloadAction } from '@reduxjs/toolkit';
import { Option, SubsidiaryClient } from '../../@shared/models';
import { SelectClientForm } from '../models';

export default {
  setData: (state: SelectClientForm, action: PayloadAction<SubsidiaryClient[]>) => {
    state.data = action.payload.map((e) => ({ ...e, label: `(${e.tradingName}) ${e.clientName}` }));
    state.hasOnlyOneClientTheUser = action.payload.length === 0;
    const options: Option<SubsidiaryClient>[] = action.payload.map((e) => ({
      label: `(${e.tradingName}) ${e.clientName}`,
      value: e,
    }));
    state.options = options;
  },
  setClientSelected(state: SelectClientForm, action: PayloadAction<boolean>) {
    state.clientSelected = action.payload;
  },
};
