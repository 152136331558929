import CategoryFormFields from '.';
import { CategoryFormParentsFieldProps } from './Optional/Parents/parents';
import { CategoryFormFieldsBaseProps, TemplateBaseProps } from './types';

export interface CategoryAddFormFieldsProps
  extends CategoryFormFieldsBaseProps,
    CategoryFormParentsFieldProps,
    TemplateBaseProps {}

const CategoryAddFormFields = ({ direction, parents, ...rest }: CategoryAddFormFieldsProps) => (
  <CategoryFormFields.Template
    direction={direction}
    name={<CategoryFormFields.Name {...rest} />}
    isDocumentCategory={<CategoryFormFields.IsDocumentCategory {...rest} />}
    isMigration={<CategoryFormFields.IsMigration {...rest} />}
    parentId={<CategoryFormFields.Parents parents={parents} {...rest} />}
  />
);

export default CategoryAddFormFields;
