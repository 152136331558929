import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, Dropdown } from 'react-bootstrap';
import ReactDataGrid from 'react-data-grid';
import { Link, useHistory } from 'react-router-dom';
import ErrorDialogPresentation from '../Dialogs/ErrorDialog/errorDialog';
import useDialog from '../Dialogs/useDialog';
import LoadingPage from '../template/_loadingPage';

import {
  AddButtonGroupProps,
  AddButtonProps,
  DataGridProps,
  ErrorDialogProps,
  FooterProps,
  HeaderProps,
  MainProps,
  PageNavigationProps,
} from './models';

import DataGridContext, { DataGridContextValues } from './context';
import usePagination from './usePagination';
import LoadingModal from '../../modules/auth/features/ForgotDialogues/LoadingModal';

const AddButtonGroup = ({
  onClick,
  className,
  to,
  title,
  children,
  ...props
}: AddButtonGroupProps) => {
  const history = useHistory();

  return (
    <Dropdown className={`d-flex justify-content-end w-100 ${className}`} title={title}>
      <Link
        {...props}
        itemProp="add-button"
        className={`${className}`}
        to={to}
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
      >
        <Button className="rounded-end-0">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </Link>
      <Dropdown.Toggle split id="dropdown-split-basic" className="rounded-start-0" />
      <Dropdown.Menu>
        {children.map((e) => (
          <Dropdown.Item
            key={Math.random()}
            onClick={(k) => {
              k?.preventDefault();
              history.push(e.to);
            }}
            href={e.to}
          >
            <div>{e.text}</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AddButton = ({
  onClick,
  className,
  to,
  className: containerClassName,
  title,
  ...props
}: AddButtonProps) => (
  <div className={`d-flex justify-content-end w-100 ${containerClassName}`} title={title}>
    <Link
      {...props}
      itemProp="add-button"
      className={`${className}`}
      to={to}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      <Button>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Link>
  </div>
);

const PageNavigation = ({
  resetPagination = false,
  currentPage,
  totalPageChange,
  previousPageDisabled,
  nextPageDisabled,
  onTurnNextPage = Function,
  onTurnPreviousPage = Function,
}: PageNavigationProps) => {
  const context = useContext(DataGridContext);

  const pagination = usePagination(context?.rows ?? [], currentPage, totalPageChange);

  pagination.useReset(resetPagination);
  pagination.useOnTurnNextPage(() => onTurnNextPage());
  pagination.useOnTurnPreviousPage(() => onTurnPreviousPage());

  return (
    <ButtonGroup size="lg" className="p-2">
      <Button
        disabled={previousPageDisabled ?? pagination.previousPageDisabled}
        onClick={() => pagination.turnPreviousPage()}
      >
        &laquo; Anterior
      </Button>
      <Button
        disabled={nextPageDisabled ?? pagination.nextPageDisabled}
        onClick={() => pagination.turnNextPage()}
      >
        Proxima &raquo;
      </Button>
    </ButtonGroup>
  );
};

// eslint-disable-next-line arrow-body-style
const Header = ({ children, className }: HeaderProps) => {
  return (
    <div
      itemProp="header"
      className={`bg-light border border-1 border-bottom-0 d-flex w-100 ${className}`}
    >
      {children}
    </div>
  );
};

const Footer = ({ children }: FooterProps) => (
  <div itemProp="footer" className="bg-light border border-1 border-top-0 d-flex w-100">
    {children}
  </div>
);
export const noRowsFallback = (
  <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>Ops não foi encontrado nenhum item</div>
);

const Main = ({
  rowHeight = 58,
  rows = null,
  rowId,
  columns,
  loading,
  className,
  selectedRows,
  style,
  onSelectedRowsChange,
  ...props
}: MainProps) => {
  const context = useContext(DataGridContext);

  useEffect(() => {
    if (context) context.rows = rows ?? [];
  }, [context, JSON.stringify(rows)]);

  if (!rowId) return <LoadingPage />;
  return (
    <ReactDataGrid
      {...props}
      rows={rows ?? []}
      className={className}
      columns={columns}
      rowHeight={rowHeight}
      rowKeyGetter={(e) => e[rowId]}
      selectedRows={selectedRows ?? (new Set() as any)}
      onSelectedRowsChange={(e) => {
        if (onSelectedRowsChange) onSelectedRowsChange(e);
      }}
      components={{ noRowsFallback }}
      style={style}
    />
  );
};

const ErrorDialogCustom = ({ message, isInvalid }: ErrorDialogProps) => {
  const errorDialog = useDialog();
  const [m, setM] = useState<string | null>(null);

  useEffect(() => {
    if (message !== '' && message !== null) setM(message);
  }, [message, m, isInvalid]);

  useEffect(() => {
    if (isInvalid && m !== null) errorDialog.open();
  }, [isInvalid, m]);

  if (message === null && isInvalid === true) return <LoadingModal />;
  return (
    <ErrorDialogPresentation
      title="Atenção"
      message={m as string}
      onHide={() => errorDialog.close()}
      show={errorDialog.show}
    />
  );
};

const DataGrid = ({ children = <></>, className = 'w-100 h-100 py-4' }: DataGridProps) => {
  const cast = Array.isArray(children) ? children : [children];
  const initialValue: DataGridContextValues = { rows: [] };

  return (
    <DataGridContext.Provider value={initialValue}>
      <Container fluid className={`${className} h-100`}>
        {cast.map((e) => e)}
      </Container>
    </DataGridContext.Provider>
  );
};

DataGrid.ErrorDialog = ErrorDialogCustom;
DataGrid.Main = Main;
DataGrid.Header = Header;
DataGrid.Footer = Footer;

DataGrid.PageNavigation = PageNavigation;
DataGrid.AddButton = AddButton;
DataGrid.AddButtonGroup = AddButtonGroup;

export default DataGrid;
