import React, { useContext, useEffect } from 'react';
import AccordingShimmerPlaceholder from '../../ShimmerPlaceholders/accordingShimmerPlaceholder';
import { CategoryAccordionContext } from './categoryAccordion.context';

interface CategoryAccordionContextProps {
  children: React.ReactNode | React.ReactNode[];
  isLoading?: boolean;
  onLoading?: (e: boolean) => void;
}

const CategoryAccordingLoading = ({
  children,
  onLoading,
  isLoading,
}: CategoryAccordionContextProps) => {
  const context = useContext(CategoryAccordionContext);

  useEffect(() => {
    if (context?.loading && onLoading) {
      onLoading(context.loading);
    }
  }, [context?.loading]);

  useEffect(() => {
    if (isLoading === true) {
      context?.monitor.track('CategoryAccordingLoading', {
        loading: true,
        error: false,
        success: false,
      });
    }
    if (isLoading === false) {
      context?.monitor.track('CategoryAccordingLoading', {
        loading: false,
        error: false,
        success: true,
      });
    }
  }, [isLoading]);

  if (context?.loading) {
    return <AccordingShimmerPlaceholder />;
  }

  return <>{React.Children.map(children, (e) => e)}</>;
};

export default CategoryAccordingLoading;
