import { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import useDynamicValidator from '../../../../@shared/useDynamicValidator';
import factory from '../../../../@shared/utils/factory';
import { util } from '../../../../@shared/utils/util';
import yup from '../../../../config/yup/customYup';
import boxesEndpoints from '../../box.endpoints';
import { BoxForm, OptionCancellationDatePair } from '../../box.models';

interface UseBoxFormProps {
  fields;
  onClassificationChange;
  onCategoryChange;
  optionCancellationDatePair: OptionCancellationDatePair[];
}
export type BoxFormDisabled = {
  barcode: boolean;
  classification: boolean;
  location: boolean;
  exclusionDate: boolean;
  isManualBarcode: boolean;
};

const useBoxForm = ({
  fields,
  onCategoryChange,
  onClassificationChange,
  optionCancellationDatePair,
}: UseBoxFormProps) => {
  const schema = useDynamicValidator(fields, {
    categoryId: yup.number().required(),
    classificationId: yup.string().required(),
    location: yup.string().nullable(),
    exclusionDate: yup.date(),
    isManualBarcode: yup.bool(),
    barcode: yup.string().when('isManualBarcode', {
      is: true,
      then: yup.string().required().min(12),
      otherwise: yup.string(),
    }),
  });
  const form = useRequestForm<BoxForm>({
    validatorSchema: schema,
    submitRequest: (obj) => boxesEndpoints.add(factory.buildAddBox(obj, fields)),
  });
  const exclusionDate = useController({ control: form.control, name: 'exclusionDate' });
  const [disabled, setDisabled] = useState<BoxFormDisabled>({
    barcode: true,
    classification: true,
    location: true,
    exclusionDate: true,
    isManualBarcode: true,
  });
  const watch = useWatch({
    control: form.control,
  });

  // useClearFormRegisters({
  //   form,
  //   keepValues: factory.buildKeepValuesForBoxForm(fields),
  //   trigger: fields,
  // });
  useEffect(() => {
    const pair = optionCancellationDatePair.find((e) =>
      util.isEqualDeep(String(e.option.value), String(watch.classificationId))
    );

    if (pair?.cancelationDate) {
      exclusionDate.field.onChange(pair.cancelationDate);
    }
  }, [JSON.stringify(watch.classificationId)]);

  useEffect(
    () => onClassificationChange(watch.classificationId),
    [JSON.stringify(watch.classificationId)]
  );
  useEffect(() => onCategoryChange(watch.categoryId), [JSON.stringify(watch.categoryId)]);
  useEffect(() => {
    if (watch)
      setDisabled({
        classification: !watch.categoryId,
        location: !watch.classificationId,
        exclusionDate: !watch.classificationId,
        isManualBarcode: !watch.classificationId,
        barcode: !watch.isManualBarcode ?? true,
      });
  }, [watch]);

  return {
    disabled,
    success: form.submitResponse.success,
    loading: form.submitResponse.loading,
    control: form.control,
    register: form.register,
    validated: form.validator.validated,
    errors: form.validator.errors,
    handleSubmit: form.handleSubmit(form.onSubmit),
  };
};

export default useBoxForm;
