import { UseFormReturn } from 'react-hook-form';
import isEmpty from '../../utils/util';

const utils = {
  defineStepStatus(form: UseFormReturn) {
    switch (true) {
      case form.formState.submitCount === 0:
        return 'init';

      case form.formState.isSubmitSuccessful:
        return 'successful';

      case Object.keys(form.formState.errors).length > 0:
        return 'error';

      default:
        return '';
    }
  },
  getStepsFormContextDefaultValue(): StepsFormContextValues {
    return {
      numberOfSteps: 0,
      stepsStatus: [],
      setStepsStatus: () => {},
      canGoToNextStep: false,
      setCanGoToNextStep: () => {},
      disabledSteps: [],
      setDisabledSteps: () => {},
      currentStep: 0,
      setCurrentStep: () => {},
      value: {},
      setValue: () => {},
      errors: {},
      setErrors: () => {},
      populate: {},
      setPopulate: () => {},
      successfulAllForms: false,
      setSuccessfulAllForms: () => {},
      setSubmit: () => {},
      submit: false,
    };
  },
  updateStepError(index: number, value: any, context: StepsFormContextValues) {
    const v = { ...context!.errors };
    if (!isEmpty(value)) {
      v[index] = value;
      context?.setErrors(v);
    }
    return v;
  },
  stepIsInit(index: number, context: StepsFormContextValues) {
    return context?.stepsStatus?.at(index) === 'init';
  },
  stepIsValidated(index: number, context: StepsFormContextValues) {
    return context?.stepsStatus?.at(index) === 'validated';
  },
  isSuccessfulAllForms(context: StepsFormContextValues) {
    return context?.successfulAllForms ?? false;
  },
  successfulAllForms(context: StepsFormContextValues) {
    context?.setSuccessfulAllForms(true);
    context.setSubmit(true);
  },
  submit(context: StepsFormContextValues) {
    context?.setSubmit(true);
  },
  notSuccessfulAllForms(context: StepsFormContextValues) {
    context?.setSuccessfulAllForms(false);
  },
  updateStepsStatus(index: number, value: StepStatus, context: StepsFormContextValues) {
    const v = [...context!.stepsStatus];
    v[index] = value;
    context?.setStepsStatus(v);
    return v;
  },
  updateStepValidated(index: number, context: StepsFormContextValues) {
    const v = [...context!.stepsStatus];
    v[index] = 'validated';
    if ((context?.numberOfSteps ?? 0) > index + 1) {
      v[index + 1] = '';
    }
    context?.setStepsStatus(v);
    return v;
  },
  updateStepInvalided(index: number, context: StepsFormContextValues) {
    return utils.updateStepsStatus(index, 'invalided', context);
  },
  updateStepValue(index: number, value: any, context: StepsFormContextValues) {
    const v = { ...context!.value };
    v[index] = value;
    context?.setValue(v);
  },
};

export default utils;
