/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';

import ErrorFormAlert, { FormError } from '../Alerts/errorFormAlert';
import RequestForm, {
  FormProps,
  FormPropsOmitForDialog,
  FormRegisterProps,
} from '../Forms/RequestForm';
import { UseRequestForm } from '../Forms/RequestForm/useRequestForm';
import { JsxElements } from '../models';

export type FormDialog = DialogProps & UseRequestForm;
export type DialogProps = ModalProps & {
  title: string;
  onHide?: () => void;
  show: boolean;
};
export interface IDialog extends DialogProps {}

interface IDialogContext extends DialogProps {
  form?: FormProps;
  header: { children?: JsxElements };
  body: { children?: JsxElements; className?: string };
  footer: { children?: JsxElements };
}

interface IChildrenOptional {
  children?: JsxElements;
}

export const DialogContext = React.createContext<IDialogContext>({} as IDialogContext);

export interface FormDialogProps extends FormPropsOmitForDialog, DialogProps {
  children?: JsxElements;
}
export interface IFormDialogOmitChildren extends Omit<FormDialogProps, 'children'> {}

const Dialog = ({ title, onShow, children, open, onHide, ...dialogRest }: IDialog) => (
  <DialogContext.Provider
    value={
      {
        title,
        onShow,
        children,
        open,
        header: {},
        body: {},
        footer: {},
        onHide,
        ...dialogRest,
      } as IDialogContext
    }
  >
    <>
      {dialogRest.show && (
        <Modal
          {...dialogRest}
          onHide={() => {
            if (onHide) onHide();
          }}
          backdrop="static"
        >
          {children}
        </Modal>
      )}
    </>
  </DialogContext.Provider>
);

interface IHeader extends IChildrenOptional {
  className?: string;
}
const Header = ({ children, className = '' }: IHeader) => {
  const dialogProps = useContext(DialogContext);
  if (children) {
    dialogProps.body.children = children;
  }
  return (
    <Modal.Header closeButton className={className}>
      {children ?? <Modal.Title>{dialogProps.title}</Modal.Title>}
    </Modal.Header>
  );
};

interface IBody extends IChildrenOptional {
  className?: string;
}
const Body = ({ children, className }: IBody) => {
  const dialogProps = useContext(DialogContext);
  if (children) {
    dialogProps.body.children = children;
  }
  if (className) {
    dialogProps.body.className = className;
    return (
      <Modal.Body className={className} key="body">
        {children}
      </Modal.Body>
    );
  }
  return (
    <Modal.Body className={dialogProps.bodyClassName} key="body">
      {children}
    </Modal.Body>
  );
};

interface IFooter extends IChildrenOptional {}
const Footer = ({ children }: IFooter) => {
  const dialogProps = useContext(DialogContext);
  if (children) {
    dialogProps.footer.children = children;
  }
  return <Modal.Footer key="footer">{children}</Modal.Footer>;
};

const CloseButton = ({ type = 'button', onClick, defaultBehavior = true }: DefaultButtonsProps) => {
  const dialogProps = useContext(DialogContext);
  return (
    <Button
      variant="secondary"
      type={type}
      onClick={() => {
        if (onClick) onClick();
        if (defaultBehavior && dialogProps.onHide) dialogProps.onHide();
      }}
    >
      Fechar
    </Button>
  );
};

const ConfirmButton = ({
  type = 'submit',
  onClick,
  defaultBehavior = true,
}: DefaultButtonsProps) => {
  const dialogProps = useContext(DialogContext);
  return (
    <Button
      variant="primary"
      type={type}
      onClick={() => {
        if (onClick) onClick();
        if (defaultBehavior && dialogProps.onHide) dialogProps.onHide();
      }}
    >
      Confirma
    </Button>
  );
};

interface DefaultButtonsProps {
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
  defaultBehavior?: boolean;
}
const DefaultButtons = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dialogProps = useContext(DialogContext);
  return (
    <>
      <CloseButton />
      <ConfirmButton />
    </>
  );
};

const Form = ({ children, ...formProps }: FormProps) => {
  const dialogProps = useContext(DialogContext);
  dialogProps.form = formProps;

  return (
    <RequestForm {...formProps} errors={undefined}>
      {children}
    </RequestForm>
  );
};

interface IErrorAlert {
  errors?: Record<string, FormError>;
  className?: string;
  fullSize?: boolean;
  disableDefaultHeading?: boolean;
  dismissible?: boolean;
  style?: React.CSSProperties;
}

const ErrorAlert = ({
  className,
  errors,
  fullSize = false,
  disableDefaultHeading = true,
  dismissible = true,
  style,
}: IErrorAlert) => {
  const dialogProps = useContext(DialogContext);
  const formContext = dialogProps.form;
  if (!formContext) {
    const f = { errors } as FormRegisterProps;
    dialogProps.form = f;
  }

  return (
    <ErrorFormAlert
      className={className}
      errors={dialogProps.form?.errors}
      fullSize={fullSize}
      disableDefaultHeading={disableDefaultHeading}
      dismissible={dismissible}
      style={style}
    />
  );
};

Dialog.Header = Header;
Dialog.Body = Body;
Dialog.Footer = Footer;
Dialog.DefaultButtons = DefaultButtons;
Dialog.ConfirmButton = ConfirmButton;
Dialog.CloseButton = CloseButton;
Dialog.Form = Form;
Dialog.ErrorAlert = ErrorAlert;

export default Dialog;
