import { createSlice } from '@reduxjs/toolkit';

import { SelectClientForm } from '../models';
import selectClientFormReducer from '../reducers/selectClientFormReducer';

const nameSlice = 'list-user-rows';

const initialState: SelectClientForm = {
  data: [],
  options: null,
  hasOnlyOneClientTheUser: false,
  clientSelected: false,
};

export const slice = createSlice({
  name: nameSlice,
  initialState,
  reducers: selectClientFormReducer,
});

export const selectClientFormSliceActions = slice.actions;

export default slice.reducer;
