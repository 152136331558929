import { useLocation } from 'react-router';
import RedirectAlert from '../../../../@shared/Alerts/redirectAlert';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import SuccessPage from '../../../../@shared/template/_successPage';
import useEditUserForm from '../useEditUserForm';
import UserForm from '../userForm';

const UserEditForm = () => {
  const { state: userId } = useLocation<number>();
  const { handleSubmit, onSubmit, control, validator, register, loading, fill, error, success } =
    useEditUserForm(userId);

  if (error) {
    return (
      <RedirectAlert
        text="Usuário não encontrado"
        variant="danger"
        linkText="voltar para pagina de usuários"
        linkUrl="/users"
      />
    );
  }

  if (success) {
    return (
      <SuccessPage
        text="usuário editado com sucesso!"
        linkText="voltar para pagina de usuários"
        linkUrl="/users"
      />
    );
  }
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <UserForm
      type="edit"
      method="post"
      register={register}
      errors={validator.errors}
      validated={validator.validated}
      control={control}
      fill={fill}
      noValidate
      userId={userId}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export default UserEditForm;
