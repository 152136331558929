import { useContext } from 'react';
import NodeRadioButton from '../../../../../../../@shared/Fields/TreeView/nodeRadioButton';
import TreeView from '../../../../../../../@shared/Fields/TreeView/treeView';
import { TreeViewNode } from '../../../../../../../@shared/Fields/models';
import { shouldPrefix } from '../../../../../../../@shared/utils/util';
import { CategoryFormContext } from '../../../categoryForm.context';
import { CategoryFormFieldsBaseProps } from '../../types';

export interface CategoryFormParentsFieldProps extends CategoryFormFieldsBaseProps {
  parents?: TreeViewNode[];
}

const CategoryFormParentsField = ({
  control: controlProp,
  errors,
  prefix,
  readonly,
  parents: parentsProp,
}: CategoryFormParentsFieldProps) => {
  const context = useContext(CategoryFormContext);
  const control = controlProp ?? context?.config?.control;
  const parents = parentsProp ?? context?.parents ?? [];

  return (
    <TreeView
      typeValue="number"
      id={shouldPrefix(prefix, 'parentId')}
      name={shouldPrefix(prefix, 'parentId')}
      control={control}
      fill={parents}
      label="Categoria Pai"
      type={NodeRadioButton}
      errorMessage={errors?.parentId?.message}
      isInvalid={errors?.parentId !== undefined}
      disabled={readonly}
    />
  );
};

export default CategoryFormParentsField;
