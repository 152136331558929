import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';

interface ISuccessToast {
  show: boolean;
  text: string;
  onShow?: Function;
  title?: string;
}

const SuccessToast = ({
  text: textParam,
  show: successParam,
  onShow: onShowParam,
  title,
}: ISuccessToast) => {
  const delay = 8000;
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (successParam) {
      setShow(true);
      setText(textParam);
    }
  }, [successParam]);

  useEffect(() => {
    if (show && onShowParam) {
      onShowParam();
    }
  }, [show]);

  return (
    <ToastContainer
      position="bottom-start"
      className="p-3 position-fixed"
      style={{ zIndex: 99999 }}
    >
      <Toast onClose={() => setShow(false)} show={show} delay={delay} autohide>
        <Toast.Header>
          <div className="p-2 ps-0">
            <CheckCircleFill className="pl-2" color="green" size={30} />
          </div>
          <strong className="me-auto">{title || 'operação bem sucedida'}</strong>
        </Toast.Header>
        <Toast.Body>{text}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default SuccessToast;
