import { useEffect, useState } from 'react';

function useUnique<T>(data: T[] | null, uniqueField: (item: T) => any): T[] {
  const [uniqueObjects, setUniqueObjects] = useState<T[]>([]);

  useEffect(() => {
    if (data) {
      const uniqueObjectsMap = new Map<any, T>();
      data.forEach((item) => {
        const fieldValue = uniqueField(item);
        uniqueObjectsMap.set(fieldValue, item);
      });

      setUniqueObjects(Array.from(uniqueObjectsMap.values()));
    } else {
      setUniqueObjects([]);
    }
  }, [JSON.stringify({ data, uniqueField })]);

  return uniqueObjects;
}

export default useUnique;
