import { useState } from 'react';
import './categoryAddDialog.css';
import Choices from './choices';

interface CategoryChooserProps {
  children: {
    isCreating: React.ReactNode;
    isIncludeAlreadyExist: React.ReactNode;
  };
}

const CategoryChooser = ({ children }: CategoryChooserProps) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isIncludeAlreadyExist, setIsIncludeAlreadyExist] = useState<boolean>(false);

  return (
    <>
      <>{isCreating && children.isCreating}</>
      <>{isIncludeAlreadyExist && children.isIncludeAlreadyExist}</>
      <>
        {!isCreating && !isIncludeAlreadyExist && (
          <CategoryChooser.Choices
            onCreating={() => setIsCreating(true)}
            onIncludeAlreadyExist={() => setIsIncludeAlreadyExist(true)}
          />
        )}
      </>
    </>
  );
};
CategoryChooser.Choices = Choices;
export default CategoryChooser;
