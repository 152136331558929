/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import boxesEndpoints from '../../modules/box/box.endpoints';
import categoryEndpoints from '../../modules/category/category.endpoints';
import { InputGroup } from '../Fields/models';
import { Exception, Option } from '../models';
import useRequest from './useRequest';
import useRequestGetBoxClassificationItemTextBox from './useRequestGetBoxClassificationItemTextBox';

export interface UseCategoryDynamicFieldRequestProps {
  classificationSelected: number;
  loading: boolean;
  fields: InputGroup[];
  options: {
    classifications: Option[];
    categories: Option[];
  };
  errors: boolean;
  backEndErrors: Exception | null;
}

const useCategoryDynamicFieldRequest = (
  categorySelected: number,
  classificationSelected: number,
  getCategories: boolean,
  getClassifications: boolean,
  getFields: boolean
): UseCategoryDynamicFieldRequestProps => {
  const [classificationOptions, setClassificationOptions] = useState<Option[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<Option[]>([]);

  const requestFillCategory = useRequest<Option[]>(categoryEndpoints.listUserCategoryOptions());
  const requestFillClassification = useRequest<Option[]>(
    boxesEndpoints.listBoxClassificationOptions({ categoryId: categorySelected })
  );
  const requestDynamicFields = useRequestGetBoxClassificationItemTextBox(classificationSelected, {
    disableDefaultFetch: true,
  });
  const isCategoryValid = categorySelected !== undefined && categorySelected !== 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isClassificationValid =
    classificationSelected !== undefined && classificationSelected !== 0;

  requestFillCategory.useFetchBy(getCategories, 'init');
  requestFillClassification.useFetchBy(
    getClassifications && categorySelected && isCategoryValid,
    JSON.stringify(categorySelected)
  );

  requestDynamicFields.useFetchBy(
    classificationSelected && requestDynamicFields.params,
    requestDynamicFields.params
  );

  requestFillClassification.useSuccess(() => {
    setClassificationOptions(requestFillClassification.successData ?? []);
  });
  requestFillCategory.useSuccess(() => {
    setCategoriesOptions(requestFillCategory.successData ?? []);
  });

  return {
    errors:
      requestFillCategory.error || requestFillClassification.error || requestDynamicFields.error,
    backEndErrors:
      requestFillCategory.backEndErrors ||
      requestFillClassification.backEndErrors ||
      requestDynamicFields.backEndErrors,
    classificationSelected,
    loading:
      requestDynamicFields.loading ||
      requestFillCategory.loading ||
      requestFillClassification.loading,
    fields: requestDynamicFields.successData,
    options: {
      classifications: classificationOptions,
      categories: categoriesOptions,
    },
  };
};

export default useCategoryDynamicFieldRequest;
