import { PayloadAction } from '@reduxjs/toolkit';

import { GenericDataGrid } from '../models';

const dataGridReducers = {
  setRows: (
    state: GenericDataGrid,
    action: PayloadAction<{ currentRows: any[]; allRows: any[] }>
  ) => {
    state.currentRows = action.payload.currentRows;
    state.allRows = action.payload.allRows;
  },
};

export default dataGridReducers;
