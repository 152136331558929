import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { TreeViewNode } from '../Fields/models';

export type ReturnType = 'number' | 'text';
export type ChangeType = 'enum' | 'list-number' | 'list-string';

export default class InputFilter {
  static defaultValue(
    parent: TreeViewNode,
    currentData: Array<any>,
    field: ControllerRenderProps<FieldValues, string>
  ) {
    if (parent.checked) currentData.push(parent.value);
    field.onChange(currentData);
  }

  static onChangeCheckBoxGroup(e: React.ChangeEvent<HTMLInputElement>, currentData: any[]) {
    // eslint-disable-next-line no-param-reassign
    currentData ??= new Array<any>();
    let newData = new Array<any>();
    const { value } = e.target;
    newData =
      e.target.checked === true
        ? this.addValue(currentData, value)
        : this.removeValue(currentData, value);

    return newData;
  }

  private static addValue(currentData: any[], value: any) {
    if (currentData !== undefined) currentData?.push(value);

    return currentData;
  }

  private static removeValue(currentData: any[], value: any) {
    return currentData?.filter((e) => e !== value);
  }

  static switchType(currentData: Array<any>, returnType: ChangeType) {
    if (!Array.isArray(currentData)) return undefined;

    const reducerForEnum = (accumulator: string, curr: string) =>
      (Number(accumulator) + Number(curr)).toString();

    switch (returnType) {
      case 'enum':
        return currentData === undefined || currentData?.length === 0
          ? 0
          : Number(currentData?.reduce(reducerForEnum));

      case 'list-number':
        return currentData?.map((e: string) => parseInt(e, 10));

      default:
        return currentData;
    }
  }
}
