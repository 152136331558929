import { PayloadAction } from '@reduxjs/toolkit';
import { FilterStatus, MigrationCategory } from '../../modules/box/box.models';

import { BoxDataGrid } from '../models';
import genericDataGridReducer from './genericDataGridReducer';
import pagerReducer from './pagerReducer';

import { PageSize } from '../../constants/constants';
import RowsSelectorReducer from './rowsSelectorReducer';
import validatorReducer from './validatorReducer';

const initialState: BoxDataGrid = {
  anySubmit: false,
  showSolicitConsultationFormDialog: false,
  showSolicitCancellationFormDialog: false,
  showSolicitDigitalizationFormDialog: false,
  showSolicitExclusionFormDialog: false,
  showSolicitMigrationFormDialog: false,
  statusFilter: FilterStatus.All,
  descriptionFilter: '',
  isSelectedValueInStatusFilter: false,
  wasSearchButtonClicked: false,
  migrationOptions: [],
  isFetchingRows: false,
  totalStatusFilterChange: 0,
  isFirstPage: true,
  rowsId: 'boxId',
  currentRows: [],
  allRows: [],
  selectedIds: new Set<React.Key>(),
  selectedRows: [],
  isInvalid: false,
  errorMessage: '',
  alreadyInit: false,
  page: 1,
  totalPageChanges: 0,
  lastSelectedRows: [],
  locationFilter: '',
  finalDate: '',
  initialDate: '',
  isDownloadingCsv: false,
  isFetchingFaceSheetLabel: false,
  faceSheetLabelPageSize: PageSize.A4,
};

const extraReducers = {
  initialize: (state: BoxDataGrid) => {
    state.allRows = initialState.allRows;
    state.selectedIds = initialState.selectedIds;
    state.alreadyInit = initialState.alreadyInit;
    state.anySubmit = initialState.anySubmit;
    state.currentRows = initialState.currentRows;
    state.descriptionFilter = initialState.descriptionFilter;
    state.isFetchingRows = initialState.isFetchingRows;
    state.isFirstPage = initialState.isFirstPage;
    state.isInvalid = initialState.isInvalid;
    state.errorMessage = initialState.errorMessage;
    state.isSelectedValueInStatusFilter = initialState.isSelectedValueInStatusFilter;
    state.migrationOptions = initialState.migrationOptions;
    state.page = initialState.page;
    state.totalPageChanges = initialState.totalPageChanges;
    state.lastSelectedRows = initialState.lastSelectedRows;
    state.wasSearchButtonClicked = initialState.wasSearchButtonClicked;
    state.isDownloadingCsv = initialState.isDownloadingCsv;
    state.descriptionFilter = initialState.descriptionFilter;
    state.statusFilter = initialState.statusFilter;
    state.finalDate = initialState.finalDate;
    state.initialDate = initialState.initialDate;
    state.locationFilter = initialState.locationFilter;
    state.isFetchingFaceSheetLabel = initialState.isFetchingFaceSheetLabel;
    state.faceSheetLabelPageSize = initialState.faceSheetLabelPageSize;
    state.selectedRows = initialState.selectedRows;
  },
  setWasSearchButtonClicked: (
    state: BoxDataGrid,
    action: PayloadAction<{ wasSearchButtonClicked: boolean }>
  ) => {
    state.wasSearchButtonClicked = action.payload.wasSearchButtonClicked;
  },
  setIsDownloadingCsv: (state: BoxDataGrid, action: PayloadAction<{ isDownloading: boolean }>) => {
    state.isDownloadingCsv = action.payload.isDownloading;
  },
  reset: (state: BoxDataGrid) => {
    state.allRows = initialState.allRows;
    state.selectedIds = initialState.selectedIds;
    state.alreadyInit = initialState.alreadyInit;
    state.anySubmit = initialState.anySubmit;
    state.currentRows = initialState.currentRows;
    state.descriptionFilter = initialState.descriptionFilter;
    state.isFetchingRows = initialState.isFetchingRows;
    state.isFirstPage = initialState.isFirstPage;
    state.isInvalid = initialState.isInvalid;
    state.errorMessage = initialState.errorMessage;
    state.isSelectedValueInStatusFilter = initialState.isSelectedValueInStatusFilter;
    state.migrationOptions = initialState.migrationOptions;
    state.page = initialState.page;
    state.totalPageChanges = initialState.totalPageChanges;
    state.lastSelectedRows = initialState.lastSelectedRows;
    state.locationFilter = initialState.locationFilter;
    state.isFetchingFaceSheetLabel = initialState.isFetchingFaceSheetLabel;
    state.faceSheetLabelPageSize = initialState.faceSheetLabelPageSize;
  },
  resetBtnSearchWasClicked: (state: BoxDataGrid) => {
    state.wasSearchButtonClicked = false;
  },
  setFilters: (
    state: BoxDataGrid,
    action: PayloadAction<{
      isBtnSearchWasClicked: boolean;
      descriptionFilter: string;
      statusFilter: FilterStatus;
      totalStatusFilterChange: number;
      initialDate: string;
      finalDate: string;
      locationFilter: string;
    }>
  ) => {
    const {
      isBtnSearchWasClicked,
      descriptionFilter,
      statusFilter,
      totalStatusFilterChange,
      initialDate,
      finalDate,
      locationFilter,
    } = action.payload;
    state.anySubmit = false;
    state.wasSearchButtonClicked = isBtnSearchWasClicked;
    state.descriptionFilter = descriptionFilter;
    state.statusFilter = statusFilter;
    state.initialDate = initialDate;
    state.finalDate = finalDate;
    state.locationFilter = locationFilter;

    state.isSelectedValueInStatusFilter = statusFilter === FilterStatus.Selected;
    state.totalStatusFilterChange = totalStatusFilterChange;
  },
  setIsSelectedValueInStatusFilter: (
    state: BoxDataGrid,
    action: PayloadAction<{ value: boolean }>
  ) => {
    state.isSelectedValueInStatusFilter = action.payload.value;
  },
  setAnySubmit: (state: BoxDataGrid, action: PayloadAction<{ value: boolean }>) => {
    state.anySubmit = state.anySubmit || action.payload.value;
  },
  openSolicitConsultationDialog: (state: BoxDataGrid) => {
    state.showSolicitConsultationFormDialog = true;
  },
  closeSolicitConsultationDialog: (state: BoxDataGrid) => {
    state.showSolicitConsultationFormDialog = false;
  },
  openSolicitCancellationDialog: (state: BoxDataGrid) => {
    state.showSolicitCancellationFormDialog = true;
  },
  closeSolicitCancellationDialog: (state: BoxDataGrid) => {
    state.showSolicitCancellationFormDialog = false;
  },
  openSolicitDigitalizationDialog: (state: BoxDataGrid) => {
    state.showSolicitDigitalizationFormDialog = true;
  },
  closeSolicitDigitalizationDialog: (state: BoxDataGrid) => {
    state.showSolicitDigitalizationFormDialog = false;
  },
  openSolicitExclusionDialog: (state: BoxDataGrid) => {
    state.showSolicitExclusionFormDialog = true;
  },
  closeSolicitExclusionDialog: (state: BoxDataGrid) => {
    state.showSolicitExclusionFormDialog = false;
  },
  openSolicitMigrationDialog: (state: BoxDataGrid) => {
    state.showSolicitMigrationFormDialog = true;
  },
  closeSolicitMigrationDialog: (state: BoxDataGrid) => {
    state.showSolicitMigrationFormDialog = false;
  },
  setMigrationOptions(state: BoxDataGrid, action: PayloadAction<MigrationCategory[]>) {
    state.migrationOptions = action.payload;
  },
  setIsFetchingRows(state: BoxDataGrid, action: PayloadAction<boolean>) {
    state.isFetchingRows = action.payload;
  },
  fetchingRowsCompleted(state: BoxDataGrid) {
    state.isFetchingRows = false;
  },
  fetchingRowsIncomplete(state: BoxDataGrid) {
    state.isFetchingRows = true;
  },
  faceSheetLabelCompleted(state: BoxDataGrid) {
    state.isFetchingFaceSheetLabel = true;
  },
  setFaceSheetLabelSizePage(state: BoxDataGrid, action: PayloadAction<PageSize>) {
    state.faceSheetLabelPageSize = action.payload;
  },
  faceSheetLabelIncomplete(state: BoxDataGrid) {
    state.isFetchingFaceSheetLabel = false;
  },

  closeAllDialogs(state: BoxDataGrid) {
    state.showSolicitConsultationFormDialog = false;
    state.showSolicitCancellationFormDialog = false;
    state.showSolicitDigitalizationFormDialog = false;
    state.showSolicitExclusionFormDialog = false;
    state.showSolicitMigrationFormDialog = false;
  },
};

const boxDataGridReducers = {
  ...extraReducers,
  ...genericDataGridReducer,
  ...pagerReducer,
  ...validatorReducer,
  ...RowsSelectorReducer,
};

export default boxDataGridReducers;
