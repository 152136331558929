import { Form } from 'react-bootstrap';
import AddAccordion from '../../../../../../@shared/Accordions/Add';
import BoxClassificationItemAddDialogFormController from '../../../../../../@shared/BoxClassificationItems/Forms/AddDialog/boxClassificationItemAddDialogFormController';
import BoxClassificationItemFormFields from '../../../../../../@shared/BoxClassificationItems/Forms/boxClassificationItemForm.fields';
import Step from '../../../../../../@shared/Steps';
import BoxClassificationItemAccordionItem from '../../boxClassificationItemAccordionItem';
import { BoxClassificationAddFormProps } from '../boxClassificationAddFormSteps.types';
import useBoxClassificationAddFormStep2 from './boxClassificationAddForm.step2.hook';

const BoxClassificationAddFormStep2 = ({
  defaultValue,
  onNext,
  onPrevious,
}: BoxClassificationAddFormProps.Step2) => {
  const { control, formState, fields, handleSubmit, formats } = useBoxClassificationAddFormStep2({
    defaultValue,
    onSubmit: onNext,
  });

  return (
    <AddAccordion value={fields.items} onChange={fields.onItemsChange}>
      <Form onSubmit={handleSubmit} className="h-100">
        <Step>
          <div>
            <AddAccordion.Header title="Itens da Classificação" />
            <AddAccordion.Body>
              {(e, actions, i) => (
                <BoxClassificationItemAccordionItem
                  key={i.toString()}
                  title={e.name}
                  eventKey={i.toString()}
                  onRemove={() => actions.remove(i)}
                >
                  <BoxClassificationItemFormFields
                    formats={formats}
                    prefix={`items[${i}]`}
                    defaultValue={e}
                    type="add"
                    control={control}
                    errors={(formState.errors as any).items?.[i] ?? {}}
                  />
                </BoxClassificationItemAccordionItem>
              )}
            </AddAccordion.Body>
          </div>

          <Step.ButtonGroup>
            <Step.PreviousButton
              onClick={() => onPrevious && onPrevious({ items: fields.items })}
            />
            <Step.NextButton />
          </Step.ButtonGroup>
        </Step>
      </Form>

      <AddAccordion.Dialog>
        {(_, actions) => (
          <BoxClassificationItemAddDialogFormController
            show
            onSuccess={(k) => actions.add(k.successData)}
            onHide={actions.close}
          />
        )}
      </AddAccordion.Dialog>
    </AddAccordion>
  );
};

export default BoxClassificationAddFormStep2;
