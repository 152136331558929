import { HTMLProps, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { Option } from '../../../models';
import { util } from '../../../utils/util';
import createOptions from '../../createOptions';

interface SelectColumnProps extends HTMLProps<HTMLInputElement> {
  id: string;
  control: Control;
  disabled?: boolean;
  options?: Option[];
  value?: string;
  defaultValue?: any;
}

const SelectColumn = ({
  id,
  control,
  disabled,
  options,
  value,
  defaultValue,
}: SelectColumnProps) => {
  const { field } = useController({
    name: id,
    control,
    defaultValue,
  });

  useEffect(() => {
    if (!util.isEqualDeep(field.value, value)) field.onChange(value);
  }, [JSON.stringify(value)]);

  return (
    <th className="column">
      <Form.Select
        value={field.value}
        ref={field.ref}
        name={field.name}
        disabled={disabled}
        onBlur={field.onBlur}
        onChange={field.onChange}
      >
        {options && createOptions(options ?? [], '')}
      </Form.Select>
    </th>
  );
};

export default SelectColumn;
