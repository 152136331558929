/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { BaseSchema } from 'yup';
import { AnyObject, RequiredObjectSchema, TypeOfShape } from 'yup/lib/object';
import yup from '../config/yup/customYup';
import { YupFieldReturn } from '../modules/box/box.models';

import { InputGroup } from './Fields/models';

import factory from './utils/factory';
import { util } from './utils/util';

const useDynamicValidator = (
  dynamicFields: InputGroup[],
  staticFields?: Record<string, YupFieldReturn | BaseSchema<any, AnyObject, any>> | null,
  deps?: React.DependencyList
) => {
  const [schema, setSchema] = useState<RequiredObjectSchema<
    any,
    AnyObject,
    TypeOfShape<any>
  > | null>(null);

  useEffect(() => {
    const d = factory.buildDynamicValidators(dynamicFields);

    const y = !d
      ? yup.object({ ...staticFields }).required()
      : yup
          .object({
            ...staticFields,
            ...d,
          })
          .required();
    if (!util.isEqualDeep(y, schema)) setSchema(y);
  }, deps ?? [(JSON.stringify(staticFields), JSON.stringify(dynamicFields), schema)]);

  return schema;
};

export default useDynamicValidator;
