import { Container } from 'react-bootstrap';
import Layout from '../../layout';
import AdminDataGrid from '../features/AdminDataGrid';

// eslint-disable-next-line arrow-body-style
const AdminListPage = () => {
  return (
    <Layout>
      <Container>
        <AdminDataGrid />
      </Container>
    </Layout>
  );
};

export default AdminListPage;
