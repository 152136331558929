/* eslint-disable @typescript-eslint/no-unused-vars */
import useAuthSlice from '../../../../@shared/Auth/useAuthSlice';
import ConfirmationDialog from '../../../../@shared/Dialogs/confirmationDialog';
import useRequestForm from '../../../../@shared/Forms/RequestForm/useRequestForm';
import passwordValidator from '../../../../@shared/passwordValidator';
import Template from '../../../../@shared/template/template';

import resetPasswordEndpoints from '../../reset-password.endpoints';
import NewPasswordFormPresentation from './newPasswordForm';

const NewPasswordForm = ({ onClickConfirm }: { onClickConfirm: Function }) => {
  const redux = useAuthSlice();
  const authState = redux.useState();

  const form = useRequestForm({
    submitRequest: (obj) => resetPasswordEndpoints.reset(obj),
    validatorSchema: passwordValidator,
  });

  if (
    (authState.twoStepAuthenticator === null || authState.twoStepAuthenticator === undefined) &&
    !form.submitResponse.success
  ) {
    return <Template.Pages.ErrorPage text="É necessário o token de acessos" />;
  }
  if (form.submitResponse.error) {
    return <Template.Pages.ErrorPage text={form.submitResponse.backEndErrors?.message} />;
  }
  if (form.submitResponse.loading) {
    return <Template.Pages.LoadingPage />;
  }
  return (
    <>
      <NewPasswordFormPresentation
        method="post"
        register={form.register}
        control={form.control}
        onSubmit={form.handleSubmit(form.onSubmit)}
        validated={form.validator.validated}
        errors={form.validator.errors}
      />
      <ConfirmationDialog
        show={form.submitResponse.success}
        title="Senha alterada com sucesso"
        bodyText="Nova senha definida com sucesso, agora você sera redirecionado para login"
        handleClose={() => onClickConfirm()}
      />
    </>
  );
};

export default NewPasswordForm;
