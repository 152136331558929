import { useEffect } from 'react';
import authEndpoints from '../../../modules/auth/auth.endpoints';
import useAuthSlice from '../../Auth/useAuthSlice';
import useRequest from '../../Requests/useRequest';
import Template from '../../template/template';
import { TwoStepAuthenticator } from '../../models';

export type VerifyAlreadyDoneTwoStepChildrenType = 'redirects' | 'dialogs';
interface IVerifyAlreadyDoneTwoStep {
  onSuccess: JSX.Element;
}

const RedirectVerifyAlreadyDoneTwoStep = ({ onSuccess }: IVerifyAlreadyDoneTwoStep) => {
  const redux = useAuthSlice();
  const { twoStepAuthenticator } = redux.useState();
  const request = useRequest<TwoStepAuthenticator>();

  useEffect(() => {
    if (twoStepAuthenticator === null) {
      request.fetch(authEndpoints.generateQrCode());
    }
  }, [onSuccess, twoStepAuthenticator]);

  redux.useVerifyAlreadyDoneTwoStep(request, true);

  if (request.error) {
    redux.logout();
    return <Template.Pages.ErrorPage text={request.backEndErrors?.message ?? null} />;
  }

  if (twoStepAuthenticator) {
    return onSuccess;
  }

  return <Template.Pages.LoadingPage />;
};

export default RedirectVerifyAlreadyDoneTwoStep;
