import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

interface ToolBarProps {
  title: string;
  children?: ReactNode;
  className?: string;
}

const ToolBar = ({ title, children, className }: ToolBarProps) => (
  <Card className={`collapsed rounded-bottom-0 border-bottom-0 ${className}`}>
    <Card.Header className="d-flex justify-content-between align-items-center collapsed">
      <Card.Title className="text-center m-0">{title}</Card.Title>
      {children}
    </Card.Header>
  </Card>
);

export default ToolBar;
