import { PayloadAction } from '@reduxjs/toolkit';

export default {
  newToast: (state, action: PayloadAction<{ text: string; show: boolean }>) => {
    state.text = action.payload.text;
    state.show = action.payload.show;
  },
  resetToast: (state) => {
    state.text = '';
    state.show = false;
  },
};
