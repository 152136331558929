import { Stack } from 'react-bootstrap';
import { InputGroup } from '../../../../@shared/Fields/models';

import { Option } from '../../../../@shared/models';

import { BoxLinkProps, OptionCancellationDatePair } from '../../box.models';

import ErrorPage from '../../../../@shared/template/_errorPage';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import SuccessPage from '../../../../@shared/template/_successPage';
import useBoxForm from './boxForm.hook';
import BoxFormPresentation from './boxForm.presentation';

interface BoxAddFormLogicProps {
  top?: (props: BoxLinkProps) => JSX.Element;
  fields: InputGroup[];
  options: {
    classifications: OptionCancellationDatePair[] | null;
    categories: Option[] | null;
  };
  onClassificationChange: (v) => void;
  onCategoryChange: (v) => void;
  errorMessage?: string | null;
  loading?: boolean;
}

const BoxAddForm = ({
  fields,
  options,
  top,
  onClassificationChange,
  onCategoryChange,
  errorMessage,
  loading,
}: BoxAddFormLogicProps) => {
  const form = useBoxForm({
    fields,
    onCategoryChange,
    onClassificationChange,
    optionCancellationDatePair: options.classifications ?? [],
  });
  if (errorMessage) return <ErrorPage text={errorMessage} />;
  if (form.success)
    return (
      <SuccessPage
        text="Registrado com sucesso"
        linkText="voltar para consulta"
        linkUrl="/consult"
      />
    );
  if (loading || form.loading) return <LoadingPage />;
  return (
    <Stack direction="vertical" className="mt-5">
      {top && top({ options })}
      <BoxFormPresentation
        disabled={form.disabled}
        dynamicFields={fields}
        options={options}
        control={form.control}
        className="mt-5"
        method="post"
        register={form.register}
        validated={form.validated}
        errors={form.errors}
        onSubmit={form.handleSubmit}
      />
    </Stack>
  );
};

export default BoxAddForm;
