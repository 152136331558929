import { Control } from 'react-hook-form';
import LoadingPage from '../../../../@shared/template/_loadingPage';
import SuccessPage from '../../../../@shared/template/_successPage';
import { adminPagesUrl } from '../../admin.urls';
import AdminForm from './adminForm';
import useAdminAddForm from './useAdminAddForm';

const AdminAddForm = () => {
  const { control, onSubmit, handleSubmit, validator, submitResponse } = useAdminAddForm();
  if (submitResponse.loading) {
    return <LoadingPage />;
  }
  if (submitResponse.success) {
    return (
      <SuccessPage
        text="Administrador adicionado com sucesso"
        linkText="voltar para tela de listagem administradores"
        linkUrl={adminPagesUrl.baseUrl}
      />
    );
  }
  return (
    <AdminForm
      errors={validator.errors as any}
      onSubmit={handleSubmit(onSubmit)}
      control={control as Control<any, any>}
      register={control.register}
      type="add"
    />
  );
};

export default AdminAddForm;
