import { Col, Row } from 'react-bootstrap';
import { Control, FieldValues } from 'react-hook-form';
import { IDialog } from '../../../../../@shared/Dialogs';
import ConfirmationDialog from '../../../../../@shared/Dialogs/confirmationDialog';
import FormDialog from '../../../../../@shared/Dialogs/formDialog';
import TextField from '../../../../../@shared/Fields/textField';
import { FormPropsOmitChildren } from '../../../../../@shared/Forms/RequestForm';
import { BoxRow, MigrationCategory } from '../../../box.models';
import BoxDescriptionAlert from '../boxDescriptionAlert';
import MigrationRadioButtonGroup from './migrationRadioButtonGroup';

type SolicitationDialogProps = FormPropsOmitChildren & IDialog;

interface ISolicitationDialogProps extends SolicitationDialogProps {
  control: Control<FieldValues, object>;
  options: MigrationCategory[];
  descriptions: BoxRow[];
}

const SolicitMigrationFormDialogPresentation = (props: ISolicitationDialogProps) => {
  const { options, descriptions, register, control, ...pr } = props;
  // eslint-disable-next-line react/destructuring-assignment
  switch (true) {
    case options?.length > 0:
      return (
        <FormDialog {...pr} bodyClassName="dialog-body">
          <Row className="m-0">
            <Col>
              <MigrationRadioButtonGroup
                control={control}
                options={options}
                errorMessage={pr.errors?.migrationType?.message}
                isInvalid={pr.errors?.migrationType != null}
              />
              <TextField
                control={control}
                name="description"
                label="Breve Descrição do movimento"
                id="description"
                as="textarea"
                rows={6}
                errorMessage={pr.errors?.description?.message}
                isInvalid={pr.errors?.description != null}
              />
            </Col>
            <Col sm={6}>
              {descriptions?.map((e: BoxRow) => (
                <BoxDescriptionAlert key={e.boxId} values={e} />
              ))}
            </Col>
          </Row>
        </FormDialog>
      );

    default:
      return (
        <ConfirmationDialog title="Migração não encontrada" onHide={pr.onHide} show={pr.show}>
          <p>Não foi encontrado nenhuma migração possível</p>
        </ConfirmationDialog>
      );
  }
};

export default SolicitMigrationFormDialogPresentation;
