import { Switch } from 'react-router';
import PrivateRoute from '../../@shared/Routes/PrivateRoute';
import ProfileEditPage from './pages/profile';

const baseUrl = '/profile';

const routes = {
  baseUrl,
};

const ProfileRoutes = () => (
  <Switch>
    <PrivateRoute path={routes.baseUrl} exact>
      <ProfileEditPage />
    </PrivateRoute>
  </Switch>
);

export default ProfileRoutes;
