import AlertRedirect from '../Alerts/redirectAlert';

interface ErrorPageProps {
  text: string | null | undefined;
  linkUrl?: string;
  linkText?: string;
  onClickLink?: Function;
  hiddenLink?: boolean;
  className?: string;
}

const ErrorPage = ({
  text,
  hiddenLink,
  linkText,
  linkUrl,
  onClickLink,
  className,
}: ErrorPageProps) => (
  <div className={`w-100 h-100 p-4 ${className}`}>
    <AlertRedirect
      title="Erro"
      text={text ?? 'não definido'}
      onClick={onClickLink}
      linkText={linkText}
      linkUrl={linkUrl}
      hiddenLink={hiddenLink}
      variant="danger"
    />
  </div>
);

export default ErrorPage;
